import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { base64ToFile, Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-resize-image',
  templateUrl: './resize-image.component.html',
  styleUrls: ['./resize-image.component.scss']
})
export class ResizeImageComponent implements OnInit {

  @Input() type: string = 'event';
  @Input() imageChangedEvent;
  @Input() aspectRatio: number;
  @Input() canvasRotation;
  @Input() transform;
  @Input() showCropper = false;
  @Input() containWithinAspectRatio = false;
  @Input() imageURL;
  @Input() file;
  @Output() getImage = new EventEmitter<any>();

  mediaTypes = ['event', 'file', 'url'];
  constructor(
    private dialog: MatDialog,
    public dialModalRef: MatDialogRef<ResizeImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private formBuilder: RxFormBuilder
  ) {
    this.imageChangedEvent = data.imageChangedEvent ?? this.imageChangedEvent;
    this.aspectRatio = data.aspectRatio ?? this.aspectRatio;
    this.canvasRotation = data.canvasRotation ?? this.canvasRotation;
    this.transform = data.transform ?? this.transform;
    this.showCropper = data.showCropper ?? this.showCropper;
    this.containWithinAspectRatio = data.containWithinAspectRatio ?? this.containWithinAspectRatio;
    this.imageURL = data.imageURL ?? this.imageURL;
    this.file = data.file ?? this.file;
    this.getImage = data.getImage ?? this.getImage;
    this.type = data.type ?? this.type;
  }
  croppedImage: any = '';
  convertedFile: any;
  scale = 1;
  rotation = 0;
  loading = false;
  aspectRatioInput = 1 / 1;
  frm: FormGroup;
  ngOnInit() {
    this.frm = this.formBuilder.group({
      ratio: [RxwebValidators.numeric],
    });
    this.frm.controls['ratio'].setValue(1);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.domSanitizer.bypassSecurityTrustUrl(event.base64);
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
    this.convertedFile = base64ToFile(this.croppedImage);
    // get image:
    this.getImage.emit(this.convertedFile);

  }
  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }
  closeDialog(value: boolean) {
    // reset the crop:
    if (!value) {
      this.imageChangedEvent = null;
      this.getImage.emit(null);
    }
    this.dialModalRef.close(value);
  }
  calRatio() {
    let data = this.frm.controls.ratio.value;
    if (data.includes('/')) {
      let ar = data.trim().split('/');
      if (ar.length > 1) {
        ar = ar.map(x => x.trim());
        // array: 16/9
        let firstNum = Helper.roughScale(ar[0], 10);
        let secondNum = Helper.roughScale(ar[1], 10);
        if (secondNum == 0) this.frm.controls['ratio'].setValue(1);
        this.frm.controls.ratio.setValue(firstNum / secondNum);
      }
    } else if (data.includes(':')) {
      let ar = data.split(':');
      if (ar.length > 1) {
        ar = ar.map(x => x.trim());
        let firstNum = Helper.roughScale(ar[0], 10);
        let secondNum = Helper.roughScale(ar[1], 10);
        if (secondNum == 0) this.frm.controls['ratio'].setValue(1);
        this.frm.controls.ratio.setValue(firstNum / secondNum);
      }
    }
  }
}
