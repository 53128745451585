import { SaleLeadService } from './../sale-lead-management/sale-lead.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { Contact, Location, ProfileDetailModel } from '../profile-management/profile-detail.model';
import { Page } from 'src/app/shared/models/paging/page';
import { ProfileModel } from '../profile-management/profile-model';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { SaleLeadsViewModel } from '../sale-lead-management/add-sale-leads.model';
import { timeout } from 'rxjs/operators';
import { SaleAccountViewModel } from './add-sale-acount.model';
import { AltusLocation } from 'src/app/shared/components/stand-alone-component/location/location';

@Injectable({
  providedIn: 'root'
})
export class SaleAccountService {

  baseUrl = environment.apiSaleAccount;
  saleAccountDetail$: ReplaySubject<ReturnResult<ProfileDetailModel>> = new ReplaySubject<ReturnResult<ProfileDetailModel>>(1);
  isRefreshSaleGrid$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  searchSaleAccount$: ReplaySubject<ReturnResult<ProfileDetailModel[]>> = new ReplaySubject<ReturnResult<ProfileDetailModel[]>>(1);
  cacheSaleAccounts: string[] = [];

  constructor(private http: HttpClient) {
  }

  getSaleAccountPaging(page: Page): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.baseUrl}/get`,
      page);
  }

  saveSaleAccount(model: SaleAccountViewModel) {
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/SaveSaleAccount`, model);
  }

  refreshSaleAccountById(id: string): Observable<ReturnResult<ProfileDetailModel>> {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.baseUrl}/SaleAccount?id=${id}`)
      .pipe(tap(resp => this.saleAccountDetail$.next(resp)));
  }

  getSaleAccountById(): Observable<ReturnResult<ProfileDetailModel>> {
    return this.saleAccountDetail$.asObservable();
  }

  importProfile(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/import`, formData).pipe(
      timeout(1200000)
    );
  }

  exportProfile(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/export`, page);
  }

  saveArchivedSaleLead(model: SaleLeadsViewModel) {
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.baseUrl}/SaveArchivedSaleLeads`, model);
  }
  exportArchivedSaleLeads(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ExportArchiveSaleLeads`, page);
  }
  archivesMany(ids: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ArchivesMany`, ids);
  }
  unArchivesMany(ids: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UnArchiveMany`, ids);
  }

  editSaleAccount(model: { id: string, saleAccountModel?: ProfileDetailModel, contactModel?: Contact, locationModel?: Location }): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaleAccount/Update?id=${model.id}`, model)
      .pipe(tap(resp => this.isRefreshSaleGrid$.next(resp.result)));
  }

  getSearchSaleAccount() {
    return this.searchSaleAccount$.asObservable();
  }

  searchSaleAccount(keyword: string): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/Search?keyword=${keyword}`)
      .pipe(tap(resp => this.searchSaleAccount$.next(resp)));
  }

  editSaleAccountProperties(saleAccountId: string, contactId: number, properties: {}) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaleAccount/Update/Properties?saleAccountId=${saleAccountId}&contactId=${contactId}`, properties)
      .pipe(tap(resp => this.isRefreshSaleGrid$.next(resp.result)));
  }

  refreshSaleAccountRelationship(actGroupId: string): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/SaleAccountRelationship?actGroupId=${actGroupId}`);
  }

  pushIdToCacheSaleAccount(id: string) {
    if (id && this.cacheSaleAccounts)
      this.cacheSaleAccounts.push(id);
  }

  getLastIdCacheSaleAccount(): string {
    if (this.cacheSaleAccounts && this.cacheSaleAccounts.length > 0)
      return this.cacheSaleAccounts.pop();
  }
  // 2022-10-07 tienlm add start

  GetSaleOpportunityPagingBySaleAccountId(id: string, page: Page): Observable<ReturnResult<PagedData<ProfileDetailModel[]>>> {
    return this.http.post<ReturnResult<PagedData<ProfileDetailModel[]>>>(`${this.baseUrl}/GetSaleOpportunityPagingBySaleAccountId/${id}`, page);
  }
  // 2022-10-07 tienlm add end
  deleteProfileByFilter(model) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAllAsync`, model);
  }
  suggestRecentlyAddedSaleAccount(id: string = null): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/SuggestRecentlyModifySaleAccount${id ? '?id=' + id : ''}`);
  }

  getResellerPaging(id: string, page: Page): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.baseUrl}/GetResellersById/${id}`, page);
  }

  addEditResellerOrRelationship(parentId: string, childId: string, type: string, isRemove: boolean = false): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddEdit${type}`, { parentId, childId, isRemove })
      .pipe(tap(resp => this.isRefreshSaleGrid$.next(resp.result)));
  }

  changePrimaryResellerOrRelationship(parentId: string, childId: string, type: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ChangePrimary${type}`, { parentId, childId })
      .pipe(tap(resp => this.isRefreshSaleGrid$.next(resp.result)));
  }

  getRelationshipPaging(id: string, page: Page): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.baseUrl}/GetRelationshipsById/${id}`, page);
  }
  SearchSaleAccountByDisplayName(displayName: string): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/SearchSaleAccountByDisplayName?displayName=${displayName}`);
  }

  parentAccountExisting(): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/ParentAccountExisting`);
  }

  bulkAddTag(page: Page, tags: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAddTags?tags=${tags}`, page);
  }

  getVitalsHistory(id: string, page: Page): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${environment.apiVitalIVRKeyPair}/GetVitalsHistory/${id}`, page);
  }

  getLastVitalSign(id: string): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${environment.apiVitalIVRKeyPair}/GetLastestVital/${id}`);
  }
  getPagingScheduleGroupPaging(id: string, page: Page): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${environment.apiVitalIVRKeyPair}/GetCallerInfoById/${id}`, page);
  }

  exportPatient(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/exportPatient`, page);
  }

  addNewPatient(data: string): Observable<ReturnResult<string>> {
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/AddNewPatient`, { data });
  }

  importVitalHistotry(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${environment.apiVitalIVRKeyPair}/import`, formData).pipe(
      timeout(1200000)
    );
  }
}
