import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith } from 'rxjs/operators';
import { TagService } from 'src/app/shared/components/stand-alone-component/auto-complete-tag/tag.service';
import { ReturnResult } from '../../models/return-result';
import { Tags } from '../stand-alone-component/auto-complete-tag/tag.model';

@Component({
  selector: 'app-dialog-add-tag',
  templateUrl: './dialog-add-tag.component.html',
  styleUrls: ['./dialog-add-tag.component.scss']
})
export class DialogAddTagComponent implements OnInit, AfterViewInit {

  tagsFrm: FormControl = new FormControl('', [
    RxwebValidators.required({ message: "This field is required" }),
  ]);

  tags: Tags[] = [];
  filteredTag: Observable<string[]>;

  constructor(
    public dialModalRef: MatDialogRef<DialogAddTagComponent>,
    public tagService: TagService
  ) { }

  ngOnInit(): void {
    this.tagService.getTags().subscribe((x: any) => {
      if (x.result) this.tags = x.result
    });
  }

  ngAfterViewInit(): void {
    this.filteredTag = this.tagsFrm.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      pairwise(),
      map(([pre, cur]) => {
        var checkSelect = this.tags.findIndex(i => i.tagsName == cur) > -1;
        if (checkSelect && pre != `${cur},`) {
          var splitChar: string[] = pre.split(",");
          splitChar = splitChar.map((x, i) => {
            x = x.trim();
            if (i == splitChar.length - 1)
              x = cur;
            return x;
          });

          this.tagsFrm.setValue(splitChar.join(", ").trim());
        }

        return cur;
      }),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    var lastValue = '';
    if (value && value.length > 0) {
      var splitTags = value.slice().split(",");
      if (splitTags && splitTags.length > 1)
        lastValue = splitTags.pop();
      else lastValue = splitTags.shift();
    }

    if (!lastValue) lastValue = value;
    const filterValue = lastValue.toLowerCase().trim();
    return this.tags.map(x => x.tagsName || '').filter(x => x.toLowerCase().includes(filterValue));
  }
}
