<!-- <mat-form-field>
    <mat-label>{{columnName}}</mat-label>
    <input matInput [ngxMatDatetimePicker]="picker" [formControl]="datetime" (dateChange)="onClickSubmit()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker>
      <ng-template>
        <span>OK</span>
      </ng-template>
    </ngx-mat-datetime-picker>
  </mat-form-field> -->
<div [ngSwitch]="operationMode">
  <mat-form-field *ngSwitchDefault [appearance]="appearance">
    <mat-label>{{columnName}}</mat-label>
    <input matInput [matDatepicker]="datepicker" [formControl]="datetime" (dateChange)="onClickSubmit()">
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker>
      <mat-datepicker-actions>
        <button mat-stroked-button matDatepickerApply>OK</button>
      </mat-datepicker-actions>
    </mat-datepicker>
  </mat-form-field>
  <mat-form-field *ngSwitchCase="'Between'" [appearance]="appearance">
    <mat-label>{{columnName}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" (dateChange)="onClickDateRangeSubmit()" (dateInput)="checkData()">
      <input matEndDate formControlName="end" (dateChange)="onClickDateRangeSubmit()"  (dateInput)="checkData()">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
      <mat-datepicker-actions>
        <button mat-stroked-button matDatepickerApply (click)="onClickDateRangeSubmit()">OK</button>
      </mat-datepicker-actions>
    </mat-date-range-picker>
    <mat-error *ngIf="range.controls.start.hasError('matErrorDateInvalid')">End date cannot be earlier than start date</mat-error>
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
  <mat-form-field *ngSwitchCase="'Quarter'" [appearance]="appearance">
    <mat-label>{{columnName}}</mat-label>
    <mat-select #quarterDateFilter [formControl]="datetime" multiple  (openedChange)="onClickDropdown($event)">
      <mat-option *ngFor="let quarter of quarterList" [value]="quarter.value">{{quarter.text}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>



<!-- <span>
    {{columnName}}
    <button mat-icon-button [nbPopover]="templateRef" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
      (click)="toggleDatetimePop()">
      <mat-icon fontSet="material-icons-outlined">filter_alt</mat-icon>
    </button>
    <ng-template #templateRef>
      <nb-card class="mb-0" style="width: 217px;border: unset;">
        <nb-card-body>
          <div class="row">
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="fromPicker" placeholder="From Date-time" [formControl]="from">
              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #fromPicker>
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="toPicker" placeholder="To Date-time" [formControl]="to">
              <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #toPicker>
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" mat-button (click)="toggleDatetimePop()">
              Cancel
            </button>
            <button mat-raised-button color="primary" (click)="onClickSubmit()">
              Ok
            </button>
          </div>

        </nb-card-body>

      </nb-card>
    </ng-template>
  </span> -->
