import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ChangeLog } from '../models/request/change-log.model';
import { ReturnResult } from '../models/return-result';

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {
  baseUrl = environment.changeLogUrl;
  constructor(private http: HttpClient) { }
  getChangeLogsByUserId(): Observable<ReturnResult<ChangeLog[]>> { 
    return this.http.get<ReturnResult<ChangeLog[]>>(`${this.baseUrl}/GetChangeLogsByUserId`);
  }
  markReadChangeLog(changelogs: ChangeLog[]): Observable<ReturnResult<boolean>>{
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MarkReadChangeLog`,changelogs);
  }
  getAllChangeLogs(isShowChangeLogs:boolean): Observable<ReturnResult<ChangeLog[]>> {
    return this.http.get<ReturnResult<ChangeLog[]>>(`${this.baseUrl}/GetAllChangeLogs?isShowChangeLogs=${isShowChangeLogs}`);
  }
  getChangelogPaging(page: Page): Observable<ReturnResult<PagedData<ChangeLog>>> {
    return this.http.post<ReturnResult<PagedData<ChangeLog>>>(`${this.baseUrl}/GetChangelogPaging`, page);
  }
  saveChangelog(changelog: ChangeLog): Observable<ReturnResult<boolean>>{
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveChangeLog`,changelog);
  }
  deleteChangelog(changelogId: number): Observable<ReturnResult<boolean>>{
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/DeleteChangelog/${changelogId}`);
  }
  deleteRanges(ids: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteRangeChangelogs`, ids);
  }

}
