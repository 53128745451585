<ng-container *ngIf="!isLoading;else bodyLoading">

  <h1 *ngIf="!showMoreInformation" mat-dialog-title align="center">Your {{environment.titleLead}} has been converted
  </h1>
  <h1 *ngIf="showMoreInformation" mat-dialog-title align="center">Convert History</h1>

  <mat-dialog-content class="no-scroll">
    <div *ngIf="!showMoreInformation" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/images/success.gif" alt="" width="50">
    </div>
    <ng-container *ngIf="showMoreInformation && saleLeadName && saleLeadId">
      <div class="alert alert-success" role="alert">
        This result is converted from {{environment.titleLead}} <b class="pointer"
          (click)="openConvertedLead()">{{saleLeadName |
          autoPlaceholder:
          'Unknown'}}</b>
      </div>
    </ng-container>
    <div>
      <mat-grid-list [(cols)]="actualCol" rowHeight="350px">
        <!-- New Account -->
        <mat-grid-tile *ngIf="model && model.toAccountId">
          <ng-container *ngIf="model && model.toAccountId">
            <div fxLayout="column" fxLayoutAlign="start center">
              <h2>{{model.isNewAccount? 'NEW' : 'EXISTED'}} {{environment.titleAccount | uppercase}}</h2>
              <nb-card style="height: 100% !important; padding-bottom: 10px !important;width: 100% !important;">
                <nb-card-header fxLayout="row" fxLayoutAlign="space-between center">
                  <span [matTooltip]="environment.titleAccount + ' Name'" class="pointer"
                    (click)="navigateEntity('sale-account',model.saleAccount?.profileId)">
                    {{model.saleAccount?.displayName | autoPlaceholder: 'No Name'}}
                  </span>
                </nb-card-header>
                <nb-card-body>
                  <div class="col-12">
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">call</mat-icon>
                      <span><strong>Phone:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        {{(model.saleAccount.saleLeadsContact.contactPhone | phoneFormat) || 'Unknown'}}
                      </span>
                    </div>
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">email</mat-icon>
                      <span><strong>Mail:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        {{model.saleAccount.saleLeadsContact?.contactEmail || 'No mail provided'}}
                      </span>
                    </div>
                    <!-- blank -->
                    <ng-container [ngTemplateOutlet]="blankRow">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="blankRow">
                    </ng-container>
                  </div>
                </nb-card-body>
                <nb-card-footer fxLayout="row" [fxLayoutAlign]="accountOwner? 'space-between center': 'end center'">
                  <ng-container *ngIf="model.saleAccount && accountOwner">
                    <span matTooltip="Owner">
                      <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true"
                        [picture]="helper.userURL(accountOwner?.pictureURL)"
                        [name]="accountOwner.userName || 'Unknown'">
                      </nb-user>
                      {{accountOwner.userName}}
                    </span>
                  </ng-container>
                  <span class="float-right"
                    [matTooltip]="'Date created this ' + (environment.titleAccount | lowercase)">
                    <ng-container
                      *ngIf="model.saleAccount && model.saleAccount.datecreated && helper.isDateString(model.saleAccount.datecreated); else noDate">
                      {{model.saleAccount.datecreated | date:'short'}}
                    </ng-container>
                  </span>
                </nb-card-footer>
              </nb-card>
            </div>
          </ng-container>
        </mat-grid-tile>
        <!-- NEW CONTACT -->
        <mat-grid-tile *ngIf="model && model.toContactId > 0">
          <ng-container *ngIf="model && model.toContactId > 0">
            <div fxLayout="column" fxLayoutAlign="start center">
              <h2>{{model.isNewContact? 'NEW' : 'EXISTED'}} CONTACT</h2>
              <nb-card style="height: 100% !important; padding-bottom: 10px !important;width: 100% !important;">
                <nb-card-header fxLayout="row" fxLayoutAlign="space-between center">
                  <span matTooltip="Contact Name" class="pointer"
                    (click)="navigateEntity('contact',model.saleContact?.contactId)"
                    *ngIf="model.saleContact?.contactName || model.saleContact?.contactLastName ;else noName">
                    {{model.saleContact?.contactName ?? ''}} {{ model.saleContact?.contactLastName?? ''}}
                  </span>
                  <ng-template #noName>
                    <span>
                      No Contact Name
                    </span>
                  </ng-template>
                </nb-card-header>
                <nb-card-body>
                  <div class="col-12">
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">call</mat-icon>
                      <span><strong>Phone:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        {{(model.saleContact?.contactPhone | phoneFormat) || 'Unknown'}}
                      </span>
                    </div>
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">email</mat-icon>
                      <span><strong>Mail:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        {{model.saleContact?.contactEmail || 'No mail provided'}}
                      </span>
                    </div>
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">phone_iphone</mat-icon>
                      <span><strong>Mobile:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        {{((model.saleContact?.cellPhone ? model.saleContact?.cellPhone :
                        model.saleContact?.secondaryPhone) | phoneFormat) ||
                        'Unknown'}}
                      </span>
                    </div>
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">location_on</mat-icon>
                      <span><strong>Country:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        {{model.saleContact?.location? model.saleContact?.location.country?? 'Unknown' : 'Unknown'}}
                      </span>
                    </div>
                  </div>
                </nb-card-body>
                <nb-card-footer fxLayout="row" [fxLayoutAlign]="contactOwner? 'space-between center': 'end center'"
                  style="min-height: 57px;">
                  <ng-container *ngIf="model.saleContact && contactOwner">
                    <span matTooltip="owner">
                      <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true"
                        [picture]="helper.userURL(contactOwner?.pictureURL)"
                        [name]="contactOwner.userName || 'Unknown'">
                      </nb-user>
                      {{contactOwner.userName}}
                    </span>
                  </ng-container>
                  <span class="float-right" matTooltip="Date created this contact">
                    <ng-container
                      *ngIf="model.saleContact && model.saleContact.dateCreated && helper.isDateString(model.saleContact.dateCreated); else noDate">
                      {{model.saleContact.dateCreated | date:'short'}}
                    </ng-container>
                  </span>
                </nb-card-footer>
              </nb-card>
            </div>
          </ng-container>
        </mat-grid-tile>
        <!-- OPPORTUNITY -->
        <mat-grid-tile *ngIf="model && model.toOpportunityId">
          <ng-container *ngIf="model && model.toOpportunityId">
            <div fxLayout="column" fxLayoutAlign="start center">
              <h2 class="limit-line" style="--line: 1">{{environment.titleOpportunity | uppercase}}</h2>
              <nb-card style="height: 100% !important; padding-bottom: 10px !important;width: 100% !important;">
                <nb-card-header fxLayout="row" fxLayoutAlign="space-between center">
                  <span [matTooltip]="environment.titleOpportunity + ' Name'" class="pointer"
                    (click)="navigateEntity('opportunity',model.saleOpportunityProfile?.profileId)">
                    {{model.saleOpportunityProfile?.saleOpportunity?.opportunityName | autoPlaceholder: 'No Name'}}
                  </span>
                </nb-card-header>
                <nb-card-body>
                  <div class="col-12">
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">event_busy</mat-icon>
                      <span><strong>Closed Date:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        <ng-container
                          *ngIf="model.saleOpportunityProfile?.saleOpportunity?.closeDate && helper.isDateString(model.saleOpportunityProfile?.saleOpportunity?.closeDate.toString());else noDate">
                          {{model.saleOpportunityProfile?.saleOpportunity?.closeDate | date : 'M/dd/y'}}
                        </ng-container>
                      </span>
                    </div>
                    <div class="row flex-nowrap">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">numbers</mat-icon>
                      <div><strong>Estimate:</strong></div>
                      <div class="currency-color" style="padding-left:10px;">{{currency()}}</div>
                      {{(model.saleOpportunityProfile?.saleOpportunity?.amount || '0') | numberFormat}}
                    </div>
                    <div class="row flex-nowrap" style="visibility: hidden;">
                      <mat-icon class="middle-align material-icons-outlined contact-icon">manage_accounts</mat-icon>
                      <span><strong>{{environment.titleAccount}} Name:</strong></span>
                      <span style="padding-left:10px;" class="wrap-line">
                        {{model.saleOpportunityProfile?.saleOpportunity?.accountName || 'Unknown'}}
                      </span>
                    </div>
                    <!-- blank -->
                    <ng-container [ngTemplateOutlet]="blankRow">
                    </ng-container>
                  </div>
                </nb-card-body>
                <nb-card-footer fxLayout="row" [fxLayoutAlign]="opportunityOwner? 'space-between center': 'end center'">
                  <ng-container *ngIf="model.saleOpportunityProfile && opportunityOwner">
                    <span matTooltip="Owner">
                      <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true"
                        [picture]="opportunityOwner?.pictureURL" [name]="opportunityOwner.userName || 'Unknown'">
                      </nb-user>
                      {{opportunityOwner.userName}}
                    </span>
                  </ng-container>
                  <span class="float-right"
                    [matTooltip]="'Date created this ' + (environment.titleOpportunity | lowercase)">
                    <ng-container
                      *ngIf="model.saleOpportunityProfile && model.saleOpportunityProfile.datecreated && helper.isDateString(model.saleOpportunityProfile.datecreated); else noDate">
                      {{model.saleOpportunityProfile.datecreated | date:'short'}}
                    </ng-container>
                  </span>
                </nb-card-footer>
              </nb-card>
            </div>
          </ng-container>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center" style="background-color: white;">
      <button mat-raised-button color="primary" class="float-right" mat-dialog-close>
        OK
      </button>
    </mat-toolbar>
  </div>

</ng-container>


<ng-template #bodyLoading>
  <ngx-skeleton-loader count="15" appearance="line"></ngx-skeleton-loader>
</ng-template>

<ng-template #noDate>
  <span>Unknown</span>
</ng-template>

<ng-template #blankRow>
  <div class="row" style="visibility: hidden;">
    <mat-icon class="middle-align material-icons-outlined contact-icon">email</mat-icon>
    <span><strong>Mail:</strong></span>
    <span style="padding-left:10px;">
      blank
    </span>
  </div>
</ng-template>