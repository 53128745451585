<div [hidden]="!isReview" mat-dialog-title>Campaign Information</div>
<mat-dialog-content [hidden]="!isReview">
    <div *ngIf="primasTable">
        <div class="alert alert-warning" role="alert"
            *ngIf="primasTable.table && primasTable.table.rowCount == 0 && primasTable.isLoading == 0">
            The email contact is empty. You should back to the previous step and select at least 1 email contact
        </div>
        <div class="alert alert-success" role="alert" *ngIf="reviewData && reviewData.contactUseSecondaryEmails && reviewData.contactUseSecondaryEmails.length > 0">
            We have {{reviewData.contactUseSecondaryEmails.length}} contact use the secondary email because it have no primary email.   
            <a class="exportInvalidContact" (click)="exportContactUseSecondaryEmail()">Click here</a> to download. 
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="reviewData && reviewData.inValids.length > 0">
            We have {{reviewData.inValids.length}} duplicated or has wrong format contacts that was filtered out
            automatically.
            <a class="exportInvalidContact" (click)="exportInvalidContact()">Click here</a> to download.
            <br>
            <span *ngIf="!rowSuccessAddTag; else afterAddTag">
                <a class="exportInvalidContact" (click)="addTags()">Do you wish to add tags for these invalid emails?</a>
            </span>
            <ng-template #afterAddTag>
                <span [nbSpinner]="rowSuccessAddTag == -1">
                    Current tag(s): <b>{{tagsAdd}}</b> success
                    {{rowSuccessAddTag >= 0? rowSuccessAddTag :0}}/
                    {{reviewData.inValids.length}}.
                    Do you wish to <a class="exportInvalidContact" (click)="addTags()">add more?</a>
                </span>
            </ng-template>
        </div>
    </div>
    <app-primas-toolbar (refreshData)="refreshData($event)" [table]="primasTable" [resource]="resource.toolbar"
        [isEnablecustomButtonFunction]="true" [customSelectsFunction]="customAction">
    </app-primas-toolbar>

    <app-primas-table #primasTable (onRefresh)="refreshData($event)" [columnsTable]="columns"
        [resource]="resource.table" [allowSelectRow]="false" [externalSorting]="false" [tableAction]="false"
        [optionHeight]="'45vh'">
    </app-primas-table>
</mat-dialog-content>

<app-create-new-campaign [hidden]="isReview" [stepper]="true" (onBack)="isReview = $event">
</app-create-new-campaign>

<mat-dialog-actions align="end" [hidden]="!isReview">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" class="float-right" (click)="isReview = false" [disabled]="primasTable.table && primasTable.table.rowCount == 0 && primasTable.isLoading == 0">Next</button>
</mat-dialog-actions>

<ng-template #customAction>
    <h6>Preview valid email contact(s)</h6>
</ng-template>