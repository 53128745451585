import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { fromEvent, observable, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ReturnResult } from 'src/app/shared/models/return-result';

@Component({
  selector: 'app-primas-auto-complete',
  exportAs: 'primasAutoComplete',
  templateUrl: './primas-auto-complete.component.html',
  styleUrls: ['./primas-auto-complete.component.scss']
})
export class PrimasAutoCompleteComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild(MatAutocomplete) public autocomplete: MatAutocomplete;
  @Input() input: any;
  // search prop is value to search:
  @Input() searchProperty: string;
  // key prop to display mat option tag
  @Input() keyProperty: string;
  // value prop to get the value when click:
  @Input() valueProperty: string;

  // Required:
  @Input() searchAPI: Observable<ReturnResult<any[]>>;

  // output:

  @Output() toggleLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();

  filterObservable: Observable<any[]>;
  private destroy$: Subject<void> = new Subject<void>();
  searchArray: any[] = [];
  checkBindingKeyup: Subscription;
  dirtyCheck: boolean = false;
  constructor() { }
  ngAfterViewInit(): void {
    if (this.input) {
      fromEvent(this.input, "focus").subscribe({
        next: e => {
          if (!this.dirtyCheck) {
            this.filterObservable = this.getData('');
            this.dirtyCheck = true;
            if (this.input) {
              // let isTrigger = this.input instanceof MatAutocompleteTrigger ? true : false;
              // debugger;
              // this.input?.openPanel();
            }
          }
        }
      })
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    let isInput = changes.input;
    if (isInput && !isInput.firstChange) {
      this._bindingSearch();
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    if (this.input) {
      this._bindingSearch();
    }
  }
  getData(searchInput: string): Observable<any[]> {
    if (this.searchAPI) {
      this.toggleLoading.emit(true);
      return this.searchAPI.pipe(takeUntil(this.destroy$), map(x => {
        if (x.result) {
          this.searchArray = x.result;
          this.toggleLoading.emit(false);
          if (searchInput === '') {
            return x.result.slice();
          } else {
            return this._filter(searchInput.toLowerCase().trim(), x.result);
          }
        } else {
          this.toggleLoading.emit(false);
          return [];
        }
      }));
    } else {
      return null;
    }
  }
  private _filter(searchInput: string, filterArray: any[]) {
    if (searchInput || searchInput === '') {
      return filterArray.filter(x => x[this.searchProperty]?.toLowerCase().trim().includes(searchInput))
    } else {
      return filterArray.slice();
    }
  }
  private _bindingSearch() {
    if (this.checkBindingKeyup) {
      this.checkBindingKeyup.unsubscribe();
    }
    this.checkBindingKeyup = fromEvent(this.input, "keyup").pipe(
      map((event: any) => {
        return event.target.value ?? '';
      }),
      debounceTime(300),
    ).subscribe((keyword: string) => {
      this.filterObservable = this.getData(keyword ?? '');
    });
  }
  choiceSelected(optionSelected: MatAutocompleteSelectedEvent) {
    if (this.onSelected.observers.length > 0) {
      this.onSelected.emit(optionSelected.option.value);
    }
  }
}
