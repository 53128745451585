import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private numPipe: NumberFormatPipe){

  }
  transform(value) {
    const getCurrency = localStorage.getItem('currency');
    return `<div class="currency-color">${getCurrency ?? ''}<span class="primary-currency-color" >${value ? this.numPipe.transform(value): 0}</span></div>`;
  }

}
