<mat-form-field class="example-chip-list" appearance="legacy">
    <mat-label>Tags</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip
        *ngFor="let tag of contactTagList"
        (removed)="remove(tag)" style="background-color: #307abd;color: white;">
        {{tag}}
        <mat-icon matChipRemove>cancel</mat-icon>

      </mat-chip>
      <input
        placeholder="Select or typing tags"
        #fruitInput
        [formControl]="fruitCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayProperty">
      <mat-option *ngFor="let data of tagList" [value]="data" >
        {{data}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>