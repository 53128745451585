<mat-form-field style="width: 22px;" color="primary" class="without-arrow hidden-line">
    <mat-select (keydown.enter)="$event.stopImmediatePropagation();" [(ngModel)]="selectedFilter"
        (selectionChange)="onChangeFilterOperator($event.value)" [value]="selectedFilter">
        <mat-option *ngFor="let operator of filterOperators" [value]="operator" style="font-size: 14px;">
            {{operator}}
        </mat-option>
    </mat-select>
    <button matPrefix mat-icon-button>
        <mat-icon style="font-size: 24px;" fontSet="material-icons-outlined">filter_alt</mat-icon>
    </button>
</mat-form-field>