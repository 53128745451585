<form class="form-container" [formGroup]="convertSaleLeadInputForm" mat-dialog-content>
  <mat-grid-list cols="2" [rowHeight]="dynamicRowHeight" [gutterSize]="'5px'">
    <!-- Account -->
    <mat-grid-tile [colspan]="1" [rowspan]="4">
      <nb-card style="height: 100% !important; padding-bottom: 10px !important">
        <nb-card-body fxLayout="column" fxLayoutAlign="start start" style="overflow-y: hidden">
          <!-- create new radio -->
          <mat-checkbox color="primary" formControlName="isNewAccount"
            (change)="inputAccount && $event.checked ? inputAccount.focus() : ''"
            [matTooltip]="'Check this radio if you want to create new ' + environment.titleAccount">
            Create new
          </mat-checkbox>
          <div style="font-weight: 600;">New {{environment.titleAccount}}</div>
          <mat-form-field appearance="standard">
            <mat-label>{{environment.titleAccount}} Name</mat-label>
            <input #inputAccount matInput autocomplete="off" formControlName="newAccountName"
              [readonly]="!convertSaleLeadInputForm.controls.isNewAccount.value" trim="blur" />
            <!-- validate -->
            <mat-error
              *ngIf="convertSaleLeadInputForm.controls.newAccountName.errors && (convertSaleLeadInputForm.controls.newAccountName.dirty || convertSaleLeadInputForm.controls.newAccountName.touched)">
              {{convertSaleLeadInputForm.controls.newAccountName['errorMessage']}}</mat-error>
          </mat-form-field>
        </nb-card-body>
      </nb-card>
    </mat-grid-tile>


    <!-- Add exist -->
    <mat-grid-tile [colspan]="1" [rowspan]="4">
      <nb-card style="height: 100% !important; padding-bottom: 10px !important">
        <nb-card-body fxLayout="column" fxLayoutAlign="start start" style="overflow-y: hidden">
          <!-- create new radio -->
          <mat-checkbox color="primary" [checked]="!convertSaleLeadInputForm.controls.isNewAccount.value"
            (change)="onToggleCheckedChanged($event,'isNewAccount')"
            [matTooltip]="'Check this radio if you want to add exist ' + environment.titleAccount">Existed</mat-checkbox>
          <!-- Show Account Chosen or search -->
          <ng-container
            *ngIf="!this.convertSaleLeadInputForm.controls.saleAccountProfileModel.value; else existedSaleAccount">
            <div style="font-weight: 600;visibility: hidden;">New {{environment.titleAccount}}</div>
            <app-profile-chosen style="width:100%;padding-top: 11.25px;"
              (profileSelected)="getSaleAccountSelected($event)"
              [apiGet]="apiGetSearchRelationship ? apiGetSearchRelationship : null"
              [placeholder]="placeholderSearch ? placeholderSearch : null" [isOneLineResult]="true">
            </app-profile-chosen>
            <ng-container [ngTemplateOutlet]="recommendBlock" [ngTemplateOutletContext]="{
              inputTem: recommendAccount,
              count: recommendConvert?.recommendSaleAccounts ?? 0,
              objName: environment.titleAccount
            }"></ng-container>
            <input #recommendAccount="matAutocompleteTrigger" matInput style="width: 100%; height: 0;"
              [matAutocomplete]="autocompleteRecommendAccount">
          </ng-container>
        </nb-card-body>
      </nb-card>
    </mat-grid-tile>


    <!-- CONTACT -->
    <mat-grid-tile [colspan]="1" [rowspan]="4">
      <nb-card style="height: 100% !important; padding-bottom: 10px !important">
        <nb-card-body fxLayout="column" fxLayoutAlign="start start">
          <!-- create new radio -->
          <mat-checkbox color="primary" formControlName="isNewContact"
            matTooltip="Check this radio if you want to create new contact">Create new</mat-checkbox>
          <div style="font-weight: 600;">New Contact</div>
        </nb-card-body>
      </nb-card>
    </mat-grid-tile>

    <!-- Exist -->
    <mat-grid-tile [colspan]="1" [rowspan]="4">
      <nb-card style="height: 100% !important; padding-bottom: 10px !important">
        <nb-card-body fxLayout="column" fxLayoutAlign="start start" style="overflow-y: hidden">
          <!-- create new radio -->
          <mat-checkbox color="primary" [checked]="!convertSaleLeadInputForm.controls.isNewContact.value"
            (change)="onToggleCheckedChanged($event,'isNewContact')"
            matTooltip="Check this radio if you want to add exist Contact">Existed</mat-checkbox>
          <ng-container *ngIf="!this.convertSaleLeadInputForm.controls.contact.value;else existedContact">
            <app-buyer-chosen style="width:100%;padding-top: 11.25px;" (contactSelected)="getContactSelected($event)"
              [isFilterOutSaleLeads]="true">
            </app-buyer-chosen>
            <ng-container [ngTemplateOutlet]="recommendBlock" [ngTemplateOutletContext]="{
              inputTem: recommendContact,
              count: recommendConvert?.recommendContacts ?? 0,
              objName: 'Contact'
            }"></ng-container>
            <input #recommendContact="matAutocompleteTrigger" matInput style="width: 100%; height: 0;"
              [matAutocomplete]="autocompleteRecommendContact">
          </ng-container>
        </nb-card-body>
      </nb-card>
    </mat-grid-tile>

    <!-- Opportunity -->
    <mat-grid-tile [colspan]="2" [rowspan]="6">
      <nb-card style="height: 100% !important; padding-bottom: 10px !important">
        <nb-card-body fxLayout="column" fxLayoutAlign="start start">
          <!-- create new radio -->
          <mat-checkbox color="primary" formControlName="isNewOpportunity"
            [matTooltip]="'Check this radio if you want to create new ' + environment.titleOpportunity">Create
            new</mat-checkbox>
          <div style="font-weight: 600;">New {{environment.titleOpportunity}}</div>
          <mat-form-field appearance="standard">
            <mat-label>{{environment.titleOpportunity}} Name{{convertSaleLeadInputForm.controls.isNewOpportunity.value ?
              '*': ''}}
            </mat-label>
            <input matInput autocomplete="off" formControlName="opportunityName" trim="blur" />
            <!-- validate -->
            <mat-error
              *ngIf="convertSaleLeadInputForm.controls.opportunityName.errors && (convertSaleLeadInputForm.controls.opportunityName.dirty || convertSaleLeadInputForm.controls.opportunityName.touched)">
              {{convertSaleLeadInputForm.controls.opportunityName['errorMessage']}}</mat-error>
          </mat-form-field>
          <!-- amount -->
          <mat-form-field appearance="standard">
            <mat-label>Estimate</mat-label>
            <input matInput autocomplete="off" formControlName="amount" (blur)="onNumberUnFocus('amount')"
              (focus)="onNumberFocus('amount')" />
            <!-- validate -->
            <mat-error *ngIf="convertSaleLeadInputForm.controls.amount.errors ">
              {{convertSaleLeadInputForm.controls.amount['errorMessage']}}</mat-error>
          </mat-form-field>
          <!-- closed date -->
          <mat-form-field appearance="standard" trim="blur">
            <mat-label>Closed Date</mat-label>
            <input matInput [matDatepicker]="closeDatePicker" formControlName="closeDate">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="closeDatePicker">
              <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #closeDatePicker disabled="false"></mat-datepicker>
            <mat-error *ngIf="convertSaleLeadInputForm.controls.closeDate.errors ">
              {{convertSaleLeadInputForm.controls.closeDate['errorMessage']
              || 'Please enter valid format: MM/DD/YYYY. Ex: 12/21/2022'}}
            </mat-error>
          </mat-form-field>
        </nb-card-body>
      </nb-card>
    </mat-grid-tile>


    <!-- Blank Opportunity -->
    <!-- <mat-grid-tile>
      <nb-card style="height: 100% !important; padding-bottom: 10px !important">
        <nb-card-body fxLayout="column" fxLayoutAlign="start start">

        </nb-card-body>
      </nb-card>
    </mat-grid-tile> -->
  </mat-grid-list>
</form>

<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" [disabled]="!convertSaleLeadInputForm.valid" [nbSpinner]="isLoading"
    (click)="onSubmitClicked()">
    <mat-icon>autorenew</mat-icon>Convert
  </button>
</div>

<!-- Template -->
<ng-template #existedSaleAccount>
  <app-sale-account-relationship-card style="width:100%; max-height: 160px;" (removeAccount)="removeSelectedAccount()"
    [model]="this.convertSaleLeadInputForm.controls.saleAccountProfileModel.value" [isRemovable]="true">
  </app-sale-account-relationship-card>
</ng-template>

<ng-template #existedContact>
  <app-contact-info style="width:100%; max-height: 160px;" [readonlyContactInfo]="true" [isRemovable]="true"
    (removeContactFnc)="removeSelectedContact()" [hideUnlinkBtn]='true' [forceReadOnly]="true"
    [contact]="this.convertSaleLeadInputForm.controls.contact.value">
  </app-contact-info>
</ng-template>

<ng-template #recommendBlock let-inputTem="inputTem" let-count="count" let-objName="objName">
  <div *ngIf="count && count > 0" (click)="inputTem ? inputTem.openPanel() : ''; $event.stopPropagation()"
    style="width: 100%; --line: 1; margin: 0;" class="alert alert-warning" role="alert">
    <span style="--line: 1;" class="limit-line">
      We found <span style="color: green" [nbSpinner]="!recommendConvert">
        <b>{{count | number: '2.0'}} {{objName}}(s)</b>
      </span> that may have similar information to this {{environment.titleLead}}
    </span>
  </div>
</ng-template>

<mat-autocomplete #autocompleteRecommendAccount="matAutocomplete"
  (optionSelected)="getSaleAccountSelected($event.option.value)">
  <mat-option *ngFor="let item of recommendConvert?.saleAccounts;" [value]="item">
    <div fxLayout="row" fxLayoutAlign="start end">
      <div style="font-size:14px" fxFlex="40" class="limit-line" style="--line:1;font-size:12px; margin-right:15px;">
        <b>{{item.displayName || "No name display"}}</b>
      </div>
      <div class="text-secondary limit-line" [matTooltip]="" style="--line:1;font-size:12px; margin-right:15px ;"
        fxFlex="40">
        {{item?.saleLeadsContact.contactEmail || "No email display" }} </div>
      <div class="text-secondary limit-line" style="--line:1;font-size:12px" fxFlex="20">
        {{(item?.saleLeadsContact.contactPhone | phoneFormat) || "No phone display" }}
      </div>
    </div>
  </mat-option>
</mat-autocomplete>

<mat-autocomplete #autocompleteRecommendContact="matAutocomplete" class="search-mat-autocomplete-overlay"
  (optionSelected)="getContactSelected($event.option.value)">
  <mat-option class="col custom-option" *ngFor="let contact of recommendConvert?.contacts;" [value]="contact">
    <strong>{{contact.contactName || ""}} &nbsp; {{contact.contactLastName || ""}}</strong><br>
    <span><strong>Email: </strong>{{
      contact?.contactEmail || "No email display" }} <br> </span>
    <span><strong>Phone: </strong>{{
      (contact?.contactPhone | phoneFormat) || "No phone display" }} <br></span>
  </mat-option>
</mat-autocomplete>