<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset; padding: 1px 10px 1px 28px"
  [nbSpinner]="isLoading">
  <button mat-button color="primary" (click)="saveData()" [disabled]="!frmUser.valid">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
  </button>
  <button mat-button color="primary" (click)="closeDialog()">
    <mat-icon>clear</mat-icon>Cancel
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frmUser" class="container" (ngSubmit)="saveData()"
    style="padding-top: 25px; padding-right: 50px; padding-left: 50px">
    <div class="row" [style.display]="action == 3 ? 'block' : 'none'">
      <div class="col-12 d-flex justify-content-center">
        <app-headshot [userModel]="userModel" #headshot></app-headshot>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>User Name</mat-label>
          <input matInput formControlName="userName" autocomplete="off" required [readonly]="action == 1 || action == 3"
            trim="blur" />
          <mat-error *ngIf="
              frmUser.controls.userName.errors &&
              (frmUser.controls.userName.dirty ||
                frmUser.controls.userName.touched)
            ">
            {{ frmUser.controls.userName["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="action == 0" class="col-12">
        <div class="alert alert-primary" role="alert">
          If password and confirm password are empty, the system will generate new password.
        </div>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label *ngIf="action == 1 || action == 3">New Password</mat-label>
          <mat-label *ngIf="action == 0">Password</mat-label>
          <input *ngIf="action == 1 || action == 3" matInput type="password" formControlName="password"
            autocomplete="off" trim="blur" />
          <input *ngIf="action == 0" matInput type="password" formControlName="password" autocomplete="off" trim="blur" />
          <mat-error *ngIf="
              frmUser.controls.password.errors &&
              (frmUser.controls.password.dirty ||
                frmUser.controls.password.touched)
            ">
            {{ frmUser.controls.password["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label *ngIf="action == 1 || action == 3">Confirm New Password</mat-label>
          <mat-label *ngIf="action == 0">Confirm Password</mat-label>
          <input *ngIf="action == 1 || action == 3" matInput type="password" formControlName="confirmPassword"
            autocomplete="off" trim="blur" />
          <input *ngIf="action == 0" matInput type="password" formControlName="confirmPassword" autocomplete="off" trim="blur" />
          <mat-error *ngIf="
              frmUser.controls.confirmPassword.errors &&
              (frmUser.controls.confirmPassword.dirty ||
                frmUser.controls.confirmPassword.touched)
            ">
            {{ frmUser.controls.confirmPassword["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Email</mat-label>
          <input matInput id="email" type="email" formControlName="email" autocomplete="off" required trim="blur" />
          <mat-error *ngIf="
              frmUser.controls.email.errors &&
              (frmUser.controls.email.dirty || frmUser.controls.email.touched)
            ">
            {{ frmUser.controls.email["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Phone Number</mat-label>
          <input matInput type="tel" formControlName="phoneNumber" autocomplete="off" trim="blur" />
          <mat-error *ngIf="
              frmUser.controls.phoneNumber.errors &&
              (frmUser.controls.phoneNumber.dirty ||
                frmUser.controls.phoneNumber.touched)
            ">
            {{ frmUser.controls.phoneNumber["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Outbound Caller Id</mat-label>
          <input matInput type="text" formControlName="outboundCallerId" autocomplete="off" trim="blur"
            [readonly]="readonlyCaller" />
          <mat-error *ngIf="
              frmUser.controls.outboundCallerId.errors &&
              (frmUser.controls.outboundCallerId.dirty ||
                frmUser.controls.outboundCallerId.touched)
            ">
            {{ frmUser.controls.outboundCallerId["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName" autocomplete="off" required trim="blur" />
          <mat-error *ngIf="
              frmUser.controls.firstName.errors &&
              (frmUser.controls.firstName.dirty ||
                frmUser.controls.firstName.touched)
            ">
            {{ frmUser.controls.firstName["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName" autocomplete="off" required trim="blur" />
          <mat-error *ngIf="
              frmUser.controls.lastName.errors &&
              (frmUser.controls.lastName.dirty ||
                frmUser.controls.lastName.touched)
            ">
            {{ frmUser.controls.lastName["errorMessage"] }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6" *ngIf="action != 3">
        <mat-form-field appearance="standard">
          <mat-label>Role</mat-label>
          <mat-select formControlName="roles" required multiple>
            <mat-option *ngFor="let item of listRoles" [value]="item.id | lowercase">
              {{ item.displayName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
              frmUser.controls.roles.errors &&
              (frmUser.controls.roles.dirty || frmUser.controls.roles.touched)
            ">
            {{ frmUser.controls.roles["errorMessage"] }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12" style="height: fit-content">
        <mat-label style="color: #666666">Job Title</mat-label>
        <quill-editor [modules]="editorOptions" placeholder="Job title" formControlName="jobTitle">
        </quill-editor>
      </div>
      <button type="submit" style="display: none"></button>
    </div>
  </form>
</mat-dialog-content>
