<nb-card *nbPortal [class.supports-scrollbar-theming]="!isFirefox()" class="nb-timepicker-container">
  <nb-card-header class="column-header">
    <ng-container *ngIf="singleColumn; else fullTimeHeadersBlock">
      <div class="header-cell">Time</div>
    </ng-container>
    <ng-template #fullTimeHeadersBlock>
      <div class="header-cell">{{ hoursText }}</div>
      <div class="header-cell">{{ minutesText }}</div>
      <div *ngIf="withSeconds" class="header-cell">{{ secondsText }}</div>
      <div *ngIf="twelveHoursFormat" class="header-cell">
        <ng-template [ngIf]="showAmPmLabel">{{ ampmText }}</ng-template>
      </div>
    </ng-template>
  </nb-card-header>

  <div class="picker-body">
    <ng-container *ngIf="singleColumn; else fullTimeColumnBlock">
      <nb-list class="values-list">
        <nb-list-item class="list-item" [class.selected]="isSelectedFullTimeValue(item)"
          *ngFor="let item of fullTimeOptions; trackBy: trackBySingleColumnValue.bind(this)">
          <nb-timepicker-cell [value]="getFullTimeString(item)" [selected]="isSelectedFullTimeValue(item)"
            (select)="selectFullTime(item)">
          </nb-timepicker-cell>
        </nb-list-item>
      </nb-list>
    </ng-container>

    <ng-template #fullTimeColumnBlock>
      <nb-list class="values-list">
        <nb-list-item class="list-item" [class.selected]="isSelectedHour(item.value)"
          *ngFor="let item of hoursColumnOptions; trackBy: trackByTimeValues">
          <nb-timepicker-cell [value]="item.text" [selected]="isSelectedHour(item.value)"
            (select)="setHour(item.value)">
          </nb-timepicker-cell>
        </nb-list-item>
      </nb-list>
      <nb-list class="values-list">
        <nb-list-item class="list-item" [class.selected]="isSelectedMinute(item.value)"
          *ngFor="let item of minutesColumnOptions; trackBy: trackByTimeValues">
          <nb-timepicker-cell [value]="item.text" [selected]="isSelectedMinute(item.value)"
            (select)="setMinute(item.value)">
          </nb-timepicker-cell>
        </nb-list-item>
      </nb-list>
      <nb-list *ngIf="showSeconds()" class="values-list">
        <nb-list-item class="list-item" [class.selected]="isSelectedSecond(item.value)"
          *ngFor="let item of secondsColumnOptions; trackBy: trackByTimeValues">
          <nb-timepicker-cell [value]="item.text" [selected]="isSelectedSecond(item.value)"
            (select)="setSecond(item.value)">
          </nb-timepicker-cell>
        </nb-list-item>
      </nb-list>
      <nb-list *ngIf="twelveHoursFormat" class="values-list">
        <nb-list-item class="list-item am-pm-item" [class.selected]="isSelectedDayPeriod(dayPeriod)"
          *ngFor="let dayPeriod of dayPeriodColumnOptions; trackBy: trackByDayPeriod">
          <nb-timepicker-cell [value]="dayPeriod" [selected]="isSelectedDayPeriod(dayPeriod)"
            (select)="changeDayPeriod(dayPeriod)">
          </nb-timepicker-cell>
        </nb-list-item>
      </nb-list>
    </ng-template>
  </div>

  <nb-card-footer *ngIf="showFooter" class="actions-footer">
    <nb-calendar-actions [applyButtonText]="applyButtonText" [currentTimeButtonText]="currentTimeButtonText"
      (setCurrentTime)="setCurrentTime()" (saveValue)="saveValue()"></nb-calendar-actions>
  </nb-card-footer>
</nb-card>