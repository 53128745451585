import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { SaveTagsViewModel, Tags } from './tag.model';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagService {


  constructor(private http: HttpClient) {


  }
  baseUrl = environment.tagManagement;
  tagsObservable$ = new Subject<Tags[]>();
  fetchTags(): Observable<Tags[]> {
    return this.tagsObservable$.asObservable();
  }
  getTags(): Observable<Tags[]> {
    return this.http.get<Tags[]>(`${this.baseUrl}`);
  }
  refresh() {
    return this.http.get(`${this.baseUrl}`).subscribe(
      (item: ReturnResult<Tags[]>) => {
        this.tagsObservable$.next(item.result);
      }
    );
  }

  saveTags(tagLst: string[], profileId: string): Observable<ReturnResult<SaveTagsViewModel>> {
    return this.http.post<ReturnResult<SaveTagsViewModel>>(`${this.baseUrl}/AddTags`, {
      tagLst, profileId
    });
  }
  removeTag(tag: string, profileId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/RemoveTagFromProfile`, { tag, profileId });
  }

  addTagObjectList(tags: string, profileIds: string[]): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/AddTagObjectList`, { tags, profileIds });
  }
  getTagPaging(page: Page): Observable<ReturnResult<PagedData<Tags>>> {
    return this.http.post<ReturnResult<PagedData<Tags>>>(`${this.baseUrl}/GetTagsPaging`, page);
  }
  saveTagManagement(model: Tags): Observable<ReturnResult<Tags>> {
    return this.http.post<ReturnResult<Tags>>(
      `${this.baseUrl}/SaveTagManagement`,
      model
    );
  }
  deleteTagManagement(tagId: number) {
    return this.http.delete<ReturnResult<Boolean>>(
      `${this.baseUrl}/DeleteTagManagement/${tagId}`
    );
  }
  deleteTagsManagement(tagIds: number[]) {
    return this.http.post<ReturnResult<Boolean>>(
      `${this.baseUrl}/DeleteTagsManagement`,
      tagIds
    );
  }
  exportTagManagement(page: Page): Observable<ReturnResult<Tags>> {
    return this.http.post<ReturnResult<Tags>>(
      `${this.baseUrl}/Export`,
      page
    );
  }
  importTagManagement(formData: FormData): Observable<ReturnResult<Tags>> {
    return this.http
      .post<ReturnResult<Tags>>(`${this.baseUrl}/import`, formData)
      .pipe(timeout(1200000));
  }
}
