<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;"
  [nbSpinner]="isLoading">
  <button mat-button color="primary" (click)="saveImage()" [disabled]="false">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
  </button>
  <button mat-button color="primary" (click)="closeDialog()">
    <mat-icon>clear</mat-icon>Cancel
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography" style="text-align: center;">
  <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatioWidth / aspectRatioHeight" format="png" (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
  </image-cropper>
  <img [src]="croppedImage" style="height: 40vh;" />

</mat-dialog-content>