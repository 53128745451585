export const GENERAL_DATE_TIME_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'M/DD/yyyy, HH:mm:ss',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

export const DISPLAY_INPUT_DATE = {
    parse: {
        dateInput: 'M/DD/yyyy, HH:mm:ss',
    },
    display: {
        dateInput: 'M/DD/yyyy, HH:mm:ss',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

export const DISPLAY_INPUT_DATE_TIME_FORMAT = {
    parse: {
        dateInput: ['MM/DD/yyyy, HH:mm:ss', 'MM/DD/yyyy HH:mm:ss', 'LL', 'LLL'],
    },
    display: {
        dateInput: 'M/DD/yyyy, HH:mm:ss',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};
