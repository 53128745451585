import { NumberFormatPipe } from './../../../../shared/pipes/number-format.pipe';
import { SettingService } from './../../../../shared/services/setting.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbPopoverComponent, NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { NumericValueType, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { QuillEditorComponent } from 'ngx-quill';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { MultiSelectDropDownComponent } from 'src/app/shared/components/stand-alone-component/multi-select-drop-down/multi-select-drop-down.component';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { CallService } from 'src/app/shared/services/call.service';
import { ContactService } from 'src/app/shared/services/contact.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { Contact, ContactContactType, ContactType, Location, ProfileAdditionDetail, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { LinkscopeEmailComponent } from '../../profile-management/profile-detail/action-block/linkscope-email/linkscope-email.component';
import { ProfileService } from '../../profile-management/profile.service';
import { AddEditTaskComponent } from '../../task-management/add-edit-task/add-edit-task.component';
import { TaskBoardService } from '../../task-management/task-board/task-board.service';
import { UserModel } from '../../user-management/user-model';
import { UserService } from '../../user-management/user.service';
import { SaleAccountService } from '../sale-account.service';
import { EntityColorEnums } from 'src/app/shared/enums/entity-color.enums';
import { Note, NoteDetails } from 'src/app/shared/components/note-management/noteManagement.model';
import { NoteTabComponent } from 'src/app/shared/components/note-tab/note-tab.component';
import { ContactComponent } from 'src/app/shared/components/stand-alone-component/contact/contact.component';
import { TableVariable } from 'src/app/shared/contances/data-table-contance';
import { AddExistingContactComponent } from '../../profile-management/profile-detail/contact-tab/add-existing-contact/add-existing-contact.component';
import { ContactRelationshipGridComponent } from 'src/app/shared/components/contact-relationship-grid/contact-relationship-grid.component';
import { OpportunityModalComponent } from 'src/app/shared/components/stand-alone-component/opportunity-modal/opportunity-modal.component';
import { SaleAccountOpportunityTabComponent } from './sale-account-opportunity-tab/sale-account-opportunity-tab.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { OverlayNoteDetailsComponent } from 'src/app/shared/components/note-management/overlay-note-details/overlay-note-details.component';
import { permissionGridNoteSaleAccount, permissionSaleAccountFile } from 'src/app/shared/contances/permission';
import { NoteManagementService } from 'src/app/shared/components/note-management/note-management.service';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { ProfileAdditionDetailService } from 'src/app/shared/services/profile-addition-detail.service';
import { AddExistingProfileComponent } from '../../producer-management/add-edit-producer/producer-profile-tab/add-existing-profile/add-existing-profile.component';
import { SaleAccountResellerTabComponent } from './sale-account-reseller-tab/sale-account-reseller-tab.component';
import { SaleAccountRelationshipTabV2Component } from './sale-account-relationship-tab-v2/sale-account-relationship-tab-v2.component';
import { LocationComponent } from 'src/app/shared/components/stand-alone-component/location/location.component';
import { LocationGridComponent } from './location-grid/location-grid.component';
import { AltusLocation } from 'src/app/shared/components/stand-alone-component/location/location';
import { ContactTypeFilter } from 'src/app/shared/components/stand-alone-component/contact/contact-type';
import { DropDownValue } from '../../summary-report/summary-report.component';
import { DropdownSearchingComponent } from 'src/app/shared/components/dropdown-searching/dropdown-searching.component';
import { AddEditUserUploadComponent } from '../../user-upload-management/add-edit-user-upload/add-edit-user-upload.component';
import { FormControl } from '@angular/forms';
import { UserUploadManagementService } from '../../user-upload-management/user-upload-management.service';
import { UserUpload } from '../../user-upload-management/user-upload.model';
import { ProfileFileTabComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/profile-file-tab.component';
import { HotToastService } from '@ngneat/hot-toast';
import { TabOpportunityGridComponent } from './sale-account-opportunity-tab/tab-opportunity-grid/tab-opportunity-grid.component';
import { TaskGridByProfileIdComponent } from '../../sale-lead-management/sale-lead-details/task-grid-by-profile-id/task-grid-by-profile-id.component';
import { ReviewContactCampaignComponent } from '../../email-campaign-management/email-campaign-detail/create-new-campaign/review-contact-campaign/review-contact-campaign.component';
import { CreateCampaignModelComponent } from '../../profile-management/create-campaign-model/create-campaign-model.component';
import { ContactPage } from 'src/app/shared/models/paging/page';
import { AddContactButtonComponent } from 'src/app/shared/components/add-button-in-tab/add-contact-button/add-contact-button.component';
import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { TabTagIndexModel } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { SaleAccountContactTabComponent } from './sale-account-contact-tab/sale-account-contact-tab.component';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { isThisSecond } from 'date-fns';
import { SaleAccountScheduleTabComponent } from './sale-account-schedule-tab/sale-account-schedule-tab.component';
import { DatePipe } from '@angular/common';
import { PhoneFormatPipe } from 'src/app/shared/pipes/phone-format.pipe';
import { environment } from 'src/environments/environment';
import { VitalHelperComponent } from './vital-helper/vital-helper.component';

@Component({
  selector: 'app-sale-account-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class SaleAccountDetailsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() id: string = null;
  @Input() openByDialog = true;
  @Input() optionalTaskId: string;
  @Input() creatableTask = true;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Input() isShowButton: boolean = false;


  @Output() onAdvancedImport: EventEmitter<any> = new EventEmitter();
  @Output() refreshTask: EventEmitter<void> = new EventEmitter();

  @ViewChild('popupEditor') popupEditor: TemplateRef<any>;

  quillEditor: QuillEditorComponent;
  @ViewChild('quillEditor', { static: false }) set setQuillEditor(content: QuillEditorComponent) { if (content) this.quillEditor = content };

  // saleAccountTypeComponent: MultiSelectDropDownComponent
  // @ViewChild('saleAccountType', { static: false }) set setSaleAccountTypeComponent(content: MultiSelectDropDownComponent) { if (content) this.saleAccountTypeComponent = content };

  //Note: When using 1 component in tab by view child, please clean component when switch tab.
  //Name function: cleanComponentTab();
  noteTab: NoteTabComponent;
  @ViewChild('gridNoteTab', { static: false }) set setGridNoteTab(content: NoteTabComponent) { if (content) this.noteTab = content };

  contactTab: SaleAccountContactTabComponent;
  @ViewChild('contactGrid', { static: false }) set contactAccount(content: SaleAccountContactTabComponent) { if (content) this.contactTab = content };

  locationGrid: LocationGridComponent;
  @ViewChild('locationGrid', { static: false }) set setLocationGrid(content: LocationGridComponent) { if (content) this.locationGrid = content };

  opportunityTab: TabOpportunityGridComponent;
  @ViewChild('opportunityTab', { static: false }) set setOpportunityTab(content: TabOpportunityGridComponent) { if (content) this.opportunityTab = content };

  resellerTab: SaleAccountResellerTabComponent;
  @ViewChild('resellerTab', { static: false }) set setResellerTab(content: SaleAccountResellerTabComponent) { if (content) this.resellerTab = content };

  relationshipTab: SaleAccountRelationshipTabV2Component;
  @ViewChild('relationshipTab', { static: false }) set setRelationshipTab(content: SaleAccountRelationshipTabV2Component) { if (content) this.relationshipTab = content };

  fileTab: ProfileFileTabComponent;
  @ViewChild('profileFileTab', { static: false }) set setProfileFileTab(content: ProfileFileTabComponent) { if (content) this.fileTab = content }

  parentContactTypeSearch: DropdownSearchingComponent;
  childContactTypeSearch: DropdownSearchingComponent;
  @ViewChild('parentContactTypeSearch', { static: false }) set getParentContactTypeSearch(content: DropdownSearchingComponent) {
    if (content) this.parentContactTypeSearch = content;
  }
  @ViewChild('childContactTypeSearch', { static: false }) set getchildContactTypeSearch(content: DropdownSearchingComponent) {
    if (content) this.childContactTypeSearch = content;
  }
  nbMailPopover: NbPopoverDirective;
  @ViewChild(NbPopoverDirective, { static: false }) set setSendMailBtn(content: NbPopoverDirective) { if (content) this.nbMailPopover = content }
  makeCallPopover: NbPopoverDirective;
  @ViewChild('makeCallPopoverBtn', { static: false }) set setMakeCallBtn(content: NbPopoverDirective) { if (content) this.makeCallPopover = content }
  @ViewChild('contactSelectPop', { static: true }) contactSelectPop: TemplateRef<any>;
  taskGrid: TaskGridByProfileIdComponent;
  @ViewChild('taskGrid', { static: false }) set setTaskGrid(content: TaskGridByProfileIdComponent) { if (content) this.taskGrid = content };

  contactButton: AddContactButtonComponent
  @ViewChild('contactButton', { static: false }) set setContactButton(content: AddContactButtonComponent) { if (content) this.contactButton = content };

  scheduleGroupComponent: SaleAccountScheduleTabComponent
  @ViewChild('scheduleTab', { static: false }) set setScheduleTab(content: SaleAccountScheduleTabComponent) { if (content) this.scheduleGroupComponent = content };

  environment = environment;
  saleAccountModel: ProfileDetailModel;
  ownerModel: UserModel;
  contactDialogRef: any;
  existingContactDialogRef: MatDialogRef<any, any>;
  isCopy = false;
  saleAccountColorCode = EntityColorEnums;
  isLoading: boolean = false;
  isRefreshing: boolean = false;
  isLoadingEdit: boolean = false;
  isLoadingContact: boolean = false;
  editorOptions = QuillConfiguration;
  public get TypeEditModelSaleAccount(): typeof TypeEditModelSaleAccount { return TypeEditModelSaleAccount; }
  dialogQuillEditor: MatDialogRef<any, any>;
  contentQuillEditor = "";
  isHideOpportunity: boolean = false;
  partnerType = [];
  formatValueQueryType = { key: 'contactTypeId', value: 'contactTypeName', color: 'contactTypeColorCode', parentId: 'parentId' };
  accountContactTypes: ContactTypeFilter[] = [];
  getContactType = this.contactService.GetAllContactTypeAsync("ACCOUNT")
    .pipe(tap(x => {
      if (x.result) {
        this.accountContactTypes = x.result;
        this.parentContactTypeAsDropdownModel = this.accountContactTypes.slice().filter(x => x.parentId == 0).map(x => {
          return new DropDownValue(x.contactTypeName, x.contactTypeId.toString(), x.contactTypeColorCode);
        });
      }
    }), map((vals: ReturnResult<any[]>) => Helper.mapArrayToFormat(this.formatValueQueryType, vals.result)));
  selectType: number[] = [];
  validProperties = {
    accountName: { valid: [RxwebValidators.required()], message: `${this.environment.titleAccount} Name is required` },
    contactEmail: { valid: [RxwebValidators.email()], message: "Email is not valid" },
    contactPhone: { valid: [RxwebValidators.pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ } })], message: "Phone is not valid" },
    annualRevenue: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "Annual revenue is not valid" },
    employees: {
      valid: [RxwebValidators.pattern({
        expression: { employees: /\b([0-9]{1,9}|1[0-9]{9}|2(0[0-9]{8}|1([0-3][0-9]{7}|4([0-6][0-9]{6}|7([0-3][0-9]{5}|4([0-7][0-9]{4}|8([0-2][0-9]{3}|3([0-5][0-9]{2}|6([0-3][0-9]|4[0-7])))))))))\b/ }
      }), RxwebValidators.digit()], message: "The Employees field is not valid or larger than 2147483647"
    }
  }
  isHasPrimaryContact = false;
  isCalling = false;
  isCallLoading = false;
  dynamicContentIndustryAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentCountryAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentStateAPI: Observable<ReturnResult<DynamicContentModel[]>>

  dynamicContentPhoneNumber: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_phone');

  dynamicContentWebsite: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_webpage');

  dynamicContentEmail: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_email');

  addNewTask = AddEditTaskComponent;
  selectTab: TabSaleAccount = TabSaleAccount.Contact;
  user;
  private routeSub: Subscription;

  apiGetSearchRelationship: (data) => void = null;
  resource = permissionGridNoteSaleAccount;
  overlayNoteDetails: OverlayNoteDetailsComponent;
  prefixCurrency: string;
  lastNote: NoteDetails;
  apiGetSearchOwner: (data) => void = (data) => this.userService.searchUser(data);
  isTabMode = Helper.checkTabMode();
  channelList: any[] = [{ key: "Direct", value: "Direct" }, { key: "Indirect", value: "Indirect" }]
  private destroy$: Subject<void> = new Subject<void>();
  // All contact type in backend:
  allContactTypesAsDropdownModel: DropDownValue[] = [];
  parentContactTypeAsDropdownModel: DropDownValue[] = [];
  subContactTypeAsDropdownModel: DropDownValue[] = [];
  selectedContactTypeParent: DropDownValue;
  selectedContactTypeChild: DropDownValue;
  uploadedFile: any;
  uploading: boolean = false;
  fileResource = permissionSaleAccountFile;
  contacts: Contact[] = null;
  resetContacts: () => void = () => this.contacts = null;

  lastVitalSignInfo: any;
  //saleAccountContactId: number = 0;
  currentTab: number = 0;
  extendData: any = {};
  extendDataList = [];
  today = new Date();
  tabPatientMode = "PatientDashBoard"
  validNumber = [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false })];
  validPhoneNumber = [RxwebValidators.pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ } })];
  validNumberWithDecimal = [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })];
  users: UserModel[];
  isDisableAssign: boolean = false;
  isDisableAssignDoctor: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<SaleAccountDetailsComponent>,
    private saleAccountService: SaleAccountService,
    private taskService: TaskBoardService,
    private clipboard: Clipboard,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private userService: UserService,
    private contactService: ContactService,
    private profileService: ProfileService,
    private callService: CallService,
    private authService: NbAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private setting: SettingService,
    private settingPoolService: SettingPoolService,
    public numberPipe: NumberFormatPipe,
    public noteService: NoteManagementService,
    private dynamicContentService: DynamicContentService,
    private profileAdditionDetailService: ProfileAdditionDetailService,
    private builder: RxFormBuilder,
    private userUploadService: UserUploadManagementService,
    private hotToast: HotToastService,
    private tabModeService: AdminTabModeService,
    private datePipe: DatePipe,
    public phoneFormat: PhoneFormatPipe
  ) {
    this.id = this.data?.model?.profileId ?? this.id;
    this.isLoading = true;
    this.routeSub = this.route.params.subscribe(params => {
      if (params.id !== undefined) {
        this.id = params.id;
        //if (this.id && this.router.url.includes(`configuration/sale-account`)) Helper.cancelNavigate(`configuration/sale-account/${this.id}`);
      }
    });

    this.validSetting();
    if (this.router.url.includes('/configuration/sale-account') ||
      this.router.url.includes('/configuration/opportunity') ||
      this.router.url.includes('/configuration/email-history') ||
      this.router.url.includes('/configuration/email-inbox') ||
      this.router.url.includes('/configuration/history-data') ||
      this.router.url.includes('/configuration/email-campaign') ||
      this.router.url.includes('/configuration/dashboard-contact-map') ||
      this.router.url.includes('/configuration/sale-convert-history') ||
      this.router.url.includes('/configuration/import-contact') ||
      this.router.url.includes('/configuration/activity-log') ||
      this.router.url.includes('/configuration/patient')
    ) {
      this.isShowButton = true;
    }
    // this.dynamicContentIndustryAPI = this.dynamicContentService.getDynamicContentByType('saleaccount-generalindustry');
    this.apiGetSearchRelationship = (data) => this.saleAccountService.searchSaleAccount(data).pipe(map(x => {
      let newFilter = x;
      if (newFilter.result) {
        newFilter.result = newFilter.result.filter(item => item.profileId !== this.id);
      }
      return newFilter;
    }));
    this.dynamicContentIndustryAPI = this.dynamicContentService.getDynamicContentByType('general_industry');
    this.dynamicContentCountryAPI = this.dynamicContentService.getDynamicContentByType('country');
    this.dynamicContentStateAPI = this.dynamicContentService.getDynamicContentByType('location_state');
    // Get Contact Type:
    this.getContactType.pipe(takeUntil(this.destroy$)).subscribe((returnData: AccountContactType[]) => {
      if (returnData) {
        this.allContactTypesAsDropdownModel = returnData.slice().map(x => {
          return new DropDownValue(x.value, x.key.toString(), x.color);
        });
        this.setupContactType();
      }
    });

    this.userService.getAllDoctors().subscribe({
      next: resp => this.users = resp?.result || this.users,
      error: err => this.users = [],
      complete: () => {
        var defaultUser = { id: "-1", userName: "None" } as UserModel;
        this.users.unshift(defaultUser);
      },
    });
  }

  ngOnInit(): void {
    if (this.id) {
      this.refreshData();
      this.getLastIVR();
    };
    if (this.openByDialog && this.dialModalRef?.componentInstance) {
      if (this.isTabMode) this.dialModalRef.afterOpened().subscribe(() => {
        var overlayBackdrops = window.document
          .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
        for (var i = 0; i < overlayBackdrops.length; i++)
          overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
      })

      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
      if (this.data && this.data.advancedImport) {
        this.dialModalRef.updateSize(this.openByDialog ? '1600px' : '1600px', Helper.heightDialog());
      }
      else {
        this.dialModalRef.updateSize(this.openByDialog ? '1400px' : '1600px', Helper.heightDialog());
      }
    }
    // this.taskService.getAllTaskStatus();
    // this.taskService.getAllPriority();
    // this.taskService.getAllTaskType();
    this.getSettingToHideSaleOpportunity();
    this.formatCurrencyPrefix();
    this.getLastNote();
  }

  ngAfterViewInit(): void {

    this.overlayNoteDetails = this.noteService.overlayNoteDetailsComponent;
    if (this.router.url.includes('/configuration/sale-account')) {
      let currentTabMode = this.tabModeService.getCurrentActiveTab();
      let tag: string = null;
      if (currentTabMode) {
        let tabTagIndex: TabTagIndexModel = this.tabModeService.getTabTagIndex(currentTabMode.tabId);
        if (tabTagIndex && tabTagIndex.uniqueId && typeof tabTagIndex.uniqueId == 'string' && tabTagIndex.uniqueId == this.id) {
          tag = tabTagIndex.tag;
        }
      }
      let patternRegex = /#([\d]+)/;
      if (!Helper.isNullOrEmpty(tag)) {
        this.selectTab = parseInt(tag.replace('#', ''));
        Helper.handleTabChangedUrl(this.selectTab, '/configuration/sale-account');

      } else if (patternRegex.test(this.router.url)) {
        let matchTabIndex = this.router.url.match(patternRegex).length > 0 ? Number.parseInt(this.router.url.match(patternRegex)[0].replace('#', '')) : TabSaleAccount.Contact;
        this.selectTab = matchTabIndex;
        Helper.handleTabChangedUrl(this.selectTab, '/configuration/sale-account');
      }

      // if end with #tabIndex then move to that tab

    } else {
      this.selectTab = this.openByDialog ? TabSaleAccount.Contact : TabSaleAccount.ActivityLog;
    }
    this.currentTab = this.selectTab;
    this.saleAccountService.getSaleAccountById()
      .pipe(
        tap(resp => this.profileService._profileDetailData$.next(resp)),
        takeUntil(this.destroy$)
      ).subscribe({
        next: resp => { if (resp.result) this.setupSaleAccount(resp.result); },
        complete: () => this.isLoading = false,
      });

    this.saleAccountService.isRefreshSaleGrid$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isRefresh: boolean) => this.isRefreshing = isRefresh);

    this.overlayNoteDetails.onRefreshed().pipe(
      takeUntil(this.destroy$),
      debounceTime(2000)
    ).subscribe(resp => {
      this.overlayNoteDetails.completedLoading();
      if (resp && this.noteTab)
        this.noteTab.refreshData();
      this.getLastNote(resp);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeId = changes.id;
    if (changeId && !changeId.firstChange && changeId.currentValue != changeId.previousValue) {
      this.refreshData();
    }
  }

  async validSetting() {
    var resp = await this.setting.getSettingByKeyAndGroup("DOCTOR_ROLE_NAME", "PATIENT").toPromise();

    this.authService.onTokenChange().pipe(takeUntil(this.destroy$))
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
          if (this.user['role'] && resp?.result && this.user['role']?.toString()?.includes(resp?.result?.value?.toLowerCase()))
            this.isDisableAssign = true;

          if (this.user['role'] && resp?.result &&
            (this.user['role']?.toString()?.includes(resp?.result?.value?.toLowerCase())
              || this.user['role']?.toString()?.includes('nurse')))
            this.isDisableAssignDoctor = true;
        }
      });
  }
  ngOnDestroy(): void {
    if (this.saleAccountService.saleAccountDetail$)
      this.saleAccountService.saleAccountDetail$.next(new ReturnResult<any>());
    this.destroy$.next();
    this.destroy$.complete();
  }

  refreshData() {
    this.saleAccountService.refreshSaleAccountById(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => { if (resp.result) this.setupSaleAccount(resp.result); },
        complete: () => {
          this.isLoading = false;
          this.isLoadingEdit = false;
          this.getOwnerSaleAccount();
        },
      });
  }

  setupSaleAccount(data: ProfileDetailModel) {
    if (data) {
      this.saleAccountModel = data;
      if (this.saleAccountModel.extendData) {
        try {
          this.extendData = JSON.parse(this.saleAccountModel.extendData);
          this.extendData['Doctor'] = this.saleAccountModel.ownerId;
          if (!this.extendData['Doctor']) this.extendData['Doctor'] = "-1";

          if (!this.extendData.hasOwnProperty('Weight')) this.extendData["Weight"] = null;
          this.extendDataList = this.convertExtendToList();
        }
        catch (ex) {
          console.log(ex)
        }
      }
      //this.saleAccountContactId = data.accountContactId;
      //this.contacts = this.saleAccountModel.profileContacts.map((contact) => contact.contact);
      this.selectType = this.saleAccountModel?.saleLeadsContact?.contactContactTypes?.map(x => x.contactTypeId) ?? [];
      // init selected contact type:
      if (this.allContactTypesAsDropdownModel.length > 0) this.setupContactType();
      this.saleAccountModel.tagList = this.saleAccountModel.tags !== '' && this.saleAccountModel.tags != null
        ? this.saleAccountModel.tags.split(',') : [];
      if (this.partnerType && this.partnerType.length > 0) {
        this.hideOpportunityConfig();
      }
    }
  }

  editAccountContact(value: any, prop: string, typeModel: TypeEditModelSaleAccount = TypeEditModelSaleAccount.SaleAccount) {
    if (!prop) {
      this.toast.danger('Failed', "Error");
      return;
    }

    if (prop == 'displayName') this.refreshTask.emit();
    if ((prop == 'annualRevenue' || prop == 'noOfEmployee') && !value)
      value = 0;
    let saleAccountRequest: ProfileDetailModel, contactRequest: Contact, locationRequest: Location

    if (prop === 'channel' || prop === 'primaryReseller') {
      if (prop === 'channel' && this.channelList.find(x => x.value === value) === undefined) {
        value = null;
      }
      if (this.saleAccountModel.profileAdditionDetail) {
        this.saleAccountModel.profileAdditionDetail[prop] = value;
      } else {
        let newExtendDetail: ProfileAdditionDetail = new ProfileAdditionDetail();
        newExtendDetail.profileId = this.saleAccountModel.profileId;
        newExtendDetail[prop] = value;
        this.saleAccountModel.profileAdditionDetail = newExtendDetail;
      }
      saleAccountRequest = Object.assign({}, this.saleAccountModel);
      contactRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact);
      locationRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact.location, { [prop]: value });


    } else {
      saleAccountRequest = Object.assign({}, this.saleAccountModel, { [prop]: value });
      contactRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact, { [prop]: value });
      locationRequest = Object.assign({}, this.saleAccountModel.saleLeadsContact.location, { [prop]: value });

    }

    let id;
    switch (typeModel) {
      case TypeEditModelSaleAccount.SaleAccount: {
        id = this.saleAccountModel.profileId
        break;
      }
      case TypeEditModelSaleAccount.Contact: {
        id = this.saleAccountModel.saleLeadsContact.contactId.toString()
        break;
      }
      case TypeEditModelSaleAccount.Location: {
        id = this.saleAccountModel.saleLeadsContact.locationId.toString()
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    this.isLoadingEdit = true;
    this.saleAccountService.editSaleAccount({
      id: id,
      saleAccountModel: typeModel == TypeEditModelSaleAccount.SaleAccount
        ? saleAccountRequest : null,
      contactModel: typeModel == TypeEditModelSaleAccount.Contact
        ? contactRequest : null,
      locationModel: typeModel == TypeEditModelSaleAccount.Location
        ? locationRequest : null,
    }).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => {
          if (resp.result) {
            this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
            if (prop == "ownerId") this.ownerModel = null;
            this.refreshData();
            if (prop == "contactPhone" || prop == "contactEmail")
              this.contactTab.refreshData();
          }
        },
        error: err => this.isLoadingEdit = false,
        complete: () => this.isLoadingEdit = false
      });
  }

  closeDialog() {
    // remove tab cache:
    this.tabModeService.removeCurrentTabTag();
    if (this.saleAccountService.cacheSaleAccounts && this.saleAccountService.cacheSaleAccounts.length > 0) {
      this.id = this.saleAccountService.getLastIdCacheSaleAccount();
      this.refreshData();
    }
    // if (this.router.url.includes(`configuration/sale-account`)) window.history.back();

    if (this.dialModalRef?.componentInstance)
      this.dialModalRef.close(this.isRefreshing);
    this.onClose.emit(this.isRefreshing);

    if (Helper.checkUrlDetailObj('sale-account')) {
      const urlBack = Helper.popBackURL() || `/configuration/sale-account`;
      this.router.navigate([urlBack]);
    }
    if (Helper.checkUrlDetailObj('patient')) {
      const urlBack = Helper.popBackURL() || `/configuration/patient`;
      this.router.navigate([urlBack]);
    }
  }

  getOwnerSaleAccount() {
    if (!this.saleAccountModel && Helper.isNullOrEmpty(this.saleAccountModel.ownerId) || Helper.isEmptyOrSpaces(this.saleAccountModel.ownerId))
      this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel);

    if (!this.ownerModel && this.saleAccountModel.ownerId)
      this.userService.getUserById(this.saleAccountModel.ownerId).pipe(takeUntil(this.destroy$))
        .subscribe(
          resp => {
            if (resp.result) {
              this.ownerModel = resp.result;
              let fullName = `${this.ownerModel.firstName} ${this.ownerModel.lastName}`;
              this.ownerModel.fullName = fullName.trim() ? fullName : "Unknown";
              this.ownerModel.pictureURL = this.userURL(this.ownerModel.pictureURL);
            };
          },
          err => this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel));
  }

  userURL(data): string {
    if (data)
      return data.replace(/\\/g, '/');
    else null;
  }

  convertHtmlToNormalStyle(data: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = data;
    return tmp.textContent || tmp.innerText || "";
  }

  openComponentEditor(model: string, prop: string) {
    this.dialogQuillEditor = this.dialog.open(this.popupEditor, {
      disableClose: true,
      autoFocus: false,
      width: '60vw',
      data: this.saleAccountModel[model][prop]
    });

    this.dialogQuillEditor.afterClosed().subscribe(resp => {
      if (resp) this.editAccountContact(resp, prop, TypeEditModelSaleAccount.Contact)
    });
  }

  setFocus(editor, data) {
    if (this.dialogQuillEditor) {
      const width = this.dialogQuillEditor._containerInstance._config.width;
      let toolbarEditor = this.quillEditor.elementRef.nativeElement.getElementsByClassName("ql-toolbar ql-snow")[0];
      let containerEditor = this.quillEditor.elementRef.nativeElement.getElementsByClassName("ql-container ql-snow")[0];
      toolbarEditor.style = `position: absolute; width: calc(${width} - 48px); background-color: snow; z-index: 100;`;
      containerEditor.style = `padding-top: 2.625rem; margin-bottom: 1px;`;
      this.contentQuillEditor = data;
      editor.focus();
    }
  }

  saveEditor() {
    if (this.dialogQuillEditor) {
      if (this.contentQuillEditor && this.contentQuillEditor !== "")
        this.closeDialogEditor(this.contentQuillEditor);
      else this.toast.danger("Content error or empty!", "Error");
    }
  }

  closeDialogEditor(data?: string) {
    if (this.dialogQuillEditor) this.dialogQuillEditor.close(data);
  }

  copyToClipboard() {
    const url = window.location.href.split('configuration')[0] + `configuration/sale-account?saleAccountId=${this.id}`;
    this.clipboard.copy(url);
    this.toast.info(`Copied this ${this.environment.titleAccount} url to clipboard`, 'Success');
  }
  // Legacy contact:
  editSaleAccountType(event: number[]) {
    if (event) {
      if (JSON.stringify(this.selectType.sort()) !== JSON.stringify(event.sort())) {
        // if (this.saleAccountTypeComponent) this.saleAccountTypeComponent.isLoading = true;
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, event)
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
              // if (this.saleAccountTypeComponent) this.saleAccountTypeComponent.isLoading = false;
            }
          });
      }
    }
  }

  sendMailClick(profile: ProfileDetailModel = null) {
    const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        profileModel: profile ?? this.saleAccountModel,
        entity: 'profile',
        optionTaskId: this.optionalTaskId,
        lastSentEmailEntity: this.environment.titleAccount
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response != null) {
        // return when closed
      }
    });
  }

  async onClickCall(alternativeContact: Contact = null) {
    this.isCallLoading = true;
    await this.callService.makeACall(
      alternativeContact ?? this.saleAccountModel.contact,
      this.saleAccountModel.profileContacts,
      this.user.nameid,
      this.saleAccountModel.profileId,
      this.creatableTask,
      this.optionalTaskId ? parseInt(this.optionalTaskId) : null,
      this.isCallLoading,
      this.toast,
      this.isCalling,
      'profile'
    );
    setTimeout(() => {
      this.isCallLoading = false;
    }, 3000);
  }

  createTask() {
    if (this.addNewTask) {
      const dialogRef = this.dialog.open(this.addNewTask, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          action: TblActionType.Add,
          profile: this.saleAccountModel,
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.toast.success('Create task success!', 'Success');
          if (this.taskGrid) this.taskGrid.refreshData();
        }
      });
    }
  }
  getSettingToHideSaleOpportunity() {
    // 2022-02-03 ducqm start add
    // this.setting.getSettingByKeyAndGroup("PARTNER_TYPE", "PROFILE").subscribe(resp => {
    //   if (resp.result && resp.result.value) {
    //     this.partnerType = resp.result.value.split(",").map(Number);
    //     this.hideOpportunityConfig();
    //   }
    // })
    this.settingPoolService.getSettingByKeyAndGroup("PARTNER_TYPE", "PROFILE").subscribe(resp => {
      if (resp.result && resp.result.value) {
        this.partnerType = resp.result.value.split(",").map(Number);
        this.hideOpportunityConfig();
      }
    })
    // 2022-02-03 ducqm end add
  }
  hideOpportunityConfig() {
    if (this.partnerType && this.partnerType.length > 0) {
      this.isHideOpportunity = false;
      this.partnerType.forEach(e => {
        const findExisted = this.selectType.find(x => x == e);
        if (findExisted) {
          this.isHideOpportunity = true;
        }
      });
      //this.isHideOpportunity = listPartnerLst && listPartnerLst.length > 0 ? true : false;
    }
  }
  copyGUIDToClipboard() {
    this.clipboard.copy(this.id);
    this.toast.info(`Copied this ${this.environment.titleAccount} ID to clipboard`, 'Success');
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }

  onAddOpportunityClick() {
    const dialog = this.dialog.open(OpportunityModalComponent, {
      hasBackdrop: true,
      autoFocus: false,
      disableClose: true,
      data: {
        actionType: TblActionType.Add,
        saleAccountId: this.id,
        companyName: this.saleAccountModel.displayName
      }
    });

    dialog.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          if (this.opportunityTab)
            this.opportunityTab.refreshData();
        }
      }
    });
  }

  updatePrimaryContact(contactLst: Contact[]) {
    if (this.saleAccountModel) {
      let primaryContact = contactLst.find(x => x.primaryContact == true);
      //this.contacts = contactLst ? contactLst.map((x: any) => x.contact) : this.contacts;
      if (!this.contacts.find(x => x.contactId == this.saleAccountModel.saleLeadsContact.contactId)) {
        this.contacts.push(this.saleAccountModel.saleLeadsContact);
      }
      if (primaryContact) {
        this.saleAccountModel.contact = primaryContact;
        this.saleAccountModel.contactId = primaryContact.contactId;
        this.saleAccountModel.accountContactId = primaryContact.contactId;
        this.isHasPrimaryContact = this.saleAccountModel.contact && !this.saleAccountModel.contact.deleted ? true : false;
      } else {
        this.saleAccountModel.contact = null;
        this.saleAccountModel.contactId = null;
        this.saleAccountModel.accountContactId = 0;
        this.isHasPrimaryContact = false;
      }
    }
  }

  tabIndexChanged(tabIndex: number) {
    this.currentTab = tabIndex;
    if (this.router.url.includes('configuration/sale-account')) {
      Helper.handleTabChangedUrl(tabIndex, '/configuration/sale-account');
      this.tabModeService.setTagIndexToCurrentTab(tabIndex, this.id);
    }
  }

  cleanComponentTab() {
    this.noteTab = null;
    this.contactTab = null;
    this.locationGrid = null;
    this.opportunityTab = null;
    this.resellerTab = null;
    this.relationshipTab = null;
  }

  formatCurrencyPrefix() {
    this.prefixCurrency = `<div class="currency-color">${localStorage.getItem("currency") ?? ""}</div>`
  }

  getLastNote(resp?: number, isDelete: boolean = false) {
    if (this.lastNote && this.lastNote.id == resp && isDelete)
      this.lastNote = null;

    if ((this.lastNote && this.lastNote.id <= resp && this.lastNote.referenceId == this.id) || !this.lastNote)
      this.noteService.lastNoteByReferenceId(this.id, ShadowProfileEnum[ShadowProfileEnum.SALEACCOUNT])
        .subscribe({
          next: resp => {
            if (resp.result)
              this.lastNote = Object.assign(resp.result, {
                innerTextNote: Helper.removeStyleHtml(resp.result.note),
                ownerNote: (`${resp.result.user?.firstName || ''} ${resp.result.user?.lastName || ''}`).trim() || resp.result.user?.userName || 'Unknown',
                avatarNote: resp.result.user?.pictureURL?.replace(/\\/g, '/'),
                //referenceName: this.saleAccountModel.displayName
              })
          },
          //complete: () => this.overlayNoteDetails.completedLoading()
        });
  }

  savePrimaryResellerOrRelationship = (data: ProfileDetailModel, type: string) => {
    if (data && type) {
      this.isLoadingEdit = true;
      this.saleAccountService.changePrimaryResellerOrRelationship(data.profileId, this.id, type).subscribe({
        next: res => {
          if (res.message) this.isLoadingEdit = false;
          if (res.result) {
            this.toast.success(`Save primary ${type?.toLowerCase()} success!`, "Success");
            if (this.resellerTab && type == "Reseller") this.resellerTab.refreshData();
            if (this.relationshipTab && type == "Relationship") this.relationshipTab.refreshData();
            this.refreshData();
          }
        },
        error: (err) => this.isLoadingEdit = false,
      })
    }
  }

  callerAddEditResellerOrRelationship(data: ProfileDetailModel, type: string) {
    this.toast.success(`Save add new ${type?.toLowerCase()} success!`, "Success");
    this.saleAccountService.addEditResellerOrRelationship(this.id, data.profileId, type).subscribe(res => {
      if (res.result) {
        if (this.resellerTab && type == "Reseller") this.resellerTab.refreshData();
        if (this.relationshipTab && type == "Relationship") this.relationshipTab.refreshData();
        if (this.opportunityTab) this.opportunityTab.refreshData();
      }
    })
  }

  openPopupFindingResellerOrRelationship(isReseller: boolean = true) {
    const dialogRef = this.dialog.open(AddExistingProfileComponent, {
      width: '40vw',
      height: '60vh',
      data: {
        id: this.id,
        isProfile: true,
        placeholder: `Search ${isReseller ? 'reseller' : 'relationship'}...`,
        label: isReseller ? 'Reseller' : 'Relationship',
        apiSearch: (data) => this.saleAccountService.searchSaleAccount(data),
        isListenEvent: true,
        firstSuggest: true,
        firstSuggestAPI: this.saleAccountService.suggestRecentlyAddedSaleAccount(this.id),
        idProp: 'profileId'
      }
    });

    dialogRef.afterClosed().subscribe((data: ProfileDetailModel) => {
      if (data && this.id)
        this.callerAddEditResellerOrRelationship(data, isReseller ? "Reseller" : "Relationship");
    });
  }



  openLocationDialog() {
    const locationDialogRef = this.dialog.open(LocationComponent, {
      disableClose: true,
      height: '80vh',
      width: '55vw',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        profileId: this.id,
        isUpdateAction: false,
        selfGetApi: false,
        isDialog: true,
        activeSetPrimary: false,
        closeDialogEvent: (result) => {
          if (locationDialogRef) {
            locationDialogRef.close(result);
          }
        }
      }
    });
    locationDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshData();
        this.locationGrid.refreshData(true);

      }
    });
  }
  setupContactType() {
    if (this.saleAccountModel?.profileId == this.id)
      this.selectType.forEach(item => {
        let itemInstance = this.accountContactTypes.find(x => x.contactTypeId == item);
        if (itemInstance && itemInstance.parentId > 0) {
          // if has parent: this is child:
          this.selectedContactTypeChild = new DropDownValue(itemInstance.contactTypeName, itemInstance.contactTypeId.toString(), itemInstance.contactTypeColorCode);
        }
        if (itemInstance && itemInstance.parentId == 0) {
          this.selectedContactTypeParent = new DropDownValue(itemInstance.contactTypeName, itemInstance.contactTypeId.toString(), itemInstance.contactTypeColorCode);
          // filter child dropdown:
          let childIds = this.accountContactTypes.slice().filter(x => x.parentId == itemInstance.contactTypeId).map(x => x.contactTypeId.toString());
          this.subContactTypeAsDropdownModel = this.allContactTypesAsDropdownModel.slice().filter(x => childIds.includes(x.value));
        }
      });
  }


  editNewSingleContactType(event: DropDownValue, type: string) {
    if (event) {
      let id: number = parseInt(event.value);
      let selectedTarget = this.accountContactTypes.find(x => x.contactTypeId === id);
      if (selectedTarget) {
        if (type === AccountSingleContactType.Parent) {
          // parent change
          if (!this.selectedContactTypeParent || (this.selectedContactTypeParent.value !== event.value)) {
            this.selectedContactTypeParent = event;
            this.selectedContactTypeChild = null;
            let newParent = this.accountContactTypes.find(x => x.contactTypeId === parseInt(this.selectedContactTypeParent.value));
            this._filterDropdownContactTypeChild(newParent);
          } else {
            // same result:
            return;
          }
        } else if (type === AccountSingleContactType.Child) {
          if (!this.selectedContactTypeChild || (this.selectedContactTypeChild.value !== event.value)) {
            // child change:
            this.selectedContactTypeChild = event;
          } else {
            // same result:
            return
          }
        }
        // filter the seleted Ids:
        let newIds = [];
        if (this.selectedContactTypeParent && !isNaN(parseInt(this.selectedContactTypeParent.value))) newIds.push(parseInt(this.selectedContactTypeParent.value));

        if (this.selectedContactTypeChild && !isNaN(parseInt(this.selectedContactTypeChild.value))) newIds.push(this.selectedContactTypeChild.value);
        // call api:
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, newIds)
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
            }
          });
      }
    } else {
      if (this.selectedContactTypeParent && type === AccountSingleContactType.Parent) {
        this.selectedContactTypeParent = undefined;
        this.selectedContactTypeChild = undefined;
        this.subContactTypeAsDropdownModel = [];
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, [])
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
            }
          });
      } else if (this.selectedContactTypeChild && type === AccountSingleContactType.Child) {
        this.selectedContactTypeChild = undefined;
        this.contactService.editContactContactType(this.saleAccountModel.saleLeadsContact.contactId, this.selectedContactTypeParent ? [parseInt(this.selectedContactTypeParent.value)] : [])
          .pipe(tap(() => this.saleAccountService.isRefreshSaleGrid$.next(true)))
          .subscribe({
            next: resp => {
              if (resp.result) {
                this.toast.success(`Edit ${this.environment.titleAccount} success!`, "Success");
                this.refreshData();
              }
            },
            complete: () => {
            }
          });
      }
    }
  }
  // filter the dropdown of sub contact type:
  private _filterDropdownContactTypeChild(itemInstance: ContactTypeFilter) {
    if (!itemInstance) return;
    let childIds = this.accountContactTypes.slice().filter(x => x.parentId == itemInstance.contactTypeId).map(x => x.contactTypeId.toString());
    this.subContactTypeAsDropdownModel = this.allContactTypesAsDropdownModel.slice().filter(x => childIds.includes(x.value));
  }
  addSaleAccountFile(fileInputEvent: any) {
    this.uploading = true;
    this.uploadedFile = fileInputEvent.target.files[0];
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    let IsAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    if (IsAccept) {
      let submitModel = new UserUpload();
      submitModel.fileName = this.uploadedFile.name;
      submitModel.referenceType = this.saleAccountModel.typeName;
      submitModel.referenceId = this.saleAccountModel.profileId;
      submitModel.owners = this.user.nameid;
      this.userUploadService.uploadFile(this.uploadedFile, submitModel).pipe(this.hotToast.observe(
        {
          loading: 'Uploading file...',
          success: (s) => 'file successfully uploaded',
          error: (e) => 'An error occurred while uploading...'
        }
      )).subscribe({
        next: res => {
          if (res.result) {
            this.uploading = false;
            // this.toast.success('Upload file successfully', 'Success');
            this.fileTab?.refreshData(true);
          }
        },
        complete: () => {
        }
      });
    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pdf,html,zip,csv,txt', "Warning");
    }
  }
  saveIndividualProfileAdditionDetail(data: string, type: string) {
    if (data) {
      // save:
      this.isLoadingEdit = true;
      let model: ProfileAdditionDetail = new ProfileAdditionDetail();
      model[type] = data;
      model.profileId = this.id;
      this.profileAdditionDetailService.saveSingleProfileAdditionByPropName(type, model).pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res.result) {
          this.toast.success(`Edit ${this.environment.titleAccount} success`, 'Successfully');
          this.refreshData();
          this.isLoadingEdit = false;

        }
      })
    }
  }

  getSelectedContactMail(contact: Contact) {
    debugger;
    if (contact) {
      let newProfileModel = Object.assign(this.saleAccountModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.sendMailClick(newProfileModel);
    } else {
      // skip mode (select primary contact)
      this.sendMailClick(null);
    }
  }
  getSelectedContactCall(contact: Contact) {
    debugger;
    if (contact) {
      let newProfileModel = Object.assign(this.saleAccountModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.onClickCall(newProfileModel.contact);
    } else {
      // skip mode (select primary contact)
      this.onClickCall(null);
    }
  }
  get checkNumberOfContact() {
    return this.saleAccountModel?.profileContacts?.length ?? 0;
  }
  confirmSendMailClick() {
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to send email to ${Helper.getEmailFromContact(this.saleAccountModel?.saleLeadsContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.sendMailClick();
      }
    });
  }
  confirmMakeACall() {
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to make a call to number ${Helper.getPhoneFromContact(this.saleAccountModel?.saleLeadsContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.onClickCall();
      }
    });
  }
  createContactCampaign() {
    if (!this.contactTab) {
      this.toast.warning('Please select to contact tab to start creating campaign');
      return;
    }
    const dialogRef1 = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to create campaign with ${this.numberPipe.transform((this.contactTab?.primasTable.selected && this.contactTab?.primasTable.selected.length != 0) ? this.contactTab?.primasTable.selected.length : this.contactTab?.primasTable.table.rowCount)} item(s)?`
      }
    });
    dialogRef1.afterClosed().subscribe(response => {
      if (response) {
        const campaignData: ContactPage = Object.assign({}, this.contactTab.primasTable.page, { relationshipId: this.saleAccountModel.profileId, type: "Profile" });
        const ComponentOpen: any = ReviewContactCampaignComponent


        const dialogRef = this.dialog.open(ComponentOpen, {
          width: '1300px',
          disableClose: true,
          autoFocus: false,
          data: {
            contactPage: campaignData,
            isSelectAll: true,
            externalComponent: true,
          }
        });
        dialogRef.afterClosed().subscribe(e => {
          this.contactTab.primasTable.selected = [];
        });
      }
    });
  }

  onClickImport(dataImport): void {
    this.profileService.importContactWithRelationship(dataImport, this.saleAccountModel.profileId).subscribe(resp => {
      this.contactButton.finishImport(resp);
      this.refreshData();
    });
  }

  async onClickAdvanceImport(action: string) {
    if (action == "Skip") {
      const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to skip 1 item(s)?`
        }, disableClose: true,
      })
      const result = await dialogConfirm.afterClosed().toPromise();
      if (result) {
        this.onAdvancedImport.emit(action);
        this.dialModalRef.close();
      }
    }
    else
      this.onAdvancedImport.emit(action);
  }
  async getLastIVR() {
    try {
      const result = await this.saleAccountService.getLastVitalSign(this.id).toPromise();
      if (result && result.result) {
        this.lastVitalSignInfo = result.result;
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }

  convertExtendToList() {
    let keyPairLst = [];
    if (this.extendData) {
      for (const key in this.extendData) {
        if (Object.prototype.hasOwnProperty.call(this.extendData, key)) {
          if (key == 'ANI' || key == 'Gender' || key == 'DOB' || key == 'Age'
            || key == 'PatientName' || key == 'Weight' || key == 'PatientId'
            || key == 'Pregnant' || key == 'Doctor')
            continue;
          var column = {
            name: Helper.displayNameProp(key),
            prop: key,
            value: this.extendData[key],
          };
          keyPairLst.push(column);
        }
      }
    }
    return keyPairLst;
  }

  refreshScheduleTable(data) {
    if (data) {
      this.scheduleGroupComponent.refreshData(true)
    }
  }
  async saveExtendData(value, key) {
    let changeObject = {};
    changeObject[key] = value;
    const jsonString =
      this.saleAccountModel.extendData
        ? JSON.stringify(Object.assign(JSON.parse(this.saleAccountModel.extendData), changeObject)) : JSON.stringify(changeObject);
    const result = await this.profileService.saveExtendDataProfile(this.id, jsonString).toPromise();
    if (result.result) {
      this.toast.success(`Saved`, 'Success');
      this.refreshData();
    }
  }

  async saveExtendDataFromDropDown(event, key) {
    if (event?.value == "-1" && key == "Doctor") event.value = null;
    let value = event.value;
    await this.saveExtendData(value, key);
  }

  async saveExtendDataFromDatePicker(event, key) {
    let value = event.value;
    await this.saveExtendData(value, key);
  }

  reloadData() {
    this.isLoading = true;
    this.refreshData();
    this.getLastIVR();
  }
  openDescription(key: string, img: string = null): void {
    const data = vitalDescription.find(x => x.key == key);
    const vitalData = this.lastVitalSignInfo[key];
    const normal = this.vitalSafety(key);
    this.dialog.open(VitalHelperComponent, { data: { vitalInfo: data, vitalData, img,normal } });
  }
  vitalSafety(key) {
    const vitalData = this.lastVitalSignInfo ? this.lastVitalSignInfo[key] : null;
    const vitalDataNumber = +vitalData;
    let normal = false;
    switch (key) {
      case 'HR': {
        normal = vitalDataNumber >= 60 && vitalDataNumber <= 100;
        break;
      }
      case 'BR': {
        normal = vitalDataNumber >= 12 && vitalDataNumber <= 20;
        break;
      }
      case 'HRV': {
        normal = vitalDataNumber > 49;
        break;
      }
      case 'PNS': {
        normal = vitalDataNumber > 1 && vitalDataNumber < 3;
        break;
      }
      case 'SL': {
        normal = vitalDataNumber >= 1 && vitalDataNumber <= 3;
        break;
      }
      case 'BP': {
        if(vitalData) {
          const bloodPressure = vitalData.split('/');
          if(bloodPressure.length >=2) normal = bloodPressure[0] < 121 && bloodPressure[1] < 81;
        }
        break;

      }
      case 'HG': {
        normal = vitalDataNumber >= 9 && vitalDataNumber <= 17;
        break;
      }
      case 'A1c': {
        normal = vitalDataNumber >= 3 && vitalDataNumber <= 6;
        break;
      }
      default: {
        break;
      }
    }
    return normal;
  }
}

export enum TypeEditModelSaleAccount {
  SaleAccount,
  Contact,
  Location
}

export enum TabSaleAccount {
  Contact,
  Opportunity,
  Reseller,
  Location,
  MoreInfo, //ExtendData
  Relationship,
  Files,
  Note,
  Tasks,
  ActivityLog
}

class AccountContactType {
  key: string;
  value: string;
  color: string;
  parentId: string;
}
enum AccountSingleContactType {
  Parent = 'parent',
  Child = 'child'
}

export const vitalDescription = [
  {
    key: 'HR',
    title: 'Heart Rate',
    unit: 'BPM',
    range: `Normal range: 60 - 100`,
    description: `<p>The heart rate vital sign measures the number of times the heart beats per minute. The normal resting
  heart rate
  for
  a healthy adult is between 60-100 bpm. Think of the heart as a pump that pushes blood throughout the
  body. With
  every beat, the heart pumps blood containing oxygen and nutrients around the body and brings back waste
  products. A
  healthy heart supplies the body with the right amount of blood at a rate proportionate to whatever
  activity the
  body
  is undertaking.</p>
<p>
  Normal resting rates can differ between people. Furthermore, heart rates are lower when at rest and
  increase
  during
  exercise and can be influenced by factors like, weather, body position, emotions, body size, medication,
  and use
  of
  caffeine and nicotine.</p>
<p>
  At rest, a fast heart rate may indicate acute health conditions such as an infection, dehydration,
  stress,
  anxiety,
  thyroid disorder, shock, anemia, or certain heart conditions. Moreover, it can predict a long term risk
  for
  cardiovascular events. A low heart rate is common for people who exercise frequently and participate in
  athletics.
</p>
<p>
  Tracking heart rate can provide insight into fitness levels, heart health, and emotional health. For
  individuals
  taking medication for cardiovascular conditions, daily heart rate measurements can assist the doctor in
  advising
  on the proper course of treatment.
</p`
  },
  {
    key: 'BP',
    title: 'Blood Pressure',
    unit: 'mmHg',
    range: `Systolic < 121 Diastolic < 81`,
    description: `<p>Blood pressure is a critical vital sign that measures the pressure of circulating blood against artery walls, and it is measured in two numbers. The first number, or systolic pressure, refers to the pressure inside the artery when the heart contracts and pumps blood throughout the body. The second number, or diastolic pressure, refers to the pressure inside the artery when the heart is at rest and is filling with blood. </p>
    <p>Most people don’t know if they have high blood pressure – especially since there may be no noticeable warning signs or symptoms – and therefore the blood pressure must be monitored.</p>
    <p>High blood pressure (hypertension), which is a major risk factor for health problems such as heart disease, heart attack, and stroke. In most cases, high blood pressure has no defined cause, and it is called primary hypertension. However, it is related to unhealthy lifestyles such as physical inactivity, stress, obesity, shift-work, pregnancy, etc. It should be emphasized that blood pressure can be managed through diagnosis, lifestyle changes, medication and long-term monitoring.</p>
    <p>Blood pressure is categorized as low, normal, or elevated: Low blood pressure is a systolic of less than 100, normal blood pressure is a systolic of 100 to 129, while elevated blood pressure is systolic of 130 or higher.</p>`
  },
  {
    key: 'BR',
    title: 'Breathing Rate',
    unit: 'BRPM',
    range: `Normal range: 12 - 20`,
    description: `<p>Breathing rate is a vital sign that measures the number of breaths taken per minute. The normal breathing rate for adults at rest ranges between 12-20 breaths per minute, but may reach up to 24 breaths per minute for some older individuals. </p>
    <p>When inhaling, oxygen enters the lungs and circulates to the various internal organs. When exhaling, carbon dioxide moves out of the body. The breathing rate plays a critical role in keeping the balance of oxygen and carbon dioxide even in the body. If the carbon dioxide level in the blood is high and the oxygen level in the blood is low, breathing rate increases.</p>
    <p>Various factors affect breathing rates, including injuries, exercise, fever, anxiety, emotions, mood, alcohol, medication, metabolic issues, and medical conditions. A high or low rate might be the result of an activity, and therefore does not indicate that there is anything wrong. </p>
    <p>Monitoring the breathing rate vital sign can assist the doctor in providing medical advice. Furthermore, Athletes may use respiratory rate as an objective indicator of how hard they are working. </p>`
  },
  {
    key: 'HRV',
    title: 'Heart Rate Variability',
    unit: 'MA',
    range: `Normal range: above 49`,
    description: `<p>A healthy heart does not beat at regular intervals as would a metronome; rather, there are variations in time between beat to beat. These variations are measured by the Heart Rate Variability (HRV) metric and they reflect the functions of the parasympathetic and sympathetic nervous systems, the two components of the Autonomic Nervous System (ANS), which controls heart activity. </p>
    <p>The sympathetic system is activated when the body is under stress, causing the heart to beat faster and more regularly, and causing HRV to decrease. The parasympathetic system manages the heart’s activity to help the body reach a relaxed state and to recover from a stressful event. This relaxation response results in a slower and less regular heartbeat and is indicated by a higher HRV.  </p>
    <p>High levels of HRV generally indicate aerobic and general fitness. Athletes may track HRV to adjust their training program. They can learn when the body is being overworked, which often results in a drop in HRV and to learn how fast they recover. </p>`
  },
  {
    key: 'PNS',
    title: 'Recovery Ability',
    unit: 'PNS',
    range: `Normal level: above 1 and below 3`,
    description: `<p>The parasympathetic nervous system (PNS) regulates the body’s “rest and digest” response and plays an important role in alleviating stress and promoting recovery. It does so by inhibiting the activity of the sympathetic nervous system and the release of stress hormones, and returning bodily functions to their resting state by slowing the heart rate, lowering blood pressure, reducing muscle tension and restoring regular breathing, digestion and glandular activity. </p>
    <p>The parasympathetic metric measures the activity of the PNS and indicates how capable a person is of relaxing or recovering after a stressful occurrence. A low score would indicate a stressful state, while a high score would suggest a relaxed one. </p>`
  },
  {
    key: 'SL',
    title: 'Stress Level',
    unit: 'LEVEL',
    range: `Normal level is 1-3`,
    description: `<p>The sympathetic nervous system promotes the body’s response to stress, commonly referred to as the “fight or flight” response. When preparing for an emergency, the sympathetic nervous system (SNS) activates numerous complex pathways and components. These physiological activities help to achieve a faster heart rate, breathing rate, and blood pressure. Noticeable changes include blood flow that moves away from the skin and stomach, and is redirected from the intestines to the brain, heart, and muscles, as well as sweating, “goose-bumps”, dilation of the pupils, and a host of other feelings that appear during the stress response. </p>
    <p>The sympathetic stress metric uses HRV to calculate the magnitude of one’s stress response, i.e., how stressed a person is. A low score would indicate a relaxed state, while a high score would suggest a stressed state. </p>`
  },
  {
    key: 'HG',
    title: 'Hemoglobin',
    unit: 'g/dL',
    range: `Normal range: 9 - 17`,
    description: `<p>Hemoglobin - is a protein in a person’s red blood cells that carries oxygen to the human body's organs and tissues and transports carbon dioxide from your organs and tissues back to your lungs. </p>
    <p>Hemoglobin, perhaps the best studied of all macromolecules, has not revealed all its secrets even at the clinically relevant levels, to say nothing of biophysical studies at the levels of its atoms and electrons. In recent years, although unexpected new functions have been found, the central goal of most biomedical hemoglobin research has been the development of a mechanistic description of the developmental control of the α- and β-globin gene clusters. </p>
    <p>This field of research has been of great interest to those interested in the whole range of hemoglobin studies—from the most basic molecular genetics, to various “translational” models, to clinical problems in treating patients. It has been the hope that understanding these control mechanisms would lead to the discovery or design of drugs to treat the genetic hemoglobin diseases by efficient elevation of fetal hemoglobin and would also improve the efficiency of stem cell and gene transfer approaches to therapy.  </p>
    <p>Although some of these therapies have progressed greatly during this period, we are still far from understanding the basic processes controlling developmental changes in the globin gene clusters. Despite the enormous body of experimental data obtained from cell, animal, and clinical studies, no predictive model has yet been proposed to explain the control of this obviously complex system.</p>`
  },
  {
    key: 'A1c',
    title: 'Hemoglobin A1c',
    unit: '%',
    range: `Normal range: 3 - 6`,
    description: `<p>Hemoglobin A1C - represents the average blood glucose (sugar) level for the last two to three months. HbA1c is measured in percentage with resolution up to 0.01% </p>
    <p>Hemoglobin is a protein only found in red blood cells. In fact, hemoglobin is what gives blood its bright red coloring. Since red blood cells live about an average of three months, the A1c test will reflect those red blood cells that are present in the bloodstream at the time of the test; this is why the A1c serves as an average of blood sugar control. </p>
    <p>The main job of hemoglobin is to carry oxygen from the lungs to all the cells of the body. Hemoglobin becomes glycated or coated with glucose from the bloodstream. The amount of glucose that is present in the blood will attach to the hemoglobin protein, and increased glucose levels will reflect on the surface of the hemoglobin protein, thereby rendering a higher A1c level.</p>`
  }

]
