import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UploadVideoSpotlightr } from '../components/stand-alone-component/upload-video/upload-video-spotlightr-model';

@Injectable({
  providedIn: 'root'
})
export class SpotlightrService {
  private http: HttpClient;
  private _obsVideoUpload$: ReplaySubject<UploadVideoSpotlightr> = new ReplaySubject<UploadVideoSpotlightr>(1);

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  apiBase = environment.apiSpotlightr;

  createVideoSpotlightr(data: FormData) {
    return this.http.post(`${this.apiBase}/createVideo`, data, { responseType: 'text' });
  }

  changeObsVideoUpload(model: UploadVideoSpotlightr) {
    this._obsVideoUpload$.next(model);
  }

  getObsVideoUploadSpotlightr(): Observable<UploadVideoSpotlightr> {
    return this._obsVideoUpload$.asObservable();
  }
}
