import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges, AfterViewInit, TemplateRef, ViewChild, Inject, AfterContentInit, ViewEncapsulation, Output, EventEmitter, ElementRef
} from '@angular/core';
import { Media, MediaResizeConfigEnums, MediaType } from './multi-media.model';
import baguetteBox from 'baguettebox.js';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MutliMediaService } from 'src/app/shared/services/mutli-media.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { file, RxwebValidators } from '@rxweb/reactive-form-validators';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, Observable, of } from 'rxjs';
import { ContactComponent } from 'src/app/shared/components/stand-alone-component/contact/contact.component';

//2021-11-5 HMTien add start
import { FileValidator } from 'ngx-material-file-input';
import { Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UploadVideoComponent } from 'src/app/shared/components/stand-alone-component/upload-video/upload-video.component';
import { SpotlightrService } from 'src/app/shared/services/spotlightr.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Helper } from 'src/app/shared/utility/Helper';
import { ResizeImageComponent } from 'src/app/shared/components/stand-alone-component/resize-image/resize-image.component';
import { profile } from 'console';
import { switchMap } from 'rxjs/operators';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';
//2021-11-5 HMT add end


@Component({
  selector: 'app-multi-media',
  templateUrl: './multi-media.component.html',
  styleUrls: ['./multi-media.component.scss'],
})
export class MultiMediaComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('wrapperContainer', { static: true }) wrapperContainer: ElementRef;
  @ViewChild('uploadImg') dialogRef: TemplateRef<any>;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('uploadVideo') uploadVideoRef: TemplateRef<any>;
  @Input() profileId = '';
  @Input() displayName = '';
  @Input() primaryVideo: number;
  @Input() primaryImage: number = 0;
  //2021-11-1 vuonglqn start add
  @Input() readonlyMultiMedia = false;
  //2021-11-1 vuonglqn start end
  @Output() refreshData = new EventEmitter<any>();
  //2022-04-26 vuonglqn add start
  @Input() dataField: DisplayComponentMultimediaTab = DisplayComponentMultimediaTab.All;
  @Output() countPhotoVideo: EventEmitter<any> = new EventEmitter<any>();
  //2022-04-26 vuonglqn add end

  //2021-11-5 HMTien add start
  readonly maxSize = environment.maxImageSizeUpload;
  imgSrc: any | null;
  imgSrcFlag = new Image();
  imgSrcMedia = new Media();
  //imgSrc : any | null;
  //2021-11-5 HMTien add end
  webUrl = "((https?|ftp|gopher|telnet|file):((//)|(\\\\))+[\\w\\d:#@%/;$()~_?\\+-=\\\\\\.&]*)"
  files: File[] = [];
  photos: Media[] = [];
  cssPhoto: boolean[] = [];
  videos: Media[] = [];
  imageCheckLst: MediaCheck[] = [];
  videoCheckLst: MediaCheck[] = [];
  imageInput = this.formBuilder.group({
    input: ['', [RxwebValidators.extension({ extensions: ['png', 'jpg', 'jpeg'] }),
    //2021-11-5 HMTien add start
    Validators.required,
    FileValidator.maxContentSize(this.maxSize)]]
    //2021-11-5 HMTien add end

  });
  // RxwebValidators.image({ minHeight: 100, minWidth: 100 })
  // variable to validate model
  updateImageHolder;


  videoValidate = false;
  videoValidateMessage = 'Inavlid input type(Input must be an image)';
  deleteImages: [] = [];
  uploadDialogRef: any;
  gallery: any;
  videoGallery: any;
  uploadVideoDialogRef: any;
  // 2022-02-08 tienlm add start
  videoInput = this.formBuilder.group({
    videoUrlInput: ['', Validators.pattern(this.webUrl)]
  })
  // 2022-02-08 tienlm add end
  videoUrlInput = '';
  fileToUpload: File | null = null;
  loading = false;
  rawData: MediaType[] = [];
  deleteVideos: Media[] = [];
  deleteLoading = false;
  @Input() isDeleteMode = false;
  videoDeleteMode = false;
  isShowDragArea = false;
  menuTopLeftPosition = { x: '0', y: '0' };
  blockInput = false;
  obsUploadVideo = this.spotlightrService.getObsVideoUploadSpotlightr();
  isUploadVideoURL = false;

  constructor(
    private service: MutliMediaService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    private formBuilder: FormBuilder,
    private spotlightrService: SpotlightrService,
    private clipboard: Clipboard,

  ) { }
  ngAfterViewInit(): void {

  }
  reloadMulti(): void {
    this.service.getMediaByProfileId(this.profileId, this.dataField);
  }
  ngOnChanges(changes: SimpleChanges): void {
    const newChanges = changes.profileId;
    if (newChanges.currentValue !== newChanges.previousValue && newChanges.previousValue) {
      this.ngOnInit();
    }
  }
  // right click video
  handldeVideoRighClick(videoPayload: {
    event: MouseEvent,
    item: Media,
    table: string,
    type: string
  }) {
    // pay load include: event, item (media), table, type
    this.onRightClick(videoPayload.event, videoPayload.item, videoPayload.table, videoPayload.type);
  }
  // right click img
  onRightClick(event: MouseEvent, item, table: string, type: string) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item, table, type };

    // we open the menu
    this.matMenuTrigger.openMenu();

  }
  public menuAction() {
    console.log('menu clicked!');
  }

  showImage(e, index: number) {
    if (e.target.src) {
      this.gallery = baguetteBox.run('.gallery');
      e.preventDefault();
      setTimeout(() => {
        baguetteBox.show(index, this.gallery[0]);
      }, 200);
    } else {
      e.preventDefault();
    }
  }
  ngOnInit(): void {
    if (this.profileId !== null && this.profileId !== '') {
      this.loading = true;
      this.service.getMediaType().subscribe(item => {
        this.rawData = item;
        item.forEach(mt => {
          if (mt.mediaType1 === 'Image') {
            this.photos = mt.medias;
            this.imageCheckLst = [];
            this.cssPhoto = [];
            this.photos.forEach(photo => {
              //Mapping to thumbnailMedia and OriginalMedia
              var thumbnailMedia = photo.mediaResizes.find(x =>
                x.mediaResizeConfigId == Helper.getIdEnumTypeEnumString(MediaResizeConfigEnums, MediaResizeConfigEnums.Size320x191, 1));

              var originalMedia = photo.mediaResizes.find(x =>
                x.mediaResizeConfigId == Helper.getIdEnumTypeEnumString(MediaResizeConfigEnums, MediaResizeConfigEnums.SizeOriginal, 1));

              photo.thumbnailMedia = Helper.getMediaUrl(thumbnailMedia?.externalUrl, thumbnailMedia?.internalUrl, photo.media1);
              photo.originalMedia = Helper.getMediaUrl(originalMedia?.externalUrl, originalMedia?.internalUrl, photo.media1);
              photo.bkThumbnailMedia = photo.thumbnailMedia;
              //end

              this.imageCheckLst.push({
                value: false,
                photo
              });
              this.cssPhoto.push(true);
            });

          } else if (mt.mediaType1 === 'Video') {
            this.videos = mt.medias;
            this.videoCheckLst = [];
            this.videos.forEach(video => {
              this.videoCheckLst.push({
                value: false,
                photo: video
              });
            });
          }
        });
        this.loading = false;
        this.gallery = baguetteBox.run('.gallery', {
          async: true
        });
        this.videoGallery = baguetteBox.run('.video-gallery', {
          async: true
        });
        this.countPhotoVideo.emit({ photos: this.photos.length, videos: this.videos.length });
      });
      this.service.getMediaByProfileId(this.profileId, this.dataField);

    }

    // detect drag file:
    const drag$ = fromEvent(this.wrapperContainer.nativeElement, 'dragover');
    drag$.subscribe((event: DragEvent) => {
      const dt = event.dataTransfer;
      if (dt.types.length > 0 && !this.isShowDragArea) {
        // turn on drag file flag
        this.isShowDragArea = true;
      }
    });
  }
  onSelectClick() {
    this.isDeleteMode = true;
  }
  onVideoSelectMode() {
    this.videoDeleteMode = !this.videoDeleteMode;
  }
  // delete video
  onDeleteVideoClick() {
    const isExist = this.videoCheckLst.find(video => video.value === true);
    if (isExist === undefined) {
      this.videoDeleteMode = !this.videoDeleteMode;
    } else {
      // delete
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to delete these videos?`
        }
      });
      // catch delete value true of false
      dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          // delete
          const deleteVideo: Media[] = [];
          this.videoCheckLst.forEach(item => {
            if (item.value) {
              item.photo.media1 = item.photo.media1 ? item.photo.media1 : 'assets/images/error-video.jpg';
              deleteVideo.push(item.photo);
            }
          });
          this.service.removeMedias(deleteVideo).subscribe(result => {
            if (result.result) {
              this.toast.success('Delete video successfully!', 'Success');
              this.service.getMediaByProfileId(this.profileId, this.dataField);
            } else {
              this.toast.danger('Delete video fail. Please try again', 'Error');
              this.videoCheckLst.forEach(item => {
                item.value = false;
              });
            }
          });
          this.videoDeleteMode = !this.videoDeleteMode;
        }
      });
    }
  }
  onDeleteImagesClick() {
    // filter on photos which marked as deleted;
    const anyDeleteChange = this.imageCheckLst.filter(item => item.value === true);
    if (anyDeleteChange.length > 0) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to delete these images?`
        }
      });
      dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          const deletePhotos: Media[] = [];
          this.imageCheckLst.forEach(item => {
            if (item.value) {
              deletePhotos.push(item.photo);
            }
          });
          this.service.removeMedias(deletePhotos).subscribe(result => {
            if (result.result) {
              this.toast.success('Delete images successfully!', 'Success');
              this.service.getMediaByProfileId(this.profileId, this.dataField);
            } else {
              this.toast.danger('Delete image fail. Please try again');
              this.imageCheckLst.forEach(item => {
                item.value = false;
              });
            }
          });
          this.isDeleteMode = !this.isDeleteMode;
        }
      });
    } else {
      this.isDeleteMode = !this.isDeleteMode;
    }
  }
  // init baguettebox
  onManageClick() {
    this.gallery = baguetteBox.run('.gallery');
    this.imgSrc = this.imgSrcFlag;
    console.log(this.imgSrc);
    this.openAddImg();

  }
  handleFileInput(files: FileList, event) {

    this.fileToUpload = files.item(0);
    //03-2rd-2022 hmtien add start
    this.imgSrc = files.item(0);
    this.imgSrcMedia = this.imgSrc;
    // console.log(files.item(0));
    // console.log(this.imgSrc);
    //03-2rd-2022 hmtien add start
    // 2022-06-21 tienlm add start
    //  show crop component:
    let splitLen = this.imgSrc.name.split('.').length;
    if (this.imgSrc.name.split('.')[splitLen - 1] !== 'gif') {
      let cropDialogRef = this.openCropComponent(event);
      cropDialogRef.componentInstance.getImage.subscribe((data: Blob) => {
        let name = files.item(0).name;
        this.imgSrc = data;
        this.fileToUpload = new File([data], name);
        // console.log(data);
      });
    }

    // 2022-06-21 tienlm add end
    const img = new Image();
    const lastType = this.fileToUpload.name.split('.').pop().toLowerCase();
    if (lastType.includes('png') || lastType.includes('gif') || lastType.includes('jpeg') || lastType.includes('jpg')) {

      if (this.fileToUpload.size >= environment.maxImageSizeUpload) {
        this.blockInput = true;
        //03-2rd-2022 hmtien add start
        var maxSize = (environment.maxImageSizeUpload / 1048576).toFixed(2);

        //03-2rd-2022 hmtien add end
        this.videoValidateMessage = 'User must select file with lower size than ' + maxSize + ' mb';
      } else {
        img.src = window.URL.createObjectURL(files.item(0));
        img.onload = () => {
          if (img.height <= 100) {
            // do not allow to upload:
            console.log('not allow to upload');
            this.blockInput = true;
            this.videoValidateMessage = 'User must select image with higher height than 100';
          } else {
            this.blockInput = false;
            // this.videoValidateMessage = 'Invalid input type(Input must be an image)';
          }
        };
      }

    } else {
      this.blockInput = true;
      this.videoValidateMessage = 'Inavlid input type(Input must be an image)';
      return;
    }

  }
  openAddImg(): void {
    this.imageInput.reset();
    this.uploadDialogRef = this.dialog.open(this.dialogRef, {
      data: 'some data', width: '30vw',
      maxWidth: '30vw'
    });
  }
  closeAddImg() {
    if (this.uploadDialogRef) {
      this.imgSrc = new Image();
      this.uploadDialogRef.close();
    }
  }
  openVideoDialogRef() {
    this.uploadVideoDialogRef = this.dialog.open(this.uploadVideoRef, {
      width: '30vw',
      maxWidth: '30vw'
    });
  }
  closeVideoDialogRef() {
    if (this.uploadVideoDialogRef) {
      this.uploadVideoDialogRef.close();
    }
  }
  onUploadClick() {
    if (!this.imageInput.get('input').valid) {
      return;
    }
    this.service.uploadImage(this.profileId, this.fileToUpload).subscribe((item: any) => {
      if (item.url !== null) {
      }
      this.closeAddImg();
      this.toast.success('Upload image successfully!', 'Success');
      this.reloadMulti();
      this.refreshData.emit();

    });
  }
  // upload img
  onManageVideoClick() {

    if (this.videoInput.controls.videoUrlInput.valid) {
      let finalLink = '';
      let result: string | false;
      const test = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      this.videoValidate = false;
      // if (!this.videoInput.controls.videoUrlInput.value.toLowerCase().startsWith('http') || !this.videoInput.controls.videoUrlInput.value.toLowerCase().startsWith('https')) {
      //   debugger;
      //   this.videoValidateMessage = 'URL must start with https or https://';
      //   this.videoValidate = true;
      //   this.toast.danger('URL must start with https or https://','Failed');
      //   return
      // }
      // convert video url to embedded:
      result = this.youtube_parser(this.videoInput.controls.videoUrlInput.value);
      if (result === false) {
        // this is unknown link:
        // this.videoValidate = false;
        // this.toast.danger('Your video url does not supported yet. Please try another link');
        // return;
        finalLink = this.videoInput.controls.videoUrlInput.value;
      } else {
        this.videoValidate = true;
        finalLink = `https://www.youtube.com/embed/${result}`;
      }
      this.isUploadVideoURL = true;
      this.service.uploadVideoLink(this.profileId,
        {
          mediaId: null,
          media1: finalLink,
          profileId: this.profileId
        } as Media).subscribe((item: any) => {
          this.isUploadVideoURL = false;
          this.videoInput.reset({ videoUrlInput: '' });
          this.closeVideoDialogRef();
          this.toast.success('Upload video url successfully!', 'Success');
          this.reloadMulti();
        });
    }
  }
  // split name from url:
  splitNameFromUrl(url: string): string {
    const splitResult = url.split('\\');
    return url.split('\\')[splitResult.length - 1];
  }
  // on checkbox clicked (is image us flag to check on image when clicked)
  onImgChecked(state: boolean, index: number, isImg = false) {
    if (!isImg) {
      this.imageCheckLst[index].value = state;
    } else {
      this.imageCheckLst[index].value = !this.imageCheckLst[index].value;
    }
    console.log(this.imageCheckLst);
  }
  videoDeleteButton() {
    // check data
    if (this.videoCheckLst.filter(item => item.value === true).length > 0) {
      return true;
    }
    return false;
  }
  getDeletedVideos(videosNeedToDeleted: MediaCheck[]) {
    console.log(videosNeedToDeleted);
    this.videoCheckLst = videosNeedToDeleted;
  }
  // set primary for image:
  SetPrimaryMedia(media: Media, table: string, type: string) {
    this.loading = true;
    this.service.SetPrimaryMediaAsync(media, this.profileId, table, type).subscribe(resp => {
      if (resp.result) {
        this.toast.success('Change Primary Media successfully', 'Success');
        this.refreshData.emit();
      } else {
        this.toast.warning('Change Primary Media failed. Please try again', 'Error');
      }
      this.loading = false;
    });
  }
  youtube_parser(url): string | false {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }
  cancelDragBox() {
    this.isShowDragArea = false;
    this.files = [];
  }
  // select files
  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
    // this.imgSrc = event.target.files;
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
  //03-2rd-2022 hmtien add start
  onRemoveImg() {
    this.imgSrc = null;
  }
  //03-2rd-2022 hmtien add start

  uploadMultipleImage() {
    this.loading = true;
    if (this.files.length > 0) {
      this.service.uploadMultipleMediaAsync(this.profileId, this.files).subscribe(resp => {
        if (resp.result === true) {
          this.toast.success('Upload images successful', 'Success');
          this.files = [];
          this.isShowDragArea = false;
          this.refreshData.emit();
        }
        this.loading = false;
        this.reloadMulti();
      });
    }
  }
  // 2021-09-29 tien add test:
  // createContactForOwner() {
  //   const contactDialogRef = this.dialog.open(ContactComponent, {
  //     disableClose: true,
  //     height: '80vh',
  //     width: '65vw',
  //     panelClass: 'dialog-detail',
  //     autoFocus: false,
  //     data: {
  //       includeLocation: true,
  //       profileId: null,
  //       selfGetApi: false,
  //       isDialog: true,
  //       isUpdateAction: false,
  //       isAddMoreBtn: false,
  //       isDupplicatable: false,
  //       isSetPrimaryable: false,
  //       isOwnerMode: true,
  //       closeDialogEvent: (result) => {
  //         if (contactDialogRef) {
  //           contactDialogRef.close(result);
  //         }
  //       }
  //     }
  //   });
  // }
  changeSizeImage(url, index) {
    let result = true;
    const img = new Image();
    img.src = url;
    img.onload = () => {
      if (img.width / img.height >= 1.5) {
        // do not allow to upload:
        this.cssPhoto[index] = false;
        result = false;
      } else {
        this.cssPhoto[index] = true;
      }
    };
    return result;
  }
  // getMeta(url, callback) {
  //   const img = new Image();
  //   img.src = url;
  //   img.onload = function() { callback(this.width, this.height); }
  // }

  changePublishedMedia(index: Media) {
    if (index) {
      this.service.updatePublishedMedia(index.mediaId).subscribe(res => {
        if (res.result) {
          index.published = !index.published;
          this.toast.success('Saved');
        } else this.toast.danger('Failed');
      });
    }
  }

  openUploadVideo() {
    const dialogUploadVideo = this.dialog.open(UploadVideoComponent, {
      disableClose: true,
      width: '30vw',
      maxWidth: '30vw',
      autoFocus: false,
      data: {
        profileId: this.profileId,
        displayName: this.displayName,
      }
    })

    dialogUploadVideo.afterClosed().subscribe(res => {
      if (res) { }
    });
  }

  public get displayComponentMultimedia(): typeof DisplayComponentMultimediaTab {
    return DisplayComponentMultimediaTab;
  }

  onMoveUp(media: Media, table: string, type: string) {
    this.loading = true;
    this.service.moveUpMedia(media, this.profileId, table, type).subscribe(resp => {
      if (resp.result) {
        this.toast.success('Move up media successfully', 'Success');
        this.reloadMulti();
      } else {
        this.toast.warning('Move up media failed. Please try again', 'Warning');
      }
    }).add(() => this.loading = false);
  }

  onMoveDown(media: Media, table: string, type: string) {
    this.loading = true;
    this.service.moveDownMedia(media, this.profileId, table, type).subscribe(resp => {
      if (resp.result) {
        this.toast.success('Move down media successfully', 'Success');
        this.reloadMulti();
      } else {
        this.toast.warning('Move down media failed. Please try again', 'Warning');
      }
    }).add(() => this.loading = false);
  }

  onToTop(media: Media, table: string, type: string) {
    this.loading = true;
    this.service.moveToTopMedia(media, this.profileId, table, type).subscribe(resp => {
      if (resp.result) {
        this.toast.success('Move to top media successfully', 'Success');
        this.reloadMulti();
      } else {
        this.toast.warning('Move to top media failed. Please try again', 'Warning');
      }
    }).add(() => this.loading = false);
  }

  onToBottom(media: Media, table: string, type: string) {
    this.loading = true;
    this.service.moveToTheBottom(media, this.profileId, table, type).subscribe(resp => {
      if (resp.result) {
        this.toast.success('Move to the bottom media successfully', 'Success');
        this.reloadMulti();
      } else {
        this.toast.warning('Move to the bottom media failed. Please try again', 'Warning');
      }
    }).add(() => this.loading = false);
  }

  copyUrlMedia(media: Media, type: string) {
    if (media) {
      if (type == "Image") {
        this.clipboard.copy(Helper.isEmptyOrSpaces(media.originalMedia) ? 'https://' : media.originalMedia);
        this.toast.info(`Copied ${type} url to Clipboard`, 'Success')
      } else {
        //replace youtube.
        var result = this.youtube_parser(media.media1);
        if (result !== false) {
          media.media1 = `https://www.youtube.com/watch?v=${result}`;
        }

        this.clipboard.copy(Helper.isEmptyOrSpaces(media.media1) ? 'https://' : media.media1);
        this.toast.info(`Copied ${type} url to Clipboard`, 'Success')
      }
    } else this.toast.danger(`Copied ${type} url to Clipboard`, `Fail`)
  }

  dropzoneClicked(e) {
    console.log(e);
    e.preventDefault();
  }
  openCropComponent(event): MatDialogRef<ResizeImageComponent, any> {
    let componentRef = this.dialog.open(ResizeImageComponent, {
      disableClose: true,
      data: {
        imageChangedEvent: event,
        aspectRatio: 4 / 3,
        canvasRotation: 0,
        transform: {},
        showCropper: false,
        containWithinAspectRatio: false,
        type: 'event'
      }
    });
    return componentRef;
  }
  openResizeModal(photo: Media) {
    let names = photo.media1.split('Upload\\');
    let fileName = Helper.makeid(10) + '_resize';
    if (names.length > 1) {
      fileName = names[1];
    }
    let componentRef = this.dialog.open(ResizeImageComponent, {
      disableClose: true,
      data: {
        aspectRatio: 4 / 3,
        canvasRotation: 0,
        transform: {},
        showCropper: false,
        containWithinAspectRatio: false,
        type: 'url',
        imageURL: photo.media1
      }
    });
    componentRef.componentInstance.getImage.subscribe((res: Blob) => {
      if (res) {
        // debugger
        let name = fileName;
        let newFile = new File([res], name);
        this.updateImageHolder = newFile;
      }
    }, err => {
      this.toast.danger('Cannot get image from server', 'Error');
    });
    componentRef.afterClosed().subscribe(res => {
      if (res) {
        // call api update:
        this.service.updateImage(this.profileId, this.updateImageHolder, photo.mediaId).subscribe(response => {
          if (response.result) {
            this.toast.success('Update Image Success', 'Success');
            this.reloadMulti();
            this.refreshData.emit();
          }
        });
      }
    })
  }
  disableResizeGif(photo: Media) {
    let result = false;
    try {
      var fileName = photo.media1.split('/').pop();
      var fileExt = Helper.getFileExtension(fileName);
      if (fileExt != undefined && fileExt[0] == 'gif') {
        result = true;
      } else {
        result = false;
      }
    } catch (error) {
      return true;
    }
    return result;
  }

  uploadAndResizeMedia(media: Media) {
    if (media && media.mediaId > 0) {
      media.isLoading = true;
      this.service.uploadAndResizeImage(media.mediaId).subscribe(resp => {
        if (resp.result) this.toast.success('Resize media success', 'Success');
        else this.toast.success('Resize media fail', 'Error');
        media.isLoading = false;
      }, err => media.isLoading = false);
    }
  }
}

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}
export interface MediaCheck {
  value: boolean;
  photo: Media;
}

export enum DisplayComponentMultimediaTab {
  All,
  Photos,
  Videos,
}
