<div style="width: 100%;" >
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!isShowInAdmin">
            <h3>Support ticket management</h3>
            <button mat-icon-button (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="isShowInAdmin">
        <h5 appDetectTabMode>Support ticket management</h5>
    </div>
    <mat-divider></mat-divider>

</div>

<!-- <div *ngIf="isShowInAdmin"> -->
    <!-- <h5>Support Ticket Management</h5>
    <app-primas-toolbar #primasToolbar
        [selectedCout]="primasTable?.selected?.length" 
        (onAfterAddFinish)="refreshData(true)" 
        [customView]="false" [table]="primasTable"
        [columns]="columns" [resource]="resource.toolbar">
    </app-primas-toolbar> -->
    <!-- <app-primas-table
    #primasTable [columnsTable]="columns" [resource]="resource.table"
    [addEditComponet]="addEditComponet"
    (onRefresh)="refreshData($event)"
    ></app-primas-table> -->
<!-- </div> -->
<app-primas-table #primasTable [columnsTable]="columns" (onRefresh)="refreshData($event)" [addEditComponet]="addEditComponet"
[resource]="resource.table" [tableAction]="false" [allowSelectRow]="false" (activate)="openDetail($event)">
</app-primas-table>
<ng-template #description let-row="row">
    {{convertHtmlToNormalStyle(row.companyDescription)}}
</ng-template>
<ng-template #dateCreated let-row="row">
    <div>
        {{row.dateCreated | date : 'M/dd/y, h:mm:ss aaa'}}
    </div>
</ng-template>
<ng-template #titleName let-row="row" let-rowIndex="rowIndex">
    <div>
       <b>Support ticket {{row.ticketId}}: &nbsp;</b> <i style="font-weight:500">{{row.title || 'No data display'}}</i>
    </div>
</ng-template>
<ng-template  #resolution let-row="row">
    <div [ngClass]="row.resolutionCode ?  'badge badge-pill badge-success' : 'badge badge-pill badge-secondary'" style="font-size:11px">
        {{row.resolutionCode || 'WAITING FOR SUPPORT'}}
      </div>
</ng-template>