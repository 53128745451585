<h2 mat-dialog-title>Please Confirm The Changes To This Profile</h2>
<mat-dialog-content class="mat-typography" style="height:90vh;width: 70vw">
  <div  *ngIf="!loading; else loader">
    <h4 style="color: #ffc000;" *ngIf="profileUpdateInfomation && profileUpdateInfomation.modified.length > 0">
      <b>Changes made
        to this profile:</b>
    </h4>
    <div *ngIf="profileUpdateInfomation && profileUpdateInfomation.modified">
      <div *ngFor="let mod of profileUpdateInfomation.modified; let i = index">
        <app-merge-change-input [updateInformation]="mod" [entityReference]="getEntityByUpdateAct(mod)"
          (reLoadFunction)="reloadData()" (addToList)="addToList($event)" (removeFromLst)="removeFromLst($event)">
        </app-merge-change-input>
      </div>
    </div>
    <h4 style="color: #28a745;" *ngIf="profileUpdateInfomation && profileUpdateInfomation.added.length > 0"><b>Object
        added
        to this profile:</b></h4>
    <div *ngIf="profileUpdateInfomation && profileUpdateInfomation.added">
      <div *ngFor="let mod of profileUpdateInfomation.added; let i = index">
        <app-merge-change-input [updateInformation]="mod" [entityReference]="getEntityByUpdateAct(mod)"
          (reLoadFunction)="reloadData()" (addToList)="addToList($event)" (removeFromLst)="removeFromLst($event)">
        </app-merge-change-input>
      </div>
    </div>
    <h4 style="color: #F44336;" *ngIf="profileUpdateInfomation && profileUpdateInfomation.deleted.length > 0">
      <b>Detached from profile:</b>
    </h4>
    <div *ngIf="profileUpdateInfomation && profileUpdateInfomation.deleted">
      <div *ngFor="let mod of profileUpdateInfomation.deleted; let i = index">
        <app-merge-change-input [updateInformation]="mod" [entityReference]="getDeleteEntity(mod)"
          (reLoadFunction)="reloadData()" (addToList)="addToList($event)" (removeFromLst)="removeFromLst($event)">
        </app-merge-change-input>
      </div>
    </div>
  </div>
  <div *ngIf="!loading">
    <div
    *ngIf="!profileUpdateInfomation || (profileUpdateInfomation.added?.length == 0 && profileUpdateInfomation.modified?.length == 0)">
    <app-blank-page [iconSize]="'500px'" [message]="'No changes Made for this profile'"></app-blank-page>
  </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-raised-button color="primary" (click)="acceptAllChanges()">Accept all</button> -->
  <button *ngIf="profileUpdateInfomation?.added.length > 0 || profileUpdateInfomation?.modified.length > 0 || profileUpdateInfomation?.deleted.length >0" mat-raised-button color="warn" (click)="declineAll()" [nbSpinner]="declineAllLoading" [disabled]="declineAllLoading">Decline all</button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>


<ng-template #loader>
    <div class="gravatar" style="margin-bottom: 18px;width: 66vw;">
      <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
    </div>
</ng-template>