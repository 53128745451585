import { FormControl } from '@angular/forms';
import { prop, required } from '@rxweb/reactive-form-validators';
import { Observable, Subject } from 'rxjs';
import { CountryObject } from 'src/app/shared/models/country-object.model';

export class AltusLocation {
    @prop()
    locationId: number;
    @prop()
    city: string;
    @required()
    country: string = "United States of America";
    @prop()
    zipcode: string;
    @prop()
    address: string;
    @prop()
    address2: string;
    @prop()
    region: string;
    @prop()
    state: string;
    @prop()
    primaryLocation: boolean;
    @prop()
    nearestBigCity: string;
    @prop()
    willTravel: boolean;
    @prop()
    howFar: string;
    @prop()
    deleted: boolean = false;
    countryObservable: Observable<CountryObject[]>;
}
