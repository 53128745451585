import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmailTemplate } from 'src/app/modules/admin/setting-management/setting-campaign/email-template';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { EditorEmailComponent } from 'src/app/shared/components/stand-alone-component/editor-email/editor-email.component';
import { EmailQuillEditorComponent } from 'src/app/shared/components/stand-alone-component/email-quill-editor/email-quill-editor.component';
import { TableEntity } from 'src/app/shared/enums/table-entities.enum';
import { BodyEmbedded } from 'src/app/shared/models/body-embedded.model';
import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { SettingService } from 'src/app/shared/services/setting.service';
import { ProfileDetailModel } from '../../../profile-detail.model';
import { SingleMailBodyTemplate } from '../campaign-linkscope-email.model';
import { CampaignLinkscopeService } from '../campaign-linkscope.service';

@Component({
  selector: 'app-edit-email-linkscope',
  templateUrl: './edit-email-linkscope.component.html',
  styleUrls: ['./edit-email-linkscope.component.scss']
})
export class EditEmailLinkscopeComponent implements OnInit, OnDestroy {

  editorEmail: EditorEmailComponent
  @ViewChild('editorEmailLinkScope', { static: false }) set contentEditorEmail(content: EditorEmailComponent) { if (content) this.editorEmail = content };

  editorQuill: EmailQuillEditorComponent
  @ViewChild('quillEditor', { static: false }) set setEditorQuill(item: EmailQuillEditorComponent) { if (item) this.editorQuill = item };

  @Input() selectedTemplate: SingleMailBodyTemplate = null;
  @Input() profileModel: ProfileDetailModel;
  @Input() isNormalEditor: boolean = false;
  @Input() isClassicEditor: boolean = true;
  @Input() bodyEmbedded: BodyEmbedded = null;
  @Input() userModel: UserModel;
  @Input() targetEmail: string = "";
  frmTitle: FormGroup = this.frmBuilder.group({
    title: ['', RxwebValidators.required()]
  })
  // 2022-05-11 tienlm add start
  @Input() buyerModel: BuyerModel;
  @Input() entity = 'profile';
  // 2022-05-11 tienlm add end
  emailTemplate: EmailTemplate;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private frmBuilder: RxFormBuilder,
    private campaignService: CampaignLinkscopeService,
    private toast: NbToastrService,
    private settingService: SettingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<EditEmailLinkscopeComponent>,
  ) {
      if(this.data) {
        this.emailTemplate = this.data.selectedTemplate ?? this.selectedTemplate;
        this.isNormalEditor = this.data.isNormalEditor ?? this.isNormalEditor;
        this.isClassicEditor = this.data.isClassicEditor ?? this.isClassicEditor;
        if(this.data.title) this.frmTitle.controls['title'].setValue(this.data.title ?? '');
      }
  }

  ngOnInit(): void {
    if (this.selectedTemplate) {
      this.emailTemplate = {
        DesignTemplate: this.selectedTemplate.bodyJson,
        HtmlTemplate: this.selectedTemplate.body,
      } as EmailTemplate;

      this.frmTitle.patchValue({ title: this.selectedTemplate.title });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //Note: Can't add destroy to this function
  sendEmail() {
    if (this.frmTitle.valid) {
      if (!this.isNormalEditor) this.saveEmailWithUnLayer();
      else this.saveEmailWithQuill();
    }
  }

  saveEmailWithUnLayer() {
    this.editorEmail.exportHtml();
    this.editorEmail.getEmailHtml().subscribe(data => {
      this.sendEmailWithEditor(data);
    });
  }

  saveEmailWithQuill() {
    var data = this.editorQuill.getEmailHtmlQuill();
    this.sendEmailWithEditor(data);
  }

  sendEmailWithEditor(data: any) {
    if (data) {
      var bodyEmail = data.html;
      var titleEmail = this.frmTitle.controls['title'].value;
      if (this.entity.toLowerCase() == TableEntity.Profile.toLowerCase()) {
        this.campaignService.sendWithEditorEmail(this.profileModel, titleEmail, bodyEmail).subscribe(resp => {
          if (resp.result) {
            this.toast.success(`Send Email for ${this.profileModel.displayName} successfully`, 'Success');
          } else {
            this.toast.danger(`Send Email for ${this.profileModel.displayName} fail`, 'Error');
          }
        });
      } else if (this.entity.toLowerCase() == TableEntity.Buyer.toLowerCase()) {
        this.campaignService.sendWithEditorEmailByBuyerId(this.buyerModel, titleEmail, bodyEmail).subscribe(resp => {
          if (resp.result) {
            this.toast.success(`Send Email for ${this.buyerModel.companyName} successfully`, 'Success');
          } else {
            this.toast.danger(`Send Email for ${this.buyerModel.companyName} fail`, 'Error');
          }
        });
      }
      // send specific email
      else if (this.entity.toLowerCase() == TableEntity.NoEntity.toLowerCase()) {
        this.campaignService.sendEmailIndividual(titleEmail, bodyEmail, this.targetEmail).subscribe(res => {
          if (res.result) this.toast.success(`Send Email for ${this.targetEmail} successfully`, 'Success');
          else this.toast.danger(`Send Email for ${this.targetEmail} fail`, 'Error');
        })
      }
    } else {
      this.toast.danger(`Can't get body email from library!`, 'Error');
    }
  }

   exportUnLayerEmail()  {
    this.editorEmail.exportHtml();
    this.editorEmail.getEmailHtml().subscribe(e => {
      this.dialModalRef.close({Title: this.frmTitle.value, EmailData: e});

    })

  }
}
