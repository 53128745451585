import { ProfileContact } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PagingResult } from 'src/app/shared/models/paging-result';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { WorkFlow } from 'src/app/shared/models/workflow.model';
import { environment } from 'src/environments/environment';
import { CampaignTrackingDetailViewModel } from './campaign-detail/campaign-log/campaign-log.model';
import { CampaignProfileNameViewModel } from './campaign-detail/campaign-profile/campaign-profile.model';
import { CampaignContactListModel, CampaignReport, CampaignWorkflowLogModel, CleanCampaignResultModel, PerformanceReport } from './campaign-report.model';
import { CampaignTrackingView, PageCampaignTracking } from './campaign-tracking.model';
import { ContactEmailFromCX, SelectEmailContact } from '../email-campaign-management/create-email-campaign/email-contact-cart/email-contact-cart.model';
import { CampaignDetailDataWithContactId, ReviewCampaignContactResponse } from '../email-campaign-management/email-campaign.model';
import { CreateCampaignResponse, LinkScopeCreateCampaignResponseModel } from '../profile-management/create-campaign-model/create-campaign.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignManagementService {
  baseUrl = environment.apiCampaign;
  pagingCampaign$ = new BehaviorSubject<PagedData<PageCampaignTracking>>(undefined);
  pagedCampaignProfiles$ = new BehaviorSubject<PagingResult>(undefined);
  campaignProfileTab$ = new BehaviorSubject<PagedData<CampaignProfileNameViewModel>>(undefined);
  campaignProfileTabDetails$ = new BehaviorSubject<PagedData<CampaignTrackingView>>(undefined);
  campaignReport$ = new BehaviorSubject<PagedData<CampaignReport>>(undefined);
  workflowCampaignLogPaging$ = new BehaviorSubject<PagedData<CampaignWorkflowLogModel>>(undefined);
  workflowNameList$ = new BehaviorSubject<WorkFlow[]>(undefined);
  constructor(private http: HttpClient) {
  }
  getPagingCampaign(): Observable<PagedData<PageCampaignTracking>> {
    return this.pagingCampaign$.asObservable();
  }
  reFreshPagingCampaign(page: Page): void {
    this.http.post<ReturnResult<PagedData<PageCampaignTracking>>>(`${this.baseUrl}/Filter`, page).subscribe(resp => {
      if (resp.result) {
        console.log(resp);
        if (resp.result.data && resp.result.data.length > 0) {
          resp.result.data.forEach(item => {
            item.remains = item.total - item.totalSucess - item.totalFailed;
          });
        }
        this.pagingCampaign$.next(resp.result);
      }
    });
  }
  getPagingCampaignProfiles(): Observable<PagedData<PageCampaignTracking>> {
    return this.pagedCampaignProfiles$.asObservable();
  }
  refreshPagingCampaignProfiles(campaignId: number, page: Page): void {
    this.http.post<ReturnResult<PagedData<CampaignTrackingDetailViewModel>>>(`${this.baseUrl}/Log/${campaignId}`, page).subscribe(resp => {
      if (resp.result) {
        this.pagedCampaignProfiles$.next(resp.result);
      }
    });
  }
  refreshCampaignProfileName(campaignId: number, page: Page) {
    this.http.post<ReturnResult<PagedData<CampaignProfileNameViewModel>>>(`${this.baseUrl}/Profiles/${campaignId}`, page)
      .subscribe(resp => {
        if (resp.result) {
          console.log(resp);
          this.campaignProfileTab$.next(resp.result);
        }
      });
  }
  getPagingCampaignProfilesTab(): Observable<PagedData<CampaignProfileNameViewModel>> {
    return this.campaignProfileTab$.asObservable();
  }
  refreshCampaignProfileLog(page: Page, campaignId: number, profileId: string) {
    this.http.post<ReturnResult<PagedData<CampaignTrackingView>>>(`${this.baseUrl}/LogProfile`, {
      page,
      campaignId, profileId
    }).subscribe(response => {
      if (response.result) {
        this.campaignProfileTabDetails$.next(response.result);
      }
    });
  }
  refreshCampaignReport(page: Page, isExport: boolean = false) {
    this.http.post<ReturnResult<PagedData<CampaignReport>>>(`${this.baseUrl}/GetCampaignReportPaging?isExport=${isExport}`, page).subscribe(response => {
      if (response.result) {
        this.campaignReport$.next(response.result);
      }
    });
  }
  getCampaignProfileLog(): Observable<PagedData<CampaignTrackingView>> {
    return this.campaignProfileTabDetails$.asObservable();
  }
  getCampaignReport(): Observable<PagedData<CampaignReport>> {
    return this.campaignReport$.asObservable();
  }
  exportCampaignLog(campaignId: number, page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ExportCampaignLog/${campaignId}`, page);
  }
  cleanCampaignResult(emails: string[]): Observable<ReturnResult<CleanCampaignResultModel[]>> {
    return this.http.post<ReturnResult<CleanCampaignResultModel[]>>(`${this.baseUrl}/CleanCampaignResult`, { emails });
  }
  refreshWorkflowList(): void {
    this.http.get<ReturnResult<WorkFlow[]>>(`${this.baseUrl}/ListOfWorkflow`).subscribe(resp => {
      if (resp.result) {
        this.workflowNameList$.next(resp.result);
      }
    });
  }
  // tienlm add start
  getWorkflowList(): Observable<WorkFlow[]> {
    return this.workflowNameList$.asObservable();
  }
  // tienlm add end
  getCampaign(campaignId: number, page: Page): Observable<ReturnResult<CampaignTrackingDetailViewModel>> {
    return this.http.post<ReturnResult<CampaignTrackingDetailViewModel>>(`${this.baseUrl}/GetCampaign/${campaignId}`, page);
  }
  addToWorkflow(flowId: string, data: CampaignTrackingDetailViewModel[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddToWorkflow?flowId=${flowId}`, data);
  }
  refreshWorkflowCampaignLog(campaignId, page) {
    // tslint:disable-next-line:max-line-length
    this.http.post<ReturnResult<PagedData<CampaignWorkflowLogModel>>>(`${this.baseUrl}/GetWorkflowLog/${campaignId}`, page).subscribe(resp => {
      if (resp.result) {
        this.workflowCampaignLogPaging$.next(resp.result);
      }
    });
  }
  getWorkflowCampaignLog(): Observable<PagedData<CampaignWorkflowLogModel>> {
    return this.workflowCampaignLogPaging$.asObservable();
  }
  getContactCampaign(page, typeName) {
    page.typeName = typeName;
    return this.http.post<ReturnResult<PagedData<ProfileContact>>>(`${this.baseUrl}/GetContactCampaign`, page);
  }

  getTheSelectedEmailContact(data: SelectEmailContact) {
    // return this.http.post<ReturnResult<PagedData<ProfileContact>>>(`${this.baseUrl}/GetTheSelectedContact`, data);
    return this.http.post<ReturnResult<CampaignContactListModel>>(`${this.baseUrl}/GetTheSelectedContact`, data);
  }

  getEmailContactFromPrimasCX(data, contactGroup) {
    data.contactGroupLst = contactGroup;
    return this.http.post<ReturnResult<PagedData<ContactEmailFromCX>>>(`${this.baseUrl}/GetContactData`, data);
  }
  getAllWorkflow() {
    return this.http.get<ReturnResult<WorkFlow[]>>(`${this.baseUrl}/ListOfWorkflow`);
  }
  getCampaignPerformanceById(campaignId: number) {
    return this.http.get<ReturnResult<PerformanceReport>>(`${this.baseUrl}/GetCampaignPerformanceById?campaignId=${campaignId}`);
  }
  createCampaignWithExitedContactId(data: CampaignDetailDataWithContactId) {
    return this.http.post<ReturnResult<LinkScopeCreateCampaignResponseModel>>(`${this.baseUrl}/CreateCampaignFromExistedContact`, data);
  }
  sendTestEmailCampaign(subject: string, body: string, receiverId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SendTestEmailCampaign`, { subject, body, receiverId });
  }

  //Create campaign with objects profile, sale lead, sale account, opportunity at screen.
  createCampaignWithObjects(model: CampaignDetailDataWithContactId): Observable<ReturnResult<CreateCampaignResponse>> {
    return this.http.post<ReturnResult<CreateCampaignResponse>>(`${this.baseUrl}/CreateCampaignWithObjects`, model)
  }

  reviewCampaignContact(data: CampaignDetailDataWithContactId, pageReview: Page): Observable<ReturnResult<ReviewCampaignContactResponse>> {
    const model = Object.assign({}, data, { pageReview: pageReview });
    return this.http.post<ReturnResult<ReviewCampaignContactResponse>>(`${this.baseUrl}/ReviewCampaignContact`, model);
  }

  //2023-02-08 ducqm start add new function
  getCampaignGroupData(data: SelectEmailContact){
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/GetEmailCampaignGroupData`, data);
  }
  // 2023-02-08 ducqm end add new function
}
