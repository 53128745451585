import { prop,} from '@rxweb/reactive-form-validators';
export interface SupportTicket {
    ticketId: number;
    bodyHtml: string;
    title: string;
    userName: string;
    dateModified: string | null;
    dateCreated: string | null;
    resolutionCode: string;
    systemComment: string;
}
export class SupportTicketModel {
    ticketId: number;
    bodyHtml: string;
    title: string;
    userName: string;
    dateModified: string | null;
    dateCreated: string | null;
    @prop()
    resolutionCode: string;
    @prop()
    systemComment: string;
}
