<mat-dialog-content style="padding-bottom: 10px;">
    <form [formGroup]="frmTitle">
        <mat-form-field appearance="standard">
            <mat-label>Subject</mat-label>
            <input matInput type="text" formControlName="title" autocomplete="off" placeholder="Subject email..."
                required trim="blur">
            <mat-error
                *ngIf="frmTitle.controls.title.errors && (frmTitle.controls.title.dirty || frmTitle.controls.title.touched)">
                {{frmTitle.controls.title['errorMessage']}}</mat-error>
        </mat-form-field>
    </form>
    <app-editor-email *ngIf="!isNormalEditor; else normalEditor" #editorEmailLinkScope [emailDesign]="emailTemplate"
        [classicEditor]="isClassicEditor" [bodyEmbedded]="bodyEmbedded" [userModel]="userModel" >
    </app-editor-email>
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.showDialogAction" align="end">
    <button mat-dialog-close mat-button> Cancel</button>
    <button mat-raised-button color="primary" (click)="exportUnLayerEmail()">
        Save
    </button>
</mat-dialog-actions>


<ng-template #normalEditor>
    <app-email-quill-editor #quillEditor [emailDesign]="emailTemplate" [bodyEmbedded]="bodyEmbedded"
        [userModel]="userModel">
    </app-email-quill-editor>
</ng-template>