export class ProfileRequest {
    profileId: string | null;
    displayName: string;
    referenceLink: string | null;
    realName: string | null;
    webPage: string | null;
    bio: string | null;
    notes: string | null;
    rider: string | null;
    travel: string | null;
    published: boolean | null;
    dataState: number | null;
    dateModified: string | null;
    datecreated: string | null;
    tags: string | null;
    lastUser: string | null;
    facebook: string | null;
    setList: string | null;
    testimonials: string | null;
    previousClients: string | null;
    parentId: string | null;
    signature: string | null;
    isMaster: boolean | null;
    isActive: boolean | null;
    locationId: number | null;
    contactId: number | null;
    deleted: boolean | null;
    dateDeleted: string | null;
    tiktok: string | null;
    instagram: string | null;
    twitter: string | null;
    youtube: string | null;
    skype: string | null;
    automateDataStateId: number | null;
    // 2022-05-25 tienlm add start
    maxPrice: number | null;
    minPrice: number | null;
    price: string | null;
    // 2022-05-25 tienlm add end
}