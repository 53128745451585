<div mat-dialog-content>
  <h6>Enter your group name</h6>
  <mat-form-field appearance="standard">
    <mat-label>Group Name</mat-label>
    <input *ngIf="!isAddGroupExisting" matInput [(ngModel)]="groupName" required trim="blur">
    <mat-select *ngIf="isAddGroupExisting" [(ngModel)]="groupName">
      <mat-option *ngFor="let item of listGroups" [value]="item">{{item.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="isError === true|| groupName === ''">
      {{errorMsg}}
    </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div class="float-right">
    <button mat-button color="primary" (click)="onOkClick()">Ok</button>
    <button mat-button color="warn" (click)="onCancelClick()">Cancel</button>
  </div>
</div>