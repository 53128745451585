import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-save-all-change',
  templateUrl: './confirm-save-all-change.component.html',
  styleUrls: ['./confirm-save-all-change.component.scss']
})
export class ConfirmSaveAllChangeComponent implements OnInit {
  message = "Are you sure?";
  falseTitle = "No";
  yesTitle = "Yes";
  isShowCancel: boolean = true;
  constructor(private dialogRef: MatDialogRef<ConfirmSaveAllChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.message) {
      this.message = this.data.message;
      this.falseTitle = this.data.falseTitle ?? this.falseTitle;
      this.yesTitle = this.data.yesTitle ?? this.yesTitle;
      this.isShowCancel = this.data.isShowCancel ?? this.isShowCancel;
    }
  }
  onConfirm(data) {
    this.dialogRef.close(data);
  }
  onClose() {
    this.dialogRef.close();
  }
}
