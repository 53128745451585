import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropDownValue } from 'src/app/modules/admin/summary-report/summary-report.component';
import { PrimasMailActionFilterOperator } from '../../enums/primas-email-action-filter-operator';
import { FilterConfig } from '../dropdown-filter/filter-config';

@Component({
  selector: 'app-mail-log-filter',
  templateUrl: './mail-log-filter.component.html',
  styleUrls: ['./mail-log-filter.component.scss']
})
export class MailLogFilterComponent implements OnInit, OnChanges {
  @Input() columnName: string;
  @Input() value: string;
  @Input() filter: FilterConfig;
  @Input() operationMode: string;

  @Output() onFilterText = new EventEmitter<any>();
  @Output() onEmailActionFilter = new EventEmitter<any>();

  mailActions: DropDownValue[] = [
    { text: 'Read', value: 'Read' },
    { text: 'Send', value: 'Send' },
    { text: 'Click', value: 'Click' },
    { text: 'Unsubscribed', value: 'Unsubscribed' },
    { text: 'Deferred', value: 'Deferred' },
    { text: 'Reply', value: 'Reply' },
    { text: 'Bounce', value: 'Bounce' },
    { text: 'Not Sent', value: 'NotSent' },
    { text: 'Reply Detail', value: 'ReplyDetail' },
  ];
  actions = new FormControl();

  constructor() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    let value = changes['value'];
    if (value && !value.firstChange) {
      if ((this.operationMode == PrimasMailActionFilterOperator.EmailActions || this.operationMode == 'EmailActions')) {
        if (value.currentValue) {
          this.actions.setValue(this.value);
        }
      }
    }

  }

  ngOnInit() {
    if (this.value) {
      this.actions.setValue(this.value);
    }
  }
  onTextChanged(data: string) {
    this.onFilterText.emit(data);
  }

  onClickDropdown(event) {
    if (!event) {
      const value = this.actions.value ?? [];
      this.onEmailActionFilter.emit(value);
    }
  }
}
