<div appChangeUrlObjects [id]="id" [isOpenDialog]="openByDialog" [topic]="'opportunity'"></div>
<mat-chip-list></mat-chip-list>
<!-- <app-overlay-note-details #overlayNoteDetails *ngIf="isShowNoteDetails" [(listNote)]="listNote"
    [referenceType]="'OPPORTUNITY'" [referenceId]="id" (onRefresh)="noteTab ? noteTab.refreshData() : ''">
</app-overlay-note-details> -->
<ng-container *ngIf="isShowButton">
    <div class="d-flex flex-wrap align-items-space-between" mat-dialog-title [nbSpinner]="isLoadingEdit"
        style="margin: unset;padding: 8px 10px 1px 28px;">
        <div [hidden]="isLoading" class="d-flex flex-wrap" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-icon style="transform: scale(1.5);" [ngStyle]="{'color':opportunityEnums.Opportunity}">
                    account_circle</mat-icon>
            </div>
            <div class="entity-name-id">
                <span class="entity-name" fxLayout="row" fxLayoutAlign="space-around center">
                    {{environment.titleOpportunity}}
                </span>
                <span *ngIf="!isCopy;else copied"
                    [matTooltip]="'Click to copy this ' + environment.titleOpportunity + ' ID: '+ id"
                    (click)="copyGUIDToClipboard()" class="underline-id">#{{id | truncateText:[6,
                    '...']}}</span>
            </div>
            <mat-divider [vertical]="true">
            </mat-divider>

        </div>
        <div class="ml-auto" fxLayout="row">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="ml-auto float-start" matTooltipPosition="left"
                    [matTooltip]="'Copy this ' + environment.titleOpportunity + ' url to clipboard'"
                    (click)="copyToClipboard()">
                    <mat-icon style="transform: scale(0.9);">
                        share</mat-icon>
                </button>
                <mat-divider [vertical]="true" style="height:100%">
                </mat-divider>
            </div>
            <app-next-or-previous-detail #btnNextOrPre>
            </app-next-or-previous-detail>
            <button *ngIf="openByDialog" mat-icon-button matTooltip="Close" appChangeUrlObjects [isClose]="true"
                (click)="closeDialog()">
                <mat-icon style="color:red;">close</mat-icon>
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>
</ng-container>

<mat-dialog-content style="max-height: 100vh" *ngIf="isLoading; then skeleton else bodyDialog"></mat-dialog-content>

<ng-template #bodyDialog>
    <div *ngIf="opportunityModel; else notExistedById" appDetectTabMode [typeElement]="'BodyDetail'" fxLayout="column"
        class="body-detail-container px-4" [ngClass]="!openByDialog ? 'embed-parent' : ''">
        <div fxLayout="row" fxLayoutAlign="start start">
            <div *ngIf="data && data.advancedImport" style="width:224px;">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"
                    style="width:200px;padding-right: 24px;" *ngIf="!data.externalTemplate; else externalTemplate">
                    <button mat-menu-item (click)="onClickAdvanceImport('Add')" [matTooltip]="'Create new entry'">
                        <mat-icon style="color:#28a745">add</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Update')"
                        [matTooltip]="'Update existing entry'">
                        <mat-icon color="primary">edit</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Skip')" [matTooltip]="'Skip Entry'">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>
                </div>
                <div style="overflow:auto; height: 80vh;width:200px;">
                    <div *ngFor="let item of data.importColumn" style=" margin-right: 24px;">
                        <div class="import-advanced-label">
                            {{item.name}}:
                        </div>
                        <div [matTooltip]="item.value" style="white-space: nowrap;">
                            {{item.value || 'No data display'}}
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
            <div>
                <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-3">
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'account_circle', toolTip: environment.titleOpportunity + ' Name',
                property: 'opportunityName',
                value: opportunityModel.saleOpportunity.opportunityName,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.opportunityName
            }"></ng-container>
                    <div fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="49" fxLayout="column" class="mb-2 pr-2"
                        [matTooltip]="environment.titleAccount + ' Name'" matTooltipPosition="left">
                        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                            {{environment.titleAccount}} Name
                        </span>
                        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary"
                                style="font-size: 24px;">
                                person
                            </span>
                            <app-search-edit-inline fxFlex="1 1 calc(100%-30px)" [isShowSuggest]="true"
                                [inputData]="opportunityModel?.saleOpportunity?.opportunityProfile?.displayName"
                                [apiSearching]="apiGetSearchRelationship" (handleSave)="saveAccount($event)"
                                [displayKey]="'displayName'" [widthDisplay]="'100%'">
                            </app-search-edit-inline>
                        </div>
                    </div>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'help', toolTip: 'Type',
                property: 'type',
                value: opportunityModel.saleOpportunity.type,
                typeModel: TypeEditModelOpportunity.Opportunity,
                observable :dynamicContentTypeAPI,
                searchProp: 'content'
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'percent', toolTip:'Probability',
                property: 'probability',
                value: opportunityModel.saleOpportunity.probability,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.probability,
                pipe: numPipe,
                disabled: opportunityModel['disabledProbability'],
                externalTootTip: opportunityModel['tooltipProbability']
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'apartment', toolTip: 'Lead source',
                property: 'leadSource',
                value: opportunityModel.saleOpportunity.leadSource,
                typeModel: TypeEditModelOpportunity.Opportunity,
                observable :dynamicContentLeadSourceAPI,
                searchProp: 'content'
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'numbers', toolTip: 'Estimate',
                property: 'amount',
                value: opportunityModel.saleOpportunity.amount ,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.amount,
                pipe: numPipe,
                prefix: prefixCurrency
            }"></ng-container>
                    <!-- <mat-chip class="chip-stage-seleted" [matMenuTriggerFor]="menuStage"
                [style.backgroundColor]="currentStage?.color || '#000'" selected>
                {{currentStage?.value || 'Unknown'}}
            </mat-chip> -->
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'numbers', toolTip: '1x Product',
                property: 'oneTimeProduct',
                value: (opportunityModel.profileAdditionDetail?.oneTimeProduct) ? opportunityModel.profileAdditionDetail?.oneTimeProduct: 0,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.oneTimeProduct,
                pipe: numPipe,
                prefix: prefixCurrency
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'numbers', toolTip: '1x Service',
                property: 'oneTimeService',
                value: (opportunityModel.profileAdditionDetail?.oneTimeService) ? opportunityModel.profileAdditionDetail?.oneTimeService : 0,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.oneTimeService,
                pipe: numPipe,
                prefix: prefixCurrency
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'numbers', toolTip: 'ARR',
                property: 'aRR',
                value: (opportunityModel.profileAdditionDetail?.arr)? opportunityModel.profileAdditionDetail?.arr : 0,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.arr,
                pipe: numPipe,
                prefix: prefixCurrency
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'numbers', toolTip: 'Term Of the Contract',
                property: 'termOfContract',
                value: (opportunityModel.profileAdditionDetail?.termOfContract)? opportunityModel.profileAdditionDetail?.termOfContract : 0,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.termOfContract,
                pipe: numPipe
            }"></ng-container>
                    <ng-container *ngIf="!isCalculate; else avatarSkeleton" [ngTemplateOutlet]="defaultProperty"
                        [ngTemplateOutletContext]="{
                icon: 'numbers', toolTip: 'Total Value',
                property: 'totalValue',
                value: opportunityModel.profileAdditionDetail?.totalValue ?? 0,
                typeModel: TypeEditModelOpportunity.Opportunity,
                validProp: validProperties.termOfContract,
                pipe: numPipe,
                prefix: prefixCurrency,
                disabled: true
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="ownerTemplate" [ngTemplateOutletContext]="{}"></ng-container>
                    <!-- Company -->
                    <!-- <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'business', toolTip: 'Company',
                property: 'company',
                value: opportunityModel.company,
                typeModel: TypeEditModelOpportunity.OpportunityProfile,
                observable :dynamicContentCompanyAPI,
                searchProp: 'content'
            }"></ng-container> -->
                    <div fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="49" fxLayout="column" class="pr-3"
                        matTooltip="Closed Date" matTooltipPosition="left" fxLayout="column">
                        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                            Closed Date
                        </span>
                        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary"
                                style="font-size: 24px; padding-bottom: 0.5em;">
                                calendar_month
                            </span>
                            <mat-form-field fxFlex="1 1 calc(100%-30px)" class="inline-datetime-picker">
                                <input matInput [matDatepicker]="closeDatePicker" (click)="closeDatePicker.open()"
                                    [value]="this.opportunityModel.saleOpportunity.closeDate" readonly
                                    (dateChange)="editOpportunity($event.value, 'closeDate', TypeEditModelOpportunity.Opportunity)">
                                <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #closeDatePicker>
                                    <ng-template
                                        (click)="editOpportunity($event.value, 'closeDate', TypeEditModelOpportunity.Opportunity)">
                                        <span>OK</span>
                                    </ng-template>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container [ngTemplateOutlet]=" actionButtonTemplate" [ngTemplateOutletContext]="{}">
                    </ng-container>

                    <div [fxFlex]="lostTypeId == opportunityModel.automateDataStateId ? '50' :'100'"
                        [fxFlex.lt-md]="lostTypeId == opportunityModel.automateDataStateId ? '50' :'100'"
                        fxFlexOrder="9" fxLayoutAlign="start start" class="area-last-note"
                        [style.paddingRight]="lostTypeId == opportunityModel.automateDataStateId ?'5px' : null">
                        <!-- <ng-container [ngTemplateOutlet]="actionButtonTemplate" [ngTemplateOutletContext]="{}">
                </ng-container> -->
                        <ng-container *ngIf="lastNote; else blankLastNote">
                            <nb-card class="body-last-note-card" [nbSpinner]="overlayNoteDetails?.isLoading | async"
                                (click)="noteService.pushNoteDetails(lastNote, opportunityModel.displayName)">
                                <nb-card-header fxLayout="row" fxLayoutAlign="space-between center"
                                    style="padding: 0 1rem; background-color: #f5da65;">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <button mat-icon-button debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                                            referenceId: id,
                                            referenceType: 'OPPORTUNITY',
                                            referenceName: opportunityModel.displayName
                                        })">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                        <nb-user *ngIf="lastNote.ownerId" [onlyPicture]="true" color="#8f9bb37a"
                                            [matTooltip]="lastNote.user?.userName" [name]="lastNote['ownerNote']"
                                            [picture]="lastNote['avatarNote']" size="small">
                                        </nb-user>
                                        <span class="limit-line" style="--line: 1;" [matTooltip]="lastNote.title"
                                            matTooltipPosition="left">
                                            Last note: {{lastNote.title}}
                                        </span>
                                    </div>

                                    <span fxFlex="20" class="limit-line"
                                        style="--line: 1; width: auto; font-weight: normal; text-align: end;"
                                        matTooltip="Last created" matTooltipPosition="left">
                                        {{ (lastNote.dateCreated | date: 'short') || 'None' }}
                                    </span>
                                </nb-card-header>
                                <nb-card-body style="background-color: #fef9b7;" [innerHTML]="lastNote.note">
                                </nb-card-body>
                            </nb-card>
                        </ng-container>
                    </div>
                    <div style="padding-right: 6px" *ngIf="lostTypeId == opportunityModel.automateDataStateId"
                        fxFlex="50" fxFlexOrder="10" fxFlex.lt-md="50">
                        <nb-card class="body-last-note-card">
                            <nb-card-header class="alert-danger"
                                style="padding: 0.5rem 1rem; background-color:#ffafb6 !important" fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div>Lost Reason</div>
                            </nb-card-header>
                            <nb-card-body class="alert-danger">
                                <app-inline-edit-area [inputData]="opportunityModel.saleOpportunity.lostReason"
                                    (handleSave)="editOpportunity($event, 'lostReason',TypeEditModelOpportunity.Opportunity )"
                                    [defaultNullLabel]="'This ' + environment.titleOpportunity + ' was lost, click here to add reason...'"
                                    [maxRow]="2" (handleAutomate)="handleAutomateDataState($event)">
                                </app-inline-edit-area>
                            </nb-card-body>
                        </nb-card>

                    </div>
                </div>

                <div class="mt-2">
                    <app-chevron-progress-bar [chevronProgressBarLst]="listStage" [displayText]="'value'"
                        [displayValue]="'key'" [selectedValue]="opportunityModel.automateDataStateId"
                        (onSelected)="!handleAutomate ? editStageOpportunity($event) : ''" [colorValue]="'color'">
                    </app-chevron-progress-bar>
                </div>

                <mat-tab-group #tabGroup mat-stretch-tabs animationDuration="0ms" [selectedIndex]="selectTab"
                    (selectedIndexChange)="tabIndexChanged($event)">
                    <mat-tab label="Contact">
                        <ng-template mat-tab-label>
                            Contact
                            <app-add-contact-button [id]="id" [type]="'Profile'"
                                [showCampaignButton]="tabGroup.selectedIndex == 0 ? true: false "
                                (onRefresh)="contactTab ? contactTab.refreshData(true) : ''; isHasPrimaryContact ? '': refreshData(); resetContacts()"
                                [disableCampaignButton]="contactTab?.primasTable?.rows?.length == 0"
                                (onClickCampaign)="createContactCampaign()">
                            </app-add-contact-button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-contact-relationship-grid #contactGrid [relationshipId]="id"
                                [relationshipType]="'Profile'" [maxHeight]="'55vh'"
                                (getPrimaryContactRelationship)="resetContacts()">
                                <!-- (getPrimaryContactRelationship)="updatePrimaryContact($event)" -->
                            </app-contact-relationship-grid>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Files" *nbIsGranted="['view', 'table-sale-opportunity-file']">
                        <ng-template mat-tab-label>
                            Files
                            <input #fileInput hidden="true" type="file" onclick="this.value=null"
                                (change)="addSaleOpportunityFile($event)"
                                [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
                                accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" />

                            <button *nbIsGranted="['view', 'add-sale-opportunity-file']" mat-icon-button color="primary"
                                debouncedClick [throttleTime]="500" (throttleClick)="fileInput.click()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-profile-file-tab #profileFileTab [profileModel]="opportunityModel"
                                [perm]="fileResource">
                            </app-profile-file-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Note">
                        <ng-template mat-tab-label>
                            Note
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                            referenceId: id,
                            referenceType: 'OPPORTUNITY',
                            referenceName: opportunityModel.displayName
                        })">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-note-tab #gridNoteTab [id]="id" [referenceType]="'OPPORTUNITY'" [resource]="resource"
                                (editChange)="noteService.pushNoteDetails($event.row, opportunityModel.displayName)"
                                (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : '';
                        getLastNote($event, true)" [nbSpinner]="overlayNoteDetails?.isLoading | async">
                            </app-note-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Tasks
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                                (throttleClick)="createTask()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-grid-by-profile-id #taskGrid [profileId]="id"
                                [saleModel]="opportunityModel"></app-task-grid-by-profile-id>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Activity Log">
                        <ng-template mat-tab-label>
                            Activity Log
                            <button mat-icon-button color="primary" appAddActivityLogBtn [id]="id" type="OPPORTUNITY">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-activity-tab [profileId]="id" [profileModel]="opportunityModel"></app-activity-tab>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notExistedById>
    <app-blank-page [message]="'The ' + environment.titleOpportunity + ' with id ' + id +  ' is not exist or deleted'">
    </app-blank-page>
</ng-template>

<ng-template #skeleton>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="48" count="4" animation="progress"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="48" count="4" animation="progress"></ngx-skeleton-loader>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="px-4">
        <ngx-skeleton-loader fxFlex="48" count="2" [theme]="{ height: '50px' }" animation="progress">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="48" count="3" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mt-2 mx-4"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mx-4"></mat-divider>
    <div fxLayout="column" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="100" [theme]="{
            height: '50vh'
        }" animation="progress"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #defaultProperty let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-typeModel="typeModel" let-validProp="validProp" let-disabled="disabled" let-pipe="pipe" let-prefix="prefix"
    let-externalTootTip="externalTootTip">
    <div fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="49" fxLayout="column" class="mb-2 pr-2"
        [matTooltip]="externalTootTip || toolTip" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
                (handleSave)="editOpportunity($event, property, typeModel)" [removePaddingBottom]="true"
                [widthDisplay]="'100%'" [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [readonlyInline]="disabled ?? false" [pipe]="pipe" [prefix]="prefix">
            </app-inline-edit>
        </div>
    </div>
</ng-template>

<ng-template #actionButtonTemplate>
    <div fxLayout="row wrap" fxFlex="49" fxFlex.lt-md="32" fxFlex.lt-sm="49" style="margin-left: -8px"
        fxLayoutGap="1rem" class="format-action pt-2">
        <button #sendMailBtn [nbPopover]="contactSelectPop" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            mat-icon-button matTooltip="Send an email" (click)="nbMailPopover.show()">
            <mat-icon class="material-icons-outlined green-color">email</mat-icon>
            <!--  debouncedClick (throttleClick)="sendMailClick()" -->
        </button>
        <button #makeCallPopoverBtn="nbPopover" mat-icon-button matTooltip="Make a call"
            [nbPopover]="contactSelectPopCall" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            (click)="makeCallPopoverBtn.show()" [disabled]="isCallLoading || isCalling"
            [nbSpinner]="isCallLoading || isCalling">
            <mat-icon class="material-icons-outlined green-color">call</mat-icon>
            <!-- debouncedClick (throttleClick)="onClickCall()" -->
        </button>
        <button mat-icon-button matTooltip="Add task" debouncedClick (throttleClick)="createTask()">
            <mat-icon class="material-icons-outlined green-color">
                add_task
            </mat-icon>
        </button>
    </div>
</ng-template>

<mat-menu #menuStage="matMenu" focusFirstItem="false" class="content-overlay" [overlapTrigger]="false">
    <button mat-menu-item mat-chip *ngFor="let state of listStage" class="chip-menu"
        [style.backgroundColor]="state.color"
        (click)="editOpportunity(state.key, 'automateDataStateId', TypeEditModelOpportunity.OpportunityProfile)">
        <strong>{{state.value}}</strong>
    </button>
</mat-menu>


<ng-template #copied>
    <span class="copied">
        Copied!!
    </span>
</ng-template>

<ng-template #blankLastNote>
    <app-blank-page fxFlex="auto" [linkSrc]="'assets/images/notetaking.gif'"
        [message]="'Do you wish to add a new note?'" [contentTemplate]="contentEmptyLastNote">
    </app-blank-page>
</ng-template>

<ng-template #contentEmptyLastNote>
    <span class="mb-2"><strong>Do you wish to add a new note?</strong></span>
    <button class="mb-2" mat-stroked-button color="primary" (throttleClick)="noteService.pushNoteDetails({
        referenceId: id,
        referenceType: 'OPPORTUNITY',
        referenceName: opportunityModel.displayName
    })" debouncedClick [throttleTime]="500">
        Add new
        <mat-icon>add</mat-icon>
    </button>
</ng-template>

<ng-template #ownerTemplate>
    <div fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="49" *ngIf="ownerModel; else avatarSkeleton" fxLayout="column"
        class="mb-2 pr-2" matTooltip="Owner" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            Owner
        </span>
        <div [style.color]="ownerModel?.id ? '' : 'rgb(0 0 0 / 60%)'">
            <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true"
                [picture]="ownerModel?.pictureURL" [name]="ownerModel?.fullName">
            </nb-user>
            <app-search-edit-inline fxFlex="1 1 calc(100%-30px)" [inputData]="ownerModel?.id
                ? (ownerModel?.userName + ' (' + ownerModel?.fullName + ')')
                : 'Unassigning'" [apiSearching]="apiGetSearchOwner" [widthDisplay]="'100%'"
                [externalTemplate]="optionOwnerTemplate"
                (handleSave)="editOpportunity($event.id, 'ownerId', TypeEditModelOpportunity.OpportunityProfile)">
            </app-search-edit-inline>
        </div>
    </div>
</ng-template>

<ng-template #avatarSkeleton>
    <ngx-skeleton-loader fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="49" class="pr-2" count="1" appearance="line"
        animation="progress" [theme]="{ 'margin-bottom': '0px', 'margin-top': '8px', 'height': '2.25rem' }">
    </ngx-skeleton-loader>
</ng-template>

<ng-template #optionOwnerTemplate let-item="item">
    <div fxLayout="row" fxLayoutAlign="start end">
        <div style="font-size:14px" fxFlex="40" class="limit-line" style="--line:1;font-size:12px; margin-right:15px;">
            <b>{{item?.userName || "No name display"}}</b> ({{item?.fullName || ""}})
        </div>
        <div class="text-secondary limit-line" [matTooltip]="" style="--line:1;font-size:12px; margin-right:15px ;"
            fxFlex="35">
            {{item?.email || "No email display" }}
        </div>
        <div class="text-secondary limit-line" style="--line:1;font-size:12px" fxFlex="25">
            {{(item?.phoneNumber | phoneFormat) || "No phone display" }}
        </div>
    </div>
</ng-template>


<ng-template #autocompleteInline let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-observable="observable" let-searchProp="searchProp" let-typeModel="typeModel" let-validProp="validProp"
    let-disabled="disabled" let-pipe="pipe" let-prefix="prefix">
    <div fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="49" fxLayout="column" class="mb-2 pr-2" [matTooltip]="toolTip"
        matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-auto-complete-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
                (handleSave)="editOpportunity($event, property, typeModel)" [removePaddingBottom]="true"
                [widthDisplay]="'100%'" [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [readonlyInline]="disabled ?? false" [pipe]="pipe" [prefix]="prefix"
                [searchArrayAPI]="observable" [searchProperty]="searchProp">
            </app-auto-complete-inline-edit>
        </div>
    </div>
</ng-template>


<ng-template #contactSelectPop>
    <app-pop-over-select-contacts [id]="id" [type]="'OPPORTUNITY'" [(contactList)]="contacts"
        [primaryContactId]="opportunityModel.accountContactId" [action]="'email'"
        (onSelectedContact)="getSelectedContactMail($event)" [popover]="nbMailPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #contactSelectPopCall>
    <app-pop-over-select-contacts [id]="id" [type]="'OPPORTUNITY'" [(contactList)]="contacts"
        [primaryContactId]="opportunityModel.accountContactId" [action]="'call'"
        (onSelectedContact)="getSelectedContactCall($event)" [popover]="makeCallPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>
<ng-template #externalTemplate>
    <ng-container [ngTemplateOutlet]="data.externalTemplate? data.externalTemplate : ''"
        [ngTemplateOutletContext]="{row: data.row}"></ng-container>
</ng-template>