export class SettingCompany {
    COMPANY_NAME: string;
    THEME_COLOR: string;
    COMPANY_BACKGROUND: File | null;
    COMPANY_LOGO: File | null;
    COMPANY_LOGO_PORTAL: File | null;
    DEFAULT_AVATAR: File | null;
    COMPANY_FAVICON: File | null;
}

export class SettingCompanyViewModel {
    companyName: string;
    themeColor: string;
    urlCompanyBackGround: string;
    urlCompanyLogo: string;
    urlCompanyLogoPortal: string;
    urlDefaultAvatar: string;
    urlCompanyFavicon: string;
    public static golbalDefaultAvatar: string = 'assets/images/5.jpg';
}

