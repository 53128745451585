import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { SupportTicketModel } from './../support-ticket.model';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { SupportTicketService } from './../../profile-management/send-support-ticket/support-ticket.service';
import { NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectorRef, Input } from '@angular/core';

@Component({
  selector: 'app-edit-support-ticket',
  templateUrl: './edit-support-ticket.component.html',
  styleUrls: ['./edit-support-ticket.component.scss']
})
export class EditSupportTicketComponent implements OnInit {

  @Input() ticketId: number;
  action :TblActionType;
  supportTicket : SupportTicketModel;
  isLoading = false;
  frmSupportTicket : FormGroup
  constructor(
    private diaglogRef: MatDialogRef<EditSupportTicketComponent>,
    private frmBuilder :RxFormBuilder,
    private toast :NbToastrService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private service : SupportTicketService,
    private cdref :ChangeDetectorRef
  ) { 
    this.action = data.action;
    this.supportTicket = data.model ?? new SupportTicketModel
  }

  ngOnInit(): void {
    this.frmSupportTicket=this.frmBuilder.formGroup(SupportTicketModel,this.supportTicket);
    this.diaglogRef.updatePosition({right:'0',});
  }
  closeDialog(){ 
    this.diaglogRef.close(false);
  }
  saveData(){
    this.isLoading = true;
    this.service.saveSupportTicKet(this.data.model.ticketId, this.frmSupportTicket.value.resolutionCode,this.frmSupportTicket.value.systemComment).subscribe(res=>{
      if(res.result){
        this.diaglogRef.close(res.result);
        this.toast.success(`Update support ticket successfully`, 'Success');
      }
      this.isLoading = false
    });
  }
  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    this.cdref.detectChanges();
  }
}
