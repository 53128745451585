import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { BuyerVoiceMailDataModel, ProfileVoiceMailDataModel, VoiceMailDataModel } from './call-status-modal.model';

@Injectable({
  providedIn: 'root'
})
export class CallStatusModalService {

  constructor(private http: HttpClient) { }
  profileUrl = environment.apiProfile;
  buyerUrl = environment.apiBuyer;
  callEventUrl = environment.apiCallEvent;
  getProfileVoiceMailDataAsync(taskId: string): Observable<ReturnResult<ProfileVoiceMailDataModel>> {
    return this.http.get<ReturnResult<ProfileVoiceMailDataModel>>(`${this.profileUrl}/GetProfileVoiceMailDataAsync/${taskId}`);
  }
  getBuyerVoiceMailDataAsync(taskId: string): Observable<ReturnResult<BuyerVoiceMailDataModel>> {
    return this.http.get<ReturnResult<BuyerVoiceMailDataModel>>(`${this.buyerUrl}/GetBuyerVoiceMailDataAsync/${taskId}`);
  }
  getVoiceMailDataByTaskId(taskId: string): Observable<ReturnResult<VoiceMailDataModel>> {
    return this.http.get<ReturnResult<VoiceMailDataModel>>(`${this.callEventUrl}/GetVoiceMailDataByTaskId/${taskId}`);
  }
}
