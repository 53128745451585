import { SettingService } from 'src/app/shared/services/setting.service';
import { ProfileService } from './../../profile-management/profile.service';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NbToastrService } from '@nebular/theme';
import { dataUri, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ContactComponent } from 'src/app/shared/components/stand-alone-component/contact/contact.component';
import { Contact } from 'src/app/shared/components/stand-alone-component/contact/contact.model';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { Contact as ContactDetail, KeyPairsValue, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { ProfileModel } from '../../profile-management/profile-model';
import { UserModel } from '../../user-management/user-model';
import { Priority, TaskStatus, TaskType } from '../task-board/task-board-lane/task-status.model';
import { ResolutionCode, Task, TaskFilterProp, TaskPagingFilter, TaskTemplate } from '../task-board/task-board-lane/task/task.model';
import { TaskBoardService } from '../task-board/task-board.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { SettingCompanyViewModel } from '../../setting-management/setting-company/setting-company';
import { Page } from 'src/app/shared/models/paging/page';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { BuyerManagementService } from 'src/app/shared/services/buyer-management.service';
import { TableEntity } from 'src/app/shared/enums/table-entities.enum';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { DataFieldsManagementService } from 'src/app/shared/services/data-fields-management.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { SaleLeadService } from '../../sale-lead-management/sale-lead.service';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { SaleAccountService } from '../../sale-account-management/sale-account.service';
import { OpportunityManagementService } from '../../opportunity-management/opportunity-management.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import moment, { Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DISPLAY_INPUT_DATE, GENERAL_DATE_TIME_FORMATS } from 'src/app/shared/components/stand-alone-component/primas-custom-date-time-picker/date-format.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';

interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-edit-inline-task',
  templateUrl: './edit-inline-task.component.html',
  styleUrls: ['./edit-inline-task.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DISPLAY_INPUT_DATE }]
})

export class EditInlineTaskComponent implements OnInit, OnDestroy, AfterViewInit {
  domainBOFName: string = 'https://app.altusentertainment.com/';
  @Input() task: Task = new Task();
  @Input() action: TblActionType;

  environment = environment;
  userChosen: UserModel;
  frmTask: FormGroup;
  isLoading = false;
  user: any;
  readonlyContact = false;
  taskType: TaskType[];
  taskStatus: TaskStatus[];
  taskStatusSelected: TaskStatus;
  taskResolutionCodeSelected: ResolutionCode;
  taskPriority: Priority[];
  taskPrioritySelected: Priority;
  priorityImg: string;
  taskTypeSelected: TaskType;
  matcher = new MyErrorStateMatcher();
  isRefreshing: boolean = false;
  editorOptions = QuillConfiguration
  isMaximum = false;
  isOpenDialog = false;
  profileRelationship: ProfileDetailModel;
  contactRelationship: ContactDetail;
  buyerRelationship: BuyerModel;
  contactComponent: Contact[];
  resolutionCodes: ResolutionCode[] = [];
  originalResolutionCode: ResolutionCode[] = [];
  allowChangeDueDate = true;
  // Sub property address 1 for contact if it exist
  address1 = '';
  address2 = '';
  relationshipData = [
    { value: 'profile', viewValue: 'Profile' },
    { value: 'contact', viewValue: 'Contact' },
    //{ value: 'agent', viewValue: 'Agent' },
    //{ value: 'buyer', viewValue: 'Buyer' },
    { value: 'saleLeads', viewValue: this.environment.titleLead },
    { value: 'saleAccount', viewValue: this.environment.titleAccount },
    { value: 'opportunity', viewValue: this.environment.titleOpportunity }
  ];
  isNextOrPre = false;
  indexTask = -1;
  currentTaskStatus = 0;
  listTask: Task[] = [];
  taskTemplateLst: TaskTemplate[] = [];
  defaultAvatar = SettingCompanyViewModel.golbalDefaultAvatar;
  filterList: TaskFilterProp[] = [];
  tasksLength: number = 0;
  viewModelTask: string = 'kanban';
  pagingFilter: Page;
  private destroy$: Subject<void> = new Subject<void>();
  indexPushUrgent: number = null;
  isLoadNext: boolean = false;
  isLoadPrevious: boolean = false;
  userImage = null;
  tooltipUser = '';
  userNameImage = '';
  tooltipProp: KeyPairsValue[] = [
    { key: 'displayName', value: 'Display Name' },
    { key: 'realName', value: 'Real Name' },
    { key: 'producer.producerName', value: 'Production company' },
    { key: 'parentId', value: 'Production company' }, // producer for activity tab
    { key: 'webPage', value: 'Website' },
  ];
  isLoadingDuplicate: boolean = false;
  findItemByKey = Helper.findItemByKey;
  apiGetSearchRelationship: (data) => void = null;
  funcMapTitleSearch: (data: ProfileDetailModel[]) => ProfileDetailModel[] = null;
  isTabMode = Helper.checkTabMode();
  placeholderSearch: string = null;
  reloadTabName: boolean = false;

  constructor(
    public dialModalRef: MatDialogRef<EditInlineTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskBoardService,
    private cdref: ChangeDetectorRef,
    private frmBuilder: RxFormBuilder,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private buyerService: BuyerManagementService,
    private clipboard: Clipboard,
    private router: Router,
    private settingService: SettingService,
    private dataFieldsService: DataFieldsManagementService,
    private saleLeadService: SaleLeadService,
    private saleAccountService: SaleAccountService,
    private opportunityService: OpportunityManagementService,
    private authService: NbAuthService
  ) {
    this.action = data?.action || this.action;
    this.taskService.getViewModelObser().pipe(takeUntil(this.destroy$)).subscribe(res => this.viewModelTask = res ?? this.viewModelTask);
    const dataDialog = this.data?.model?.task || this.data?.model;

    if (dataDialog) {
      this.task = dataDialog ?? new Task();
      this.taskService._taskReplay$.next(dataDialog);
      this.userChosen = dataDialog?.user ?? new UserModel();
      this.task.userName = this.userChosen.userName;
    }

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    this.taskService.getFilterTaskProp().pipe(takeUntil(this.destroy$)).subscribe(e => this.filterList = [...e]);

    this.dataFieldsService.getDisplayDataByScreen("TaskManagement").subscribe(resp => {
      if (resp.result) this.tooltipProp = [...Helper.formatDisplayColumn(this.tooltipProp, resp.result, 'key', 'value')];
    });
  }

  ngOnInit(): void {
    window.sessionStorage.removeItem('phonenumber');
    if (this.isMaximum) this.isMaximum = !this.isMaximum;

    if (this.dialModalRef?.componentInstance) {
      this.dialModalRef.updateSize('1350px', Helper.heightDialog());
      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
    }

    if (!this.isNextOrPre) {
      this.taskService.selfGetListResolutionCode().pipe(takeUntil(this.destroy$)).subscribe(resp => {
        if (resp) {
          this.resolutionCodes = JSON.parse(JSON.stringify(resp));
          this.originalResolutionCode = JSON.parse(JSON.stringify(resp));
          // filter task
          this.filterResolutionCodeByTaskType();
        }
      });

      if (this.isTabMode && this.dialModalRef?.componentInstance)
        this.dialModalRef.afterOpened().subscribe(() => {
          var overlayBackdrops = window.document
            .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
          for (var i = 0; i < overlayBackdrops.length; i++)
            overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
        })

      this.taskService.taskStatusList().pipe(takeUntil(this.destroy$)).subscribe(e => this.taskStatus = e);
      this.taskService.taskTypeList().pipe(takeUntil(this.destroy$)).subscribe(e => this.taskType = e);
      this.taskService.taskPriorityList().pipe(takeUntil(this.destroy$)).subscribe(e => this.taskPriority = e);
      this.settingService.getSettingByKeyAndGroup("BACK-OFFICE", "DOMAIN").subscribe(res => {
        if (res.result) this.domainBOFName = res.result.value;
      });
    }

    this.configApiRelationship();
  }

  ngAfterViewInit(): void {
    this.taskService.getTaskReplay().pipe(takeUntil(this.destroy$)).subscribe(e => {
      this.task = e ?? new Task();
      this.userChosen = this.task?.user ?? new UserModel();
      this.task.userName = this.userChosen.userName;

      this.bindingDataTaskBoard();
      if (!this.isNextOrPre) this.setUpNextPre();
      this.setupData();
    });
  }

  ngOnDestroy(): void {
    this.profileRelationship = null;
    this.buyerRelationship = null;
    this.contactRelationship = null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  setupData() {
    if (this.task) {
      this.checkShadowProfileType();
      this.taskPrioritySelected = { ...this.taskPriority.find(x => x.priorityId === this.task.taskPriority) };
      this.priorityImg = `assets/images/priority/${this.taskPrioritySelected.priorityId.toString().toLowerCase()}.svg`;
      this.taskTypeSelected = { ...this.taskType.find(x => x.taskTypeId === this.task.taskTypeId) }
      this.taskStatusSelected = { ...this.taskStatus.find(x => x.taskStatusId === this.task.taskStatusId) }
      this.task.relationshipType = this.task.relationshipType || 'none';
      if (this.task.resolutionCodeId) {
        this.allowChangeDueDate = false;
      } else {
        this.allowChangeDueDate = true;
      }

      this.userImage = this.userChosen?.pictureURL?.replace(/\\/g, '/');
      //Note: Backend return 'UNKNOWN'. if system have changed, please update code.
      this.userNameImage = (`${this.userChosen?.firstName || ''} ${this.userChosen?.lastName || ''}`).trim();
      this.tooltipUser = (this.task.userName || '') + (this.userNameImage.length > 0 ? ` - ${this.userNameImage}` : '');
    }
  }

  setUpNextPre() {
    switch (this.viewModelTask) {
      case "kanban":
        this.taskService.getTaskBoardLst().pipe(takeUntil(this.destroy$)).subscribe(res => {
          if (res) {
            this.currentTaskStatus = this.task.taskStatusId ?? this.currentTaskStatus;
            this.listTask = res.find(x => x.taskStatusId == this.currentTaskStatus).tasks;
            this.indexTask = this.listTask.findIndex(x => x.taskId == this.task.taskId);
            this.tasksLength = this.listTask.length;
          }
        });
        break;
      case "list":
        this.taskService.getTaskList().pipe(takeUntil(this.destroy$)).subscribe(res => {
          if (res) {
            this.currentTaskStatus = 0;
            this.listTask = res;
            this.indexTask = this.listTask.findIndex(x => x.taskId == this.task.taskId);
            this.tasksLength = this.listTask.length;
          }
        });
        break;
      case "calendar":
        this.taskService.getListTaskSeletedCalendar().pipe(takeUntil(this.destroy$)).subscribe(res => {
          if (res.length > 0) {
            this.currentTaskStatus = 0;
            this.listTask = res;
            this.indexTask = this.listTask.findIndex(x => x.taskId == this.task.taskId);
            this.tasksLength = this.listTask.length;
          }
        });
        break;
      case "grid":
        this.taskService.getFilterPagingObser().pipe(takeUntil(this.destroy$)).subscribe(res => {
          if (res.page && res.pageData) {
            this.pagingFilter = res.page;
            this.listTask = res.pageData.data;
            this.currentTaskStatus = 0;
            this.indexTask = this.listTask.findIndex(x => x.taskId == this.task.taskId) + (this.pagingFilter.pageNumber * this.pagingFilter.size);
            this.tasksLength = res.pageData.page.totalElements;
          }
        });
        break;
    }
  }

  bindingDataTaskBoard() {
    this.taskService.getTaskBoardLst().pipe(first()).subscribe(resp => {
      if (resp) {
        const dataBinding = Object.assign(this.task, {
          taskPriorities: this.taskPriority.find(x => x.priorityId == this.task.taskPriority),
          resolutionCode: this.resolutionCodes.find(x => x.resolutionCodeId == this.task.resolutionCodeId),
          taskStatus: this.taskStatus.find(x => x.taskStatusId == this.task.taskStatusId),
          taskType: this.taskType.find(x => x.taskTypeId == this.task.taskTypeId),
          typeNameRelationshipProfile: Helper.splitSpaceString(this.task.relationshipSubTypeName) || Helper.splitSpaceString(this.task.relationshipType),
          relationshipName: this.task.relationshipDisplayName,
          colorRelationshipProfile: this.colorRelationshipProfile()
        });

        var oldStatus = resp.find(x => x.tasks && x.tasks.find(y => y.taskId == this.task.taskId));
        if (oldStatus) {
          var changeStatus = !(oldStatus.taskStatusId == this.task.taskStatusId);
          resp = [...resp.map(status => {
            if (changeStatus && status.taskStatusId == oldStatus.taskStatusId) {
              var indexTask = status.tasks.findIndex(x => x.taskId == this.task.taskId);
              if (indexTask >= 0) status.tasks.splice(indexTask, 1);
            }

            if (changeStatus && status.taskStatusId == this.task.taskStatusId) {
              if (!status.tasks) status.tasks = [];
              status.tasks.unshift(dataBinding);
            }

            if (!changeStatus && status.taskStatusId == this.task.taskStatusId)
              status.tasks = [...status.tasks.map(x => {
                if (x.taskId == this.task.taskId) x = dataBinding
                return x;
              })];

            return status;
          })]

          this.taskService.setTaskBoardLst(resp);
        }
      }
    })
  }

  colorRelationshipProfile() {
    var result: string = '';

    if (this.profileRelationship)
      switch (this.profileRelationship.typeName) {
        case "LEADS":
          result = "#5c5c8a";
          break;
        case "SALEACCOUNT":
          result = "#808000";
          break;
        case "OPPORTUNITY":
          result = "#004d4d";
          break;
        default:
          result = "#808080";
          break;
      }

    return result;
  }

  clearAssignee() {
    this.task.assigneeTo = null;
    this.task.userName = null;
    this.userChosen = null;
    this.saveData();
  }

  getAssignee(data: UserModel) {
    this.task.assigneeTo = data.id;
    this.task.userName = data.userName;
    this.userChosen = data;
    //if picture URL is default URL then we assum pictureURL is EMPTY;
    if (data.pictureURL !== "assets/images/man.png") this.userImage = data.pictureURL;
    else
      this.userImage = data.pictureURL;
    this.userNameImage = (`${this.userChosen.firstName == "Unknown" ? '' : this.userChosen.firstName} ${this.userChosen.lastName == "Unknown" ? '' : this.userChosen.lastName}`).trim();
    this.saveData();
  }

  async configApiRelationship() {
    if (this.task) {
      this.reversShadowProfileType();
      this.profileRelationship = null;
      this.buyerRelationship = null;
      this.contactRelationship = null;
    }

    if (this.task?.relationshipType == TableEntity.Buyer.toLowerCase() && this.task?.relationshipId) {
      // get buyer data:
      // let data = await this.buyerService.GetGeneralDataBuyer(this.task?.relationshipId).toPromise();
      // if (data.result) {
      //   this.isMaximum = true;
      //   this.buyerRelationship = data.result;
      //   if (this.dialModalRef?.componentInstance) this.dialModalRef.updateSize('1600px', Helper.heightDialog());
      // }
      this.isMaximum = true;
      this.buyerRelationship = Object.assign({ buyerId: this.task.relationshipId });
      if (this.dialModalRef?.componentInstance) this.dialModalRef.updateSize('1600px', Helper.heightDialog());
    } else if (this.task?.relationshipType === 'contact' && this.task?.relationshipId) {
      let data = await this.taskService.getContactData(this.task.taskId, this.task.relationshipType).pipe(takeUntil(this.destroy$)).toPromise();
      this.contactRelationship = data.result;
      this.address1 = [
        this.contactRelationship?.location?.address,
        this.contactRelationship?.location?.address2].filter(Boolean).join(', ');
      this.address2 = [
        this.contactRelationship?.location?.city,
        this.contactRelationship?.location?.state,
        this.contactRelationship?.location?.zipcode,
        this.contactRelationship?.location?.country].filter(Boolean).join(', ');
    } else if (this.task?.relationshipType === 'profile' && this.task?.relationshipId) {
      // let data = await this.taskService.getProfileData(this.task.taskId, this.task.relationshipType).pipe(takeUntil(this.destroy$)).toPromise();
      // if (data.result != null) {
      //   this.isMaximum = true;
      //   this.profileRelationship = data.result;
      //   this.checkShadowProfileType();
      //   if (this.dialModalRef?.componentInstance) this.dialModalRef.updateSize('1600px', Helper.heightDialog());
      // }
      this.isMaximum = true;
      this.profileRelationship = Object.assign({ profileId: this.task.relationshipId, typeName: this.task.relationshipSubTypeName });
      this.checkShadowProfileType();
      if (this.dialModalRef?.componentInstance) this.dialModalRef.updateSize('1600px', Helper.heightDialog());
    }

    this.setupData();
  }

  checkShadowProfileType() {
    if (this.profileRelationship) {
      //convert type for shadow profile: [ 'profile', 'saleLead' ]
      switch (this.profileRelationship.typeName) {
        case ShadowProfileEnum[ShadowProfileEnum.LEADS]:
          this.task.relationshipType = 'saleLeads';
          break;
        case ShadowProfileEnum[ShadowProfileEnum.SALEACCOUNT]:
          this.task.relationshipType = 'saleAccount';
          break;
        case ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY]:
          this.task.relationshipType = 'opportunity';
          break;
        default:
          this.task.relationshipType = 'profile';
          break;
      }

      this.configApiSearchRelationship();
    }
  }

  selectionChangeRelationship() {
    if (this.task.relationshipType == `none`) {
      this.profileRelationship = null;
      this.buyerRelationship = null;
      this.contactRelationship = null;
      this.task.relationshipId = null;
      this.task.relationshipType = null;
      this.saveData();
      return;
    }

    this.apiGetSearchRelationship = null;
    this.funcMapTitleSearch = null;
    this.placeholderSearch = null;
    this.configApiSearchRelationship();
  }

  configApiSearchRelationship() {
    switch (this.task.relationshipType) {
      case 'saleLeads':
        this.apiGetSearchRelationship = (data) => this.saleLeadService.searchSaleLead(data);
        this.placeholderSearch = `Search ${environment.titleLead.toLowerCase()}`;
        break;
      case 'saleAccount':
        this.apiGetSearchRelationship = (data) => this.saleAccountService.searchSaleAccount(data);
        this.placeholderSearch = `Search ${environment.titleAccount.toLowerCase()}`;
        break;
      case 'opportunity':
        this.apiGetSearchRelationship = (data) => this.opportunityService.searchOpportunity(data);
        this.funcMapTitleSearch = (data: ProfileDetailModel[] = []) => {
          var result = data;
          if (result && result.length > 0) {
            result = result.map(x => Object.assign(x, {
              displayName: x.saleOpportunity.opportunityName,
              realName: x.saleOpportunity.accountName
            }));
          }
          return result;
        }
        this.placeholderSearch = `Search ${environment.titleOpportunity.toLowerCase()}`;
        break;
    }
  }

  reversShadowProfileType() {
    if (this.profileRelationship) {
      switch (this.task.relationshipType) {
        case 'profile':
        case 'saleLeads':
        case 'saleAccount':
        case 'opportunity':
          this.task.relationshipType = 'profile';
          break;
      }
    }

    if (this.task.relationshipType == 'none')
      this.task.relationshipType = null;
  }

  getContactSelected(data: ContactDetail) {
    this.profileRelationship = null;
    this.contactRelationship = data;
    this.buyerRelationship = null;
    this.task.relationshipId = data.contactId.toString();
    this.address1 = [
      this.contactRelationship?.location?.address,
      this.contactRelationship?.location?.address2].filter(Boolean).join(', ');
    this.address2 = [
      this.contactRelationship?.location?.city,
      this.contactRelationship?.location?.state,
      this.contactRelationship?.location?.zipcode,
      this.contactRelationship?.location?.country].filter(Boolean).join(', ');
    this.saveData()
  }
  getProfileSelected(data: ProfileDetailModel) {
    this.contactRelationship = null;
    this.profileRelationship = data;
    this.buyerRelationship = null;
    this.task.relationshipId = data.profileId;
    this.reversShadowProfileType();
    this.saveData()
  }
  deletedRelationship() {
    this.contactRelationship = null;
    this.profileRelationship = null;
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  closeDialog() {
    this.reversShadowProfileType();
    this.taskService.updatIsRefreshEditInline(this.isRefreshing);
    if (this.dialModalRef?.componentInstance)
      this.dialModalRef.close(this.isRefreshing);

    if (Helper.checkUrlDetailObj('task')) {
      var urlBack = Helper.popBackURL() || `/configuration/task`;
      this.router.navigate([urlBack]);
    }
  }
  changeTaskType(data: MatSelect) {
    this.taskTypeSelected = { ...this.taskType.find(x => x.taskTypeId === data.value) };
    // filter resolution code:
    this.filterResolutionCodeByTaskType();
    this.saveData();
  }
  changeTaskStatus(data: MatSelect) {
    this.taskStatusSelected = { ...this.taskStatus.find(x => x.taskStatusId === data.value) };
    if (this.taskStatusSelected.taskStatusId != this.currentTaskStatus) {
      this.listTask = [...this.listTask.filter(x => x.taskId != this.task.taskId)];
      //this.indexTask -= 1;
    }
    this.saveData();
  }
  changeTaskTaskPriority(data: MatSelect) {
    this.taskPrioritySelected = { ...this.taskPriority.find(x => x.priorityId === data.value) };
    this.priorityImg = `assets/images/priority/${this.taskPrioritySelected.priorityId.toString().toLowerCase()}.svg`;
    this.saveData();
  }
  changeResolutionCode(data: MatSelect) {
    this.taskResolutionCodeSelected = { ...this.resolutionCodes.find(x => x.resolutionCodeId === data.value) };
    this.saveData();
  }
  saveData(isDueDate: boolean = true) {
    this.isLoading = !this.isLoading;
    this.reversShadowProfileType();
    this.taskService.saveTask(this.task).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result && resp.result.success) {
        this.isRefreshing = true;
        this.toast.success('Saved', 'Success');
        this.taskService.refreshTaskById(this.task.taskId).subscribe();
        // this.taskService.getTaskReplay().pipe(takeUntil(this.destroy$)).subscribe(e => {
        //   this.task = e;
        //   this.setupData();

        // });
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.isLoading = !this.isLoading;
      if (isDueDate) {
        if (this.profileRelationship)
          this.profileService.refreshProfileLogByTask();
      }
    })
  }
  saveTitle(data: string) {
    this.isLoading = !this.isLoading;
    this.task.taskName = data;
    this.reversShadowProfileType();
    this.taskService.saveTask(this.task).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result && resp.result.success) {
        this.isRefreshing = true;
        this.toast.success('Saved');
        this.taskService.refreshTaskById(this.task.taskId).subscribe();
        // this.taskService.getTaskReplay().pipe(takeUntil(this.destroy$)).subscribe(e => {
        //   this.task = e;
        //   this.setupData()
        // });
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.isLoading = !this.isLoading;
    })
  }
  saveDescription(data: string) {
    this.isLoading = !this.isLoading;
    this.task.taskDescription = data;
    this.reversShadowProfileType();
    this.taskService.saveTask(this.task).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result && resp.result.success) {
        this.isRefreshing = true;
        this.toast.success('Saved');
        this.taskService.refreshTaskById(this.task.taskId).subscribe();
        // this.taskService.getTaskReplay().pipe(takeUntil(this.destroy$)).subscribe(e => {
        //   this.task = e;
        //   this.setupData()
        // });
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.isLoading = !this.isLoading;
    })
  }
  maximumScreen() {
    this.isMaximum = !this.isMaximum;
    if (this.isMaximum) {
      if (this.contactRelationship) {
        this.contactComponent = ContactComponent.parseContactFromDialogInput([this.contactRelationship]);
        var arrayRole = [this.user.role.toString()];
        arrayRole = [...arrayRole[0].split(',')];
        var existingRoleAdmin = arrayRole.find(x => x == "admin");
        if (existingRoleAdmin == "admin") {
          this.readonlyContact = false;
        } else {
          if (this.contactComponent[0].ownerId !== this.user.nameid) {
            this.settingService.checkByPassUserSetting(this.user.nameid).subscribe(resp => {
              if (resp.result) {
                this.readonlyContact = false;
              } else {
                this.readonlyContact = true;
                this.toast.warning('This contact not belongs to this user, you can read-only', 'Notification');
              }
            })
          } else {
            this.readonlyContact = false;
          }
        }
        if (this.dialModalRef?.componentInstance)
          this.dialModalRef.updateSize('1600px', Helper.heightDialog());
      }

    }
    else {
      if (this.dialModalRef?.componentInstance)
        this.dialModalRef.updateSize("1350px", Helper.heightDialog());
    }
  }

  //2021-12-30 vuonglqn add start
  saveResolution(data: string) {
    this.isLoading = !this.isLoading;
    this.task.resolution = data;
    this.reversShadowProfileType();
    this.taskService.saveTask(this.task).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp.result && resp.result.success) {
        this.isRefreshing = true;
        this.toast.success('Saved');
        this.taskService.refreshTaskById(this.task.taskId).subscribe();
        // this.taskService.getTaskReplay().pipe(takeUntil(this.destroy$)).subscribe(e => {
        //   this.task = e;
        //   this.setupData()
        // });
      }
      else this.toast.danger('Failed')
    }).add(() => {
      this.isLoading = !this.isLoading;
    })
  }
  //2021-12-30 vuonglqn add end
  // 2021-01-04 tienlm add start
  deleteTask() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: 'Do you wish to delete this task?'
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.taskService.deleteTask(this.task.taskId).pipe(takeUntil(this.destroy$)).subscribe(result => {
          if (result.result) {
            this.toast.success(`Delete ${this.task.taskName} successfully`, 'Success');
            this.setRefreshTask();
            this.closeDialog()
          } else {
            this.toast.danger(`Delete ${this.task.taskName} failed, please try again...`, 'Failed');
          }
        });
      }
    });
  }
  // 2021-01-04 tienlm add end

  filterResolutionCodeByTaskType() {
    if (!this.taskTypeSelected) {
      return;
    }
    // 2021-01-05 tienlm add start
    this.resolutionCodes = JSON.parse(JSON.stringify(this.originalResolutionCode));
    // tslint:disable-next-line:max-line-length
    this.resolutionCodes = this.resolutionCodes.filter(x => x.taskTypeId === null || (x.taskTypeId && x.taskTypeId === this.taskTypeSelected.taskTypeId));
    // 2021-01-05 tienlm add end
  }

  //2022-01-19 vuonglqn add start
  nextTaskClick() {
    this.isLoadNext = true;
    this.taskService.getTaskByTaskOrderId(this.indexTask, this.currentTaskStatus, true, this.filterList, this.pagingFilter, this.viewModelTask, this.task.taskId, this.indexPushUrgent)
      .subscribe(resp => {
        if (resp.result) {
          this.profileRelationship = null;
          this.contactRelationship = null;
          this.buyerRelationship = null;
          this.taskService._taskReplay$.next(resp.result.task);
          this.isLoadNext = false;
          this.indexPushUrgent = resp.result.indexPushUrgent;
          this.indexTask = resp.result.indexTask;
          this.task = resp.result.task;
          this.formatURLName();
          this.tasksLength = resp.result.tasksLength;
          this.userChosen = this.task.user;
          this.isNextOrPre = true;
          setTimeout(() => this.ngOnInit(), 1);
          //if (this.router.url.includes('configuration/task')) Helper.cancelNavigateNoTrackingHistory(`configuration/task?taskId=${this.task.taskId}`);
        }
      });
  }

  preTaskClick() {
    this.isLoadPrevious = true;
    this.taskService.getTaskByTaskOrderId(this.indexTask, this.currentTaskStatus, false, this.filterList, this.pagingFilter, this.viewModelTask, this.task.taskId, this.indexPushUrgent)
      .subscribe(resp => {
        if (resp.result) {
          this.profileRelationship = null;
          this.contactRelationship = null;
          this.buyerRelationship = null;
          this.taskService._taskReplay$.next(resp.result.task);
          this.isLoadPrevious = false;
          this.indexPushUrgent = resp.result.indexPushUrgent;
          this.indexTask = resp.result.indexTask;
          this.task = resp.result.task;
          this.formatURLName();
          this.tasksLength = resp.result.tasksLength;
          this.userChosen = this.task.user;
          this.isNextOrPre = true;
          setTimeout(() => this.ngOnInit(), 1);
          //if (this.router.url.includes('configuration/task')) Helper.cancelNavigateNoTrackingHistory(`configuration/task?taskId=${this.task.taskId}`);
        }
      });
  }
  //2022-01-19 vuonglqn add end
  buyerSelected(data: BuyerModel) {
    this.buyerRelationship = data;
    this.contactRelationship = null;
    this.profileRelationship = null;
    this.task.relationshipId = data.buyerId;
    this.saveData();
  }
  copyToClipboard() {
    const url = this.domainBOFName + `/configuration/task?taskId=${this.task.taskId}`
    this.clipboard.copy(url.toString());
    this.toast.info('Copied this task url to clipboard', 'Success');
  }
  async duplicateTask() {
    this.isLoadingDuplicate = true;
    try {
      const result = await this.taskService.duplicateTask(this.task.taskId).toPromise();
      if (result && result.result) {
        this.setRefreshTask();
        this.toast.success("Duplicate task successfully", "Success");
        const dialog = this.dialog.open(ConfirmModalComponent, {
          disableClose: true,
          data: {
            message: `<b>Task-${this.task.taskId}</b> was duplicated to <b>Task-${result.result.taskId}</b> successfully. Do you wish to open <b>Task-${result.result.taskId}</b>?`
          }
        });
        var afterCloseDialog = await dialog.afterClosed().toPromise();
        if (afterCloseDialog) {
          if (this.dialModalRef?.componentInstance) {
            this.dialModalRef.close();
            this.dialog.closeAll();
            const taskDialog = this.dialog.open(EditInlineTaskComponent, {
              panelClass: 'dialog-detail',
              disableClose: true,
              data:
              {
                model: result.result
              }
            })
          } else {
            const urlDetail = `/configuration/task/${result?.result?.taskId}`;
            this.router.navigate([urlDetail]);
          }
        }
      }
    }
    catch (e) {
      console.log(e);
    }
    this.isLoadingDuplicate = false;

  }

  setRefreshTask() {
    window.sessionStorage.setItem('modifyTask', 'true');
  }

  dateChanged(data: { value: Moment, isInitial: boolean }, prop: string) {
    this.task[prop] = data.value.format('M/DD/yyyy, HH:mm:ss');
    this.saveData(prop == 'taskStartDate' ? true : null);
  }

  processDateChangeInput(data: MatDatepickerInputEvent<any>, prop: string, isDueDate: boolean = null) {
    if ((<HTMLInputElement>data?.targetElement)?.value) {
      if (moment((<HTMLInputElement>data.targetElement).value).isValid()) {
        this.task[prop] = (<HTMLInputElement>data.targetElement).value;
        this.saveData(isDueDate);
      }
    } else {
      this.saveData(isDueDate);
    }
  }

  formatURLName() {
    const path = window.location.pathname.split("/");
    if (path && path.length > 3 && path[2] == 'task' && this.task) {
      Helper.cancelNavigateNoTrackingHistory(`${path.slice(0, 3).join("/")}/${this.task.taskId}`)
      this.reloadTabName = true;
      setTimeout(() => this.reloadTabName = false, 1);
    }
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid);
  }
}
