<div class="header-row">

  <div class="row-1" *ngIf="isSwitchUserMode">
    <div class="alert-user-message">
      You're log-in as {{loginAsInfo}}, go back to <a class="pointer" href="#" (click)="backToOrgUser()">{{orgInfo}}</a>
    </div>
  </div>
  <div class="row-1-noti" *ngIf="systemNotification">
    <div class="alert-testing-message">
      {{systemNotification}}
    </div>
  </div>

  <div class="noti-state-phone" style="--background-state-phone: green;--color-text: white;"
    *ngIf="isNotiStatePhoneCall && isStatusCalling" [@openClose]="isNotiStatePhoneCall ? 'open' : 'closed'">
    YOU'RE READY TO RECEIVE THE CALLBACK
  </div>
  <div class="noti-state-phone" style="--background-state-phone: #fe8a05;--color-text: white;"
    *ngIf="isNotiStatePhoneCall && !isStatusCalling" [@openClose]="isNotiStatePhoneCall ? 'open' : 'closed'">
    YOU'RE NOT READY TO RECEIVE THE CALLBACK
  </div>


  <div class="row-2 header-wrapper">
    <div class="header-container header-nav">
      <div class="logo-container">
        <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
          <nb-icon icon="menu-2-outline" style="color: white;"></nb-icon>
        </a> -->
        <a class="logo" href="#" (click)="navigateHome()" style="border-left: unset;">
          <img src="https://i.ibb.co/VCfXtnm/logo.png"
          style="height: 40px;transform: scale(0.85);" class="img-fluid" alt="Responsive image"
          (error)="configCompany.urlCompanyLogo ='../../../../assets/images/logo-altus-full.png'">
          <!-- <img src="configCompany.urlCompanyLogo | safeIframe"
            style="height: 40px;transform: scale(3); margin-top: 5px;" class="img-fluid" alt="Responsive image"
            (error)="configCompany.urlCompanyLogo ='../../../../assets/images/logo-altus-full.png'"> -->
        </a>
      </div>
      <!-- <div class="text-white" style="font-size:14px; align-self: flex-end;">{{versionCode}}</div> -->

      <!-- tab mode: -->
      <ng-container *ngIf="tabMode">
        <ng-content select="[headerNav]"></ng-content>
      </ng-container>
    </div>

    <div class="header-container">
      <!-- <button mat-icon-button matBadge="8" matBadgeColor="accent" [matMenuTriggerFor]="menu">
        <mat-icon style="color: #ffffff;">notifications</mat-icon>
      </button> -->
      <nb-actions size="small">
        <!-- change log btn -->
        <nb-action *ngIf="isEnableChangeStatePhoneCall" style="border-left: 0px !important">
          <mat-slide-toggle [nbSpinner]="isStatusCalling == null" [disabled]="isStatusCalling == null"
            [checked]="isStatusCalling" color="primary" style="margin-right: 5px;" (change)="updateStatusCall($event)">
          </mat-slide-toggle>
          <mat-icon [nbSpinner]="isStatusCalling == null" [style.color]="isStatusCalling ? '#00d68f': '#f44336'"
            [matTooltip]="isStatusCalling ? 'Ready': 'Not ready'">
            {{ isStatusCalling ? 'wifi_calling_3' : 'phone_locked'}}
          </mat-icon>
        </nb-action>
        <!-- <nb-action style="border-left: 0px !important">
          <button mat-icon-button (click)="getListNotifications()" style="color: white" matTooltip="Notifications"
            [matMenuTriggerFor]="realtimeNotification" #matMenuTrigger="matMenuTrigger">
            <mat-icon>notifications</mat-icon>
          </button>
        </nb-action>
        <nb-action style="border-left: 0px !important">
          <button *ngIf="showSupportTicket === 'true' || showSupportTicket == '1'" mat-icon-button (click)="onClickSupportAgentTicket()"
            style="color: white" matTooltip="Support ticket management">
            <mat-icon>support_agent</mat-icon>
          </button>
        </nb-action> -->
        <nb-action style="border-left: 0px !important" [nbSpinner]="changelogLoading"
          [badgeDot]="changelogs.length > 0? true: false" badgeStatus="danger" icon="star-outline"
          matTooltip="Changelogs" (click)="showchangelogsDialog()">
        </nb-action>
        <!-- <nb-action style="border-left: 0px !important"
          *ngIf="tasksNotification && tasksNotification.length > 0;else NoNotifications" icon="bell-outline"
          [badgeDot]="showDotNotification(tasksNotification)" badgeStatus="warning" [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{notifies: notificationMenu}">
        </nb-action> -->
        <nb-action class="user-action">
          <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.userName"
            [picture]="currentPicture||picturelUrlSafe">
          </nb-user>
          <nb-action [badgeDot]="true" [badgeStatus]="statusBadge.className" [badgePosition]="statusBadge.position"
            style="position:absolute;bottom:0;left:20px">
          </nb-action>
        </nb-action>
      </nb-actions>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent style="max-width: auto;" let-notifies="notifies"
    *ngIf=" tasksNotification && tasksNotification.length > 0">
    <div *ngFor="let noti of notifies | slice:0:maxNoti" style="margin: 1px">
      <button (click)="openTask(noti.task, noti)" *ngIf="noti.isSeen != true; else read"
        class="header-hover dropdown-item notification-size notification-word-display"
        style="background-color: rgb(214, 237, 252);">
        <span style="font-size: 12px;">{{noti.dateCreated | date: 'short'}}</span>
        <br>
        <span><b>{{noti.firstPart}}</b></span>
        <span>{{noti.connector}}</span>
        <b>{{noti.secondPart}} </b>
        <br>
        <span><b>{{noti.thirdPart|| ''}}</b></span>
        <span>{{noti.fourthPart|| ''}}</span>
        <br>
        <span><b>{{noti.fifthPart|| ''}}</b></span>
        <span>{{noti.sixthPart|| ''}}</span>
      </button>
      <ng-template #read>
        <button (click)="openTask(noti.task, noti)"
          class="header-hover dropdown-item notification-size notification-word-display" #read>
          <span style="font-size: 12px;">{{noti.dateCreated | date: 'short'}}</span>
          <br>
          <span><b>{{noti.firstPart}}</b></span>
          <span>{{noti.connector}}</span>
          <b>{{noti.secondPart}} </b>
          <br>
          <span><b>{{noti.thirdPart|| ''}}</b></span>
          <span>{{noti.fourthPart|| ''}}</span>
          <br>
          <span><b>{{noti.fifthPart|| ''}}</b></span>
          <span>{{noti.sixthPart|| ''}}</span>
        </button>
      </ng-template>
    </div>
    <button mat-button class="header-hover dropdown-item" (click)="seeMore($event)"
      *ngIf="maxNoti < tasksNotification.length" style="text-align:center; ">
      <b>Show more notifications </b>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

  </ng-template>

  <ng-template matMenuContent style="max-width: auto;" let-notifies="notifies"
    *ngIf=" tasksNotification && tasksNotification.length == 0">
    <div class="container">
      <app-blank-page [message]="'No Notification available'"></app-blank-page>
    </div>
  </ng-template>

</mat-menu>

<ng-template #NoNotifications>
  <nb-action style="border-left: 0px !important" icon="bell-outline" [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{notifies: notificationMenu}">
  </nb-action>
</ng-template>
<!--       [nbContextMenu]="notificationMenu" nbContextMenuAdjustment="clockwise" -->

<mat-menu #realtimeNotification="matMenu">
  <div *ngIf="!skeletonLoading; else loadingNotification">
    <div matMenuContent style='width: 20vw'>
      <div style="width: 20vw; max-width: 20vw; max-height: 50vh !important;" infiniteScroll
        [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="infiniteScrollUpDistance"
        [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScroll()" [immediateCheck]="true"
        [alwaysCallback]="true">
        <div *ngFor="let noti of realtimeNotifications; let i = index" style="margin: 1px" class="cheat_mode">
          <ng-container [ngTemplateOutlet]='realtimeNotificationDetail' [ngTemplateOutletContext]="{noti:noti, i:i}">
          </ng-container>
        </div>
        <div *ngIf="!loading; else loadingNotificationEnd"></div>
      </div>
    </div>

    <div matMenuContent *ngIf="realtimeNotifications.length === 0">
      <div class="container" style="max-width: 22vw; max-height: 50vh !important;">
        <app-blank-page [message]="'No Notification Available'"></app-blank-page>
      </div>
    </div>
  </div>
</mat-menu>

<ng-template #realtimeNotificationDetail let-noti="noti" let-i='i'>
  <div class="realtime-notification noti-{{noti.type}}" id="noti-{{noti.realtimeNotificationId}}"
    (click)="callReference(noti, $event)" [ngStyle]="{'background-color': !noti.isClicked? '#e6f2ff':'#fff'}">
    <div class="col col-1 icon-space">
      <div class="row" style="justify-content: flex-end; margin-top: 5px" [ngSwitch]="noti.type">
        <nb-icon *ngSwitchCase="'success'" icon="checkmark-square-2" status="success"></nb-icon>
        <nb-icon *ngSwitchCase="'warning'" icon="alert-triangle" status="warning"></nb-icon>
        <nb-icon *ngSwitchCase="'error'" icon="close-square" status="danger"></nb-icon>
      </div>
    </div>
    <div class="col col-11" style="padding: 0px 25px 0px 5px;">
      <div style="margin-top: 5px;">
        <span class="limit-line" style="--line: 1;"><b>{{noti.title}}</b></span>
      </div>
      <div class="wrap" style="display: flex">
        <input type="checkbox" id="checkbox{{i}}" class="checkbox" style="display: none">
        <div #elRefNotifications class="limit-line notification-body">
          <label class="btn" for="checkbox{{i}}" (click)="$event.stopImmediatePropagation(); clickExpandBtn(i, $event);"
            style="display: none;">
            {{realtimeNotificationExpandBtn[i]}}
          </label>
          {{noti.body}}
        </div>
      </div>
      <div class="notification-timer">
        <span style="font-size: 12px;">{{noti.dateCreated | date: 'MM/dd/yyyy - hh:mm:ss'}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingNotification>
  <div style="width: 20vw">
    <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #loadingNotificationEnd>
  <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
</ng-template>
