<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container"
  style="cursor: move;">
  <div fxLayout="row" fxLayoutAlign="center start"  mat-dialog-title id="title">
    <h6 *ngIf="model.TaskId>0">TASK NO.
      {{model.TaskId}}</h6>
    <button *ngIf="currentRoute != '/configuration/dashboard-crm'" mat-icon-button (click)="onHidePopUp()" id="action-minimize" style="color: grey;" matTooltip="Minimize">
      <mat-icon class="icon-top">minimize</mat-icon>
    </button>
  </div>

  <mat-dialog-content style="height: calc(100% - 96px);" class="body-call">
    <p>{{formatCallEvent(model.CallEvent)}}</p>
  </mat-dialog-content>
  <div mat-dialog-actions align="center">
    <button mat-flat-button debouncedClick    (throttleClick)="onClickVoiceMail()"
      [nbSpinner]="voiceMailLoading" color="primary" matTooltip="Voice mail" [disabled]="model.CallEvent !=='ANSWERED'" >
    <mat-icon>
      voicemail
    </mat-icon>
    </button>
    <button mat-flat-button  color="accent" [disabled]="model.CallEvent !=='ANSWERED'"
      [nbSpinner]="voiceMailLoading" matTooltip="Call end" debouncedClick  (throttleClick)="makeAnEndCall()"> <mat-icon>
        call_end
      </mat-icon></button>
    <button mat-flat-button [mat-dialog-close]="true" cdkFocusInitial color="warn" matTooltip="Close"><mat-icon>
      close
    </mat-icon></button>
  </div>
</div>