import { Component, HostListener, OnInit } from '@angular/core';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { NbIconLibraries } from '@nebular/theme';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { SettingCompanyViewModel } from './modules/admin/setting-management/setting-company/setting-company';
import { SettingService } from './shared/services/setting.service';
import { Title } from '@angular/platform-browser';
import { SpotlightrService } from './shared/services/spotlightr.service';
import { first } from 'rxjs/operators';
import { MutliMediaService } from './shared/services/mutli-media.service';
import { Media } from './modules/admin/profile-management/profile-detail/multi-media/multi-media.model';
import { DisplayComponentMultimediaTab } from './modules/admin/profile-management/profile-detail/multi-media/multi-media.component';
import { BrowserTabHandlerService } from './shared/services/browser-tab-handler.service';
import { Setting } from './shared/models/setting.model';
import { SettingPoolService } from './shared/services/setting-pool.service';
import { setTimeout } from 'timers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  favIcon: HTMLLinkElement = document.querySelector('#appFavicon');
  title = 'FrontEnd';
  isUploadVideo: boolean = false;
  allSettingAllow: Setting[] = [];

  constructor(
    private settingService: SettingService,
    private settingPoolService: SettingPoolService,
    private themeService: NbThemeService,
    private titleService: Title,
    private iconLibraries: NbIconLibraries,
    private spotlightrService: SpotlightrService,
    private mutltiMedia: MutliMediaService,
    private toast: NbToastrService,
    private browserService: BrowserTabHandlerService
  ) {
    // use to count lifetime of the browser tab
    this.browserService.trackStartApp();

    // 2022/01/17 ducqm start add
    this.setupSettingPool();


    // this.settingPoolService.getConfigCompany().subscribe(res => {
    //   if (res && res['THEME_COLOR']) this.themeService.changeTheme(res['THEME_COLOR']);
    //   this.titleService.setTitle((res && res['THEME_COLOR']) ? res['COMPANY_NAME'] : '');
    //   this.favIcon.href = (res && res['COMPANY_FAVICON']) ?? './assets/images/logo.jpg';
    //   if (res && res['DEFAULT_AVATAR']) SettingCompanyViewModel.golbalDefaultAvatar = res['DEFAULT_AVATAR'];
    // })
    // this.settingService.getConfigCompany().subscribe(res => {
    //   if (res.themeColor)
    //     this.themeService.changeTheme(res.themeColor);

    //   this.titleService.setTitle(res.companyName ?? '');
    //   this.favIcon.href = res.urlCompanyFavicon ?? './assets/images/logo.jpg';

    //   if (res.urlDefaultAvatar)
    //     SettingCompanyViewModel.golbalDefaultAvatar = res.urlDefaultAvatar;
    // })

    // 2022/01/17 ducqm end add

    this.iconLibraries.registerFontPack('material-icons-outlined', {
      ligature: true,
    });

    //2022-03-30 vuonglqn add start
    //listen data upload soptlightr
    this.spotlightrService.getObsVideoUploadSpotlightr().subscribe(res => {
      if (res && res.videos?.length > 0) {
        this.isUploadVideo = true;
        this.spotlightrService.createVideoSpotlightr(res.videos[0]).pipe(first()).subscribe(resSpot => {
          console.log(resSpot);
          if (resSpot) {
            const linkVideo = resSpot.replace('/publish/', '/watch/');
            this.mutltiMedia.uploadVideoLink(res.profileId,
              {
                mediaId: null,
                media1: linkVideo,
                profileId: res.profileId
              } as Media).subscribe((item: any) => {
                this.isUploadVideo = false;
                this.spotlightrService.changeObsVideoUpload(null); // update null.
                this.toast.success('Upload video url successfully!', 'Success');
                this.mutltiMedia.getMediaByProfileId(res.profileId, DisplayComponentMultimediaTab.All);
              });
          }
        });
      }
    });
    //2022-03-30 vuonglqn add end

    // 2023-01-18 ducqm start add
    // this.settingService.getSettingByKeyAndGroup("SUBJECT", "PROFILE").subscribe(resp => {
    //   if (resp.result) window.localStorage.setItem("subject_profile", resp.result.value);
    // })

    // 2023-01-18 ducqm end add
  }

  ngOnInit(): void {
    // this.settingService.getRequestConfigCompany();

    ReactiveFormConfig.set({
      internationalization: {
        dateFormat: 'dmy',
        seperator: '/'
      },
      validationMessage: {
        alpha: 'Only alphabelts are allowed.',
        alphaNumeric: 'Only alphabet and numbers are allowed.',
        compare: 'Input are not matched',
        contains: 'value is not contains in the input',
        creditcard: 'creditcard number is not correct',
        digit: 'Only digit are allowed',
        email: 'Email is not valid',
        greaterThanEqualTo: 'please enter greater than or equal to the joining age',
        greaterThan: 'please enter greater than to the joining age',
        hexColor: 'please enter hex code',
        json: 'Please enter valid json',
        lessThanEqualTo: 'Please enter less than or equal to the current experience',
        lessThan: 'Please enter less than or equal to the current experience',
        lowerCase: 'Only lowercase is allowed',
        maxLength: 'Maximum length is {{1}} characters',
        maxNumber: 'Enter value less than equal to {{1}}',
        minNumber: 'Enter value greater than equal to {{1}}',
        password: 'Please enter valid password',
        pattern: 'Please enter valid zipcode',
        range: 'Please enter value between {{1}} to {{2}}',
        required: 'This field is required',
        time: 'Only time format is allowed',
        upperCase: 'Only uppercase is allowed',
        url: 'Only url format is allowed',
        zipCode: 'Enter valid zip code',
        minLength: 'Minimum length is {{1}} characters',
        numberic: 'Only numberic is allowed',
      }
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: Event) {
    if (this.isUploadVideo) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  async setupSettingPool(){
    await this.settingPoolService.getAllSettingAllow();

    const configCompany = await this.settingPoolService.getConfigCompany();
    configCompany.subscribe(res => {
      if (res.themeColor)
        this.themeService.changeTheme(res.themeColor);

      this.titleService.setTitle(res.companyName ?? '');
      this.favIcon.href = res.urlCompanyFavicon ?? './assets/images/logo.jpg';

      if (res.urlDefaultAvatar)
        SettingCompanyViewModel.golbalDefaultAvatar = res.urlDefaultAvatar;
    })

    this.settingPoolService.getSettingByKeyAndGroup("SUBJECT", "PROFILE").subscribe(resp => {
      if (resp.result) window.localStorage.setItem("subject_profile", resp.result.value);
    })
  }
}
