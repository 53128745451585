import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { ProfileUpdateInformationViewModel, UpdateInformation } from './update-information.model';

@Injectable({
  providedIn: 'root'
})
export class MergeChangesService {
  baseUrl = environment.apiOnboarding;
  updateInformationSubject$ = new BehaviorSubject<ProfileUpdateInformationViewModel>(undefined);
  constructor(private http: HttpClient) { }
  refreshUpdateInformationByProfileId(profileId: string) {
    // tslint:disable-next-line:max-line-length
    this.http.get<ReturnResult<ProfileUpdateInformationViewModel>>(`${this.baseUrl}/GetUpdateInformationByPIDAsync/${profileId}`).subscribe(resp => {
      if (resp.result) {
        this.updateInformationSubject$.next(resp.result);
      }
    });
  }
  getUpdateInformationByProfileId(): Observable<ProfileUpdateInformationViewModel> {
    return this.updateInformationSubject$.asObservable();
  }
  acceptChangesModified(updateInformation: UpdateInformation, entity: any, type: string): Observable<ReturnResult<boolean>> {
    switch (type) {
      case 'Profile':
        {
          return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveModifiedUpdateInformation`, {
            updateInformation,
            profile: entity,
            type
          });
        }
      case 'Contact':
        {
          return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveModifiedUpdateInformation`, {
            updateInformation,
            contact: entity,
            type
          });
        }
      case 'Location':
        {
          return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveModifiedUpdateInformation`, {
            updateInformation,
            type
          })
        }
    }
  }
  acceptChangesAdded(updateInformation: UpdateInformation, entity: any, type: string): Observable<ReturnResult<boolean>> {
    switch (type) {
      case 'Profile':
        {
          return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveAddedUpdateInformation`, {
            updateInformation,
            profile: entity,
            type
          });
        }
      case 'NewProfile':
        {
          return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveAddedUpdateInformation`, {
            updateInformation,
            newProfile: entity,
            type
          });
        }
      case 'NewContact':
        {
          return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveAddedUpdateInformation`, {
            updateInformation,
            newContact: entity,
            type
          });
        }
      default:
        break;
    }
  }
  rejectChanges(updateInformation: UpdateInformation): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RejectChanges`, updateInformation);
  }
  rejectAll(updateInformations: UpdateInformation[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RejectAll`, updateInformations);
  }
  isAnyUpdateInformation(profileId: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/IsAnyUpdateInformation/${profileId}`);
  }
  acceptDeletedInformation(updateInformation: UpdateInformation): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AcceptDeletedInformation`, updateInformation);
  }
}
