<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;"
  [nbSpinner]="isLoading">
  <button mat-button color="primary" (click)="saveData()" [disabled]="!frmSupportTicket.valid || isLoading">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frmSupportTicket" class="container" (ngSubmit)="saveData()"
    style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
    <div class="row">
        
        <div style="padding: 12px; margin-bottom: 10px; border-radius: 5px; width:100%;">
            <mat-form-field appearance="standard">
                <mat-label>Resolution Code</mat-label>
                <input matInput type="text"  formControlName="resolutionCode" autocomplete="off"
                trim="blur">
              <mat-error
                *ngIf="frmSupportTicket.controls.resolutionCode.errors && (frmSupportTicket.controls.resolutionCode.dirty || frmSupportTicket.controls.resolutionCode.touched)">
                {{frmSupportTicket.controls.resolutionCode['errorMessage']}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>System Comment</mat-label>
                <input matInput type="text"  formControlName="systemComment" autocomplete="off"
            trim="blur">
              <mat-error
            *ngIf="frmSupportTicket.controls.systemComment.errors && (frmSupportTicket.controls.systemComment.dirty || frmSupportTicket.controls.systemComment.touched)">
            {{frmSupportTicket.controls.systemComment['errorMessage']}}</mat-error>
            </mat-form-field>
          </div>
        
      <!-- <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>Resolution Code</mat-label>
          <input matInput type="text"  formControlName="resolutionCode" autocomplete="off"
            trim="blur">
          <mat-error
            *ngIf="frmSupportTicket.controls.resolutionCode.errors && (frmSupportTicket.controls.resolutionCode.dirty || frmSupportTicket.controls.resolutionCode.touched)">
            {{frmSupportTicket.controls.resolutionCode['errorMessage']}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>System Comment</mat-label>
          <input matInput type="text"  formControlName="systemComment" autocomplete="off"
            trim="blur">
          <mat-error
            *ngIf="frmSupportTicket.controls.systemComment.errors && (frmSupportTicket.controls.systemComment.dirty || frmSupportTicket.controls.systemComment.touched)">
            {{frmSupportTicket.controls.systemComment['errorMessage']}}</mat-error>
        </mat-form-field>
      </div> -->

      <button type="submit" style="display: none;"></button>
    </div>
  </form>
</mat-dialog-content>