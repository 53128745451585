import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { ProfileDetailModel } from '../profile-management/profile-detail.model';
import { ProducerModel, ProducerProfile, ProducerRequest, ProducerType } from './producer-model';

@Injectable({
  providedIn: 'root'
})
export class ProducerService {

  baseUrl = environment.apiProducer;
  profileUrl = environment.apiProfile;
  private _profileDetailData$: ReplaySubject<ReturnResult<ProducerModel>> = new ReplaySubject<ReturnResult<ProducerModel>>(1);
  private _existingProfile$: ReplaySubject<ReturnResult<ProfileDetailModel[]>> = new ReplaySubject<ReturnResult<ProfileDetailModel[]>>(1);
  private _existingProducer$: ReplaySubject<ReturnResult<ProducerModel[]>> = new ReplaySubject<ReturnResult<ProducerModel[]>>(1);
  constructor(private http: HttpClient) { }

  getProducerPaging(page: Page): Observable<ReturnResult<PagedData<ProducerModel>>> {
    return this.http.post<ReturnResult<PagedData<ProducerModel>>>(`${this.baseUrl}/get`, page);
  }

  getProducerById(): Observable<ReturnResult<ProducerModel>> {
    return this._profileDetailData$.asObservable();
  }

  refreshData(id: any): Observable<ReturnResult<ProducerModel>> {
    return this.http.get<ReturnResult<ProducerModel>>(`${this.baseUrl}/id?id=${id}`)
      .pipe(tap(response => {
        this._profileDetailData$.next(response);
      }));
  }

  editProducer(id: string, producerRequest: ProducerRequest) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/update/${id}`, producerRequest);
  }
  deleteProducer(id: string): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/${id}`);
  }

  deleteProducers(id: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/delete`, id);
  }
  getProfileList() {
    return this._existingProfile$.asObservable();
  }
  getProducerList() {
    return this._existingProducer$.asObservable();
  }
  searchProfile(keyword: string) {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.profileUrl}/Search?keyword=${keyword}`).subscribe(res => {
      this._existingProfile$.next(res);
    })
  }
  searchProducer(keyword: string) {
    return this.http.get<ReturnResult<ProducerModel[]>>(`${this.baseUrl}/Search?keyword=${keyword}`).subscribe(res => {
      this._existingProducer$.next(res);
    })
  }
  addProducerToProfile(request: ProducerProfile) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddProfile`, request);
  }
  editProducerOfProfile(request: ProducerProfile) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/EditProducerOfProfile`, request);
  }

  createProducer(model: ProducerModel): Observable<ReturnResult<ProducerModel>> {
    return this.http.post<ReturnResult<ProducerModel>>(this.baseUrl, model);
  }

  //2021-09-24 vuongle start add
  getProducerType(): Observable<ReturnResult<ProducerType[]>> {
    return this.http.get<ReturnResult<ProducerType[]>>(`${this.baseUrl}/GetProducerType`);
  }
  //2021-09-24 vuongle end add
}
