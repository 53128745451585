import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private redirectToProfile$ = new Subject<string>();
  private showSupportTicketIcon$ = new Subject<string>();
  constructor() { }
  redirectToProfile() {
    return this.redirectToProfile$.asObservable();
  }
  
  showSupportTicketIcon() {
    return this.showSupportTicketIcon$.asObservable();
  }
  
  setRedirectToProfile(value: string) {
    localStorage.setItem('REDIRECT_TO_PROFILE', value);
    const settingVal = localStorage.getItem("REDIRECT_TO_PROFILE");
    this.redirectToProfile$.next(value);
  }
  cleanRedirectToProfile() {
    localStorage.removeItem('REDIRECT_TO_PROFILE');
    this.redirectToProfile$.next(null);
  }
  setShowSupportTicketIcon(value: string) {
    localStorage.setItem('SHOW_SUPPORT_ICON ', value);
    const settingVal = localStorage.getItem("SHOW_SUPPORT_ICON");
    this.showSupportTicketIcon$.next(value);
  }
  cleanShowSupportTicketIcon() {
    localStorage.removeItem('SHOW_SUPPORT_ICON');
    this.showSupportTicketIcon$.next(null);
  }
}
