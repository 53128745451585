import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerApply, MatDatepickerInput, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatFormField, MAT_FORM_FIELD } from '@angular/material/form-field';
import { DISPLAY_INPUT_DATE_TIME_FORMAT, GENERAL_DATE_TIME_FORMATS } from './date-format.model';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { default as _rollupMoment } from 'moment';
import { takeUntil } from 'rxjs/operators';
const moment = _moment || _rollupMoment;
@Component({
  selector: 'app-primas-custom-date-time-picker',
  exportAs: 'PrimasCustomDateTimePicker',
  templateUrl: './primas-custom-date-time-picker.component.html',
  styleUrls: ['./primas-custom-date-time-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: DISPLAY_INPUT_DATE_TIME_FORMAT }]

})
export class PrimasCustomDateTimePickerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(MatDatepicker) public datePicker: MatDatepicker<any>;
  @ViewChild('', { static: false }) set setCustomDateTimePicker(content) { if (content) { } }

  @Input() date: string;
  @Input() time: Date;
  // enable minute step: 1 > otherwise off
  @Input() minuteStep: number = 15;
  //  12 or 24 hours format. default 12
  @Input() twelveHoursFormat = true;
  @Output() editManualSave: EventEmitter<{ value: moment.Moment, isInitial: boolean }> = new EventEmitter();
  /**
   * WARNING: this attribute is automatically update form control. IF TURN OFF, the form control will not trigger dateChange itself. then you need to manually call update at 
   * Output dateChange
   */
  @Output() dateChange: EventEmitter<any> = new EventEmitter();
  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField?: MatFormField,
  ) { }
  ngOnDestroy(): void {
    this._destroy.complete();
  }


  directiveSubscription: Subscription;
  timeInput: string;
  private _destroy: Subject<void> = new Subject<void>();
  public cancelUpdate: boolean = false;
  public dateChangeSubject: Subject<any> = new Subject<any>();
  ngOnInit() {
    if (!this.date) {
      this.setCurrentTime();
    }
    this.dateChangeSubject.pipe(takeUntil(this._destroy)).subscribe((data: MatDatepickerInputEvent<any>) => {
      if (data && !this.cancelUpdate) {
        if ((<HTMLInputElement>data?.targetElement)?.value) {
          if (moment((<HTMLInputElement>data.targetElement).value).isValid()) {
            let parsedValue: string = (<HTMLInputElement>data.targetElement).value;
            let neMoment: moment.Moment = moment(parsedValue);
            this.editManualSave.emit({ value: neMoment, isInitial: true });
          }
        } else {
          this.editManualSave.emit();
        }
      }
    })
  }
  ngAfterViewInit(): void {

  }
  applyClicked() {
    let isInitial = false;
    this.cancelUpdate = true;
    this.datePicker._applyPendingSelection();
    // this.datePicker.close();
    let currentTime: _moment.Moment;
    if (this.time && this._formField._control) {
      if (this._formField?._control?.value && _moment.isMoment(this._formField?._control?.value)) {
        currentTime = this._formField._control.value;
      } else {
        isInitial = true;
        currentTime = moment();
      }
      currentTime.set({
        hour: this.time.getHours(),
        minute: this.time.getMinutes(),
        second: this.time.getSeconds()
      })
      // currentTime.setHours(this.time.getHours(), this.time.getMinutes(), this.time.getSeconds());
      this._formField._control.value = currentTime;
      this.datePicker._applyPendingSelection();
      this.datePicker.close();

    }
    this.cancelUpdate = false;
    this.dateChange.emit({ value: currentTime, isInitial: isInitial });
  }
  onSelectTime(value: { time: Date, save: boolean }) {
    if (value.save) {
      this.time = value.time;
    }
  }

  getDateChangedFromMatDirective() {
    if (this.directiveSubscription) {
      this.directiveSubscription.unsubscribe();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  setCurrentTime() {
    this.date = moment().format('M/dd/yyyy, HH:mm:ss');
    this.time = new Date();
    this.time.setHours(0, 0, 0);
  }
  setTimeForTimePicker() {
    if (moment.isMoment(this._formField._control.value)) {
      let momentValue: moment.Moment = this._formField._control.value;
      this.time = momentValue.toDate();
    } else if (moment.isDate(this._formField._control.value)) {
      this.time = this._formField._control.value;
    }
  }
  setupInit() {
    if (this._formField && this._formField._control && this._formField._control.value) {
      this.setTimeForTimePicker();
    } else {
      this.setCurrentTime();
    }
  }
}

export class ExtendedMatDatepickerInput extends MatDatepickerInput<any> {
  returnValue() {
    return this.value;
  }
}