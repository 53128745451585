import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { AccountOpportunityResult, ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { SaleOpportunity, SaleOpportunityProfile, SaleOpportunityViewModel } from './opportunity.model';
import { tap, timeout } from 'rxjs/operators';
import { ProfileDetailModel } from '../profile-management/profile-detail.model';

@Injectable({
  providedIn: 'root'
})
export class OpportunityManagementService {
  baseUrl = environment.apiSaleOpportunity;
  saleOpportunity: BehaviorSubject<SaleOpportunity[]> = new BehaviorSubject<SaleOpportunity[]>(undefined);
  opportunityDetail$: ReplaySubject<ReturnResult<ProfileDetailModel>> = new ReplaySubject<ReturnResult<ProfileDetailModel>>(1);
  isRefreshOpportunityGrid$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  searchOpportunity$: ReplaySubject<ReturnResult<ProfileDetailModel[]>> = new ReplaySubject<ReturnResult<ProfileDetailModel[]>>(1);
  countAmountSubject: Subject<number> = new Subject<number>();
  constructor(private http: HttpClient) { }

  getOpportunityPaging(page: Page): Observable<ReturnResult<PagedData<SaleOpportunityProfile>>> {
    return this.http.post<ReturnResult<PagedData<SaleOpportunityProfile>>>(`${this.baseUrl}/GetOpportunityPaging`, page);
  }

  saveOpportunity(model: SaleOpportunity): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveOpportunity`, model);
  }

  deleteOpportunity(id: string): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/delete/${id}`)
  }

  deleteOpportunities(id: string[], isDellAll: boolean = false, typeName: string = null, isTemporary: boolean = false, isArchive: boolean = false): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteOpportunities?isDeletedAll=${isDellAll}&typeName=${typeName}&isTemporary=${isTemporary}&archive=${isArchive}`, id);
  }

  import(formData: FormData): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Import`, formData).pipe(
      timeout(1200000)
    );
  }

  export(page: Page, isExportContact: boolean = false, isExportSaleAccount: boolean = false): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Export?isExportContact=${isExportContact}&isExportSaleAccount=${isExportSaleAccount}`, page).pipe(
      timeout(1200000)
    );
  }
  // id: sale account id
  getSaleAccountOpportunity(id: string): Observable<ReturnResult<SaleOpportunity[]>> {
    return this.http.get<ReturnResult<SaleOpportunity[]>>(`${this.baseUrl}/GetSaleAccountOpportunity/${id}`,).pipe(timeout(1200000));
  }
  getSaleAccountOpportunityLst(): Observable<SaleOpportunity[]> {
    return this.saleOpportunity.asObservable();
  }
  refreshGetSaleAccountOpportunity(id: string, event: (loading: boolean) => void) {
    if (event) {
      event(true);
    }
    this.http.get<ReturnResult<SaleOpportunity[]>>(`${this.baseUrl}/GetSaleAccountOpportunity/${id}`,).pipe(timeout(1200000)).subscribe({
      next: (data) => {
        if (data.result) {
          this.saleOpportunity.next(data.result);
        }
      },
      complete: () => {
        if (event) {
          event(false);
        }
      }
    });

  }
  SaveAccountOpportunity(model: SaleOpportunityViewModel) {
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/SaveAccountOpportunity`, model).pipe(timeout(1200000));
  }
  unlinkOpportunitySaleAccount(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/UnlinkOpportunitySaleAccount/${id}`).pipe(timeout(1200000));
  }

  refreshOpportunityById(id: string): Observable<ReturnResult<ProfileDetailModel>> {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.baseUrl}/Opportunity?id=${id}`)
      .pipe(tap(resp => this.opportunityDetail$.next(resp)));
  }

  getOpportunityById(): Observable<ReturnResult<ProfileDetailModel>> {
    return this.opportunityDetail$.asObservable();
  }

  editOpportunity(model: {
    id: string,
    saleOpportunityProfileModel?: ProfileDetailModel,
    saleOpportunityModel?: SaleOpportunity
  }): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Opportunity/Update?id=${model.id}`, model)
      .pipe(tap(resp => this.isRefreshOpportunityGrid$.next(resp.result)));
  }

  getSearchOpportunity() {
    return this.searchOpportunity$.asObservable();
  }

  searchOpportunity(keyword: string): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/Search?keyword=${keyword}`)
      .pipe(tap(resp => this.searchOpportunity$.next(resp)));
  }
  deleteProfileByFilter(model) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAllAsync`, model);
  }
  countAmountOpportunityProfilePaging(pagingFilter: Page) {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/CountAmountOpportunityProfilePaging`, pagingFilter).pipe(tap(x => {
      if (x.result || x.result == 0) {
        this.countAmountSubject.next(x.result);
      }
    }));
  }
  getTotalAmount(): Observable<number> {
    return this.countAmountSubject.asObservable();
  }

  bulkAddTag(page: Page, tags: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAddTags?tags=${tags}`, page);
  }

  addOpportunityToAccount(accountId: string, opportunityId: string) {
    return this.http.post<AccountOpportunityResult<any>>(`${this.baseUrl}/AddOpportunityToAccount`, {accountId, opportunityId});
  }
  addAccountOpportunityToRelationship(accountId: string, opportunityId: string) {
    return this.http.post<AccountOpportunityResult<any>>(`${this.baseUrl}/AddAccountOpportunityToRelationship`, {accountId, opportunityId});
  }
}
