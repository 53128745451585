import { el } from 'date-fns/locale';
import { tap } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { ProfileSummary } from './summary-report.model';
import { GrapthReport } from './summary-report.component';

@Injectable({
  providedIn: 'root'
})
export class SummaryReportService {
  baseUrl = environment.apiProfileManagement;
  reportPaging = new BehaviorSubject<PagedData<ProfileSummary>>(undefined);
  constructor(private http: HttpClient) { }
  refreshSummaryReport(page: Page, date: string = null) : Observable<PagedData<ProfileSummary>>{
    if(date){
      return  this.http.post<PagedData<ProfileSummary>>(`${this.baseUrl}/ProfileSummaryReport?dateChossen=${date}`, page).pipe(tap((e) => {
        this.reportPaging.next(e);
      }))
    }
    else {
      return  this.http.post<PagedData<ProfileSummary>>(`${this.baseUrl}/ProfileSummaryReport`, page).pipe(tap((e) => {
        this.reportPaging.next(e);
      }))
    }
  
  }
  getSummaryReport(): Observable<PagedData<ProfileSummary>> {
    return this.reportPaging.asObservable();
  }
  refreshDataSummaryReport(date: string = null): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/ManualGenProfileReport`);
  }
  getAllRevision():Observable<ReturnResult<string[]>>{
    return this.http.get<ReturnResult<string[]>>(`${this.baseUrl}/GetAllRevision`);
  }
  getPublishedChart(revision: string = undefined, country: string, isWeek: boolean): Observable<ReturnResult<GrapthReport[]>>{
    if(revision && country) {
      return this.http.get<ReturnResult<GrapthReport[]>>(`${this.baseUrl}/GetGraphReport?revision=${revision}&country=${country}&isWeek=${isWeek}`);
    }
    else {
      return this.http.get<ReturnResult<GrapthReport[]>>(`${this.baseUrl}/GetGraphReport`);
    }
  }
  refactorReportData(): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/RefactorReportData`);

  }
}
