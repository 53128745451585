import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbAuthModule } from '@nebular/auth';
import { NbAlertModule, NbInputModule, NbButtonModule, NbCheckboxModule, NbLayoutModule, NbActionsModule, NbBadgeModule, NbCalendarModule, NbCalendarRangeModule, NbCardModule, NbChatModule, NbDatepickerModule, NbIconModule, NbListModule, NbPopoverModule, NbSpinnerModule, NbTimepickerModule, NbUserModule, NbSelectModule, NbToggleModule, NbAutocompleteModule, NbAccordionModule, NbProgressBarModule, NbStepperModule, NbFormFieldModule, NbOverlayModule } from '@nebular/theme';
import { HttpClientModule } from '@angular/common/http';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TblSwitchComponent } from './components/tbl-switch/tbl-switch.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxDefaultOptions, MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DateFormatPipe, DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { ImportModalComponent } from './components/import-modal/import-modal.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { DatetimeFilterComponent } from './components/datetime-filter/datetime-filter.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { PrimasToolbarComponent } from './components/template-management/primas-toolbar/primas-toolbar.component';
import { PrimasTableComponent } from './components/template-management/primas-table/primas-table.component';
import { PrimasCustomViewComponent } from './components/template-management/primas-custom-view/primas-custom-view.component';
import { AddEditCustomViewComponent } from './components/template-management/primas-custom-view/add-edit-custom-view/add-edit-custom-view.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FromdateTodateComponent } from './components/fromdate-todate/fromdate-todate.component';
import { DropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component';
import { ContactComponent } from './components/stand-alone-component/contact/contact.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { LocationComponent } from './components/stand-alone-component/location/location.component';
import { AutoCompleteCategoriesComponent } from './components/stand-alone-component/auto-complete-categories/auto-complete-categories.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { HtmlSafetyPipe } from './pipes/safety-html.pipe';
// import { HtmlSafetyPipe } from './pipes/safety-html.pipe';
import { InlineEditComponent } from './components/stand-alone-component/inline-edit/inline-edit.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialogRef } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill';
import { DOCUMENT } from '@angular/common';
import { RichInlineEditComponent } from './components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { AUTO_SIZE_INPUT_OPTIONS, AutoSizeInputModule, AutoSizeInputOptions } from 'ngx-autosize-input';
import { InlineEditAreaComponent } from './components/stand-alone-component/inline-edit-area/inline-edit-area.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PrimasDataStateComponent } from './components/primas-data-state/primas-data-state.component';
import { DropdownFilterOperatorComponent } from './components/dropdown-filter-operator/dropdown-filter-operator.component';
import { AutoCompleteTagComponent } from './components/stand-alone-component/auto-complete-tag/auto-complete-tag.component';
import { InlineEditObjectComponent } from './components/stand-alone-component/inline-edit-object/inline-edit-object.component';
import { SafeIframePipe } from './pipes/safe-iframe.pipe';
import { VideoPlayerComponent } from './components/stand-alone-component/video-player/video-player.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { InlineEditSocicalComponent } from './components/stand-alone-component/inline-edit-socical/inline-edit-socical.component';
import { JiraEditInlineComponent } from './components/stand-alone-component/jira-edit-inline/jira-edit-inline.component';
import { ContactInfoComponent } from './components/stand-alone-component/contact-info/contact-info.component';
import { LocationInfoComponent } from './components/stand-alone-component/location-info/location-info.component';
import { TreeStructureFilterComponent } from './components/tree-structure-filter/tree-structure-filter.component';
import { HowFarDropdownComponent } from './components/stand-alone-component/location/how-far-dropdown/how-far-dropdown.component';
import { MatTreeModule } from '@angular/material/tree';
import { AngularSplitModule } from 'angular-split';

const CUSTOM_AUTO_SIZE_INPUT_OPTIONS: AutoSizeInputOptions = {
  extraWidth: 10,
  includeBorders: false,
  includePadding: true,
  includePlaceholder: true,
  maxWidth: -1,
  minWidth: 100,
  setParentWidth: false,
  usePlaceHolderWhenEmpty: false,
};
import { InputTrimModule } from 'ng2-trim-directive';
import { NbSecurityModule } from '@nebular/security';
import { CalculatorInputComponent } from './components/stand-alone-component/calculator-input/calculator-input.component';
import { MultipleLineOptionDirective } from './directives/multiple-line-option.directive';
import { NgxCurrencyModule } from "ngx-currency";
import { InlineEditWithoutSaveComponent } from './components/stand-alone-component/inline-edit-without-save/inline-edit-without-save.component';
import { MatBadgeModule } from '@angular/material/badge';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { BlankPageComponent } from './components/stand-alone-component/blank-page/blank-page.component';
import { CustomTimelineComponent } from './components/custom-timeline/custom-timeline.component';
import { CustomTimelineEventComponent } from './components/custom-timeline/custom-timeline-event/custom-timeline-event.component';
import { DateTimelinePipe } from './pipes/date-timeline.pipe';
import { DateTimeToTimePipe } from './pipes/date-time-to-time.pipe';
import { CampaignMatrixTableComponent } from './components/stand-alone-component/campaign-matrix-table/campaign-matrix-table.component';
import { MatTableModule } from '@angular/material/table';
import { HeadshotComponent } from './components/headshot/headshot.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HeadshotDialog } from './components/headshot-dialog/headshot.dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContactTagComponent } from './components/stand-alone-component/contact/contact-tag/contact-tag.component';
import { EditorEmailComponent } from './components/stand-alone-component/editor-email/editor-email.component';
import { ShowRepliedEmailComponent } from './components/stand-alone-component/show-replied-email/show-replied-email.component';
import { EmailEditorModule } from 'angular-email-editor';
import { AnimationArrowScrollComponent } from './components/stand-alone-component/animation-arrow-scroll/animation-arrow-scroll.component';
import { CustomNgxEventCalendarComponent } from './components/custom-ngx-event-calendar/custom-ngx-event-calendar.component';
import { PopoverDetailTaskComponent } from './components/custom-ngx-event-calendar/popover-detail-task/popover-detail-task.component';
import { AdminModule } from '../modules/admin/admin.module';
import { TaskComponent } from '../modules/admin/task-management/task-board/task-board-lane/task/task.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { NextOrPreviousDetailComponent } from './components/stand-alone-component/next-or-previous-detail/next-or-previous-detail.component';
import { UploadVideoComponent } from './components/stand-alone-component/upload-video/upload-video.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AutosizeModule } from 'ngx-autosize';
import { NgxDataTableEmptyDirective } from './directives/ngx-data-table-empty.directive';
import { MultiSelectCategoryComponent } from './components/stand-alone-component/multi-select-category/multi-select-category.component';
import { DropdownSearchingComponent } from './components/dropdown-searching/dropdown-searching.component';
import { EmailQuillEditorComponent } from './components/stand-alone-component/email-quill-editor/email-quill-editor.component';
import { MultiSelectInfiniteScrollComponent } from './components/multi-select-infinite-scroll/multi-select-infinite-scroll.component';
import { NgJsonEditorModule } from 'ang-jsoneditor'
import { SearchBuyerEntityComponent } from './components/search-buyer-entity/search-buyer-entity.component';
import { BooleanFilterComponent } from './components/boolean-filter/boolean-filter.component';
import { MultiSelectDropDownComponent } from './components/stand-alone-component/multi-select-drop-down/multi-select-drop-down.component';
import { MultiAgentChosenComponent } from './components/multi-agent-chosen/multi-agent-chosen.component';
import { DebouncedClickDirective } from './directives/debounced-click.directive';
import { OnboardingFormComponent } from './components/onboarding-form/onboarding-form.component';
import { ArtistDetailsTabComponent } from './components/onboarding-form/artist-details-tab/artist-details-tab.component';
import { ContactDetailsTabComponent } from './components/onboarding-form/contact-details-tab/contact-details-tab.component';
import { ActDetailsTabComponent } from './components/onboarding-form/act-details-tab/act-details-tab.component';
import { AgreementTabComponent } from './components/onboarding-form/agreement-tab/agreement-tab.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SearchLazyComponent } from './components/search-lazy/search-lazy.component';
import { ResizeImageComponent } from './components/stand-alone-component/resize-image/resize-image.component';
import { AwsomeTooltipDirective } from './directives/awsome-tooltip.directive';
import { AwesomeTooltipComponent } from './components/stand-alone-component/awesome-tooltip/awesome-tooltip.component';
import { CustomNgxUsMapComponent } from './components/custom-ngx-us-map/custom-ngx-us-map.component';
import { CustomNgxTreeFilterComponent } from './components/custom-ngx-tree-filter/custom-ngx-tree-filter.component';
import { NoteDetailsComponent } from './components/note-details/note-details.component';
import { NoteDetailCardComponent } from './components/note-details/note-detail-card/note-detail-card.component';
import { NoteManagementComponent } from './components/note-management/note-management.component';
import { MakeCallComponent } from './components/stand-alone-component/make-call/make-call.component';
import { OpportunityModalComponent } from './components/stand-alone-component/opportunity-modal/opportunity-modal.component';
import { RealtimeNotificationComponent } from './components/realtime-notification/realtime-notification.component';
import { SearchTypeObjectsComponent } from './components/search-type-objects/search-type-objects.component';
import { SearchEditInlineComponent } from './components/search-edit-inline/search-edit-inline.component';
import { ContactRelationshipGridComponent } from './components/contact-relationship-grid/contact-relationship-grid.component';
import { GridInTabComponent } from './components/grid-in-tab/grid-in-tab.component';
import { NoteTabComponent } from './components/note-tab/note-tab.component';
import { OverlayNoteDetailsComponent } from './components/note-management/overlay-note-details/overlay-note-details.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { AutoPlaceHolderPipe } from './pipes/auto-place-holder.pipe';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { PrimasConfirmPopComponent } from './components/stand-alone-component/primas-confirm-pop/primas-confirm-pop.component';
import { AdminTabModeComponent } from './components/stand-alone-component/admin-tab-mode/admin-tab-mode.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddContactButtonComponent } from './components/add-button-in-tab/add-contact-button/add-contact-button.component';
import { AddLocationButtonComponent } from './components/add-button-in-tab/add-location-button/add-location-button.component';
import { ChevronProgressBarComponent } from './components/chevron-progress-bar/chevron-progress-bar.component';
import { WarningDialogDirective } from './directives/warning-dialog.directive';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { AddTabSearchNavigationComponent } from './components/stand-alone-component/admin-tab-mode/add-tab-search-navigation/add-tab-search-navigation.component';
import { MatInputAutoFocusDirective } from './directives/mat-input-auto-focus.directive';
import { MatMenuHoverTriggerComponent } from './components/mat-menu-hover-trigger/mat-menu-hover-trigger.component';
import { PrimasCustomMenuComponent } from './components/stand-alone-component/primas-custom-menu/primas-custom-menu.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DetailObjectsDirective } from './directives/detail-objects.directive';
import { DetectTabModeDirective } from './directives/detect-tab-mode.directive';
import { ChangeUrlObjectsDirective } from './directives/change-url-objects.directive';
import { AutoCompleteInlineEditComponent } from './components/stand-alone-component/auto-complete-inline-edit/auto-complete-inline-edit.component';
import { PrimasAutoCompleteComponent } from './components/stand-alone-component/primas-auto-complete/primas-auto-complete.component';
import { AddLocationTabDirective } from './directives/add-location-tab.directive';
import { DynamicContentFormComponent } from './components/dynamic-content-form/dynamic-content-form.component';
import { ProfileFileTabComponent } from './components/stand-alone-component/profile-file-tab/profile-file-tab.component';
import { ProfileFileTabHistoryComponent } from './components/stand-alone-component/profile-file-tab/profile-file-tab-history/profile-file-tab-history.component';
import { ProfileFileManageOwnerComponent } from './components/stand-alone-component/profile-file-tab/profile-file-manage-owner/profile-file-manage-owner.component';
import { PopOverSelectContactsComponent } from './components/stand-alone-component/pop-over-select-contacts/pop-over-select-contacts.component';
import { MailLogFilterComponent } from './components/mail-log-filter/mail-log-filter.component';
import { DialogAddTagComponent } from './components/dialog-add-tag/dialog-add-tag.component';
import { GetFileNamePipe } from './pipes/get-file-name.pipe';
import { AsteriskDirective } from './directives/asterisk.directive';
import { AdvancedImportDataComponent } from './components/advanced-import-data/advanced-import-data.component';
import { TooltipTemplateComponent } from './components/tooltip-template/tooltip-template.component';
import { TooltipTemplateDirective } from './directives/tooltip-template.directive';
import { AddExternalActivityLogComponent } from './components/add-external-activity-log/add-external-activity-log.component';
import { AddActivityLogBtnDirective } from './directives/add-activity-log-btn.directive';
import { AdvancedImportContactComponent } from './components/advanced-import-contact/advanced-import-contact.component';
import { CreateEntryContactComponent } from './components/advanced-import-contact/create-entry-contact/create-entry-contact.component';
import { ContactInfoPipe } from './pipes/contact-info.pipe';
import { PrimasCustomDateTimePickerComponent } from './components/stand-alone-component/primas-custom-date-time-picker/primas-custom-date-time-picker.component';
import { CustomNbTimepickerModule } from './components/stand-alone-component/timepicker/timepicker.module';
import { ImportHelperComponent } from './components/advanced-import-contact/import-helper/import-helper.component';
import { ContactReviewComponent } from './components/advanced-import-contact/contact-review/contact-review.component';
import { ImportModalNonMappingComponent } from './components/import-modal-non-mapping/import-modal-non-mapping.component';
import { ExtendDataInlineComponent } from './components/extend-data-inline/extend-data-inline.component';
import { UserOnlineBoardComponent } from './components/stand-alone-component/user-online-board/user-online-board.component';
import { UserStatusDialogComponent } from './components/user-status-dialog/user-status-dialog.component';
import { PrimasTitlePipe } from './pipes/primas-title.pipe';
import { TransformUppercaseFirstCharacterPipe } from './pipes/transform-uppercase-first-character.pipe';


const materialModules = [
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatButtonModule,
  MatButtonToggleModule,
  NgxDatatableModule,
  MatDialogModule,
  MatIconModule,
  MatToolbarModule,
  FlexLayoutModule,
  MatMenuModule,
  MatDividerModule,
  MaterialFileInputModule,
  MatStepperModule,
  MatCardModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
  NgxMatSelectSearchModule,
  MatListModule,
  MatTabsModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  FontAwesomeModule,
  MatGridListModule,
  QuillModule,
  AutoSizeInputModule,
  NgxSkeletonLoaderModule,
  NbToggleModule,
  MatTreeModule,
  NbAccordionModule,
  NbSecurityModule,
  NgxCurrencyModule,
  NbProgressBarModule,
  MatBadgeModule,
  MatTableModule,
  MatPaginatorModule,
  InfiniteScrollModule,
  AutosizeModule,
  NgJsonEditorModule,
  NbStepperModule,
  DragDropModule,
  NbFormFieldModule,
  MatProgressBarModule,
];

@NgModule({
  imports: [
    RxReactiveFormsModule,
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbPopoverModule,
    NbEvaIconsModule,
    NbSpinnerModule,
    NbCalendarRangeModule,
    NbInputModule,
    NbButtonModule,
    FormsModule,
    NbAlertModule,
    NbCheckboxModule,
    NbAuthModule,
    ReactiveFormsModule,
    NbLayoutModule,
    NbBadgeModule,
    NbActionsModule,
    NbUserModule,
    NbListModule,
    NbDatepickerModule,
    NbCalendarModule,
    NbTimepickerModule,
    Ng2SmartTableModule,
    NbSelectModule,
    NbAutocompleteModule,
    ...materialModules,
    NbDateFnsDateModule,
    RouterModule,
    AutoSizeInputModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    InputTrimModule,
    AngularSplitModule,
    InputTrimModule,
    NbSecurityModule,
    ImageCropperModule,
    EmailEditorModule,
    NgxDropzoneModule,
    CustomNbTimepickerModule.forRoot(),
  ],
  exports: [
    InputTrimModule,
    NbSelectModule,
    RxReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbPopoverModule,
    NbEvaIconsModule,
    NbSpinnerModule,
    NbCalendarRangeModule,
    NbAutocompleteModule,
    NbInputModule,
    NbButtonModule,
    FormsModule,
    NbAlertModule,
    NbCheckboxModule,
    NbAuthModule,
    ReactiveFormsModule,
    NbLayoutModule,
    NbBadgeModule,
    NbActionsModule,
    NbUserModule,
    NbChatModule,
    NbListModule,
    Ng2SmartTableModule,
    NbDatepickerModule,
    NbCalendarModule,
    NbTimepickerModule,
    TblSwitchComponent,
    ...materialModules,
    DateTimeFormatPipe,
    DateFormatPipe,
    ImportModalComponent,
    DatetimeFilterComponent,
    NbDateFnsDateModule,
    PrimasToolbarComponent,
    PrimasTableComponent,
    PrimasCustomViewComponent,
    FromdateTodateComponent,
    RouterModule,
    DropdownFilterComponent,
    ContactComponent,
    LocationComponent,
    MatAutocompleteModule,
    AutoCompleteCategoriesComponent,
    HtmlSafetyPipe,
    InlineEditComponent,
    RichInlineEditComponent,
    AutoSizeInputModule,
    InlineEditAreaComponent,
    AutoCompleteTagComponent,
    InlineEditObjectComponent,
    SafeIframePipe,
    VideoPlayerComponent,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    InlineEditSocicalComponent,
    JiraEditInlineComponent,
    ContactInfoComponent,
    LocationInfoComponent,
    TreeStructureFilterComponent,
    HowFarDropdownComponent,
    AngularSplitModule,
    CalculatorInputComponent,
    MultipleLineOptionDirective,
    InlineEditWithoutSaveComponent,
    StripHtmlPipe,
    TruncateTextPipe,
    BlankPageComponent,
    CustomTimelineComponent,
    CustomTimelineEventComponent,
    DateTimelinePipe,
    DateTimeToTimePipe,
    CampaignMatrixTableComponent,
    ShowRepliedEmailComponent,
    HtmlSafetyPipe,
    NbSecurityModule,
    HeadshotComponent,
    ImageCropperModule,
    HeadshotDialog,
    EditorEmailComponent,
    AnimationArrowScrollComponent,
    CustomNgxEventCalendarComponent,
    TaskComponent,
    NextOrPreviousDetailComponent,
    NgxDropzoneModule,
    MultiSelectCategoryComponent,
    DropdownSearchingComponent,
    EmailQuillEditorComponent,
    MultiSelectInfiniteScrollComponent,
    SearchBuyerEntityComponent,
    BooleanFilterComponent,
    MultiSelectDropDownComponent,
    MultiAgentChosenComponent,
    DebouncedClickDirective,
    OnboardingFormComponent,
    SearchLazyComponent,
    ResizeImageComponent,
    AwsomeTooltipDirective,
    AwesomeTooltipComponent,
    CustomNgxUsMapComponent,
    NoteDetailsComponent,
    NoteManagementComponent,
    OpportunityModalComponent,
    RealtimeNotificationComponent,
    SearchTypeObjectsComponent,
    SearchEditInlineComponent,
    ContactRelationshipGridComponent,
    GridInTabComponent,
    NoteTabComponent,
    OverlayNoteDetailsComponent,
    NumberFormatPipe,
    AutoPlaceHolderPipe,
    PhoneFormatPipe,
    PrimasConfirmPopComponent,
    AdminTabModeComponent,
    AddContactButtonComponent,
    ChevronProgressBarComponent,
    WarningDialogDirective,
    CurrencyFormatPipe,
    AddTabSearchNavigationComponent,
    MatInputAutoFocusDirective,
    MatMenuHoverTriggerComponent,
    PrimasCustomMenuComponent,
    DetailObjectsDirective,
    DetectTabModeDirective,
    ChangeUrlObjectsDirective,
    AutoCompleteInlineEditComponent,
    PrimasAutoCompleteComponent,
    AddLocationTabDirective,
    DynamicContentFormComponent,
    ProfileFileTabComponent,
    ProfileFileTabHistoryComponent,
    ProfileFileManageOwnerComponent,
    PopOverSelectContactsComponent,
    MailLogFilterComponent,
    DateFilterComponent,
    GetFileNamePipe,
    AsteriskDirective,
    TooltipTemplateDirective,
    AddActivityLogBtnDirective,
    CreateEntryContactComponent,
    ContactInfoPipe,
    PrimasCustomDateTimePickerComponent,
    PrimasTitlePipe,
    TransformUppercaseFirstCharacterPipe,
  ],
  declarations: [
    TblSwitchComponent,
    ConfirmModalComponent,
    DateTimeFormatPipe,
    DateFormatPipe,
    ImportModalComponent,
    DatetimeFilterComponent,
    PrimasToolbarComponent,
    PrimasTableComponent,
    PrimasCustomViewComponent,
    AddEditCustomViewComponent,
    FromdateTodateComponent,
    DropdownFilterComponent,
    ContactComponent,
    LocationComponent,
    AutoCompleteCategoriesComponent,
    HtmlSafetyPipe,
    InlineEditComponent,
    RichInlineEditComponent,
    InlineEditAreaComponent,
    AutoCompleteTagComponent,
    PrimasDataStateComponent,
    DropdownFilterOperatorComponent,
    InlineEditObjectComponent,
    SafeIframePipe,
    VideoPlayerComponent,
    InlineEditSocicalComponent,
    JiraEditInlineComponent,
    ContactInfoComponent,
    LocationInfoComponent,
    TreeStructureFilterComponent,
    HowFarDropdownComponent,
    CalculatorInputComponent,
    CalculatorInputComponent,
    MultipleLineOptionDirective,
    InlineEditWithoutSaveComponent,
    StripHtmlPipe,
    TruncateTextPipe,
    BlankPageComponent,
    CustomTimelineComponent,
    CustomTimelineEventComponent,
    DateTimelinePipe,
    DateTimeToTimePipe,
    CampaignMatrixTableComponent,
    DropdownFilterOperatorComponent,
    HeadshotComponent,
    HeadshotDialog,
    ContactTagComponent,
    ShowRepliedEmailComponent,
    EditorEmailComponent,
    AnimationArrowScrollComponent,
    CustomNgxEventCalendarComponent,
    PopoverDetailTaskComponent,
    TaskComponent,
    DateFilterComponent,
    NextOrPreviousDetailComponent,
    UploadVideoComponent,
    NgxDataTableEmptyDirective,
    MultiSelectCategoryComponent,
    DropdownSearchingComponent,
    EmailQuillEditorComponent,
    MultiSelectInfiniteScrollComponent,
    SearchBuyerEntityComponent,
    BooleanFilterComponent,
    MultiSelectDropDownComponent,
    MultiAgentChosenComponent,
    DebouncedClickDirective,
    OnboardingFormComponent,
    ArtistDetailsTabComponent,
    ContactDetailsTabComponent,
    ActDetailsTabComponent,
    AgreementTabComponent,
    SearchLazyComponent,
    ResizeImageComponent,
    AwsomeTooltipDirective,
    AwesomeTooltipComponent,
    CustomNgxUsMapComponent,
    CustomNgxTreeFilterComponent,
    NoteDetailsComponent,
    NoteDetailCardComponent,
    NoteManagementComponent,
    MakeCallComponent,
    OpportunityModalComponent,
    RealtimeNotificationComponent,
    SearchTypeObjectsComponent,
    SearchEditInlineComponent,
    ContactRelationshipGridComponent,
    GridInTabComponent,
    NoteTabComponent,
    OverlayNoteDetailsComponent,
    NumberFormatPipe,
    AutoPlaceHolderPipe,
    PhoneFormatPipe,
    PrimasConfirmPopComponent,
    AdminTabModeComponent,
    AddContactButtonComponent,
    AddLocationButtonComponent,
    ChevronProgressBarComponent,
    WarningDialogDirective,
    CurrencyFormatPipe,
    AddTabSearchNavigationComponent,
    MatInputAutoFocusDirective,
    MatMenuHoverTriggerComponent,
    PrimasCustomMenuComponent,
    DetailObjectsDirective,
    DetectTabModeDirective,
    ChangeUrlObjectsDirective,
    AutoCompleteInlineEditComponent,
    PrimasAutoCompleteComponent,
    AddLocationTabDirective,
    DynamicContentFormComponent,
    ProfileFileTabComponent,
    ProfileFileTabHistoryComponent,
    ProfileFileManageOwnerComponent,
    PopOverSelectContactsComponent,
    MailLogFilterComponent,
    DialogAddTagComponent,
    GetFileNamePipe,
    AsteriskDirective,
    AdvancedImportDataComponent,
    TooltipTemplateComponent,
    TooltipTemplateDirective,
    AddExternalActivityLogComponent,
    AddActivityLogBtnDirective,
    AdvancedImportContactComponent,
    CreateEntryContactComponent,
    ContactInfoPipe,
    PrimasCustomDateTimePickerComponent,
    ImportHelperComponent,
    ContactReviewComponent,
    ImportModalNonMappingComponent,
    ExtendDataInlineComponent,
    UserOnlineBoardComponent,
    UserStatusDialogComponent,
    PrimasTitlePipe,
    TransformUppercaseFirstCharacterPipe,
  ],
  providers: [
    DateTimeFormatPipe,
    DateFormatPipe,
    NumberFormatPipe,
    PhoneFormatPipe,
    CurrencyFormatPipe,
    ContactInfoPipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: AUTO_SIZE_INPUT_OPTIONS, useValue: CUSTOM_AUTO_SIZE_INPUT_OPTIONS },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }
  ],
  entryComponents: [
    ImportModalComponent,
    ConfirmModalComponent,
    AddEditCustomViewComponent,
    HeadshotDialog
  ]
})
export class SharedModule { }
