import { TagService } from 'src/app/shared/components/stand-alone-component/auto-complete-tag/tag.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Tags } from '../../auto-complete-tag/tag.model';

@Component({
  selector: 'app-contact-tag',
  templateUrl: './contact-tag.component.html',
  styleUrls: ['./contact-tag.component.scss']
})
export class ContactTagComponent implements OnInit, OnChanges {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredTag: Observable<string[]>;
  @Input()
  contactTag: string | null;
  contactTagList: string[] = []
  @Output()
  contactTagSelected = new EventEmitter<string>();;
  //fruits: string[] = ['Lemon'];
  //allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  tagList : string[] = [];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

  constructor(private tagService: TagService) {
    this.filteredTag = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.tagList.slice())),
    );
  }
  ngOnInit(): void {
    
    this.tagService.refresh();
    this.tagService.fetchTags().subscribe( e => {
      this.tagList = e.map(tag => tag.tagsName); 
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const inputChange = changes.contactTag;
    if (inputChange.currentValue !== inputChange.previousValue) {
    
      if(!this.contactTag) this.contactTagList= [];
      else
      {
        this.contactTagList = this.contactTag.split(',');  
      }
    }
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      const check = this.contactTagList.indexOf(value);
      if(check >=0) return;
      this.contactTagList.push(value);
    }
    // Clear the input value
    event.value = null;
    event.input = null;
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.emitTag()
    this.refilterTagList(event.value);

  }

  remove(fruit: string): void {
    const index = this.contactTagList.indexOf(fruit);
    if (index >= 0) {
      this.contactTagList.splice(index, 1);
    }
    this.emitTag()
  }
  refilterTagList(value: string){
    const index = this.tagList.indexOf(value);
    if(index >=0)
    {
     this.tagList.splice(index, 1);
    }
  }
  emitTag(){
    this.contactTag = this.contactTagList.join(',');
    if(this.contactTagSelected)
    this.contactTagSelected.emit(this.contactTagList.join(','))
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    const check = this.contactTagList.indexOf(event.option.viewValue);
    if(check >=0) return;
    this.contactTagList.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.emitTag();
    this.refilterTagList(event.option.viewValue);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.tagList.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }
  public displayProperty(value: string) {
    if (value) {
      return value;
    }
  }
}
