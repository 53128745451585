import { NbMenuItem } from '@nebular/theme';
import { ExtendNbMenuItem } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { environment } from 'src/environments/environment';
//  allowOpenTab: will allow open tab or not based on logic:
//  default open tab if: null || empty || true
// default not allow open if: allowOpenTab = false
export const MENU_ITEMS: ExtendNbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'group',
    data: {
      permission: 'view',
      resource: 'configuration-dashboard-nav'
    },
    children: [
      {
        link: '/configuration/patient-dashboard',
        title: 'Patient Dashboard',
        icon: 'group',
        data: {
          permission: 'view',
          resource: 'configuration-patient-dashboard'
        },
      },
      {
        link: '/configuration/license-dashboard',
        title: 'License Dashboard',
        icon: 'group',
        data: {
          permission: 'view',
          resource: 'configuration-license-dashboard'
        },
      },
    ]
  },
  {
    title: 'Sale Account',
    icon: 'smiling-face-outline',
    link: '/configuration/patient',
    data: {
      permission: 'view',
      resource: 'configuration-sale-account'
    }
  },
  {
    link: '/configuration/schedule-group',
    title: 'Schedule Groups',
    icon: 'group',
    data: {
      permission: 'view',
      resource: 'configuration-schedule-group'
    }
  },
  {
    link: '/configuration/sms-patient-monitoring',
    title: 'Message History',
    icon: 'group',
    data: {
      permission: 'view',
      resource: 'configuration-sms-patient-monitoring'
    }
  },
  {
    link: '/configuration/producer',
    title: 'Producer',
    icon: 'map-outline',
    data: {
      permission: 'view',
      resource: 'configuration-producer'
    }
  },
  {
    title: 'Admin',
    data: {
      permission: 'view',
      resource: 'configuration-admin'
    },
    icon: 'people-outline',
    children: [
      {
        title: 'Data Diagnostic',
        data: {
          permission: 'view',
          resource: 'configuration-data-diagnostic'
        },
        icon: 'trending-up-outline',
        children: [
          {
            link: '/configuration/dynamic-content',
            title: 'Dynamic Content',
            icon: 'keypad-outline',
            data: {
              permission: 'view',
              resource: 'configuration-dynamic-content',
            },
          },
        ]
      },
      {
        title: 'Configuration',
        data: {
          permission: 'view',
          resource: 'configuration-configurations'
        },
        icon: 'menu-2-outline',
        children: [
          {
            link: '/configuration/buyer-type',
            title: 'Buyer Type',
            icon: 'browser-outline',
            data: {
              permission: 'view',
              resource: 'configuration-buyer-type',
            },
          },
          {
            link: '/configuration/contact-type',
            title: 'Contact Type',
            icon: 'phone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-contact-type',
            },
          },
          {
            link: '/configuration/data-field',
            title: 'Data Fields',
            icon: 'file-text-outline',
            data: {
              permission: 'view',
              resource: 'configuration-data-field'
            },
          },
          {
            link: '/configuration/email-template',
            title: 'Email Template',
            icon: 'color-palette-outline',
            data: {
              permission: 'view',
              resource: 'configuration-email-template',
            },
          },
          {
            link: '/configuration/data-state',
            title: 'Legacy Data State',
            icon: 'browser',
            data: {
              permission: 'view',
              resource: 'configuration-data-state'
            },
          },
          {
            link: '/configuration/map-view-category',
            title: 'Map Category',
            icon: 'map-outline',
            data: {
              permission: 'view',
              resource: 'configuration-map-view-category'
            },
          },
          {
            link: '/configuration/priority',
            title: 'Priority',
            icon: 'bulb-outline',
            data: {
              permission: 'view',
              resource: 'configuration-priority'
            },
          },
          {
            link: '/configuration/resolution-code',
            title: 'Resolution Code',
            icon: 'pantone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-resolution-code'
            },
          },
          {
            link: '/configuration/task-status',
            title: 'Task Status',
            icon: 'speaker-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-status'
            },
          },
          {
            link: '/configuration/task-type',
            title: 'Task Type',
            icon: 'smartphone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-type'
            },
          },
          {
            url: '/configuration/user-note',
            title: 'User Note',
            icon: 'calendar-outline',
            data: {
              permission: 'view',
              resource: 'configuration-user-note'
            },
          },
        ]
      },
      {
        title: 'User and Role',
        data: {
          permission: 'view',
          resource: 'configuration-user-and-role'
        },
        icon: 'person-add-outline',
        children: [
          {
            title: 'User',
            icon: 'person-outline',
            link: '/configuration/user',
            data: {
              permission: 'view',
              resource: 'configuration-user'
            },
          },
          {
            title: 'Role',
            icon: 'options-2-outline',
            link: '/configuration/role',
            data: {
              permission: 'view',
              resource: 'configuration-role'
            },
          },
        ]
      },
      {
        title: 'Automation',
        data: {
          permission: 'view',
          resource: 'configuration-automation'
        },
        icon: 'layers-outline',
        children: [
          {
            link: '/configuration/api-job',
            title: 'Api Jobs',
            icon: 'lock-outline',
            data: {
              permission: 'view',
              resource: 'configuration-api-job'
            },
          },
          {
            link: '/configuration/background-job',
            title: 'Background Jobs',
            icon: 'shield-outline',
            data: {
              permission: 'view',
              resource: 'configuration-background-job'
            },
          },
          {
            title: 'Data State',
            icon: 'layout-outline',
            link: '/configuration/datastate-management',
            data: {
              permission: 'view',
              resource: 'configuration-datastate-management'
            },
          },
          {
            title: 'Task Plan',
            icon: 'file-text-outline',
            link: '/configuration/task-plan',
            data: {
              permission: 'view',
              resource: 'configuration-task-plan'
            },
          },
          {
            link: '/configuration/taskscript-management',
            title: 'Task Script',
            icon: 'archive-outline',
            data: {
              permission: 'view',
              resource: 'configuration-taskscript-management'
            },
          },
        ]
      },
      {
        title: 'General Settings',
        data: {
          permission: 'view',
          resource: 'configuration-general-setting'
        },
        icon: 'settings-2-outline',
        children: [
          {
            title: 'Changelog',
            icon: 'star-outline',
            link: '/configuration/changelog',
            data: {
              permission: 'view',
              resource: 'configuration-changelog'
            },
          },
          {
            link: '/configuration/setting',
            title: 'Setting',
            icon: 'settings-2-outline',
            data: {
              permission: 'view',
              resource: 'configuration-setting'
            },
          },
          {
            link: '/configuration/message-provider',
            title: 'Message Provider',
            icon: 'phone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-message-provider'
            },
          },
        ]
      }
    ]
  }
];


export let NAV_MENU_ITEMS: ExtendNbMenuItem[] = [
  {
    title: 'License Dashboard',
    icon: 'group',
    link: '/configuration/license-dashboard',
    data: {
      permission: 'view',
      resource: 'configuration-license-dashboard'
    },
    // children: [
    //   {
    //     link: '/configuration/patient-dashboard',
    //     title: 'Patient Dashboard',
    //     icon: 'group',
    //     data: {
    //       permission: 'view',
    //       resource: 'configuration-patient-dashboard'
    //     },
    //   },
    //   {
    //     link: '/configuration/license-dashboard',
    //     title: 'License Dashboard',
    //     icon: 'group',
    //     data: {
    //       permission: 'view',
    //       resource: 'configuration-license-dashboard'
    //     },
    //   },
    // ]
  },
  {
    title: 'Patients',
    icon: 'smiling-face-outline',
    link: '/configuration/patient',
    data: {
      permission: 'view',
      resource: 'configuration-nav-sale-account'
    }
  },
  {
    link: '/configuration/schedule-group',
    title: 'Schedule Groups',
    icon: 'group',
    data: {
      permission: 'view',
      resource: 'configuration-schedule-group'
    }
  },
  {
    link: '/configuration/sms-patient-monitoring',
    title: 'Message History',
    icon: 'group',
    data: {
      permission: 'view',
      resource: 'configuration-sms-patient-monitoring'
    }
  },
  {
    title: 'Admin',
    data: {
      permission: 'view',
      resource: 'configuration-admin'
    },
    icon: 'people-outline',
    children: [
      {
        title: 'Data Diagnostic',
        data: {
          permission: 'view',
          resource: 'configuration-data-diagnostic'
        },
        icon: 'trending-up-outline',
        children: [
          {
            link: '/configuration/dynamic-content',
            title: 'Dynamic Content',
            icon: 'keypad-outline',
            data: {
              permission: 'view',
              resource: 'configuration-dynamic-content',
            },
          },
        ]
      },
      {
        title: 'Configuration',
        data: {
          permission: 'view',
          resource: 'configuration-configurations'
        },
        icon: 'menu-2-outline',
        children: [
          {
            link: '/configuration/buyer-type',
            title: 'Buyer Type',
            icon: 'browser-outline',
            data: {
              permission: 'view',
              resource: 'configuration-buyer-type',
            },
          },
          {
            link: '/configuration/contact-type',
            title: 'Contact Type',
            icon: 'phone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-contact-type',
            },
          },
          {
            link: '/configuration/data-field',
            title: 'Data Fields',
            icon: 'file-text-outline',
            data: {
              permission: 'view',
              resource: 'configuration-data-field'
            },
          },
          {
            link: '/configuration/email-template',
            title: 'Email Template',
            icon: 'color-palette-outline',
            data: {
              permission: 'view',
              resource: 'configuration-email-template',
            },
          },
          {
            link: '/configuration/data-state',
            title: 'Legacy Data State',
            icon: 'browser',
            data: {
              permission: 'view',
              resource: 'configuration-data-state'
            },
          },
          {
            link: '/configuration/map-view-category',
            title: 'Map Category',
            icon: 'map-outline',
            data: {
              permission: 'view',
              resource: 'configuration-map-view-category'
            },
          },
          {
            link: '/configuration/priority',
            title: 'Priority',
            icon: 'bulb-outline',
            data: {
              permission: 'view',
              resource: 'configuration-priority'
            },
          },
          {
            link: '/configuration/resolution-code',
            title: 'Resolution Code',
            icon: 'pantone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-resolution-code'
            },
          },
          {
            link: '/configuration/task-status',
            title: 'Task Status',
            icon: 'speaker-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-status'
            },
          },
          {
            link: '/configuration/task-type',
            title: 'Task Type',
            icon: 'smartphone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-task-type'
            },
          },
          {
            link: '/configuration/message-provider',
            title: 'Message Provider',
            icon: 'phone-outline',
            data: {
              permission: 'view',
              resource: 'configuration-message-provider'
            },
          },
        ]
      },
      {
        title: 'User and Role',
        data: {
          permission: 'view',
          resource: 'configuration-user-and-role'
        },
        icon: 'person-add-outline',
        children: [
          {
            title: 'User',
            icon: 'person-outline',
            link: '/configuration/user',
            data: {
              permission: 'view',
              resource: 'configuration-user'
            },
          },
          {
            title: 'Role',
            icon: 'options-2-outline',
            link: '/configuration/role',
            data: {
              permission: 'view',
              resource: 'configuration-role'
            },
          },
        ]
      },
      {
        title: 'Automation',
        data: {
          permission: 'view',
          resource: 'configuration-automation'
        },
        icon: 'layers-outline',
        children: [
          {
            link: '/configuration/api-job',
            title: 'Api Jobs',
            icon: 'lock-outline',
            data: {
              permission: 'view',
              resource: 'configuration-api-job'
            },
          },
          {
            link: '/configuration/background-job',
            title: 'Background Jobs',
            icon: 'shield-outline',
            data: {
              permission: 'view',
              resource: 'configuration-background-job'
            },
          },
          {
            title: 'Data State',
            icon: 'layout-outline',
            link: '/configuration/datastate-management',
            data: {
              permission: 'view',
              resource: 'configuration-datastate-management'
            },
          },
          {
            title: 'Task Plan',
            icon: 'file-text-outline',
            link: '/configuration/task-plan',
            data: {
              permission: 'view',
              resource: 'configuration-task-plan'
            },
          },
          {
            link: '/configuration/taskscript-management',
            title: 'Task Script',
            icon: 'archive-outline',
            data: {
              permission: 'view',
              resource: 'configuration-taskscript-management'
            },
          },
        ]
      },
      {
        title: 'General Settings',
        data: {
          permission: 'view',
          resource: 'configuration-general-setting'
        },
        icon: 'settings-2-outline',
        children: [
          {
            title: 'Changelog',
            icon: 'star-outline',
            link: '/configuration/changelog',
            data: {
              permission: 'view',
              resource: 'configuration-changelog'
            },
          },
          {
            link: '/configuration/setting',
            title: 'Setting',
            icon: 'settings-2-outline',
            data: {
              permission: 'view',
              resource: 'configuration-setting'
            },
          },
        ]
      }
    ]
  }
];



// remove on header nav:
/*
{
    link: '/configuration/producer',
    title: 'Producer',
    icon: 'map-outline',
    data: {
      permission: 'view',
      resource: 'configuration-producer'
    }
  },
*/
