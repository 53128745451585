import { SettingService } from 'src/app/shared/services/setting.service';
import { ProfileAdditionDetail, ProfileAdditionDetailForOpportunity, ProfileContact } from './../../profile-management/profile-detail.model';
import { CurrencyFormatPipe } from './../../../../shared/pipes/currency-format.pipe';
import { NumberFormatPipe } from './../../../../shared/pipes/number-format.pipe';
import { SaleAccountService } from './../../sale-account-management/sale-account.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { CallService } from 'src/app/shared/services/call.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { Contact, KeyPairsValueSelectDropDown, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { LinkscopeEmailComponent } from '../../profile-management/profile-detail/action-block/linkscope-email/linkscope-email.component';
import { AutomateDatastateService } from '../../profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { ProfileService } from '../../profile-management/profile.service';
import { AddEditTaskComponent } from '../../task-management/add-edit-task/add-edit-task.component';
import { TaskBoardService } from '../../task-management/task-board/task-board.service';
import { UserService } from '../../user-management/user.service';
import { OpportunityManagementService } from '../opportunity-management.service';
import { SaleOpportunity } from '../opportunity.model';
import dateFormat, { masks } from "dateformat";
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { EntityColorEnums } from 'src/app/shared/enums/entity-color.enums';
import { NoteTabComponent } from 'src/app/shared/components/note-tab/note-tab.component';
import { Note, NoteDetails } from 'src/app/shared/components/note-management/noteManagement.model';
import { ContactComponent } from 'src/app/shared/components/stand-alone-component/contact/contact.component';
import { TableVariable } from 'src/app/shared/contances/data-table-contance';
import { ContactRelationshipGridComponent } from 'src/app/shared/components/contact-relationship-grid/contact-relationship-grid.component';
import { AddExistingContactComponent } from '../../profile-management/profile-detail/contact-tab/add-existing-contact/add-existing-contact.component';
import { ActivatedRoute, Router } from '@angular/router';
import { permissionGridNoteOpportunity, permissionSaleOpportunityFile } from 'src/app/shared/contances/permission';
import { OverlayNoteDetailsComponent } from 'src/app/shared/components/note-management/overlay-note-details/overlay-note-details.component';
import { NoteManagementService } from 'src/app/shared/components/note-management/note-management.service';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { UserModel } from '../../user-management/user-model';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { ProfileAdditionDetailService } from 'src/app/shared/services/profile-addition-detail.service';
import { HotToastService } from '@ngneat/hot-toast';
import { ProfileFileTabComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/profile-file-tab.component';
import { UserUploadManagementService } from '../../user-upload-management/user-upload-management.service';
import { UserUpload } from '../../user-upload-management/user-upload.model';
import { PopOverSelectContactsComponent } from 'src/app/shared/components/stand-alone-component/pop-over-select-contacts/pop-over-select-contacts.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TaskGridByProfileIdComponent } from '../../sale-lead-management/sale-lead-details/task-grid-by-profile-id/task-grid-by-profile-id.component';
import { ContactPage } from 'src/app/shared/models/paging/page';
import { ReviewContactCampaignComponent } from '../../email-campaign-management/email-campaign-detail/create-new-campaign/review-contact-campaign/review-contact-campaign.component';
import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { TabTagIndexModel } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
import { OpportunityContactTabComponent } from './opportunity-contact-tab/opportunity-contact-tab.component';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrls: ['./opportunity-details.component.scss']
})
export class OpportunityDetailsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() id: string = null;
  @Input() openByDialog = true;
  @Input() optionalTaskId: string;
  @Input() creatableTask = true;

  @Output() saleOpportunityEmit: EventEmitter<SaleOpportunity> = new EventEmitter<SaleOpportunity>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() onAdvancedImport: EventEmitter<any> = new EventEmitter();
  @Output() refreshTask: EventEmitter<void> = new EventEmitter();

  @ViewChild(MatMenuTrigger, { static: false }) menuStage: MatMenuTrigger;
  noteTab: NoteTabComponent;
  @ViewChild('gridNoteTab', { static: false }) set setGridNoteTab(content: NoteTabComponent) { if (content) this.noteTab = content };
  contactTab: OpportunityContactTabComponent;
  @ViewChild('contactGrid', { static: false }) set contactAccount(content: OpportunityContactTabComponent) { if (content) this.contactTab = content };
  fileTab: ProfileFileTabComponent;
  @ViewChild('profileFileTab', { static: false }) set setProfileFileTab(content: ProfileFileTabComponent) { if (content) this.fileTab = content }
  nbMailPopover: NbPopoverDirective;
  @ViewChild(NbPopoverDirective, { static: false }) set setSendMailBtn(content: NbPopoverDirective) { if (content) this.nbMailPopover = content }
  makeCallPopover: NbPopoverDirective;
  @ViewChild('makeCallPopoverBtn', { static: false }) set setMakeCallBtn(content: NbPopoverDirective) { if (content) this.makeCallPopover = content }

  taskGrid: TaskGridByProfileIdComponent;
  @ViewChild('taskGrid', { static: false }) set setTaskGrid(content: TaskGridByProfileIdComponent) { if (content) this.taskGrid = content };

  @ViewChild('contactSelectPop', { static: true }) contactSelectPop: TemplateRef<any>;

  environment = environment;
  handleAutomate: boolean = false;
  isCopy = false;
  opportunityModel: ProfileDetailModel;
  user: any = null;
  isLoading: boolean = false;
  isRefreshing: boolean = false;
  isLoadingEdit: boolean = false;
  isCalling: boolean = false;
  isCallLoading: boolean = false;
  selectTab: TabOpportunity = TabOpportunity.Contact;
  lostTypeId: number = 0;
  wonTypeId: number = 0;
  public get TypeEditModelOpportunity(): typeof TypeEditModelOpportunity { return TypeEditModelOpportunity; }
  formatValueQueryType = { key: 'automateDataStateId', value: 'dataStateName', color: 'colorCode', order: 'order', groupPlaceholder: 'groupPlaceholder' };
  listStage: KeyPairsValueSelectDropDown[] = [];
  currentStage: KeyPairsValueSelectDropDown = null;
  opportunityEnums = EntityColorEnums;
  validProperties = {
    opportunityName: { valid: [RxwebValidators.required()], message: `${this.environment.titleOpportunity} name is required` },
    probability: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "Probability is not valid" },
    amount: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "Estimate is not valid" },
    oneTimeProduct: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "1x Product is not valid" },
    oneTimeService: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "1x Service is not valid" },
    arr: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "ARR is not valid" },
    termOfContract: { valid: [RxwebValidators.numeric({ acceptValue: NumericValueType.Both, allowDecimal: true })], message: "Term Of Contract is not valid" },
  }

  private routeSub: Subscription;
  currentUrl: string = '';
  fullUrl = null;
  apiGetSearchRelationship: (data) => void = null;
  contactDialogRef: any;
  existingContactDialogRef: MatDialogRef<any, any>;
  private destroy$: Subject<void> = new Subject<void>();
  isHasPrimaryContact: boolean = false;
  resource = permissionGridNoteOpportunity;
  overlayNoteDetails: OverlayNoteDetailsComponent;
  prefixCurrency: string;
  lastNote: NoteDetails;
  ownerModel: UserModel;
  isCalculate: boolean = false;
  dynamicContentTypeAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentCompanyAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  dynamicContentLeadSourceAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  mathEquation: string = '';
  uploadedFile: any;
  uploading: boolean = false;
  fileResource = permissionSaleOpportunityFile;
  apiGetSearchOwner: (data) => void = (data) => this.userService.searchUser(data);
  isTabMode = Helper.checkTabMode();
  isShowButton: boolean = false;
  contacts: Contact[] = null;
  resetContacts: () => void = () => this.contacts = null;

  // vunh start 4/12/2022
  listChange = []; //this list is for the new logic placeholder value
  // vunh end 4/12/2022
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<OpportunityDetailsComponent>,
    private opportunityService: OpportunityManagementService,
    private profileAdditionService: ProfileAdditionDetailService,
    private taskService: TaskBoardService,
    private clipboard: Clipboard,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private userService: UserService,
    private callService: CallService,
    private authService: NbAuthService,
    private profileService: ProfileService,
    private settingService: SettingService,
    private automateDataStateService: AutomateDatastateService,
    private accountService: SaleAccountService,
    public numPipe: NumberFormatPipe,
    private router: Router,
    private route: ActivatedRoute,
    public noteService: NoteManagementService,
    public currencyFormat: CurrencyFormatPipe,
    public dynamicContentService: DynamicContentService,
    private userUploadService: UserUploadManagementService,
    private hotToast: HotToastService,
    private numberPipe: NumberFormatPipe,
    private tabMode: AdminTabModeService,
    private settingPoolService: SettingPoolService
  ) {
    this.id = this.data?.model?.profileId ?? this.id;
    this.isLoading = true;
    if (this.id) {
      this.refreshData();
    }

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    this.apiGetSearchRelationship = (data) => this.accountService.searchSaleAccount(data);

    this.automateDataStateService.getOpportunityDataState()
      .pipe(map((vals: ReturnResult<any[]>) => Helper.mapArrayToFormat(this.formatValueQueryType, vals.result)))
      .subscribe(res => {
        if (res && res.length > 0) {
          this.listStage = [...res.map(x => x as KeyPairsValueSelectDropDown)];
          if (!this.currentStage && this.opportunityModel)
            this.currentStage = this.listStage.find(x =>
              parseInt(x.key) == this.opportunityModel?.automateDataStateId ?? 0
            );

          this.checkStateOpportunity();
          // this logic already comment in the sale-lead (add placeholder value in the list)
          // var alreadyAddFlag = []
          // this.listStage.forEach(x => {
          //   var order = x.order;
          //   var sameOrderList = this.listStage.filter(x => x.order == order);

          //   if (alreadyAddFlag.filter(x => x == order)?.length <= 0) {
          //     if (sameOrderList?.length > 1) {
          //       var index = this.listStage.findIndex(x => x.order == order || x.groupPlaceholder == sameOrderList[0].groupPlaceholder);

          //       if (this.listStage[index]) {
          //         alreadyAddFlag.push(this.listStage[index]?.order);
          //         if (order < this.currentStage?.order) {
          //           index += 1;
          //         }
          //         this.listStage.splice(index, 0, { value: this.listStage[index]?.groupPlaceholder, order: this.listStage[index]?.order } as KeyPairsValueSelectDropDown)
          //       }
          //     }
          //   }
          // })
          // //the list already had the placeholder logic
          // this.listChange = [...this.listStage];
        }
      });
    this.dynamicContentTypeAPI = this.dynamicContentService.getDynamicContentByType('opportunity-type');
    this.dynamicContentLeadSourceAPI = this.dynamicContentService.getDynamicContentByType('opportunity-leadsource');
    this.dynamicContentCompanyAPI = this.dynamicContentService.getDynamicContentByType('general_company');
    if (this.router.url.includes('/configuration/opportunity') ||
      this.router.url.includes('/configuration/email-history') ||
      this.router.url.includes('/configuration/email-inbox') ||
      this.router.url.includes('/configuration/history-data') ||
      this.router.url.includes('/configuration/email-campaign') ||
      this.router.url.includes('/configuration/dashboard-contact-map') ||
      this.router.url.includes('/configuration/sale-convert-history') ||
      this.router.url.includes('/configuration/import-contact') ||
      this.router.url.includes('/configuration/activity-log')
    ) {
      this.isShowButton = true;
    }
  }

  ngOnInit(): void {
    if (this.openByDialog && this.dialModalRef?.componentInstance) {
      if (this.isTabMode) this.dialModalRef.afterOpened().subscribe(() => {
        var overlayBackdrops = window.document
          .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
        for (var i = 0; i < overlayBackdrops.length; i++)
          overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
      })

      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
      if (this.data.advancedImport) {
        this.dialModalRef.updateSize(this.openByDialog ? '1450px' : '1600px', Helper.heightDialog());
      }
      else {
        this.dialModalRef.updateSize(this.openByDialog ? '1200px' : '1600px', Helper.heightDialog());
      }
    }
    // this.taskService.getAllTaskStatus();
    // this.taskService.getAllPriority();
    // this.taskService.getAllTaskType();
    this.formatCurrencyPrefix();
    this.getLastNote();
  }

  ngAfterViewInit(): void {
    this.overlayNoteDetails = this.noteService.overlayNoteDetailsComponent;
    if (this.router.url.includes('/configuration/opportunity')) {
      // get cache tab index in local storage:
      let tag = this.getCacheTab();
      let patternRegex = /#([\d]+)/;
      if (!Helper.isNullOrEmpty(tag)) {
        this.selectTab = parseInt(tag.replace('#', ''));
        Helper.handleTabChangedUrl(this.selectTab, '/configuration/opportunity');
      } else
        // if end with #tabIndex then move to that tab
        if (patternRegex.test(this.router.url)) {
          let matchTabIndex = this.router.url.match(patternRegex).length > 0 ? Number.parseInt(this.router.url.match(patternRegex)[0].replace('#', '')) : TabOpportunity.Contact;
          this.selectTab = matchTabIndex;
        }
    } else {
      this.selectTab = this.openByDialog ? TabOpportunity.Contact : TabOpportunity.ActivityLog;
    }

    this.opportunityService.getOpportunityById()
      .pipe(
        tap(resp => this.profileService._profileDetailData$.next(resp)),
        takeUntil(this.destroy$)
      ).subscribe({
        next: resp => { if (resp.result) this.setupOpportunity(resp.result); },
        complete: () => this.isLoading = false,
      });

    this.opportunityService.isRefreshOpportunityGrid$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isRefresh: boolean) => this.isRefreshing = isRefresh);

    this.overlayNoteDetails.onRefreshed().pipe(
      takeUntil(this.destroy$),
      debounceTime(2000)
    ).subscribe(resp => {
      this.overlayNoteDetails.completedLoading();
      if (resp && this.noteTab) this.noteTab.refreshData();
      this.getLastNote(resp);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeId = changes.id;
    if (changeId && changeId.currentValue != changeId.previousValue)
      this.refreshData();
  }

  ngOnDestroy(): void {
    if (this.opportunityService.opportunityDetail$)
      this.opportunityService.opportunityDetail$.next(new ReturnResult<any>());
    this.destroy$.next();
    this.destroy$.complete();
  }

  refreshData() {
    this.opportunityService.refreshOpportunityById(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => {
          if (resp.result) {
            this.setupOpportunity(resp.result);

            /*Get New Setting */
            // this.settingService.getSettingByKeyAndGroup("TOTAL_RE_MATCH", "SALE_CONFIG").subscribe(setting => {
            //   if (setting.result) {
            //     this.mathEquation = setting.result.value;
            //     this.calculateTotalValue(this.opportunityModel.profileAdditionDetail);
            //   }
            // });
            this.getIdLossReason();
          }
        },
        complete: () => {
          this.isLoading = false;
          this.getOwner();
        },
      });
  }

  parseMathStrToValue(str) {
    return Function(`'use strict'; return (${str})`)()
  }

  calculateTotalValue(model: ProfileAdditionDetail) {
    this.mathEquation = this.mathEquation.replace('{ARR}', (model?.arr ?? 0).toString())
      .replace('{OneTimeProduct}', (model?.oneTimeProduct ?? 0).toString())
      .replace('{OneTimeService}', (model?.oneTimeService ?? 0).toString())
      .replace('{TermOfContract}', (model?.termOfContract ?? 0).toString());
    if (this.opportunityModel.profileAdditionDetail)
      this.opportunityModel.profileAdditionDetail.totalValue = this.parseMathStrToValue(this.mathEquation);
  }

  setupOpportunity(data: ProfileDetailModel) {
    if (data) {
      this.opportunityModel = Helper.copyRemoveHeap(data);
      //this.contacts = this.opportunityModel.profileContacts.map((contact) => contact.contact);
      this.currentStage = this.listStage.find(x =>
        parseInt(x.key) == this.opportunityModel?.automateDataStateId
      ) ?? this.listStage.find(x => x != null);
      if (this.opportunityModel.saleOpportunity)
        this.saleOpportunityEmit.emit(this.opportunityModel.saleOpportunity);

      this.isHasPrimaryContact = this.opportunityModel.contact && !this.opportunityModel.contact.deleted ? true : false;
      this.checkStateOpportunity();
      //vunh start 30/12/2022
      // this.listStage.forEach(item => {
      //   //get the first index in the list
      //   var index = this.listChange.findIndex(x => x.order == item.order)
      //   //get the first index of the placeholder value
      //   var placeholderIndex = this.listChange.findIndex(x => x.order == item.order && !x.key)

      //   if (this.currentStage?.order) {
      //     if (item.order > this.currentStage.order) {
      //       if (placeholderIndex > index) {
      //         //swap placeholder value and the first item in the same order
      //         [this.listChange[index]] = this.listChange.splice(placeholderIndex, 1, this.listChange[index])
      //       }
      //     }
      //     else if (item.order < this.currentStage.order) {
      //       if (placeholderIndex == index) {
      //         //swap placeholder value and the value behind it
      //         [this.listChange[index]] = this.listChange.splice(index + 1, 1, this.listChange[index])
      //       }
      //     }
      //   }
      // })
      // //re-render the progress bar
      // this.listStage = [...this.listChange]
      // //vunh end 3/1/2023
    }
  }

  checkStateOpportunity() {
    if (this.opportunityModel?.automateDataStateId && this.lostTypeId && this.wonTypeId)
      switch (this.opportunityModel?.automateDataStateId) {
        case this.lostTypeId:
        case this.wonTypeId:
          this.opportunityModel['disabledProbability'] = true;
          this.opportunityModel['tooltipProbability'] = `Can not modify the field because of the ${this.currentStage?.value} state`;
          console.log(this.opportunityModel['tooltipProbability']);
          break;
        default:
          break;
      }
  }

  editOpportunity(value: any, prop: string, typeModel: TypeEditModelOpportunity = TypeEditModelOpportunity.Opportunity) {
    if (!prop) {
      this.toast.danger('Failed', "Error");
      return;
    }

    if (prop == 'displayName') this.refreshTask.emit();
    if ((prop == 'probability' || prop == 'amount' || prop == 'oneTimeService' || prop == 'oneTimeProduct' || prop == 'aRR' || prop == 'termOfContract')) {
      value = this.numPipe.transform(value);
    }


    let saleOpportunityProfileModel: ProfileDetailModel = Object.assign({}, this.opportunityModel, { [prop]: value });
    let saleOpportunityModel: SaleOpportunity = Object.assign({}, this.opportunityModel.saleOpportunity, { [prop]: value });
    let profileAdditionDetailModel: ProfileAdditionDetailForOpportunity = Object.assign({}, this.opportunityModel.profileAdditionDetail, { [prop]: value ?? 0 });

    this.isLoadingEdit = true;

    if (prop == 'oneTimeService' || prop == 'oneTimeProduct' || prop == 'aRR' || prop == 'termOfContract') {
      if (this.opportunityModel && this.opportunityModel.profileAdditionDetail)
        this.opportunityModel.profileAdditionDetail.totalValue = 0;
      this.isCalculate = true;
      profileAdditionDetailModel.profileId = this.opportunityModel.profileId;
      this.profileAdditionService.saveSingleProfileAdditionByPropName(prop, profileAdditionDetailModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: resp => {
            if (resp.result) {
              this.toast.success(`Edit ${this.environment.titleOpportunity} success!`, "Success");
              this.refreshData();
            }
          },
          error: err => this.isLoadingEdit = false,
          complete: () => {
            this.isLoadingEdit = false;
          }
        });

      this.isCalculate = false;
      return;
    }

    this.opportunityService.editOpportunity({
      id: typeModel == TypeEditModelOpportunity.OpportunityProfile
        ? this.opportunityModel.profileId
        : this.opportunityModel.saleOpportunity.saleOpportunityId.toString(),
      saleOpportunityProfileModel: typeModel == TypeEditModelOpportunity.OpportunityProfile
        ? saleOpportunityProfileModel : null,
      saleOpportunityModel: typeModel == TypeEditModelOpportunity.Opportunity
        ? saleOpportunityModel : null,
    }).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => {
          if (resp.result) {
            if (prop != 'displayName')
              this.toast.success(`Edit ${this.environment.titleOpportunity} success!`, "Success");

            if (prop == "ownerId") this.ownerModel = null;
            this.refreshData();
          }
        },
        error: err => this.isLoadingEdit = false,
        complete: () => this.isLoadingEdit = false
      });
    this.handleAutomate = false;
  }

  closeDialog() {
    // remove tab cache:
    this.tabMode.removeCurrentTabTag();

    if (this.dialModalRef?.componentInstance)
      this.dialModalRef.close(this.isRefreshing);
    this.onClose.emit(this.isRefreshing);

    if (Helper.checkUrlDetailObj('opportunity')) {
      const urlBack = Helper.popBackURL() || '/configuration/opportunity';
      this.router.navigate([urlBack]);
    }
  }

  copyToClipboard() {
    const url = window.location.href.split('configuration')[0] + `configuration/opportunity?opportunityId=${this.id}`;
    this.clipboard.copy(url);
    this.toast.info(`Copied this ${this.environment.titleOpportunity} url to clipboard`, 'Success');
  }

  sendMailClick(profile: ProfileDetailModel = null) {
    const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        profileModel: profile ?? this.opportunityModel,
        entity: 'profile',
        optionTaskId: this.optionalTaskId,
        lastSentEmailEntity: this.environment.titleOpportunity
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response != null) {
        // return when closed
      }
    });
  }

  async onClickCall(alternativeContact: Contact = null) {
    this.isCallLoading = true;
    await this.callService.makeACall(
      alternativeContact ?? this.opportunityModel.contact,
      this.opportunityModel.profileContacts,
      this.user.nameid,
      this.opportunityModel.profileId,
      this.creatableTask,
      this.optionalTaskId ? parseInt(this.optionalTaskId) : null,
      this.isCallLoading,
      this.toast,
      this.isCalling,
      'profile'
    );
    setTimeout(() => {
      this.isCallLoading = false;
    }, 3000);
  }

  createTask() {
    const dialogRef = this.dialog.open(AddEditTaskComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Add,
        profile: this.opportunityModel,
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.toast.success('Create task success!', 'Success');
        if (this.taskGrid) this.taskGrid.refreshData();

      }
    });
  }
  apiToSearchAccount(input: string) {
    return this.accountService.searchSaleAccount(input);
  }
  saveAccount(data) {
    if (this.opportunityModel.saleOpportunity) {
      this.opportunityModel.saleOpportunity.referenceType = "SALEACCOUNT";
    }
    this.editOpportunity(data.profileId, 'referenceId', TypeEditModelOpportunity.Opportunity)
  }

  copyGUIDToClipboard() {
    this.clipboard.copy(this.id);
    this.toast.info(`Copied this ${this.environment.titleOpportunity} ID to clipboard`, 'Success');
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }

  updatePrimaryContact(contactLst: Contact[]) {
    if (this.opportunityModel) {
      let primaryContact = contactLst.find(x => x.primaryContact == true);
      this.contacts = contactLst ? contactLst.map((x: any) => x.contact) : this.contacts;
      if (primaryContact) {
        this.opportunityModel.contact = primaryContact;
        this.opportunityModel.contactId = primaryContact.contactId;
        this.opportunityModel.accountContactId = primaryContact.contactId;
        this.isHasPrimaryContact = this.opportunityModel.contact && !this.opportunityModel.contact.deleted ? true : false;
      } else {
        this.opportunityModel.contact = null;
        this.opportunityModel.contactId = null;
        this.opportunityModel.accountContactId = 0;
        this.isHasPrimaryContact = false;
      }
    }
  }
  editStageOpportunity(data) {
    if (!this.handleAutomate) {
      this.editOpportunity(data.key, 'automateDataStateId', TypeEditModelOpportunity.OpportunityProfile)
    }
  }
  handleAutomateDataState(data: boolean) {
    this.handleAutomate = data;
  }
  tabIndexChanged(tabIndex: number) {
    if (this.router.url.includes('configuration/opportunity')) {
      Helper.handleTabChangedUrl(tabIndex, '/configuration/opportunity');
      this.tabMode.setTagIndexToCurrentTab(tabIndex, this.id);
    }
  }
  formatCurrencyPrefix() {
    this.prefixCurrency = `<div class="currency-color">${localStorage.getItem("currency") ?? ""}</div>`
  }

  getLastNote(resp?: number, isDelete: boolean = false) {
    if (this.lastNote && this.lastNote.id == resp && isDelete)
      this.lastNote = null;

    if ((this.lastNote && this.lastNote.id <= resp && this.lastNote.referenceId == this.id) || !this.lastNote)
      this.noteService.lastNoteByReferenceId(this.id, ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY])
        .subscribe({
          next: resp => {
            if (resp.result)
              this.lastNote = Object.assign(resp.result, {
                innerTextNote: Helper.removeStyleHtml(resp.result.note),
                ownerNote: (`${resp.result.user?.firstName || ''} ${resp.result.user?.lastName || ''}`).trim() || resp.result.user?.userName || 'Unknown',
                avatarNote: resp.result.user?.pictureURL?.replace(/\\/g, '/'),
                //referenceName: this.opportunityModel.displayName
              })
          },
          //complete: () => this.overlayNoteDetails.completedLoading()
        });
  }

  getOwner() {
    if (!this.opportunityModel && Helper.isNullOrEmpty(this.opportunityModel.ownerId) || Helper.isEmptyOrSpaces(this.opportunityModel.ownerId))
      this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel);

    if (!this.ownerModel && this.opportunityModel.ownerId)
      this.userService.getUserById(this.opportunityModel.ownerId).pipe(takeUntil(this.destroy$))
        .subscribe(
          resp => {
            if (resp.result) {
              this.ownerModel = resp.result;
              let fullName = `${this.ownerModel.firstName} ${this.ownerModel.lastName}`;
              this.ownerModel.fullName = fullName.trim() ? fullName : "Unknown";
              this.ownerModel.pictureURL = Helper.userURL(this.ownerModel.pictureURL);
            };
          },
          err => this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel));
  }

  getIdLossReason() {
    // 2022-02-02 ducqm start add
    // this.settingService.getSettingByGroupName("SALE_CONFIG").subscribe(e => {
    //   if (e.result) {
    //     var lostType = e.result.find(x => x.key == "LOST_TYPE_ID")?.value || '';
    //     this.lostTypeId = lostType ? +lostType : 0;

    //     var wonType = e.result.find(x => x.key == "WON_TYPE_ID")?.value || '';
    //     this.wonTypeId = wonType ? +wonType : 0;
    //     this.checkStateOpportunity();
    //   }
    // })
    this.settingPoolService.getSettingByGroupName("SALE_CONFIG").subscribe(e => {
      if (e.result) {
        var lostType = e.result.find(x => x.key == "LOST_TYPE_ID")?.value || '';
        this.lostTypeId = lostType ? +lostType : 0;

        var wonType = e.result.find(x => x.key == "WON_TYPE_ID")?.value || '';
        this.wonTypeId = wonType ? +wonType : 0;
        this.checkStateOpportunity();
      }
    })
    // 2022-02-02 ducqm end add
  }
  addSaleOpportunityFile(fileInputEvent: any) {
    this.uploading = true;
    this.uploadedFile = fileInputEvent.target.files[0];
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    let IsAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    if (IsAccept) {
      let submitModel = new UserUpload();
      submitModel.fileName = this.uploadedFile.name;
      submitModel.referenceType = this.opportunityModel.typeName;
      submitModel.referenceId = this.opportunityModel.profileId;
      submitModel.owners = this.user.nameid;
      this.userUploadService.uploadFile(this.uploadedFile, submitModel).pipe(this.hotToast.observe(
        {
          loading: 'Uploading file...',
          success: (s) => 'file successfully uploaded',
          error: (e) => 'An error occurred while uploading...'
        }
      )).subscribe({
        next: res => {
          if (res.result) {
            this.uploading = false;
            // this.toast.success('Upload file successfully', 'Success');
            this.fileTab?.refreshData(true);
          }
        },
        complete: () => {
        }
      });
    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pdf,html,zip,csv,txt', "Warning");
    }
  }
  getSelectedContactMail(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.opportunityModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.sendMailClick(newProfileModel);
    } else {
      // skip mode (select primary contact)
      this.sendMailClick(null);
    }
  }
  getSelectedContactCall(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.opportunityModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.onClickCall(newProfileModel.contact);
    } else {
      // skip mode (select primary contact)
      this.onClickCall(null);
    }
  }
  get checkNumberOfContact() {
    return this.opportunityModel?.profileContacts?.length ?? 0;
  }
  confirmSendMailClick() {
    let convertContact;
    if ((this.opportunityModel?.contact as any)?.contact != null) {
      convertContact = (this.opportunityModel?.contact as any).contact;
    } else {
      convertContact = this.opportunityModel.contact;
    }
    this.opportunityModel.contact = convertContact;
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to send email to ${Helper.getEmailFromContact(convertContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.sendMailClick();
      }
    });
  }
  confirmMakeACall() {
    let convertContact;
    if ((this.opportunityModel?.contact as any)?.contact != null) {
      convertContact = (this.opportunityModel?.contact as any).contact;
    } else {
      convertContact = this.opportunityModel.contact;
    }
    this.opportunityModel.contact = convertContact;
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to make a call to number ${Helper.getPhoneFromContact(convertContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) {
        this.onClickCall();
      }
    });
  }
  createContactCampaign() {
    if (!this.contactTab) {
      this.toast.warning('Please select to contact tab to start creating campaign');
      return;
    }
    const dialogRef1 = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to create campaign with ${this.numberPipe.transform((this.contactTab?.primasTable.selected && this.contactTab?.primasTable.selected.length != 0) ? this.contactTab?.primasTable.selected.length : this.contactTab?.primasTable.table.rowCount)} item(s)?`
      }
    });
    dialogRef1.afterClosed().subscribe(response => {
      if (response) {
        const campaignData: ContactPage = Object.assign({}, this.contactTab.primasTable.page, { relationshipId: this.opportunityModel.profileId, type: "Profile" });
        const ComponentOpen: any = ReviewContactCampaignComponent


        const dialogRef = this.dialog.open(ComponentOpen, {
          width: '1300px',
          disableClose: true,
          autoFocus: false,
          data: {
            contactPage: campaignData,
            isSelectAll: true,
            externalComponent: true,
          }
        });
        dialogRef.afterClosed().subscribe(e => {
          this.contactTab.primasTable.selected = [];
        });
      }
    });
  }
  getCacheTab() {
    let currentTabMode = this.tabMode.getCurrentActiveTab();
    let tag: string = null;
    if (currentTabMode) {
      let tabTagIndex: TabTagIndexModel = this.tabMode.getTabTagIndex(currentTabMode.tabId);
      if (tabTagIndex && tabTagIndex.uniqueId && typeof tabTagIndex.uniqueId == 'string' && tabTagIndex.uniqueId == this.id) {
        tag = tabTagIndex.tag;
      }
    }
    return tag;
  }

  async onClickAdvanceImport(action: string) {
    if (action == "Skip") {
      const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to skip 1 item(s)?`
        }, disableClose: true,
      })
      const result = await dialogConfirm.afterClosed().toPromise();
      if (result) {
        this.onAdvancedImport.emit(action);
        this.dialModalRef.close();
      }
    }
    else
      this.onAdvancedImport.emit(action);
  }

}

export enum TabOpportunity {
  Contact,
  Files,
  Note,
  Tasks,
  ActivityLog
}

export enum TypeEditModelOpportunity {
  Opportunity,
  OpportunityProfile
}

export enum ProfileType {
  SALEPROFILE,
  SALEACCOUNT,
  LEADS,
  OPPORTUNITY,

}
