import { NbMenuItem } from "@nebular/theme";

export class TabModeModel {
    constructor() {
        this.tabId = 0;
        this.isActive = false;
    }
    tabId: number;
    userId: string;
    tabName: string;
    tabUrl: string;
    extendData?: string | null;
    queryParams?: any | null;
    deleted?: boolean | null;
    tabOrders?: any | null;
    isActive: boolean = false;
    tabType: string | null;
    dateDeleted?: Date | string | null;
    dateModified?: Date | string | null;
    dateCreated?: Date | string | null;
}

export class TabTypeMode {
    tabType: string;
    tabTypeId: number;
}
export enum TabTypeModeEnums {
    Dropdown = 'Dropdown',
    Link = 'Link',
}

export class ExtendNbMenuItem extends NbMenuItem {
    allowOpenTab?: boolean = false;
    title: string;
    icon?: string;
    link?: string;
    children?: ExtendNbMenuItem[];
    data?: { permission?: string; resource?: string; }
}

export class TabSwapViewModel {
    newTab: TabModeModel;
    currentTab: TabModeModel;
}


// Principle:
/*
    - history is a stack: save last Url to redirect. When user want to go back: just pop the stack and redirect:
    history: [{
        name: '',
        url: '',
    }] 
*/
export class ExtendTabData {
    /**
     *
     */
    constructor() {
        this.history = [];
    }
    history: HistoryTabState[];
    viewId?: string | number | null;
    public peekHistory(): HistoryTabState {
        return this.history[this.history.length - 1];
    }
}
export class HistoryTabState {
    /**
     *
     */
    constructor(tabName: string, url: string, viewId?: string | number | null) {
        this.name = tabName;
        this.url = url;
        this.viewId = viewId;
    }
    name: string;
    url: string;
    viewId?: string | number | null;
}

export class TabTagIndexModel {
    tabId: number;
    tag: string;
    fulUrl: string;
    partialUrl: string;
    uniqueId: any;
}