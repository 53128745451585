import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { EmailEditorComponent } from 'angular-email-editor';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AutomateDatastateService } from 'src/app/modules/admin/profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { EmailTemplate } from 'src/app/modules/admin/setting-management/setting-campaign/email-template';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { BodyEmbedded } from 'src/app/shared/models/body-embedded.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-editor-email',
  templateUrl: './editor-email.component.html',
  styleUrls: ['./editor-email.component.scss']
})
export class EditorEmailComponent implements OnInit, OnDestroy {

  @ViewChild(EmailEditorComponent, { static: true }) emailEditor: EmailEditorComponent;

  @Input() emailDesign: EmailTemplate | Observable<ReturnResult<string>> = null;
  @Input() classicEditor: boolean = false;
  @Input() bodyEmbedded: BodyEmbedded = null;
  @Input() userModel: UserModel;
  @Input() isEmbedded: boolean = true;

  @Output() exportEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() isEditorReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  public subExportEmailHtml: ReplaySubject<any> = new ReplaySubject<any>(1);
  protected _onDestroy = new Subject<void>();
  isLoadingComponent = true;
  emailTemplate: EmailTemplate;
  userId: any;
  options: any = {
    features: {
      smartMergeTags: false
    }
  }
  constructor(
    private automateDataStateService: AutomateDatastateService,
    private authService: NbAuthService,
    private userService: UserService,
    private toast: NbToastrService
  ) {
    this.automateDataStateService.requestMergeTagEmail();
    if (this.userModel == null)
      this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
          if (token.isValid()) {
            const user = token.getPayload();
            this.userId = user['nameid'];
          };
        });
  }

  ngOnInit(): void {
    if (this.userId)
      this.userService.getUserById(this.userId).pipe(first()).subscribe(resp => {
        if (resp.result) {
          this.userModel = resp.result
          if (this.isEmbedded)
            if (this.bodyEmbedded) {
              this.bodyEmbedded['username'] = this.userModel.userName;
              this.bodyEmbedded['useremail'] = this.userModel.email;
            }
        };
      })
    else this.toast.danger("Error get current user!", "Error");
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // called when the editor is created
  editorLoaded(event?) {
    // load the design json here
    this.emailTemplate = new EmailTemplate();
    this.emailTemplate.HtmlTemplate = `<html><body><div>Edit email</div></body></html>`;

    if (this.emailDesign) {
      if (this.emailDesign instanceof Observable) {
        this.emailDesign.subscribe(resp => {
          if (resp.result) {
            this.emailTemplate = JSON.parse(resp.result) as EmailTemplate;
            this.loadDesignEditor();
          }
        })
      } else {
        this.emailTemplate = this.emailDesign;
        this.loadDesignEditor();
      }
    } else {
      if (!this.classicEditor)
        this.emailTemplate = null;
      this.loadDesignEditor()
    };
  }

  // called when the editor has finished loading
  editorReady(event?) { this.isEditorReady.next(true) }

  exportHtml() {
    this.emailEditor.editor.exportHtml((data) => {
      if (data) {
        if (this.bodyEmbedded) {
          var bodyHtml = data['html'];
          var currentTimestamp = Date.now();
          this.bodyEmbedded['timestamp'] = currentTimestamp.toString();

          const str = bodyHtml.substring(
            bodyHtml.indexOf(`<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;`),
            bodyHtml.indexOf(`;EMBEDDED_END</span>`) + 20
          ).slice();
          if (str.length > 0 && str.startsWith(`<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;`) && str.endsWith(`;EMBEDDED_END</span>`)) {
            bodyHtml = bodyHtml.replace(str, '');
          }

          bodyHtml += `<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;${btoa(JSON.stringify(this.bodyEmbedded))};EMBEDDED_END</span>`;
          data['html'] = bodyHtml;
        }

        this.subExportEmailHtml.next({ html: data['html'], design: JSON.stringify(data['design']) ?? this.emailTemplate.DesignTemplate });
        this.exportEmail.next({ html: data['html'], design: JSON.stringify(data['design']) ?? this.emailTemplate.DesignTemplate });
        this.subExportEmailHtml.complete();
      }
    });
  }

  getEmailHtml() {
    return this.subExportEmailHtml.asObservable();
  }

  loadDesignEditor() {
    if (this.emailTemplate) {
      if (this.classicEditor) this.emailEditor.editor.loadDesign({ html: this.emailTemplate.HtmlTemplate, classic: true });
      else this.emailEditor.editor.loadDesign(JSON.parse(this.emailTemplate.DesignTemplate));
    }
    this.automateDataStateService.getMergeTagEmail().pipe(takeUntil(this._onDestroy))
      .subscribe(resp => {
        if (resp) {
          var mergeTag = {
            mergeTags: {}
          };

          for (let key in resp) {
            this.mapDataField(mergeTag, key, resp[key])
          }

          console.log(mergeTag);
          this.emailEditor.editor.setMergeTags(mergeTag["mergeTags"]);
        }
      })
    setTimeout(() => this.isLoadingComponent = false, 1000);
  }

  mapDataField(mergeTag: any, key: string, value: string) {
    if (mergeTag && key && value) {
      let selectParent = mergeTag["mergeTags"];
      const hasParent = key.includes(".");
      var splitField = [];
      if (hasParent) {
        splitField = key.split(".");
        splitField = splitField.filter((element, index) => index < splitField.length - 1);
        this.recursiveFindParent(mergeTag["mergeTags"], splitField.join("."));
        for (var i = 0; i < splitField.length; i++) {
          selectParent = selectParent[splitField[i]]["mergeTags"];
        }
      }

      //Add field to merge tag
      var field = key;
      if (hasParent)
        field = key.split(".").pop();

      var checkField = selectParent[field];
      if (checkField == null) {
        var objField = {
          name: Helper.displayNameProp(key),
          value: value
        }

        selectParent[`${key}`] = objField;
      }
    }
  }

  recursiveFindParent(mergeTag: any, key: string) {
    if (key) {
      var parentKey = key;
      var splitStr = key.split(".");
      //Find group parent.
      if (splitStr && splitStr.length > 1) {
        parentKey = splitStr[0];
      }

      const parentField = mergeTag[parentKey];
      if (parentField == null) {
        var createParent = {
          name: Helper.displayNameProp(parentKey),
          mergeTags: {}
        }
        mergeTag[`${parentKey}`] = createParent;
      }

      if (splitStr && splitStr.length > 1) {
        var splitParent = splitStr.filter((element, index) => index != 0);
        this.recursiveFindParent(mergeTag[parentKey].mergeTags, splitParent.join("."))
      }
    }
  }
}
