export const OnBoardingFormEnums = {
    ACT: 'ACT',
    CONTACT: 'CONTACT',
    ARTIST_DETAILS: 'ARTIST_DETAILS',
    CONTACTBLOCK: 'CONTACTBLOCK',
    ACTBLOCK: 'ACTBLOCK',
    LOCATION: 'LOCATION',
};
export const OnBoardingFormAction = {
    MODIFIED: 'MODIFIED',
    ADDED: 'ADDED',
    DELETED: 'DELETED',
};
