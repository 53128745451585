<mat-form-field color="primary">
    <mat-select [formControl]="bankMultiCtrl" placeholder="{{columnName}}" [multiple]="true" #multiSelect
      (openedChange)="changeValue($event)">
      <mat-option>
        <ngx-mat-select-search noEntriesFoundLabel="No matching item found" placeholderLabel=""
          [showToggleAllCheckbox]="checkAll" [toggleAllCheckboxIndeterminate]="isIndeterminate"
          [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleSelectAll($event)"
          [formControl]="bankMultiFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let item of filteredBanksMulti | async" [value]="item" style="margin-top: 8px">
        {{item[filter.displayText]}}
      </mat-option>
    </mat-select>
  </mat-form-field>