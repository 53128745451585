import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { NAV_MENU_ITEMS } from 'src/app/modules/admin/menu-items';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { BrowserTabHandlerService } from 'src/app/shared/services/browser-tab-handler.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { environment } from 'src/environments/environment';
import { ExtendNbMenuItem, ExtendTabData, HistoryTabState, TabModeModel, TabSwapViewModel, TabTagIndexModel } from './tab-mode.model';

@Injectable({
  providedIn: 'root'
})
export class AdminTabModeService {
  private baseUrl = environment.apiUserTab;
  public userId: string;
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public tabDataSubject: BehaviorSubject<any> = new BehaviorSubject<TabModeModel[]>([]);
  public tabData: TabModeModel[] = [];
  private historyLimit: number = 5;
  public activeTabObservable: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public unAuthorizeNav = NAV_MENU_ITEMS.slice();
  public unRoleMenu: ExtendNbMenuItem[] = [];
  constructor(
    private http: HttpClient,
    private toast: NbToastrService,
    private browserTab: BrowserTabHandlerService,
    private innerRouter: Router) { }
  toggleLoading(value: boolean): void {
    this.loading.next(value);
  }
  getToggleLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }
  createNewTab(url: string, tabName: string, tabType: string, isActive: boolean = false) {
    tabName = this.formatTabName(tabName);
    let newTab: TabModeModel = {
      tabId: 0,
      tabName: tabName,
      tabUrl: url,
      tabType: tabType,
      userId: this.userId,
      tabOrders: this.tabData.length > 0 ? this.tabData[this.tabData.length - 1].tabOrders + 1 : 1,
      isActive: isActive,
    }

    this.tabData.push(newTab);
    this.tabDataSubject.next(this.tabData);
    let newTabIndex = this.tabData.indexOf(newTab);
    if (isActive) {
      this.activeTabObservable.next(newTabIndex);
    }
    // callAPI to update the new tab ID:
    this.SaveSingleUserTabFromBackend(newTab).subscribe({
      next: (result) => {
        if (result.result) {
          this.tabData[newTabIndex] = result.result;
          this.tabDataSubject.next(this.tabData);
          if (isActive) {
            this.tabDataSubject.next(this.tabData);
            this.activeTabObservable.next(newTabIndex);
          }
        }
      }
    });

  }
  pushTabData(tab: TabModeModel) {
    this.tabData.push(tab);
    this.tabDataSubject.next(this.tabData);
  }
  setTabData(newTabList: TabModeModel[]) {
    this.tabData = newTabList;
    this.tabDataSubject.next(this.tabData);
  }

  getTabData(): Observable<TabModeModel[]> {
    return this.tabDataSubject.asObservable();
  }

  createNewExistTab(newTab: TabModeModel) {
    this.tabData.push(newTab);
    this.tabDataSubject.next(this.tabData);
    let newTabIndex = this.tabData.indexOf(newTab);
    // callAPI to update the new tab ID:
    this.SaveSingleUserTabFromBackend(newTab).subscribe({
      next: (result) => {
        if (result.result) {
          this.tabData[newTabIndex] = result.result;
          // check active tab
          this.tabDataSubject.next(this.tabData);
          if (this.tabData[newTabIndex].isActive) {
            this.activeTabObservable.next(newTabIndex);
          }
        }
      }
    });
  }
  // function auto add history for current tab:\
  // USAGE: input new tab url and name, old tab will be stored at history:
  addHistoryForTab(tabName: string, historyUrl: string) {
    let currentActiveTab = this.tabData.find(x => x.isActive == true);
    let currentTabIndex = this.tabData.indexOf(currentActiveTab);
    if (currentActiveTab) {
      let extendData = this.getExtendData(this.tabData[currentTabIndex]);
      let history: HistoryTabState[] = [];
      if (extendData) {
        // existed extend data:
        let history = extendData.history ?? [];
        if (history.length == 0) {
          history = [];
        } else if (history.length > this.historyLimit) {
          // if stack greater than limit: remove the oldest:
          history.shift();
        }
      } else {
        // new extend data:
        extendData = new ExtendTabData();
      }
      // check if current url is same as the peek() history, if it true: then remove the top of stack. otherwise push like normal:
      if (history.length == 0) {
        history.push(new HistoryTabState(currentActiveTab.tabName, currentActiveTab.tabUrl, extendData.viewId));
      } else {
        let lastNav = history[history.length - 1];
        if (lastNav.name === tabName && lastNav.url === historyUrl) history.pop();
        else history.push(new HistoryTabState(currentActiveTab.tabName, currentActiveTab.tabUrl));
      }

      extendData.history = history;
      // replace current name and url for new nav:
      this.tabData[currentTabIndex].tabName = tabName;
      this.tabData[currentTabIndex].tabUrl = historyUrl;
      // replace old tab with new tab data:
      this.tabData[currentTabIndex].extendData = JSON.stringify(extendData);
      this.SaveSingleUserTabFromBackend(this.tabData[currentTabIndex]).pipe().subscribe();
      this.tabDataSubject.next(this.tabData);
    }
  }
  // pop history in extendData array
  popCurrentNavigationHistory() {
    let currentActiveTab = this.tabData.find(x => x.isActive == true);
    let currentTabIndex = this.tabData.indexOf(currentActiveTab);
    if (currentActiveTab) {
      let extendData = this.getExtendData(this.tabData[currentTabIndex]);
      if (extendData && extendData.history && extendData.history.length > 0) {
        let redirectedTab = extendData.history.pop();
        // replace current name and url for new nav:
        this.tabData[currentTabIndex].tabName = redirectedTab.name;
        this.tabData[currentTabIndex].tabUrl = redirectedTab.url;
        if (redirectedTab.viewId) {
          extendData.viewId = redirectedTab.viewId;
        }
        this.tabData[currentTabIndex].extendData = JSON.stringify(extendData);
        this.SaveSingleUserTabFromBackend(this.tabData[currentTabIndex]).pipe().subscribe();
        this.tabDataSubject.next(this.tabData);
        return true;
      } else {
        // this.toast.warning('No history to back.', 'Warning');
        return false;
      }
    }
  }
  // return null if no extendData else tabData will be returned
  public getExtendData(tab: TabModeModel) {
    if (tab.extendData) {
      // already has:
      let parsedTabData: ExtendTabData;
      try {
        parsedTabData = JSON.parse(tab.extendData);
      } catch (error) {
        console.error(`An error occurred while parsing current tab Data: ${tab.tabName}`);
      }
      return parsedTabData;
    } else {
      // not yet:
      return null;
    }
  }
  getCurrentActiveTab(): TabModeModel {
    return this.tabData.find(x => x.isActive == true);
  }
  // function: Input index of current tab to make it active:
  setActiveTab(index: number) {
    if (this.tabData.length > index) {
      this.tabData = this.tabData.map((x: TabModeModel, pos: number) => {
        if (pos !== index) {
          x.isActive = false;
        } else {
          x.isActive = true;
        }
        return x;
      });
      this.SaveSingleUserTabFromBackend(this.tabData[index]).subscribe();
      this.tabDataSubject.next(this.tabData);
      this.activeTabObservable.next(index);
    } else {
      console.warn('selected index out of range: ' + index);
    }
  }
  // return -1 if not found otherwise return the index
  checkExistedTabByUrl(searchUrl: string): number {
    return this.tabData.slice().findIndex(x => x.tabUrl === searchUrl);
  }
  // function to set index for tab:
  internalSetActiveTabObservable(): Observable<number> {
    return this.activeTabObservable.asObservable();
  }
  // core function to navigate between tabs:
  navigateUrlTab(url: string, router: Router) {
    let isReloadApp = this.browserTab.checkTabGreaterThanReset();
    if (!isReloadApp) {
      // normal Redirect App
      let urlParams = Helper.checkUrlHasQueryParamString(url);
      if (urlParams) {
        let paramObject = Helper.getQueryParamObject(urlParams);
        if (paramObject) {
          router.navigateByUrl(url);
        }
        // url has params
      } else {
        // normal url
        router.navigate([url]);
      }
    } else {
      // reload app:
      this.redirectTo(url);
    }


  }
  // if not exist create otherwise set active
  CreateTabOtherwiseSetActive(url: string, tabName?: string, tabType: string = 'Link', isActive: boolean = false) {
    let tabIndex = this.checkExistedTabByUrl(url);
    if (tabIndex === -1) {
      // this.createNewTab(url, tabName, tabType, isActive);
      if (tabName) {
        this.unRoleMenu = [];
        this.minimizeMenuWithAllowOpen(this.unAuthorizeNav);
        let tabToCreate = this.unRoleMenu.find(x => x.link === url);
        if (tabToCreate) this.createNewTab(tabToCreate.link, tabName, tabType, isActive);
        else this.createNewTab(url, tabName, tabType, isActive);
      } else {
        // using system naming:
        let formatTab = this.formatTabBySystemName(url);
        if (formatTab) this.createNewTab(formatTab.link, formatTab.title, tabType, isActive);
        else this.createNewTab(url, url, tabType, isActive);
      }
    } else {
      this.setActiveTab(tabIndex);
    }
  }
  formatTabBySystemName(browserUrl: string) {
    let itemTab: ExtendNbMenuItem = null;
    try {
      let lastRedirect = browserUrl.split('configuration').length > 1 ? browserUrl.split('configuration')[1] : undefined;
      if (lastRedirect) {
        // find detail nav:
        //  check if it query or /:id
        let detailRegex = /\/\w+\//;
        let queryParamRegex = /\?\w*([i,I]d)=([^&]*)/;
        if (detailRegex.test(lastRedirect)) {
          // type : /profile/debb0b47-e23c-4c41-9847-712ad367e672 OR  /configuration/contact/1000
          let id = lastRedirect.replace(detailRegex, '');
          let entity = lastRedirect.match(detailRegex)[0].match(/(\w+)/)[0];
          // create new Tab:
          itemTab = Object.assign({ title: this.getTypeTabURL(entity, id), link: browserUrl })
        } else if (browserUrl.match(queryParamRegex)) {
          // query param url ex: configuration/{entityId}?id=${id} match:
          let id = browserUrl.match(queryParamRegex)[0].split('=')[1];
          // format remove sale- in url
          let entity = this.removeSaleStringInUrl(browserUrl.match(Helper.configurationUrlSuffixRegex)[0]);
          itemTab = Object.assign({ title: this.getTypeTabURL(entity, id), link: browserUrl })
        } else {
          // find in all Menu:
          let isAnyExItem: ExtendNbMenuItem = Helper.recursiveFindItemArray(this.unAuthorizeNav, 'link', browserUrl);
          // normal pattern
          return isAnyExItem ?? null;
        }
      }
    } catch (ex) {
      console.warn(ex);
    }
    return itemTab;
  }

  private formatTabName(tabName: string): string {
    var result: string = tabName;
    try {
      if (result && result.includes('/configuration/')) result = result.replace('/configuration/', '');
      if (result && result.includes('sale-')) result = result.replace('sale-', '');
      //if (result && result.includes('lead')) result = result.replace('lead', environment.titleLead);
      if (result && result.includes('account')) result = result.replace('account', environment.titleAccount);
      //if (result && result.includes('opportunity')) result = result.replace('opportunity', environment.titleOpportunity);
      if (result && result.includes('/')) {
        let splitUrl = result.split('/');
        if (splitUrl && splitUrl.length > 0) {
          let lastPath = splitUrl[splitUrl.length - 1];
          if (lastPath.includes("-")) splitUrl[splitUrl.length - 1] = `${lastPath.split("-")[0] || ''}..`;
          result = splitUrl.join(" ");
        }
      }

      result = result.charAt(0).toUpperCase() + result.substring(1);
    } catch (ex) {
      result = tabName;
      console.warn(ex);
    }

    return result;
  }

  private getTypeTabURL(entity: string, id: string): string {
    var result: string = "";
    try {
      const arr = entity ? entity.split(" ") : [];
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      var entityUpper = arr.join(" ");
      if (entityUpper) {
        switch (entityUpper.toUpperCase()) {
          // case "LEAD":
          //   entityUpper = environment.titleLead;
          //   break;
          case "ACCOUNT":
            entityUpper = environment.titleAccount;
            break;
          // case "OPPORTUNITY":
          //   entityUpper = environment.titleOpportunity;
          //   break;
        }
      }

      result = `${entityUpper} ${id.length > 10 ? id.substring(0, 8) + '..' : id}`;
    }
    catch (ex) {
      console.warn(ex);
    }
    return result;
  }

  minimizeMenuWithAllowOpen(entity: ExtendNbMenuItem[]) {
    entity.forEach(item => {
      if (!item.children) {
        // no children:
        this.unRoleMenu.push(item);
      } else {
        // has children:
        this.minimizeMenuWithAllowOpen(item.children);
      }
    });
  }
  removeSaleStringInUrl(url: string) {
    var result: string = url;
    try {
      if (url.includes('configuration/'))
        url = url.replace('configuration/', '');

      if (url) {
        result = url.replace('sale-', '').replace('-', ' ');
      } else {
        result = url;
      }
    }
    catch (ex) { console.warn(ex) }

    return result;
  }
  redirectTo(uri: string) {
    window.location.href = `.${uri}`;
  }
  // edit tab but not save EXTEND DATA:
  editTab(tabName: string, tabUrl: string, tabIndex: number = null, removeParam: boolean = false) {
    tabName = this.formatTabName(tabName);
    let tabToEdit: TabModeModel;
    if (tabIndex != null) {
      tabToEdit = this.tabData[tabIndex];
    } else {
      tabToEdit = this.getCurrentActiveTab();
    }
    let tabToEditIndex = this.tabData.indexOf(tabToEdit);
    if (tabToEditIndex < 0) return;
    this.tabData[tabToEditIndex].tabName = tabName;
    this.tabData[tabToEditIndex].tabUrl = tabUrl;
    if (removeParam) {
      this.tabData[tabToEditIndex].queryParams = null;
    }
    // replace old tab with new tab data:
    this.SaveSingleUserTabFromBackend(this.tabData[tabToEditIndex]).pipe().subscribe();
    this.tabDataSubject.next(this.tabData);
  }

  setTagIndexToCurrentTab(tagIndex: number, id: any = null) {
    let currentTab = this.getCurrentActiveTab();
    if (currentTab) {
      let tagModelsStr = window.sessionStorage.getItem('tabTagIndex') ?? "[]";
      let tagModels: TabTagIndexModel[] = [];
      if (tagModelsStr) {
        tagModels = JSON.parse(tagModelsStr);
        if (tagModels.find(x => x.tabId === currentTab.tabId)) {
          let index = tagModels.findIndex(x => x.tabId === currentTab.tabId);
          tagModels[index].tag = `#${tagIndex}`;
          tagModels[index].fulUrl = currentTab.tabUrl + `#${tagIndex}`;
          tagModels[index].partialUrl = currentTab.tabUrl;
          tagModels[index].uniqueId = id;
        } else {
          let newTag: TabTagIndexModel = new TabTagIndexModel();
          newTag.tabId = currentTab.tabId;
          newTag.tag = `#${tagIndex}`;
          newTag.fulUrl = currentTab.tabUrl + `#${tagIndex}`;
          newTag.partialUrl = currentTab.tabUrl;
          newTag.uniqueId = id;
          tagModels.push(newTag);
        }
      }
      window.sessionStorage.setItem('tabTagIndex', JSON.stringify(tagModels));
    }
  }
  // leave null to auto get current Tab
  getTabTagIndex(tabId: number = null): TabTagIndexModel {
    let currentId = tabId ? tabId : (this.getCurrentActiveTab() ? this.getCurrentActiveTab().tabId : null)
    if (currentId) {
      let tagModelsStr = window.sessionStorage.getItem('tabTagIndex');
      if (Helper.isNullOrEmpty(tagModelsStr)) {
        return null;
      } else {
        let tagModel: TabTagIndexModel[] = JSON.parse(tagModelsStr);
        if (tagModel && tagModel.find(x => x.tabId === currentId)) {
          return tagModel.find(x => x.tabId === currentId);
        } else return null;
      }
    } else return null;
  }

  removeTabTagById(tabId: number) {
    if (tabId < 1) return false;
    let result = false;
    let tagModelsStr = window.sessionStorage.getItem('tabTagIndex');
    if (Helper.isNullOrEmpty(tagModelsStr)) {
      return result;
    } else {
      // have data:
      let tagModel: TabTagIndexModel[] = JSON.parse(tagModelsStr);
      if (tagModel && tagModel.find(x => x.tabId === tabId)) {
        tagModel = tagModel.filter(x => x.tabId !== tabId);
        window.sessionStorage.setItem('tabTagIndex', JSON.stringify(tagModel));
        result = true;
      } else result = false;
    }
    return result;
  }
  removeCurrentTabTag() {
    let currentTab = this.getCurrentActiveTab();
    if (currentTab) {
      this.removeTabTagById(currentTab.tabId);
    }
  }

  editFullTab(tabName: string = null, tabUrl: string, extendData: ExtendTabData = null, tabIndex: number = null) {
    tabName = this.formatTabName(tabName);
    let tabToEdit: TabModeModel;
    if (tabIndex != null) {
      tabToEdit = this.tabData[tabIndex];
    } else {
      tabToEdit = this.getCurrentActiveTab();
    }
    let tabToEditIndex = this.tabData.indexOf(tabToEdit);
    if (tabToEditIndex < 0) return;
    this.tabData[tabToEditIndex].tabName = tabName ?? this.tabData[tabToEditIndex].tabName;
    this.tabData[tabToEditIndex].tabUrl = tabUrl;
    this.tabData[tabToEditIndex].extendData = JSON.stringify(extendData);

    // replace old tab with new tab data:
    this.SaveSingleUserTabFromBackend(this.tabData[tabToEditIndex]).pipe().subscribe();
    this.tabDataSubject.next(this.tabData);
  }
  setExtendData(extendData: string, prop: string, value: any): ExtendTabData {
    let model: ExtendTabData;
    if (extendData) {
      model = JSON.parse(extendData);
    } else {
      model = new ExtendTabData();
    }
    model[prop] = value;
    return model;
  }

  getPropExtendData(prop: string, tab: TabModeModel): any {
    if (tab && !Helper.isNullOrEmpty(tab.extendData)) {
      let extendTabData: ExtendTabData = JSON.parse(tab.extendData);
      return extendTabData[prop] != null ? extendTabData[prop] : null;
    } else return null;
  }
  // ------------------------------------- CALL API ONLY --------------------------------
  // Remove one Tab call API:
  removeTab(tab: TabModeModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RemoveTab`, tab);
  }
  savedSwapTabs(currentTab: TabModeModel, newTab: TabModeModel): Observable<ReturnResult<boolean>> {
    let model: TabSwapViewModel = {
      currentTab: currentTab,
      newTab: newTab
    }
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/savedSwapTabs`, model);
  }
  getUserTabFromBackEnd(userId: string): Observable<ReturnResult<TabModeModel[]>> {
    return this.http.get<ReturnResult<TabModeModel[]>>(`${this.baseUrl}/GetUserTabs/${userId}`);
  }
  SaveSingleUserTabFromBackend(userTab: TabModeModel): Observable<ReturnResult<TabModeModel>> {
    return this.http.post<ReturnResult<TabModeModel>>(`${this.baseUrl}/SaveSingleUserTab`, userTab);
  }
  getActiveTabFromBacked(id: string = null): Observable<ReturnResult<TabModeModel>> {
    if (!id) return this.http.get<ReturnResult<TabModeModel>>(`${this.baseUrl}/GetActiveTab`);
    else return this.http.get<ReturnResult<TabModeModel>>(`${this.baseUrl}/GetActiveTab?id=${id}`);
  }
}
