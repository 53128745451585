<div mat-dialog-title align="center">
    <h3>{{data.vitalInfo?.title}} <span class="float-right" style="display: flex;"> <button mat-icon-button  [mat-dialog-close]><mat-icon>close</mat-icon></button></span></h3>
</div>
<div mat-dialog-content>
    <nb-card class="summary-card" >
        <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px" fxLayoutGap="12px">
            <div class="summary-card-body" style="text-align: center; ">
                <img [src]="data.img" width="100px" height="100px" style="display: block;" >
            </div>
            <div class="summary-card-body">
                <div [style.color]="data.normal ? '#78b12b' : 'red'"  style="font-size:94px"><b>{{data.vitalData ==
                    'NaN' ? '_' : data.vitalData ||
                    '_'}}</b> <span class="vital-unit">{{data.vitalInfo?.unit}}</span></div>
                <div class="text-secondary" style="padding-top: 6px;"><i>{{data.vitalInfo?.range || 'No range to display'}}</i></div>
            </div>
        </div>
    </nb-card>

    <h6>About {{data.vitalInfo?.title}}</h6>
    <div [innerHTML]="data.vitalInfo?.description">
    </div>    

</div>
