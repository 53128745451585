import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { ProfileModel } from 'src/app/modules/admin/profile-management/profile-model';
import { Page } from 'src/app/shared/models/paging/page';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { digit, maxNumber, prop, required } from "@rxweb/reactive-form-validators";
import { Priority, TaskStatus, TaskType } from "../task-status.model";
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { NumberLiteralType } from 'typescript';
import { ProfileDetailModel } from 'src/app/modules/admin/profile-management/profile-detail.model';

export class Task {
    @prop()
    taskId: number | undefined = 0;
    @prop()
    @required()
    taskTypeId: number | null = 1;
    @prop()
    @required()
    taskName: string;
    @prop()
    taskDescription: string;
    @prop()
    @required()
    taskPriority: number = 1;
    @prop()
    @required()
    taskStatusId: number = 3;
    @prop()
    taskStartDate: Date | string | null;
    @prop()
    taskEndDate: Date | string | null;
    @prop()
    resolution: string;
    @prop()
    resolutionCodeId: number | null;
    assigneeTo: string;
    dateModified: Date | string | null;
    dateCreated: Date | string | null;
    userName: string | null;
    user: UserModel | null;
    
    //TaskType group
    taskType: TaskType | null = null;
    taskTypeName: string | null;
    taskTypeColorCode: string | null;
    //end
    
    taskOrderId = 0;
    taskListingOrderId = 0;
    
    //Relationship group
    relationshipId: string | null;
    relationshipType: string | null;
    relationshipDisplayName: string | null;
    relationshipSubTypeName: string | null;
    //end
    
    isAutoTask: boolean | null;
    taskStatus: TaskStatusLog | null = null;
    pictureURL: string | null = null;
    taskPriorities: Priority;

    // ------------------------------------------------------------------------------ Sub Property: Not mapped prop ------------------------------------------------------------------------------
    relationshipName?: string | null;
    profileMedia?: string | null;
    resolutionCode?: ResolutionCode;
    primaryPhoneNumber?: string | null;
    profile: ProfileDetailModel;
    buyer: BuyerModel;
    typeNameRelationshipProfile: string | null;
    colorRelationshipProfile: string | null;

}
export class TaskFilterProp {
    key: number;
    propertyValue: number[];
    rangeDateValue: RangeDate;
    searchValue?: string;
}

class TaskStatusLog {
    taskStatusId: number;
    taskStatusName: string;
    taskOrder: number;
    deleted: boolean;
    dateDeleted: Date | string | null;
    // optional:
    colorCode: string;
}
export class RangeDate {
    @prop()
    startDate: string;
    @prop()
    endDate: string;
}

export class ReturnSuccessIdModel {
    id: number;
    success: boolean;
}

export interface ResolutionCode {
    resolutionCodeId: number;
    resolutionCodeName: string;
    deleted: boolean;
    dateDeleted: Date | string | null;
    order: number;
    taskType: TaskType | null;
    taskTypeId: number | null;
}

export class ResolutionCodeModel {
    @prop()
    resolutionCodeId: number;
    @required()
    @prop()
    resolutionCodeName: string;
    @required()
    @prop()
    @digit()
    @maxNumber({ value: 100000 })
    order: number;
    @prop()
    taskTypeId: number | null;
    action: TblActionType;
}
export interface TaskTemplate {
    taskTemplateId: number;
    templateName: string;
    targetType: string;
    targetId: string;
    template: string;
    action: string;
    dateModified: string | null;
    datecreated: string | null;
    deleted: boolean;
    dateDeleted: string | null;
}

export class TaskPagingFilter extends Page {
    filterProp?: TaskFilterProp[] = []
}
export class MultipleAssignTaskModel {
    /**
     *
     */
    constructor() {
        this.numberOfTask = 0;
        this.tasks = [];
    }
    numberOfTask: number;
    pageFilter: Page;
    assigneeId: string;
    dueDate: Date | string;
    tasks: Task[];
}

export class GMTModel {
    text: string;
    value: string;
}

export const Gmts: GMTModel[] = [
    {
        text: 'UTC-1:00 - Cabo Verde Is.',
        value: '-1'
    },
    {
        text: 'UTC-1:00 - Azores',
        value: '-1'
    },
    {
        text: 'UTC-2:00 - Coordinated Universal Time -2',
        value: '-2'
    },
    {
        text: 'UTC-3:00 - Salvador',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - Saint Pierre and Miquelon',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - Punta Arenas',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - Montevideo',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - Greenland',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - City of Buenos Aires',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - Cayenne, Fortaleza',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - Brasilia',
        value: '-3'
    },
    {
        text: 'UTC-3:00 - Araguaina',
        value: '-3'
    },
    {
        text: 'UTC-3:30 - Newfoundland',
        value: '-3'
    },
    {
        text: 'UTC-4:00 - Santiago',
        value: '-4'
    },
    {
        text: 'UTC-4:00 - Georgetown, La Paz,Manaus, San Juan',
        value: '-4'
    },
    {
        text: 'UTC-4:00 - Cuiaba',
        value: '-4'
    },
    {
        text: 'UTC-4:00 - Caracas',
        value: '-4'
    },
    {
        text: 'UTC-4:00 - Atlantic Time (Canada)',
        value: '-4'
    },
    {
        text: 'UTC-4:00 - Asuncion',
        value: '-4'
    },
    {
        text: 'UTC-5:00 - Turks and Caicos',
        value: '-5'
    },
    {
        text: 'UTC-5:00 - Indiana (East)',
        value: '-5'
    },
    {
        text: 'UTC-5:00 - Havana',
        value: '-5'
    },
    {
        text: 'UTC-5:00 - Haiti',
        value: '-5'
    },
    {
        text: 'UTC-5:00 - Eastern Time (US & Canada)',
        value: '-5'
    },
    {
        text: 'UTC-5:00 - Chetumal',
        value: '-5'
    },
    {
        text: 'UTC-5:00 - Bagota, Lima, Quito, Rio Branco',
        value: '-5'
    },
    {
        text: 'UTC-6:00 - Saskatchewan',
        value: '-6'
    },
    {
        text: 'UTC-6:00 - Guadalajara, Mexico City, Monterry',
        value: '-6'
    },
    {
        text: 'UTC-6:00 - Easter Island',
        value: '-6'
    },
    {
        text: 'UTC-6:00 - Central Time (US & Canada)',
        value: '-6'
    },
    {
        text: 'UTC-6:00 - Central America',
        value: '-6'
    },
    {
        text: 'UTC-7:00 - Yukon',
        value: '-7'
    },
    {
        text: 'UTC-7:00 - Mountain Time (US & Canada)',
        value: '-7'
    },
    {
        text: 'UTC-7:00 - Chihuahua, La Paz, Mazatlan',
        value: '-7'
    },
    {
        text: 'UTC-7:00 - Arizona',
        value: '-7'
    },
    {
        text: 'UTC-8:00 - Pacific Time (US & Canada)',
        value: '-8'
    },
    {
        text: 'UTC-8:00 - Coordinated Universal Time-08',
        value: '-8'
    },
    {
        text: 'UTC-8:00 - Baja California',
        value: '-8'
    },
    {
        text: 'UTC-9:00 - Coordinated Universal Time-09',
        value: '-9'
    },
    {
        text: 'UTC-9:00 - Alaska',
        value: '-9'
    },
    {
        text: 'UTC-9:30 - Marquises Islands',
        value: '-9'
    },
    {
        text: 'UTC-10:00 - Hawaii',
        value: '-10'
    },
    {
        text: 'UTC-10:00 - Aleutian Islands',
        value: '-10'
    },
    {
        text: 'UTC-11:00 - Coordinated Universal Time-11',
        value: '-11'
    },
    {
        text: 'UTC-12:00 - International Date Line West',
        value: '-12'
    },
    {
        text: 'UTC - Coordinated Universal Time',
        value: '0'
    },
    {
        text: 'UTC+00:00 - Dublin, Edinburgh, Lisbon, London',
        value: '0'
    },
    {
        text: 'UTC+00:00 - Monrovia, Reykjavik',
        value: '0'
    },
    {
        text: 'UTC+00:00 - Sao Tome',
        value: '0'
    },
    {
        text: 'UTC+01:00 - Casablanca',
        value: '+1'
    },
    {
        text: 'UTC+01:00 - Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        value: '+1'
    },
    {
        text: 'UTC+01:00 - Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        value: '+1'
    },
    {
        text: 'UTC+01:00 - Brussels, Copenhagen, Madrid, Paris',
        value: '+1'
    },
    {
        text: 'UTC+01:00 - Sarajevo, Skopje, Warsaw, Zagreb',
        value: '+1'
    },
    {
        text: 'UTC+01:00 - West Central Africa',
        value: '+1'
    },
    {
        text: 'UTC+02:00 - Amman',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Athens, Bucharest',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Beirut',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Cairo',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Chisinau',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Damascus',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Gaza, Hebron',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Harate, Pretoria',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Jerusalem',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Juba',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Kaliningrad',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Khartoum',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Tripoli',
        value: '+2'
    },
    {
        text: 'UTC+02:00 - Windhoek',
        value: '+2'
    },
    {
        text: 'UTC+03:00 - Baghdad',
        value: '+3'
    },
    {
        text: 'UTC+03:00 - Istanbul',
        value: '+3'
    },
    {
        text: 'UTC+03:00 - Kuwait, Riyadh',
        value: '+3'
    },
    {
        text: 'UTC+03:00 - Minsk',
        value: '+3'
    },
    {
        text: 'UTC+03:00 - Moscow, St.Petersburg',
        value: '+3'
    },
    {
        text: 'UTC+03:00 - Nairobi',
        value: '+3'
    },
    {
        text: 'UTC+03:00 - Volgograd',
        value: '+3'
    },
    {
        text: 'UTC+03:30 - Tehran',
        value: '+3'
    },
    {
        text: 'UTC+04:00 - Abu Dhabi, Muscat',
        value: '+4'
    },
    {
        text: 'UTC+04:00 - Astrakhan,Ulyanovsk ',
        value: '+4'
    },
    {
        text: 'UTC+04:00 - Baku',
        value: '+4'
    },
    {
        text: 'UTC+04:00 - Izhevsk, Samara',
        value: '+4'
    },
    {
        text: 'UTC+04:00 - Port Louis',
        value: '+4'
    },
    {
        text: 'UTC+04:00 - Saratov',
        value: '+4'
    },
    {
        text: 'UTC+04:00 - Tbilisi',
        value: '+4'
    },
    {
        text: 'UTC+04:00 - Yerevan',
        value: '+4'
    },
    {
        text: 'UTC+04:30 - Kabul',
        value: '+4'
    },
    {
        text: 'UTC+05:00 - Ashgabat, Tashkent',
        value: '+5'
    },
    {
        text: 'UTC+05:00 - Ekaterinburg',
        value: '+5'
    },
    {
        text: 'UTC+05:00 - Islamabad, Karachi',
        value: '+5'
    },
    {
        text: 'UTC+05:00 - Qyzylorda',
        value: '+5'
    },
    {
        text: 'UTC+05:00 - Chennai, Kolkata, Mumbai, New Delhi',
        value: '+5'
    },
    {
        text: 'UTC+05:30 - Sri Jayawardenepura',
        value: '+5'
    },
    {
        text: 'UTC+05:45 - Kathmandu',
        value: '+5'
    },
    {
        text: 'UTC+06:00 - Astana',
        value: '+6'
    },
    {
        text: 'UTC+06:00 - Dhaka',
        value: '+6'
    },
    {
        text: 'UTC+06:00 - Omsk',
        value: '+6'
    },
    {
        text: 'UTC+06:30 - Yangon (Rangoon)',
        value: '+6'
    },
    {
        text: 'UTC+07:00 - Bangkok, Hanoi, Jakarta',
        value: '+7'
    },
    {
        text: 'UTC+07:00 - Barnaul, Gorno-Altaysk',
        value: '+7'
    },
    {
        text: 'UTC+07:00 - Hovd',
        value: '+7'
    },
    {
        text: 'UTC+07:00 - Krasnoyarsk',
        value: '+7'
    },
    {
        text: 'UTC+07:00 - Novosibirsk',
        value: '+7'
    },
    {
        text: 'UTC+07:00 - Tomsk',
        value: '+7'
    },
    {
        text: 'UTC+08:00 - Beijing, Chongqing, Hong Kong, Urumqi',
        value: '+8'
    },
    {
        text: 'UTC+08:00 - Irkutsk',
        value: '+8'
    },
    {
        text: 'UTC+08:00 - Kuala Lumpur, Singapore',
        value: '+8'
    },
    {
        text: 'UTC+08:00 - Perth',
        value: '+8'
    },
    {
        text: 'UTC+08:00 - Taipei',
        value: '+8'
    },
    {
        text: 'UTC+08:00 - Ulaanbaatar',
        value: '+8'
    },
    {
        text: 'UTC+08:45 - Eucla',
        value: '+8'
    },
    {
        text: 'UTC+09:00 - Chita',
        value: '+9'
    },
    {
        text: 'UTC+09:00 - Osaka, Sapporo, Tokyo',
        value: '+9'
    },
    {
        text: 'UTC+09:00 - Pyongyang',
        value: '+9'
    },
    {
        text: 'UTC+09:00 - Seoul',
        value: '+9'
    },
    {
        text: 'UTC+09:00 - Yakutsk',
        value: '+9'
    },
    {
        text: 'UTC+09:30 - Adelaide',
        value: '+9'
    },
    {
        text: 'UTC+09:30 - Darwin',
        value: '+9'
    },
    {
        text: 'UTC+10:00 - Brisbane',
        value: '+10'
    },
    {
        text: 'UTC+10:00 - Canberra, Melbournem, Sydney',
        value: '+10'
    },
    {
        text: 'UTC+10:00 - Guam, Port Moresby',
        value: '+10'
    },
    {
        text: 'UTC+10:00 - Hobart',
        value: '+10'
    },
    {
        text: 'UTC+10:00 - Vladivostok',
        value: '+10'
    },
    {
        text: 'UTC+10:30 - Lord Howe Island',
        value: '+10'
    },
    {
        text: 'UTC+11:00 - Bougainville Island',
        value: '+11'
    },
    {
        text: 'UTC+11:00 - Chokurdakh',
        value: '+11'
    },
    {
        text: 'UTC+11:00 - Magadan',
        value: '+11'
    },
    {
        text: 'UTC+11:00 - Norfolk Island',
        value: '+11'
    },
    {
        text: 'UTC+11:00 - Sakhalin',
        value: '+11'
    },
    {
        text: 'UTC+11:00 - Solomon Is., New Caledonia',
        value: '+11'
    },
    {
        text: 'UTC+12:00 - Anadyr, Petropavlovsk-Kamchatsky',
        value: '+12'
    },
    {
        text: 'UTC+12:00 - Auckland, Wellington',
        value: '+12'
    },
    {
        text: 'UTC+12:00 - Coordinated Universal Time+12',
        value: '+12'
    },
    {
        text: 'UTC+12:00 - Fiji',
        value: '+12'
    },
    {
        text: 'UTC+12:45 - Chatham Islands',
        value: '+12'
    },
    {
        text: 'UTC+13:00 - Coordinated Universal Time+13',
        value: '+13'
    },
    {
        text: 'UTC+13:00 - Nuku`alofa',
        value: '+13'
    },
    {
        text: 'UTC+13:00 - Samoa',
        value: '+13'
    },
    {
        text: 'UTC+14:00 - Kiritimati Island',
        value: '+14'
    },
    // {
    //     text: 'UTC-1:00 - Denmark/Portugal/Cape Verde',
    //     value: '-1'
    // },
    // {
    //     text: 'UTC-2:00 - United Kingdom/Brazil',
    //     value: '-2'
    // },
    // {
    //     text: 'UTC-3:00 - France/United Kingdom/Denmark and 6 more',
    //     value: '-3'
    // },
    // {
    //     text: 'UTC-4:00 - United States/France/Canada and 10 more',
    //     value: '-4'
    // },
    // {
    //     text: 'UTC-5:00 - Brazil/Cuba/Mexico and 11 more',
    //     value: '-5'
    // },
    // {
    //     text: 'UTC-6:00 - Canada/Mexico/Chile and 8 more',
    //     value: '-6'
    // },
    // {
    //     text: 'UTC-7:00 - United States/Canada/Mexico',
    //     value: '-7'
    // },
    // {
    //     text: 'UTC-8:00 - France/Canada/United Kingdom and 2 more',
    //     value: '-8'
    // },
    // {
    //     text: 'UTC-9:00 - United States/France',
    //     value: '-9'
    // },
    // {
    //     text: 'UTC-10:00 - United States/France/New Zealand',
    //     value: '-10'
    // },
    // {
    //     text: 'UTC-11:00 - United States/New Zealand',
    //     value: '-11'
    // },
    // {
    //     text: 'UTC-12:00 - United States',
    //     value: '-12'
    // },
    // {
    //     text: 'UTC+1:00 - Spain/Denmark/Belgium and 42 more',
    //     value: '+1'
    // },
    // {
    //     text: 'UTC+2:00 - Ukraine/Egypt/Finland and 29 more',
    //     value: '+2'
    // },
    // {
    //     text: 'UTC+3:00 - Belarus/France/Madagascar and 19 more',
    //     value: '+3'
    // },
    // {
    //     text: 'UTC+4:00 - United Arab Emirates/Georgia/Oman and 6 more',
    //     value: '+4'
    // },
    // {
    //     text: 'UTC+5:00 - Australia/Kazakhstan/Maldives and 7 more',
    //     value: '+5'
    // },
    // {
    //     text: 'UTC+6:00 - Kazakhstan/Bangladesh/Bangladesh and 4 more',
    //     value: '+6'
    // },
    // {
    //     text: 'UTC+7:00 - Vietnam/Thailand/Australia and 6 more',
    //     value: '+7'
    // },
    // {
    //     text: 'UTC+8:00 - Malaysia/Australia/Indonesia and 9 more',
    //     value: '+8'
    // },
    // {
    //     text: 'UTC+9:00 - Japan/Korea, North/South and 4 more',
    //     value: '+9'
    // },
    // {
    //     text: 'UTC+10:00 - Russia/United States/Australia and 3 more',
    //     value: '+10'
    // },
    // {
    //     text: 'UTC+11:00 - France/Australia/Vanuatu and 5 more',
    //     value: '+11'
    // },
    // {
    //     text: 'UTC+12:00 - United States/New Zealand/Fiji and 7 more',
    //     value: '+12'
    // },
    // {
    //     text: 'UTC+13:00 - New Zealand/Samoa/Tongo and 1 more',
    //     value: '+13'
    // },
    // {
    //     text: 'UTC+14:00 - Kiribati',
    //     value: '+14'
    // },
]
