import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { mergeMap, switchMap, take } from 'rxjs/operators';
import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { environment } from 'src/environments/environment';
import { UserModel } from '../../../user-management/user-model';
import { UserService } from '../../../user-management/user.service';
import { SaleConvertHistory } from '../../add-sale-leads.model';
import { SaleLeadService } from '../../sale-lead.service';

@Component({
  selector: 'app-convert-sale-lead-notification',
  templateUrl: './convert-sale-lead-notification.component.html',
  styleUrls: ['./convert-sale-lead-notification.component.scss']
})
export class ConvertSaleLeadNotificationComponent implements OnInit {
  @Input() convertHistoryId: number = 0;
  @Input() showMoreInformation: boolean = false;
  // required if showMoreInformation is true:
  @Input() saleLeadName: string;
  @Input() saleLeadId: string;
  environment = environment;
  dynamicCol = [];
  actualCol
  model: SaleConvertHistory;
  isLoading: boolean = false;
  helper = Helper;
  userList: UserModel[] = [];
  opportunityOwner: UserModel;
  contactOwner: UserModel;
  accountOwner: UserModel;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConvertSaleLeadNotificationComponent>,
    private service: SaleLeadService,
    private userService: UserService,
    private tabMode: AdminTabModeService,
    private toast: NbToastrService
  ) {
    this.convertHistoryId = data.convertHistoryId ?? this.convertHistoryId;
    this.showMoreInformation = data.showMoreInformation ?? this.showMoreInformation;
    this.saleLeadId = data.saleLeadId ?? this.saleLeadId;
    this.saleLeadName = data.saleLeadName ?? this.saleLeadName;
    this.userService.getAllUser();
  }

  ngOnInit() {
    this.isLoading = true;
    this.userService.allUserList().pipe(take(1), switchMap(e => {
      this.userList = e;
      return this.service.getSaleConvertById(this.convertHistoryId);
    })).subscribe({
      next: (data) => {
        if (data.result) {
          this.dynamicCol = [];
          this.actualCol = 1;
          this.model = data.result;
          // get owner:

          this.dynamicCol.push(this.model.toAccountId ? true : false);
          this.dynamicCol.push(this.model.toContactId && this.model.toContactId > 0 ? true : false);
          this.dynamicCol.push(this.model.toOpportunityId ? true : false);
          this.actualCol = this.dynamicCol.filter(x => x).length == 0 ? 1 : this.dynamicCol.filter(x => x).length;
          this.onResize(null);
          this.getDisplayOwner(data.result);
          this.isLoading = false;

        } else {
          this.model = null;
          this.isLoading = false;
        }
      },
      error: (data) => {
        this.model = null;
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
    // this.service.getSaleConvertById(this.convertHistoryId).subscribe();
  }
  closeDialog() {
    this.dialogRef.close(true);
  }
  getDisplayOwner(convertModel: SaleConvertHistory) {
    if (convertModel.toAccountId && convertModel.saleAccount && convertModel.saleAccount.ownerId) {
      this.accountOwner = this.userList.find(x => x.id == convertModel.saleAccount.ownerId) ?? null;
      if (this.accountOwner && this.accountOwner.pictureURL) {
        this.accountOwner.pictureURL = this.helper.userURL(this.accountOwner.pictureURL);
      }
    }
    if (convertModel.toContactId && convertModel.saleContact && convertModel.saleContact.ownerId) {
      this.contactOwner = this.userList.find(x => x.id == convertModel.saleContact.ownerId) ?? null;
      if (this.contactOwner && this.contactOwner.pictureURL) {
        this.contactOwner.pictureURL = this.helper.userURL(this.contactOwner.pictureURL);
      }
    }
    if (convertModel.toOpportunityId && convertModel.saleOpportunityProfile && convertModel.saleOpportunityProfile.ownerId) {
      this.opportunityOwner = this.userList.find(x => x.id == convertModel.saleOpportunityProfile.ownerId) ?? null;
      if (this.opportunityOwner && this.opportunityOwner.pictureURL) {
        this.opportunityOwner.pictureURL = this.helper.userURL(this.opportunityOwner.pictureURL);
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let actualDataCol = this.dynamicCol.filter(x => x).length;
    if (window.innerWidth < 1390 && actualDataCol > 2) {
      if (window.innerWidth < 950) {
        this.actualCol = 1;
      } else {
        this.actualCol = 2;
      }
    } else if (window.innerWidth > 1390 && actualDataCol > 2) {
      this.actualCol = 3;
    } else if (window.innerWidth < 1390 && actualDataCol == 2) {
      if (window.innerWidth < 950) {
        this.actualCol = 1;
      } else {
        this.actualCol = 2;
      }
    }
  }

  currency() {
    return localStorage.getItem("currency") ?? "";
  }
  openConvertedLead() {
    this.tabMode.CreateTabOtherwiseSetActive(`/configuration/sale-lead?id=${this.saleLeadId}`, null, null, true);
  }
  navigateEntity(entityUrl: string, id: any) {
    this.tabMode.CreateTabOtherwiseSetActive(`/configuration/${entityUrl}?id=${id}`, null, null, true);
  }
}
