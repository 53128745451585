<div appChangeUrlObjects [id]="id" [isOpenDialog]="openByDialog" [topic]="'patient'"></div>
<ng-container *ngIf="isShowButton">
  <div class="d-flex flex-wrap align-items-space-between" mat-dialog-title [nbSpinner]="isLoadingEdit"
    style="margin: unset;padding: 0px 8px 1px 28px">
    <div [hidden]="isLoading" class="d-flex flex-wrap" class="d-flex flex-wrap" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-icon style="transform: scale(1.5);" [ngStyle]="{'color':saleAccountColorCode.SaleAccount}">
          account_circle</mat-icon>
      </div>
      <div class="entity-name-id">
        <span class="entity-name" fxLayout="row" fxLayoutAlign="space-around center">
          Patient
        </span>
        <span *ngIf="!isCopy;else copied" [matTooltip]="'Click to copy this patient ID: '+ id" class="entity-id"
          (click)="copyGUIDToClipboard()" class="underline-id">#{{id | truncateText:[6,
          '...']}}</span>
      </div>
      <mat-divider [vertical]="true">
      </mat-divider>

    </div>
    <div class="ml-auto" fxLayout="row">
      <button *ngIf="openByDialog" mat-icon-button matTooltip="Reload" appChangeUrlObject (click)="reloadData()">
        <mat-icon>refresh</mat-icon>
      </button>
      <button *ngIf="openByDialog" mat-icon-button matTooltip="Close" appChangeUrlObjects [isClose]="true"
        (click)="closeDialog()">
        <mat-icon style="color: gray;">close</mat-icon>
      </button>
    </div>
  </div>
</ng-container>

<mat-divider></mat-divider>
<mat-dialog-content>
  <mat-dialog-content *ngIf="isLoading; then skeleton else bodyDialog"></mat-dialog-content>
  <ng-template #bodyDialog>
    <div style="padding-top: 6px; padding-right: 12px; padding-left: 12px">
      <div *ngIf="saleAccountModel; else notExistedById" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="20">
          <div class="campaign-card" style="padding: 25px;" align="center">
            <img align="center" border="0" style="border-radius: 50%" src="https://i.ibb.co/VCfXtnm/logo.png"
              height="150" width="150">
            <div style="padding-top: 12px">
              <h6 style="font-size: x-large;font-weight: bold;color: #41b541;line-height: 2rem;">
                <app-inline-edit (handleSave)="saveExtendData($event, 'PatientName')"
                  [inputData]="extendData.PatientName" [widthDisplay]="'100%'" [accessFullWidth]="true"
                  [removePaddingBottom]="true" [defaultNullLabel]="'_'" [validatorValue]="validProperties.accountName.valid"
                  [messageError]="validProperties.accountName ? validProperties.accountName.message : null"
                  [usingMessageParent]="true" [setFontSize]="'12px'" [setPaddingTop]="'8px'" [readonlyInline]="isDisableAssign"
                  ></app-inline-edit>
              </h6>
              <div style="font-size: medium;">
                <div fxLayout="row" fxLayoutAlign="center end">
                  <div fxFlex="50" class="text-muted" style="text-align: end;"> <strong>Patient
                      ID:</strong></div>
                  <div fxFlex="50" style="min-width:100px;text-align: start; padding-top: 5px">
                    <app-inline-edit (handleSave)="saveExtendData($event, 'PatientId')"
                      [inputData]="extendData.PatientId" [widthDisplay]="'100%'" [accessFullWidth]="true"
                      [removePaddingBottom]="true" [defaultNullLabel]="'_'"
                      [readonlyInline]="isDisableAssign"></app-inline-edit>
                  </div>
                </div>
              </div>
              <div class="text-muted">
                <div fxLayout="row" fxLayoutAlign="center end">
                  <div fxFlex="50" class="campaign-label" style="padding-right:unset !important;">Age:
                  </div>
                  <div fxFlex="50" class="content" style="min-width:100px;text-align: start;">
                    <app-inline-edit (handleSave)="saveExtendData($event, 'Age')" [inputData]="extendData.Age"
                      [widthDisplay]="'100%'" [accessFullWidth]="true" [removePaddingBottom]="true"
                      [validatorValue]="validNumber" messageError="Only digit is allowed" [defaultNullLabel]="'_'"
                      [readonlyInline]="isDisableAssign"></app-inline-edit>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="campaign-card" style="padding: 12px;min-height: 42vh">
            <div>
              <h6 class="text-center text-muted">Information
                <span class="float-right">
                  <app-add-extend-data-button [id]="id" [extendData]="saleAccountModel?.extendData"
                    (onRefresh)="refreshData()" [disabled]="isDisableAssign">
                  </app-add-extend-data-button>
                </span>
              </h6>

            </div>

            <br>
            <div style="padding-left:12px; padding-right:12px; display:flex; gap:'18px'; flexDirection:'column' ">
              <div fxLayout="row" fxLayoutAlign="center end">
                <div fxFlex="40" class="campaign-label">Gender</div>
                <div fxFlex="60" class="content" style="min-width:100px">
                  <mat-select [value]="extendData.Gender" [disabled]="isDisableAssign"
                    (selectionChange)="saveExtendDataFromDropDown($event, 'Gender')" matTooltip="Click to edit">
                    <mat-option [value]="'1'">Male</mat-option>
                    <mat-option [value]="'2'">Female</mat-option>
                    <mat-option [value]="'0'">Other</mat-option>
                  </mat-select>
                  <!-- <app-inline-edit (handleSave)="saveExtendData($event, 'Gender')" [inputData]="extendData.Gender"
                    [widthDisplay]="'100%'" [accessFullWidth]="true" [removePaddingBottom]="true"
                    [defaultNullLabel]="'_'"></app-inline-edit> -->
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center end">
                <div fxFlex="40" class="campaign-label">Weight</div>
                <div fxFlex="60" class="content" style="min-width:100px">
                  <app-inline-edit (handleSave)="saveExtendData($event, 'Weight')" [inputData]="extendData.Weight"
                    [widthDisplay]="'100%'" [accessFullWidth]="true" [removePaddingBottom]="true"
                    [validatorValue]="validNumberWithDecimal" messageError="Only numberic is allowed"
                    [defaultNullLabel]="'_'" [readonlyInline]="isDisableAssign"></app-inline-edit>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="40" class="campaign-label">DOB</div>
                <div fxFlex="60" class="content" style="min-width:100px; display:flex; flex-direction:row">

                  <mat-form-field class="campaign-datetime-picker">
                    <input matInput [matDatepicker]="closeDatePicker" [disabled]="isDisableAssign" [max]="today"
                      (click)="closeDatePicker.open()" [value]="extendData.DOB" readonly
                      (dateChange)="saveExtendDataFromDatePicker($event, 'DOB')">
                    <mat-datepicker-toggle matSuffix [for]="closeDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #closeDatePicker>
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center end">
                <div fxFlex="40" class="campaign-label">Message Number</div>
                <div fxFlex="60" class="content" style="min-width:100px">
                  <app-inline-edit (handleSave)="saveExtendData($event, 'ANI')" [inputData]="extendData.ANI"
                    [widthDisplay]="'100%'" [accessFullWidth]="true" [removePaddingBottom]="true"
                    [validatorValue]="validPhoneNumber" [pipe]="phoneFormat" messageError="Message Number is not valid"
                    [defaultNullLabel]="'_'" [readonlyInline]="isDisableAssign"></app-inline-edit>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center end">
                <div fxFlex="40" class="campaign-label">Pregnant</div>
                <div fxFlex="60" class="content" style="min-width:100px">
                  <mat-select [value]="extendData.Pregnant" [disabled]="isDisableAssign"
                    (selectionChange)="saveExtendDataFromDropDown($event, 'Pregnant')" matTooltip="Click to edit">
                    <mat-option [value]="'1'">Yes</mat-option>
                    <mat-option [value]="'0'">No</mat-option>
                  </mat-select>
                  <!-- <app-inline-edit (handleSave)="saveExtendData($event, 'Pregnant')" [inputData]="extendData.Pregnant"
                    [widthDisplay]="'100%'" [accessFullWidth]="true" [removePaddingBottom]="true"
                    [defaultNullLabel]="'_'"></app-inline-edit> -->
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center end">
                <div fxFlex="40" class="campaign-label">Doctor</div>
                <div *ngIf="users" fxFlex="60" class="content" style="min-width:100px">
                  <mat-select [value]="extendData.Doctor" [disabled]="isDisableAssignDoctor"
                    (selectionChange)="saveExtendDataFromDropDown($event, 'Doctor')" matTooltip="Click to edit">
                    <mat-option *ngFor="let item of users" [value]="item.id">{{item.userName}}</mat-option>
                  </mat-select>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center end" *ngFor="let item of extendDataList">
                <div fxFlex="40" class="campaign-label">{{item.prop}}</div>
                <div fxFlex="60" class="content" style="min-width:100px">
                  <app-inline-edit (handleSave)="saveExtendData($event, item.prop)" [inputData]="item.value"
                    [widthDisplay]="'100%'" [accessFullWidth]="true" [removePaddingBottom]="true"
                    [defaultNullLabel]="'_'" [readonlyInline]="isDisableAssign"></app-inline-edit>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div fxFlex="80" style="overflow:auto; max-height: 82vh;">
          <div class="campaign-card" *ngIf="true; else bodyLoading">
            <div class="text-center vital-sign-header">
              <strong style="
                                font-weight: 900;
                                color: #007424;
                                font-size: 18px;">Last Vital Signs</strong> <span style="font-size: 10px;">
                {{(lastVitalSignInfo?.DateCreated.toLocaleString() | date: 'MM/dd/yyyy - hh:mm:ss a ZZZZZ') || ' '}}</span>
            </div>
            <div style="padding-top: 12px;padding: 10px 30px 0px 30px;">
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="4px">
                <nb-card class="summary-card" (click)="openDescription('HR', 'https://cdn-icons-png.flaticon.com/512/865/865969.png')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img src="https://cdn-icons-png.flaticon.com/512/865/865969.png" width="30px" height="30px">
                    <h6 class="text-muted">Heart Rate</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div [style.color]="vitalSafety('HR') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.HR ==
                          'NaN' ? '_' : lastVitalSignInfo?.HR ||
                          '_'}}</b> <span class="vital-unit">BPM</span></div>
                    </div>
                  </nb-card-body>
                </nb-card>

                <nb-card class="summary-card" (click)="openDescription('BR','https://cdn-icons-png.flaticon.com/512/2888/2888867.png')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img src="https://cdn-icons-png.flaticon.com/512/2888/2888867.png" width="30px" height="30px">
                    <h6 class="text-muted">Breathing Rate</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div [style.color]="vitalSafety('BR') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.BR ==
                          'NaN' ? '_' : lastVitalSignInfo?.BR ||
                          '_'}}</b> <span class="vital-unit">BRPM</span></div>
                    </div>
                  </nb-card-body>
                </nb-card>

                <nb-card class="summary-card" (click)="openDescription('HRV','https://cdn-icons-png.flaticon.com/512/865/865969.png')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img src="https://cdn-icons-png.flaticon.com/512/865/865969.png" width="30px" height="30px">
                    <h6 class="text-muted">Heart Rate Variability</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div [style.color]="vitalSafety('HRV') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.HRV ==
                          'NaN' ? '_' : lastVitalSignInfo?.HRV ||
                          '_'}}</b> <span class="vital-unit">MA</span> </div>
                    </div>
                  </nb-card-body>
                </nb-card>

                <nb-card class="summary-card" (click)="openDescription('PNS','https://cdn3.iconfinder.com/data/icons/workmen-compensation-flat/64/recovery-health-treat-refresh-restoration-512.png')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img
                      src="https://cdn3.iconfinder.com/data/icons/workmen-compensation-flat/64/recovery-health-treat-refresh-restoration-512.png"
                      width="30px" height="30px">
                    <h6 class="text-muted">Recovery Ability</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div  [style.color]="vitalSafety('PNS') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.PNS ==
                          'NaN' ? '_' : lastVitalSignInfo?.PNS ||
                          '_'}}</b> <span class="vital-unit">LEVEL</span> </div>
                    </div>
                  </nb-card-body>
                </nb-card>

                <nb-card class="summary-card" (click)="openDescription('SL','https://img.freepik.com/premium-vector/stress-score-great-design-any-purposes-vector-illustration-chart-graphic-smile-icon_100456-11109.jpg?w=360')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img
                      src="https://img.freepik.com/premium-vector/stress-score-great-design-any-purposes-vector-illustration-chart-graphic-smile-icon_100456-11109.jpg?w=360"
                      width="30px" height="30px">
                    <h6 class="text-muted">Stress Level</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div  [style.color]="vitalSafety('SL') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.SL == 'NaN' ?
                          '_' : lastVitalSignInfo?.SL ||
                          '_'}}</b> <span class="vital-unit">LEVEL</span> </div>
                    </div>
                  </nb-card-body>
                </nb-card>

              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="4px">

                <nb-card class="summary-card" (click)="openDescription('BP','https://cdn-icons-png.flaticon.com/512/3389/3389240.png')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img src="https://cdn-icons-png.flaticon.com/512/3389/3389240.png" width="30px" height="30px">
                    <h6 class="text-muted">Blood Pressure</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div  [style.color]="vitalSafety('BP') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.BP ==
                          'NaN' ? '_' : lastVitalSignInfo?.BP ||
                          '_'}}</b> <span class="vital-unit">mmHg</span></div>
                    </div>
                  </nb-card-body>
                </nb-card>
                <nb-card class="summary-card" (click)="openDescription('HG','assets/images/hemoglobin.png')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img src="assets/images/hemoglobin.png" width="30px" height="30px">
                    <h6 class="text-muted">Hemoglobin</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div [style.color]="vitalSafety('HG') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.HG ==
                          'NaN' ? '_' : lastVitalSignInfo?.HG ||
                          '_'}}</b> <span class="vital-unit">g/dL</span> </div>
                    </div>
                  </nb-card-body>
                </nb-card>
                <nb-card class="summary-card"  (click)="openDescription('A1c','https://img.icons8.com/dusk/64/null/a1c-test.png')">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img src="https://img.icons8.com/dusk/64/null/a1c-test.png" width="30px" height="30px">
                    <h6 class="text-muted">Hemoglobin A1c</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div [style.color]="vitalSafety('A1c') ? '#78b12b' : 'red'" style="font-size:36px"><b>{{lastVitalSignInfo?.A1c ==
                          'NaN' ? '_' : lastVitalSignInfo?.A1c ||
                          '_'}}</b> <span class="vital-unit">%</span> </div>
                    </div>
                  </nb-card-body>
                </nb-card>
                <nb-card class="summary-card">
                  <nb-card-body class="summary-card-body" style="text-align: center;">
                    <img src="https://www.empowerhealth.ca/wp-content/uploads/2012/07/icon-medical-1.png" width="30px"
                      height="30px">
                    <h6 class="text-muted">Wellness Overall</h6>
                    <div fxLayout="row" fxLayoutAlign="center end" style="padding: 12px">
                      <div style="color: #206bf5; font-size:36px"><b>{{lastVitalSignInfo?.WR ==
                          'NaN' ? '_' : lastVitalSignInfo?.WR ||
                          '_'}}</b> </div>
                    </div>
                  </nb-card-body>
                </nb-card>
              </div>
            </div>
          </div>

          <div class="campaign-card" style="min-height: 42vh">
            <div style="display: block !important">
              <div fxFlex="row" fxLayoutAlign="end start">
                <mat-button-toggle-group class="campaign-report-mode" [(ngModel)]="tabPatientMode">
                  <mat-button-toggle value="PatientDashBoard">Vital Charts
                  </mat-button-toggle>
                  <mat-button-toggle value="ScheduleGroup" *nbIsGranted="['view', 'table-patient-schedule-group-tab']">Schedule Group
                    <app-add-schedule-button [id]="id" (onRefresh)="refreshScheduleTable($event)"
                      [disabled]="isDisableAssignDoctor">
                    </app-add-schedule-button>
                  </mat-button-toggle>
                  <mat-button-toggle value="PatientScheduleHistoryGroup" *nbIsGranted="['view', 'table-patient-schedule-history-tab']">Message History
                  </mat-button-toggle>
                  <mat-button-toggle value="Vital" *nbIsGranted="['view', 'table-patient-vital-history-tab']">Vital History
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div>
              <app-sale-account-schedule-tab *ngIf="tabPatientMode == 'ScheduleGroup'" [id]="id" #scheduleTab>
              </app-sale-account-schedule-tab>
              <app-sale-account-patient-schedule-history-tab *ngIf="tabPatientMode == 'PatientScheduleHistoryGroup'"
                [id]="id">
              </app-sale-account-patient-schedule-history-tab>
              <app-sale-account-vitals-history-tab *ngIf="tabPatientMode == 'Vital'" [id]="id"
                (onRefresh)="refreshData()" [maxHeight]="'34vh'">
              </app-sale-account-vitals-history-tab>
              <app-patient-dashboard *ngIf="tabPatientMode == 'PatientDashBoard'"
                [profileId]="id"></app-patient-dashboard>
            </div>
          </div>
        </div>

      </div>

    </div>
  </ng-template>
</mat-dialog-content>



<ng-template #bodyLoading>
  <div class="campaign-card">
    <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>




<ng-template #skeleton>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
    <ngx-skeleton-loader fxFlex="48" count="5" animation="progress"></ngx-skeleton-loader>
    <ngx-skeleton-loader fxFlex="48" count="5" animation="progress"></ngx-skeleton-loader>
  </div>
  <div fxLayout="column" class="px-4">
    <ngx-skeleton-loader fxFlex="100" count="3" animation="progress"></ngx-skeleton-loader>
  </div>
  <mat-divider class="mt-2 mx-4"></mat-divider>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
    <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
    <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
    <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
    <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
    <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
  </div>
  <mat-divider class="mx-4"></mat-divider>
  <div fxLayout="column" class="mt-3 px-4">
    <ngx-skeleton-loader fxFlex="100" [theme]="{
            height: '50vh'
        }" animation="progress"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #notExistedById>
  <app-blank-page [message]="'The patient is not exist or deleted'" linkSrc="/assets/images/expired.png"
    iconSize="500px">
  </app-blank-page>
</ng-template>

<ng-template #closeButton>
  <div fxLayout="row" fxLayoutAlign="end">
    <button mat-icon-button [mat-dialog-close]="true" appChangeUrlObjects [isClose]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>


<ng-template #copied>
  <span class="copied">
    Copied!!
  </span>
</ng-template>
