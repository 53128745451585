<nb-card [style.width.px]="noteWidth" [style.height.px]="noteHeight" class="card-container">
  <nb-card-header class="card-header" [style.background]="noteHeaderBackground" fxLayout="row"
    fxLayoutAlign="start center" (focus)="onFocusHandler()" (blur)="onBlurHandler()">
    <div fxFlex="calc(100%-120px)" fxLayoutAlign="start center" class="title-header" (click)="setFocusClick()"
      [nbPopover]="linkObjectComponent" nbPopoverClass="popover-link-note" nbPopoverTrigger="noop"
      [style.padding-left]="isMinimize ? '1rem' : ''">
      <ng-container *ngIf="!isMinimize; else collapseNote">
        <ng-container *ngIf="note.referenceId && note.referenceType; else addLinkTemplate">
          <button mat-flat-button class="link-note-button" (click)="popoverLinkComponent.show()">
            <mat-icon class="mr-1">link</mat-icon>
            <ng-container [ngSwitch]="note?.referenceType">
              <mat-icon *ngSwitchCase="'SALEPROFILE'" class="icon-account-object" style="--color-icon: #808080;">
                account_circle
              </mat-icon>
              <mat-icon *ngSwitchCase="'BUYER'" class="icon-account-object" color="primary" style="font-size: 21px">
                local_mall
              </mat-icon>
              <mat-icon *ngSwitchCase="'LEADS'" class="icon-account-object" style="--color-icon: #595974;">
                account_circle
              </mat-icon>
              <mat-icon *ngSwitchCase="'SALEACCOUNT'" class="icon-account-object" style="--color-icon: #808000;">
                account_circle</mat-icon>
              <mat-icon *ngSwitchCase="'OPPORTUNITY'" class="icon-account-object" style="--color-icon: #004d4d;">
                account_circle</mat-icon>
            </ng-container>
          </button>
          <button mat-icon-button matToolTip="Open detail" (click)="openDetailRelationship()">
            <mat-icon>open_in_browser</mat-icon>
          </button>
          <span class="limit-line mr-2" style="--line: 1; width: auto; max-width: 50%;"
            [matTooltip]="note?.referenceName" [nbSpinner]="isLoadingReference">
            {{isLoadingReference ? '' : (note?.referenceName || "Unknown") }}
          </span>
        </ng-container>
        <ng-template #addLinkTemplate>
          <button mat-flat-button class="link-note-button" (click)="popoverLinkComponent.show()">
            <mat-icon class="mr-1">add_link</mat-icon>
          </button>
        </ng-template>
        <span>{{notifySave}}</span>
      </ng-container>
      <ng-template #collapseNote>{{note.title}}</ng-template>
    </div>
    <div fxFlex="120px" fxLayoutAlign="end center">
      <button mat-icon-button [disabled]="isNewNote || isChange" (click)="onDeleteNote()">
        <mat-icon [style.color]="isNewNote || isChange ? '' : 'red'">
          delete
        </mat-icon>
      </button>
      <button mat-icon-button (click)="minimizeNote()">
        <nb-icon *ngIf="!isMinimize" icon="minus-outline" class="toggle-popup-chat"></nb-icon>
        <mat-icon *ngIf="isMinimize" fontSet="material-icons-outlined" style="font-size: 18px">zoom_out_map</mat-icon>
      </button>
      <button mat-icon-button (click)="onCloseNote()">
        <nb-icon icon="close-outline" class="toggle-popup-chat"></nb-icon>
      </button>
    </div>
  </nb-card-header>

  <nb-card-body style="padding: 0px; height: 300px; overflow: hidden;" [style.display]="displayBodyStatus"
    [style.background-color]="noteBodyBackground">
    <div style="padding: 12px 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-weight: 800;">
      <app-inline-edit #inlineTitleEdit [(inputData)]="note.title" [widthDisplay]="'100%'" [enterToSubmit]="true"
        [escapeToCancel]="true" [usingMessageParent]="true" [validatorValue]="validProperties?.noteTitle?.valid"
        [messageError]="validProperties?.noteTitle?.message" (handleSave)="saveNoteTitle($event)"
        (click)="isChange = true">
      </app-inline-edit>
    </div>
    <quill-editor #quillEditor (onEditorCreated)="setFocus($event)" [modules]="editorOptions" [(ngModel)]="note.note"
      (onFocus)="positionFocus($event)" placeholder="Enter a note">
    </quill-editor>
  </nb-card-body>
</nb-card>

<ng-template #linkObjectComponent>
  <div style="min-width: 500px; padding: 0.5rem 1rem 0">
    <app-search-type-objects #relationshipToObj [customClassIndex]="'popover-search-note'" [isShowMatChip]="false"
      (selectedValue)="saveLinkNote($event); popoverLinkComponent.hide()">
    </app-search-type-objects>
    <button mat-flat-button style="position: absolute; bottom: 4px; right: 0;" [disableRipple]="true"
      (click)="popoverLinkComponent.hide()">Cancel</button>
  </div>
</ng-template>