<mat-toolbar *nbIsGranted="['view', resource]" fxLayout="row" fxLayoutAlign="space-between center"
  style="background-color: white;height: 40px;">
  <div id="wrap-view">

    <button mat-button style="padding: unset;">
      <a [routerLink]='[currentRouter]' (click)="selectView(0)" 
        style="text-decoration: blink;" [style.font-weight]="currentViewId == 0 ? '900' : null">Default</a>
    </button>
    <button mat-button *ngFor="let view of  customView; let i = index" style="padding-left: 3px; padding-right: 3px;">
      <a (click)="selectView(view.viewId)" [routerLink]='[currentRouter+ "view/",view.viewId]' 
        style="text-decoration: blink;" [style.font-weight]="view.viewId === currentViewId ? '900' : null">{{view.viewName}}</a>
    </button>
    <!-- <a *ngFor="let view of  customView; let i = index" [routerLink]='[currentRouter,view.viewId]'
      routerLinkActive="active">{{view.viewName}}</a> -->
  </div>
  <span>

    <button type="button" *ngIf="!hideColumnFilter" mat-icon-button title="Columns" [matMenuTriggerFor]="table.columnMenu">
      <i class="fas fa-columns"></i>
    </button>
    <button type="button" *ngIf="!hideClearFilter" mat-icon-button title="Clear Filter" [disabled]="table.isLoading == 1" (click)="onNewClearFilterAction()">
      <i class="fas fa-redo"></i>
    </button>
    <button type="button" mat-icon-button title="View Options" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onClickAddEditView(true)">
        <mat-icon style="color: green;">add</mat-icon>
        <span>Add new view</span>
      </button>
      <button mat-menu-item (click)="onClickAddEditView()" [disabled]="!currentViewId">
        <mat-icon color="primary">edit</mat-icon>
        <span>Edit current view</span>
      </button>

      <button *ngIf="isAdmin ||(!isAdmin &&currentActiveView!=null&& user.nameid==currentActiveView.userId)"
        mat-menu-item (click)="onClickDeleteView()" [disabled]="!currentViewId">
        <mat-icon style="color: red;">delete</mat-icon>
        <span>Delete current view</span>
      </button>
    </mat-menu>
  </span>
</mat-toolbar>
<mat-divider></mat-divider>