import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeLog } from 'src/app/shared/models/request/change-log.model';
import { ChangeLogService } from 'src/app/shared/services/change-log.service';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
  isShowChangeLogs: boolean;
  constructor(
    private dialogRef : MatDialogRef<ChangeLogComponent>,
    private changelogService: ChangeLogService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.changelogs = data.changelogs?? this.changelogs;
   }
   loading = false;
  changelogs: ChangeLog[] = [];
  ngOnInit() {
    if (this.changelogs.length == 0) {
      this.loading = true;
      // fetch old:
      this.changelogService.getAllChangeLogs(this.isShowChangeLogs = false).subscribe(resp => {

        if (resp.result) {

          this.changelogs = resp.result;
          this.loading = false;
        }
      });
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  readOnChangeLogs(){

    this.changelogService.getAllChangeLogs(this.isShowChangeLogs = true).subscribe(resp => {
      if (resp.result) {
        this.changelogs = resp.result;
        this.loading = false;

      }
    });
  }
}
