import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { AltusLocation } from './location';
import { countries } from '../contact/contact-type';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LocationService } from './location.service';
import { NbToastrService } from '@nebular/theme';
import { map, pairwise, startWith, take } from 'rxjs/operators';
import { CountryObject } from 'src/app/shared/models/country-object.model';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private frmBuilder: RxFormBuilder,
    private service: LocationService,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private dynamicContentService: DynamicContentService
  ) {
    this.locationtLst = data.locationtLst ?? this.locationtLst;
    this.profileId = data.profileId ?? this.profileId;
    this.selfGetApi = data.selfGetApi ?? this.selfGetApi;
    this.isDialog = data.isDialog ?? this.isDialog;
    this.isAddMoreBtn = data.isAddMoreBtn ?? this.isAddMoreBtn;
    this.closeDialogEvent = data.closeDialogEvent ? data.closeDialogEvent : null;
    this.isUpdateAction = data.isUpdateAction ?? this.isUpdateAction;
    this.producerId = data.producerId ?? this.producerId;
    this.locationIndex = data.locationIndex ?? this.locationIndex;
    this.readonlyLocation = data.readonlyLocation ?? this.readonlyLocation;
    this.buyerId = data.buyerId ?? this.buyerId;
    this.activeSetPrimary = data.activeSetPrimary ?? this.activeSetPrimary;
    this.isOutPutValue = data.isOutPutValue ?? this.isOutPutValue;
  }

  @Input() locationtLst: AltusLocation[] = [];
  @Input() profileId = '';
  @Input() selfGetApi = true;
  @Input() isAddMoreBtn = true;
  @Input() locationId = '';
  @Input() isUpdateAction = false;
  @Input() producerId = '';
  @Input() buyerId = '';
  @Input() locationIndex;
  // use profileId or locationId (true == profileId)
  @Input() useProfileId = false;
  // flag To get single result
  @Input() isUpdateSingle = false;
  @Input() moreButton = true;
  // is dialog or not
  @Input() isDialog = false;
  @Input() activeSetPrimary: boolean = true;
  @Input() isOutPutValue: boolean = false;

  @Output() passingOutLocationId = new EventEmitter<number>();
  @ViewChild('countryAutocompleteTrigger', { read: MatAutocompleteTrigger }) countryAutoComplete: MatAutocompleteTrigger;
  submitted = false;
  countries: CountryObject[] = null;
  formLocationLst: FormGroup[] = [];
  deletedLocation: AltusLocation[] = [];
  countriesObservable = new Observable<CountryObject[]>();
  stateOptions=[];
  loading = false;
  howFarLst = [
    { key: 'National', value: 'National' },
    { key: 'International', value: 'International' },
    { key: '0 to 100 (hours)', value: '0TO100' },
    { key: '100 to 250 (hours)', value: '100TO250' },
    { key: 'Case-by-case', value: 'CaseByCase' },
  ];
  readonlyLocation = false;
  isChange: boolean = false;

  closeDialogEvent: (result) => void;
  ngOnInit() {
    // if (this.locationtLst.length === 0) {
    //   this.locationtLst.push(new AltusLocation());
    //   this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, this.locationtLst[0]));
    // }

    if (!this.selfGetApi) {
      this.configCountriesDynamic();
      this.getStateDynamicContent();
      return;
    }

    // self get api through flag = true
    if (this.useProfileId) {
      this.service.getLocationById(this.profileId).subscribe(item => {
        this.locationtLst = item.result;
        if (this.locationtLst.length === 0) {
          this.locationtLst.push(new AltusLocation());
          this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, this.locationtLst[0]));
        } else {
          this.locationtLst.forEach(location => {
            this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, location));
          });
        }
      });
    } else {

      this.service.getLocationByLocationId(this.locationId).subscribe(item => {
        if (item.result !== null) {
          this.locationtLst = [...this.locationtLst, item.result];
          this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, this.locationtLst[0]));
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.formLocationLst && this.formLocationLst.length > 0)
      this.formLocationLst.map(x =>
        x.valueChanges.pipe(take(1))
          .subscribe(resp => this.isChange = true))
  }

  private async configCountriesDynamic() {
    // NEW function
    if (this.locationtLst.length === 0)
      this.locationtLst.push(new AltusLocation());

    if (this.locationtLst && this.locationtLst.length > 0) {
      this.locationtLst.map((item, index: number) =>
        this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, item))
      );
    }

    var dataCountries = await this.dynamicContentService.getDynamicContentByType("country").toPromise();
    if (dataCountries.result && dataCountries.result.length > 0)
      this.countries = dataCountries.result.map(item => Object.assign({}, {
        key: item.content,
        value: item.content
      }));
    else this.countries = [];

    if (this.locationtLst && this.locationtLst.length > 0) {
      this.locationtLst = this.locationtLst.map((item, index: number) => {
        item.countryObservable = this.initObservableForCountry(this.formLocationLst[index].get('country'));
        return item;
      });
    }

    // OLD function 
    // if (this.locationtLst.length === 0) {
    //   this.locationtLst.push(new AltusLocation());
    //   this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, this.locationtLst[0]));
    //   // add observable
    //   this.locationtLst[0].countryObservable = this.initObservableForCountry(this.formLocationLst[0].get('country'));
    // } else {
    //   this.locationtLst.forEach(location => {
    //     this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, location));
    //   });
    //   this.locationtLst.forEach((item, index: number) => {
    //     item.countryObservable = this.initObservableForCountry(this.formLocationLst[index].get('country'));
    //   });
    // }
  }

  private _filter(value: string): { key: string, value: string }[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.value.toLowerCase().includes(filterValue));
  }
  // init observable
  initObservableForCountry(control: AbstractControl): Observable<CountryObject[]> {
    return control.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.key),
      map(name => name ? this._filter(name) : this.countries.slice()),
      map(x => x.map(y => y.value)
        .sort(Helper.alphabetically(true, false))
        .map(y => Object.assign({ key: y, value: y }))
      ));
  }
  onAddLocationBtn(): void {
    this.locationtLst.push(new AltusLocation());
    this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, this.locationtLst[this.locationtLst.length - 1]));
    this.locationtLst[this.locationtLst.length - 1].countryObservable =
      this.initObservableForCountry(this.formLocationLst[this.formLocationLst.length - 1].get('country'));
  }
  onDeleteLocationBtn(key: number = null): void {
    if (key != null) {
      // if delete item is available in db ==> then move it to deleted array in order to set flag deleted to true

      console.log(this.formLocationLst[key].value);
      if (this.formLocationLst[key].get('locationId').value !== null) {
        this.formLocationLst[key].setValue({
          ...this.formLocationLst[key].value,
          deleted: true,
        });
        this.deletedLocation.push(this.locationtLst[key]);
      }
      this.locationtLst.splice(key, 1);
      this.formLocationLst.splice(key, 1);
      return;
    }
    this.locationtLst.pop();
    this.formLocationLst.pop();
  }

  trackByFn(index, item) {
    return index;
  }
  onSaveBtn(): void {
    this.loading = !this.loading;
    this.submitted = true;
    let errorMsg = 'Save location Fail! at: ';
    // mark ok flag
    let isValidflag = true;
    this.formLocationLst.forEach((form, index: number) => {
      if (!form.valid) {
        form.markAllAsTouched();
        errorMsg += `Location: ${index + 1}, `;
        isValidflag = false;
        return false;
      }
    });

    if (!isValidflag) {
      this.toast.danger(`${errorMsg}`, 'Fail');
      this.loading = !this.loading;
      return;
    }
    this.formLocationLst.forEach(item => { item.disable(); });
    const locationFromFrom = this.formLocationLst.map(item => item.value);
    let locationToSave;
    if (this.deletedLocation.length > 0) {
      locationToSave = [...locationFromFrom];
      this.deletedLocation.forEach(item => {
        item.deleted = true;
        locationToSave.push(item);
      });
    } else {
      locationToSave = locationFromFrom;
    }

    // output value to parent component and change logic
    if (this.isOutPutValue) {
      this.closeDialogEvent({ isRefresh: true, value: locationToSave });
      return;
    }

    this.service.saveLocation(locationToSave, this.profileId, this.producerId, this.buyerId, this.isUpdateAction).subscribe(
      item => {
        this.locationtLst = item.result;
        this.formLocationLst = [];
        this.deletedLocation = [];
        this.locationtLst.forEach(location => {
          this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, location));
        });
        this.toast.success('Save locations successfully!', "Success");
        if (this.passingOutLocationId !== undefined) {
          if (this.locationtLst.length > 0) {
            this.passingOutLocationId.emit(this.locationtLst[0].locationId);
          }
        }
        this.loading = !this.loading;
        // this.passingOutLocationId.emit()
        // check if it a dialog or not?
        if (this.isDialog) {
          this.closeDialog(true);
        }
      }
    );
    this.formLocationLst.forEach(item => { item.enable(); });

  }
  setPrimaryLocation(index: number): void {
    this.formLocationLst.forEach(contactLocation => contactLocation.setValue({ ...contactLocation.value, primaryLocation: false }));
    this.formLocationLst[index].setValue({
      ...this.formLocationLst[index].value,
      primaryLocation: true
    });
  }
  removePrimaryLocation(index: number): void {
    this.formLocationLst[index].setValue({
      ...this.formLocationLst[index].value,
      primaryLocation: false
    });
  }

  closeDialog(result: boolean = false): void {
    if (this.isChange && !result) {
      this.isChange = false;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "Do you wish to close this popup? You will lose your unsaved data."
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) this.closeDialogEvent(result);
        else this.isChange = true;
      });
      return;
    }

    this.closeDialogEvent(result);
  }

  setWillTravel(state: boolean, index: number): void {
    console.log(state);
    this.formLocationLst[index].setValue({ ...this.formLocationLst[index].value, willTravel: state });
  }

  duplicate(formInstance: FormGroup): void {
    const newDuplicate = { ...formInstance.value, primaryLocation: false } as AltusLocation;
    this.locationtLst.push(newDuplicate);
    this.formLocationLst.push(this.frmBuilder.formGroup(AltusLocation, this.locationtLst[this.locationtLst.length - 1]));
    this.locationtLst[this.locationtLst.length - 1].countryObservable =
      this.initObservableForCountry(this.formLocationLst[this.formLocationLst.length - 1].get('country'));
  }

  displayHowFar(index: number): string {
    return this.howFarLst[index].key;
  }
  setHowFar(output: { value: string, index: number }): void {
    console.log(output);
    const { value, index } = { ...output };
    this.formLocationLst[index].setValue({ ...this.formLocationLst[index].value, howFar: value });
  }
  // disable value howFar
  disableHowFar(isChecked: boolean, index: number): void {
    if (!isChecked) {
      this.formLocationLst[index].setValue({ ...this.formLocationLst[index].value, howFar: null });
    }
  }
  checkCountryMatchOnBlur(formInstance: FormGroup): void {
    // check if in array
    if (formInstance.get('country').value) {
      const valueToCheck = formInstance.get('country').value.toLowerCase().trim();
      if (this.countries.find(c => c.value.toLowerCase().includes(valueToCheck)) === undefined) {
        formInstance.setValue({ ...formInstance.value, country: '' });
      } else {
        formInstance.setValue(
          { ...formInstance.value, country: this.countries.find(c => c.value.toLowerCase().includes(valueToCheck)).value });
      }
    }
  }
  // 2022-01-27 tienlm add start
  countrySelectChange(selected: MatAutocompleteSelectedEvent) {
    debugger;
    console.log(selected);
  }

  // 2022-01-27 tienlm add end
  async getStateDynamicContent() {
    try {
      const data = await this.dynamicContentService.getDynamicContentByType("location_state").toPromise();
      if(data.result) this.stateOptions = data.result;
    }
    catch(ex) {
      console.log(ex);
    }
  }
  
}
