import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { Helper } from '../utility/Helper';

@Directive({
  selector: '[appNgxDataTableEmpty]'
})
export class NgxDataTableEmptyDirective implements AfterViewInit, AfterViewChecked {

  private datatableBodyEl: HTMLElement;
  private datatableHeaderEl: HTMLElement;
  private datatableEmptyRowEl: HTMLElement;
  private datatableRowCenterEl: HTMLElement;
  private datatableRowLeftEl: HTMLElement;

  constructor(private renderer: Renderer2, private hostElRef: ElementRef) { }

  public ngAfterViewInit() {
      this.setUpElementReferences();
      if (this.shouldApplyHack) {
          this.hackEmptyCellWidth();
          this.setUpHeaderScrollListener();
      }
  }

  public ngAfterViewChecked() {
      if (this.shouldApplyHack) {
          this.hackEmptyCellWidth();
      }
  }

  private setUpHeaderScrollListener() {
      this.renderer.listen(this.datatableBodyEl, "scroll", (event: any) => {
          if (this.shouldApplyHack) {
              this.datatableHeaderEl.scrollLeft = event.srcElement.scrollLeft;
          }
      });
  }

  private hackEmptyCellWidth() {
      const dataRowCenter = parseInt(this.datatableRowCenterEl.style.width);
      const dataRowLeft = parseInt(this.datatableRowLeftEl.style.width);
      const newWidth = (dataRowCenter + dataRowLeft) + 'px'
      this.renderer.setStyle(this.datatableEmptyRowEl, "width", newWidth);
  }

  private setUpElementReferences() {
      const hostEl = this.hostElRef.nativeElement;
      this.datatableBodyEl = hostEl.getElementsByClassName("datatable-body")[0];
      this.datatableHeaderEl = hostEl.getElementsByClassName("datatable-header")[0];
      this.datatableEmptyRowEl = hostEl.getElementsByClassName("empty-row")[0];
      this.datatableRowCenterEl = hostEl.getElementsByClassName("datatable-row-center")[0];
      this.datatableRowLeftEl = hostEl.getElementsByClassName("datatable-row-left")[0];

  }

  private get shouldApplyHack(): boolean {
      const hostEl = this.hostElRef.nativeElement;
      this.datatableEmptyRowEl = hostEl.getElementsByClassName("empty-row")[0];
      return !!this.datatableEmptyRowEl;
  }

}
