import { fromEvent, Observable, Subject } from 'rxjs';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyPairsValue, ProfileDetailModel } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { ProducerService } from '../../../producer.service';
import { ProducerProfile } from '../../../producer-model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-add-existing-profile',
  templateUrl: './add-existing-profile.component.html',
  styleUrls: ['./add-existing-profile.component.scss']
})
export class AddExistingProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myInput') input: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;
  @Input() id: string = '';
  @Input() isProfile = false;
  @Input() tooltipProp: KeyPairsValue[] = [];
  @Input() label: string = "Profile";
  @Input() placeholder: string = null;
  @Input() apiSearch: (data) => Observable<ReturnResult<ProfileDetailModel[]>> = null;
  @Input() isListenEvent: boolean = false;
  // Flag to display first suggest mode required
  @Input() firstSuggest: boolean = true;
  // Search API Output expected: Observable<ReturnResult<any[]>> or Observable<any[]>
  @Input() firstSuggestAPI: Observable<any>;
  // display on Search mat-option
  @Input() displayProp: string;
  //  search Array: Leave empty if passing API and otherwise
  @Input() searchArray: any[] = [];
  @Input() idProp: string = "profileId";
  loading = false;
  profileList: ProfileDetailModel[] = [];

  selectedProfile: ProfileDetailModel;
  saveLoading: false;

  profileFormGroup = this.frmBuilder.group({
    profileFormInput: ''
  });
  findItemByKey = Helper.findItemByKey;
  private _destroy: Subject<void> = new Subject<void>();

  constructor(private producerService: ProducerService,
    private profileService: ProfileService,
    public dialModalRef: MatDialogRef<AddExistingProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private frmBuilder: FormBuilder) {
    this.id = data.id;
    this.isProfile = data.isProfile;
    this.tooltipProp = data.tooltipProp ?? this.tooltipProp;
    this.placeholder = data.placeholder ?? this.placeholder;
    this.label = data.label ?? this.label;
    this.apiSearch = data.apiSearch ?? this.apiSearch;
    this.isListenEvent = data.isListenEvent ?? this.isListenEvent;
    this.firstSuggest = data.firstSuggest ?? this.firstSuggest;
    this.firstSuggestAPI = data.firstSuggestAPI ?? this.firstSuggestAPI;
    this.displayProp = data.displayProp ?? this.displayProp;
    this.searchArray = data.searchArray ?? this.searchArray;
    this.idProp = data.idProp ?? this.idProp;
  }
  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit(): void {
    // this.dialModalRef.updateSize('40%', '60%');
    if (this.firstSuggest) {
      this.loading = true;
      this.firstSuggestAPI.pipe(takeUntil(this._destroy), map(x => {
        if (x.result) {
          return x.result.filter(x => x[this.idProp] != this.id);
        }
        return x.filter(x => x[this.idProp] != this.id);
      })).subscribe((res: any[]) => {
        if (res) {
          this.searchArray = res;
          this.loading = false;
          setTimeout(() => {
            this.inputAutoComplete.closePanel();
            this.inputAutoComplete.openPanel();
          }, 100);
        }
      });
    } else if (this.searchArray.length > 0) {
      // already passing an array of results:

    }
  }
  searchProfile(data: string) {
    if (this.firstSuggest) {
      if (data.length >= 0 && data.length < 3) {
        this.profileList = [];
        return;
      }
    }

    if (data === "") {
      const emptyProfile = new ProfileDetailModel();
      emptyProfile.displayName = "Not found";
      this.profileList = [emptyProfile];
      return;
    }

    //dynamic api search with shadow profile.
    if (this.apiSearch) {
      this.loading = true;
      this.apiSearch(data).subscribe({
        next: res => {
          if (res.result != null) {
            if (res.result.length !== 0)
              this.profileList = res.result;
            else {
              const emptyProfile = new ProfileDetailModel();
              emptyProfile.displayName = "Not found";
              this.profileList = [emptyProfile];
            }
          }
        },
        complete: () => this.loading = false,
      });
      return;
    }

    this.producerService.searchProfile(data);
    this.producerService.getProfileList().subscribe((res) => {
      if (res.result != null) {
        if (res.result.length !== 0)
          this.profileList = res.result;
        else {
          const emptyProfile = new ProfileDetailModel();
          emptyProfile.displayName = "Not found";
          this.profileList = [emptyProfile];
        }
      }
    });
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, "keyup").pipe(
      //get value in the input
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 2
      filter(res => res.length > 2 || res.length === 0),
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((keyword: string) => {
      //subscribe to keyword to get API
      this.searchProfile(keyword);
    });
  }
  clearSaleAccount() {
    this.profileFormGroup.controls.profileFormInput.setValue("");
    this.selectedProfile = undefined;
  }
  showSelectedProfile(data: string) {
    this.selectedProfile = undefined;

    if (this.profileList && this.profileList.length > 0 && !this.selectedProfile)
      this.selectedProfile = this.profileList.find(x => x.displayName == data);

    // if first suggest being selected: 
    if (this.firstSuggest && this.searchArray && this.searchArray?.length > 0 && !this.selectedProfile) {
      this.selectedProfile = this.searchArray.find(x => x.displayName == data);
    }
  }

  closeDialog(isRefreshing: boolean) {
    this.dialModalRef.close(isRefreshing);
  }

  saveProfile() {
    //output event to parent component
    if (this.isListenEvent) {
      this.dialModalRef.close(this.selectedProfile)
      return;
    }

    //save relationship function.
    if (this.selectedProfile) {
      if (this.isProfile) {
        this.profileService.addProfileRelationship(this.id, this.selectedProfile?.profileId).subscribe((res: ReturnResult<boolean>) => {
          if (res.result === true)
            this.closeDialog(res.result);
        });
      }
      else {
        let profileProducer: ProducerProfile = {
          producerId: this.id,
          profileId: this.selectedProfile.profileId
        }
        this.producerService.addProducerToProfile(profileProducer).subscribe((res: ReturnResult<boolean>) => {
          if (res.result === true)
            this.closeDialog(res.result);
        });
      }

    }
  }
}
