import { PrimasDropdownFilterOperator } from 'src/app/shared/enums/primas-dropdown-filter-operator';
import { PrimasDatetimeFilterOperator } from './../enums/primas-datetime-filter-operator';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbToastrService } from '@nebular/theme';
import * as FileSaver from 'file-saver';
import { DataField } from 'src/app/modules/admin/data-field-management/data-field-model';
import { Contact, KeyPairsValue, ProfileContact } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { PrimasAllFilterOperator } from '../enums/primas-all-filter-operator';
import { PrimasNumberFilterOperator } from '../enums/primas-number-filter-operator';
import { PrimasTextFilterOperator } from '../enums/primas-text-filter-operator';
import { PrimasFilterType } from '../enums/primas-value-type.enum';
import { SortOrderType } from '../enums/sort-order-type.enum';
import { OrderMapping } from '../models/order-mapping';
import { FilterMapping } from '../models/paging/filter-mapping';
import { BuyerContacts } from '../models/buyer.model';
import { ObjectValue } from '../components/stand-alone-component/inline-edit-object/inline-edit-object.component';
import { filter } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router, Routes } from '@angular/router';
import { AdminTabModeService } from '../components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { Page } from '../models/paging/page';
import { ColumnMapping } from '../models/column-mapping';
import { TaskFilterProp } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';

export class Helper {
    static configurationUrlSuffixRegex = /(configuration\/)\w+(-?\w+)/;
    static _memoryPath: string[] = [];
    static downloadFile(data) {
        let byteCharacters = atob(data.fileContents);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            let slice = byteCharacters.slice(offset, offset + 512);

            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const file = new Blob(byteArrays, { type: data.contentType });
        FileSaver.saveAs(file, data.fileDownloadName);
    }
    static formatFilter(filter: FilterMapping[], prop, value, type: PrimasFilterType = PrimasFilterType.Text, operator, mappingFilter: ColumnMapping | FilterMapping = null): FilterMapping[] {
        if (filter == null) {
            filter = [];
        }
        if (operator == undefined) {
            switch (type) {
                case PrimasFilterType.DateTime:
                case PrimasFilterType.Date: operator = PrimasDatetimeFilterOperator.IsBefore
                case PrimasFilterType.Number: operator = PrimasNumberFilterOperator.IsEqualTo
                    break;
                default: operator = PrimasTextFilterOperator.Contains
                    break;
            }

        }
        let dynamicProperty = mappingFilter?.dynamicProperty ?? null;
        let delimiter = mappingFilter?.delimiter ?? null;
        let exist = filter.find(x => x.prop == prop);
        if (exist != null) {
            if ((value == null || value?.length <= 0) && (operator != PrimasAllFilterOperator.IsEmpty
                && operator != PrimasAllFilterOperator.IsNotEmpty)) {
                filter = filter.filter(x => x.prop != prop);
            } else {
                exist.value = value;
                exist.filterOperator = this.splitSpaceString(operator);
                exist.dynamicProperty = dynamicProperty ?? undefined;
                exist.delimiter = delimiter ?? undefined;
            }
        } else {
            switch (type) {
                case PrimasFilterType.Text:
                case PrimasFilterType.DropDown:
                case PrimasFilterType.DropDownList:
                case PrimasFilterType.DynamicContent:
                case PrimasFilterType.Number:
                case PrimasFilterType.Boolean:
                case PrimasFilterType.MailActions:
                case PrimasFilterType.JsonString:
                case PrimasFilterType.JsonNumber:

                    if ((value && value.length > 0)
                        || operator == PrimasAllFilterOperator.IsEmpty
                        || operator == PrimasAllFilterOperator.IsNotEmpty) {
                        filter.push({
                            prop: prop,
                            value: value,
                            filterType: type,
                            filterOperator: this.splitSpaceString(operator),
                            dynamicProperty, delimiter
                        })
                    }
                    break;
                case PrimasFilterType.DateTime:
                    if (value != null
                        || operator == PrimasAllFilterOperator.IsEmpty
                        || operator == PrimasAllFilterOperator.IsNotEmpty) {
                        filter.push({
                            prop: prop,
                            value: value,
                            filterType: type,
                            filterOperator: this.splitSpaceString(operator),
                        })
                    }
                    break;
                case PrimasFilterType.Date:
                    if (value != null
                        || operator == PrimasAllFilterOperator.IsEmpty
                        || operator == PrimasAllFilterOperator.IsNotEmpty) {
                        filter.push({
                            prop: prop,
                            value: value,
                            filterType: type,
                            filterOperator: this.splitSpaceString(operator),
                            dynamicProperty, delimiter
                        })
                    }
                    break;
            }
        }
        // console.log(filter);
        return filter;
    }

    static formatOrder(orders: OrderMapping[], prop, value, item = null): OrderMapping[] {
        if (orders == null) {
            orders = [];
        }
        let exist = orders.find(x => x.sort == prop);
        if (exist != null) {
            if (value == null || value?.length <= 0) {
                orders = orders.filter(x => x.sort != prop);
            } else {
                exist.sort = prop;
                exist.sortDir = value == "asc" || value == 0 ? SortOrderType.ASC : SortOrderType.DESC;
            }
        } else {
            if (value != null && value.length > 0) {
                orders.push({
                    sort: prop,
                    sortDir: value == "asc" || value == 0 ? SortOrderType.ASC : SortOrderType.DESC,
                    dynamicProperty: item ? item.filter.dynamicProperty : null,
                    dataType: item ? item.filter.dataType : null
                });
            }
        }
        // console.log(orders);
        return orders;
    }

    static arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    static splitSpaceString(str: string) {
        return str?.replace(/\s/g, "") || str;
    }

    static createSpaceString(str: string) {
        if (str == null || str == undefined)
            return null
        return str.match(/[A-Z][a-z]+/g)?.join(" ") || str;
    }

    static selectColumnGrid(isOpenDialog: boolean, widthScreen: number) {
        widthScreen = isOpenDialog ? widthScreen - 700 : widthScreen;
        return (widthScreen >= 1400) ? 3 : (widthScreen <= 900) ? 1 : 2;
    }

    static selectSizeElementGrid(isOpenDialog: boolean, widthScreen: number, cols: number) {
        widthScreen = isOpenDialog ? widthScreen - 700 : widthScreen;
        return cols !== 1 ? "3:2" : (widthScreen >= 400) ? "2:1" : "3:2";
    }
    static getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    //2022-03-22 vuonglqn add start
    static convertEnumToArray(enumObject: any) {
        var arrayObjects = []

        for (const [propertyKey, propertyValue] of Object.entries(enumObject)) {
            if (!Number.isNaN(Number(propertyKey))) {
                continue;
            }
            arrayObjects.push({ id: propertyValue, name: propertyKey });
        }

        return arrayObjects;
    }

    static getIdEnumTypeEnumString(enumObject: any, value: any, startIndex: number = 0) {
        var result = -1;
        var index = startIndex;

        for (const [propertyKey, propertyValue] of Object.entries(enumObject)) {
            if (!Number.isNaN(Number(propertyKey))) {
                continue;
            }
            if (propertyValue == value) {
                result = index;
                break;
            }
            index++;
        }

        return result;
    }
    //2022-03-22 vuonglqn add end

    //2022-03-27 vuonglqn add start
    static alphabetically(ascending, isCompareLowerAndUpper = true) {
        return function (a, b) {
            if (!isCompareLowerAndUpper) {
                a = a.toLowerCase();
                b = b.toLocaleLowerCase();
            }

            // equal items sort equally
            if (a === b) {
                return 0;
            }
            // nulls sort after anything else
            else if (a === null) {
                return 1;
            }
            else if (b === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a < b ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a < b ? 1 : -1;
            }
        };
    }
    //2022-03-27 vuonglqn add end

    static displayNameProp(name: string, isBoolean: boolean = false, toLower: boolean = false) {
        var resultName = '';
        //logic tienlm
        if (!isBoolean) {
            if (name.length > 0) {
                let newName = name;
                newName = (!toLower ? newName[0].toUpperCase() : newName[0].toLowerCase()) + newName.substr(1);
                resultName = newName.replace(/([A-Z])/g, ' $1').trim();
            } else resultName = name;
        } else {
            if (name.length > 0) {
                let newName = name;
                newName = (!toLower ? newName[0].toUpperCase() : newName[0].toLowerCase()) + newName.substr(1);
                let formatedNewName = newName.replace(/([A-Z])/g, ' $1').trim();
                if (formatedNewName.toLowerCase().includes('is ')) {
                    resultName = formatedNewName.split('Is')[1]?.trim();
                } else {
                    resultName = formatedNewName;
                }
            } else resultName = name;
        }
        //end logic tienlm

        //logic vuonglqn
        if (resultName.length > 0) {
            resultName = resultName.split('.').pop().trim();
        }

        return resultName;
    }


    static convertArrayToObject = (array: any[], key?) => {
        const initialValue = {};
        return array.reduce((obj, item, index) => {
            return key
                ? { ...obj, [item[key]]: item, }
                : { ...obj, [index]: item };
        }, initialValue);
    };

    static isEmptyOrSpaces(str): boolean {
        return str === null || str.match(/^ *$/) !== null;
    }

    static mapArrayToFormat(formatData: any, arr: any[], arrInsert?: KeyPairsValue[]) {
        return arr.map(val => {
            var newVal = {};
            //Format list data exited to new format;
            Object.keys(formatData).forEach(key => {
                newVal[key] = val[formatData[key]];
            });

            //Insert new data to object.
            if (arrInsert && arrInsert.length > 0) {
                arrInsert.forEach(item => {
                    newVal[item.key] = item.value;
                });
            }

            return newVal;
        })
    }

    static makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    static isNullOrEmpty(value: string) {
        return (!value || value == undefined || value == "" || value.length == 0);
    }

    static toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    static async FileToBase64(file) {
        try {
            const result = await this.toBase64(file);
            return result
        } catch (error) {
            console.error(error);
            return;
        }
    }
    static roughScale(x, base) {
        const parsed = parseInt(x, base);
        if (isNaN(parsed)) { return 0; }
        return parsed * 100;
    }
    static getFileExtension(fileName: string) {
        return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
    }

    static getMediaUrl(externalUrl: string, internalUrl: string, defaultMedia: string, key: string = "BOF_IMG_SOURCE") {
        var result = "";
        try {
            var decodeData = window.localStorage.getItem(key);
            var keyValue = atob(decodeData);
            if (this.isNullOrEmpty(keyValue) && this.isEmptyOrSpaces(keyValue))
                result = defaultMedia;

            if (keyValue == "S3") result = (externalUrl ?? internalUrl) ?? defaultMedia;
            else if (keyValue == "NOT S3") result = internalUrl ?? defaultMedia;
            else result = defaultMedia;
        }
        catch (ex) {
            result = defaultMedia;
        }

        return result;
    }

    static getLastNameUrl(urlStr: string, limitLength: number = 0, isExtension: boolean = false) {
        var nameUrl = ""
        var extensionUrl = "";
        var limitStr = "";
        try {
            if (urlStr && urlStr.length > 0) {
                var replaceUrl = urlStr.slice().replace(/\\/g, "/");
                var splitUrl = replaceUrl.split("/");
                if (splitUrl && splitUrl.length > 0)
                    nameUrl = splitUrl[splitUrl.length - 1];

                if (limitLength > 0) {
                    limitStr = nameUrl.slice(0, limitLength) + "...";
                }

                if (isExtension) {
                    var splitNameUrl = nameUrl.split(".");
                    if (splitNameUrl && splitNameUrl.length > 1) {
                        extensionUrl = splitNameUrl[splitNameUrl.length - 1];
                    }
                }

                if (limitLength > 0) nameUrl = limitStr;
                if (isExtension) nameUrl += extensionUrl;
            }
        } catch (ex) { }
        return nameUrl;
    }


    static getExtensionUrl(urlStr) {
        var extensionUrl = ""
        try {
            if (urlStr && urlStr.length > 0) {
                var splitUrl = urlStr.split(".");
                if (splitUrl && splitUrl.length > 1)
                    extensionUrl = splitUrl[splitUrl.length - 1];
            }
        } catch (ex) { }
        return extensionUrl;
    }

    static toUpperCaseFirstLetter(str: string) {
        try {
            str = str.charAt(0).toUpperCase() + str.slice(1);
        } catch (ex) { }
        return str;
    }

    //Use in edit for compare data if it changed or not when user is typing to enable a save button
    static isDataChange(before, after): boolean {
        var isChange: boolean = false;
        if (before && after) {
            Object.keys(after).forEach((key: string) => {
                before[key] = before[key] ?? "";
                after[key] = after[key] ?? "";
                if (String(before[key]) != String(after[key])) {
                    isChange = true;
                }
            });
        }
        return isChange;
    }
    static filterClientSidePaging<T>(filters: FilterMapping[], data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filters && filters.length > 0)) {
                filters.forEach(filter => {
                    switch (filter.filterType) {
                        case PrimasFilterType.Text:
                            data = Helper.filterStringClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.Number:
                            data = Helper.filterNumberClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.DropDown:
                            data = Helper.filterDropDownClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.DateTime:
                            data = Helper.filterDateTimeClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.Date:
                            data = Helper.filterDateClientSidePaging(filter, data);
                            break;
                    }
                });

                result = [...data.slice()];
            }
        }
        catch (ex) { }
        return result;
    }

    static filterStringClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value) && !Helper.isEmptyOrSpaces(filter.value))) {
                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasTextFilterOperator.Contains):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase().includes(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.DoesNotContains):
                        data = data.slice().filter(x => !x[filter.prop].toLowerCase().includes(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.StartsWith):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase().startsWith(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.EndsWith):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase().endsWith(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.IsEqualTo):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase() == filter.value.toLowerCase());
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.IsNotEqualTo):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase() != filter.value.toLowerCase());
                        break;
                }
            }
            else {
                data = this.filterAllClientSide(filter, data);
            }

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterNumberClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value) && !Helper.isEmptyOrSpaces(filter.value))) {
                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) == parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsNotEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) != parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsGreaterThanOrEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) >= parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsGreaterThan):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) > parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsLessThanOrEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) <= parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsLessThan):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) < parseInt(filter.value));
                        break;
                }
            }

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterDropDownClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value))) {
                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasDropdownFilterOperator.Contains):
                        data = data.slice().filter((x) => {
                            let check: boolean = false;
                            filter.value.map((filterValue) => {
                                if (x[filter.prop] == filterValue) check = true;
                            })
                            return check;
                        })
                        break;
                    case Helper.splitSpaceString(PrimasDropdownFilterOperator.DoesNotContains):
                        filter.value.map((filterValue) => {
                            data = data.slice().filter(x => x[filter.prop] != filterValue);
                        })
                        break;
                }
            }

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterDateTimeClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value))) {
                let dataCheck = String(filter.value);
                let startTime: number = -1;
                let endTime: number = -1
                dataCheck = dataCheck.substring(0, dataCheck.length - 3).replace('T', ' ');

                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfter):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue > dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfterOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue >= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBefore):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue < dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBeforeOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue <= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue == dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsNotEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue != dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.Between):
                        const timeQuery = JSON.parse(filter.value);
                        let startDate = timeQuery.startDate.substring(0, dataCheck.length - 3).replace('T', ' ');
                        let endDate = timeQuery.endDate.substring(0, dataCheck.length - 3).replace('T', ' ');
                        startTime = new Date(String(startDate)).getTime();
                        endTime = new Date(String(endDate)).getTime();

                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            return startTime <= dateValue && dateValue <= endTime;
                        })
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.Quarter):
                        const currentYear = new Date().getFullYear();
                        let queryTime = []
                        for (let value of filter.value) {
                            switch (value) {
                                case '1':
                                    startTime = new Date(currentYear, 1, 1).getTime()
                                    endTime = new Date(currentYear, 3, 31, 23, 59, 59).getTime();
                                    break;
                                case '2':
                                    startTime = new Date(currentYear, 4, 1).getTime()
                                    endTime = new Date(currentYear, 6, 30, 23, 59, 59).getTime();
                                    break;
                                case '3':
                                    startTime = new Date(currentYear, 7, 1).getTime()
                                    endTime = new Date(currentYear, 9, 30, 23, 59, 59).getTime();
                                    break;
                                case '4':
                                    startTime = new Date(currentYear, 10, 1).getTime()
                                    endTime = new Date(currentYear, 12, 31, 23, 59, 59).getTime();
                                    break;
                            }
                            queryTime.push({ startTime: startTime, endTime: endTime })
                        }
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let checkedData: boolean = false;
                            queryTime.forEach((data) => {
                                if (data.startTime <= dateValue && dateValue <= data.endTime) checkedData = true;
                            })
                            return checkedData;
                        })
                        break;
                }
            }
            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterDateClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value))) {
                let dataCheck = String(filter.value);
                let startTime: number = -1;
                let endTime: number = -1
                dataCheck = dataCheck.substring(0, dataCheck.length - 3).replace('T', ' ');
                // console.log(dataCheck);

                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfter):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime();
                            let dateCheck = new Date(new Date(String(dataCheck)).toDateString()).getTime();
                            return dateValue > dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfterOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime();
                            let dateCheck = new Date(new Date(String(dataCheck)).toDateString()).getTime();
                            return dateValue >= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBefore):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime();
                            let dateCheck = new Date(new Date(String(dataCheck)).toDateString()).getTime();
                            console.log(dateValue, dateCheck)
                            return dateValue < dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBeforeOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime();
                            let dateCheck = new Date(new Date(String(dataCheck)).toDateString()).getTime();
                            return dateValue <= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime()
                            let dateCheck = new Date(new Date(String(dataCheck)).toDateString()).getTime()
                            return dateValue === dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsNotEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime();
                            let dateCheck = new Date(new Date(String(dataCheck)).toDateString()).getTime();
                            return dateValue != dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.Between):
                        const timeQuery = JSON.parse(filter.value);
                        let startDate = timeQuery.startDate.substring(0, dataCheck.length - 3).replace('T', ' ');
                        let endDate = timeQuery.endDate.substring(0, dataCheck.length - 3).replace('T', ' ');
                        startTime = new Date(new Date(String(startDate)).toDateString()).getTime();
                        endTime = new Date(new Date(String(endDate)).toDateString()).getTime();

                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime();
                            return startTime <= dateValue && dateValue <= endTime;
                        })
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.Quarter):
                        const currentYear = new Date().getFullYear();
                        let queryTime = []
                        for (let value of filter.value) {
                            switch (value) {
                                case '1':
                                    startTime = new Date(new Date(currentYear, 0, 1).toDateString()).getTime()
                                    endTime = new Date(new Date(currentYear, 2, 31).toDateString()).getTime()
                                    break;
                                case '2':
                                    startTime = new Date(new Date(currentYear, 3, 1).toDateString()).getTime()
                                    endTime = new Date(new Date(currentYear, 5, 30).toDateString()).getTime()
                                    break;
                                case '3':
                                    startTime = new Date(new Date(currentYear, 6, 1).toDateString()).getTime()
                                    endTime = new Date(new Date(currentYear, 8, 30).toDateString()).getTime()
                                    break;
                                case '4':
                                    startTime = new Date(new Date(currentYear, 9, 1).toDateString()).getTime()
                                    endTime = new Date(new Date(currentYear, 11, 3).toDateString()).getTime()
                                    break;
                            }
                            queryTime.push({ startTime: startTime, endTime: endTime })
                        }
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(new Date(String(x[filter.prop])).toDateString()).getTime();
                            let checkedData: boolean = false;
                            queryTime.forEach((data) => {
                                console.log(data.startTime, dateValue, data.endTime, new Date(String(x[filter.prop])).toDateString());
                                if (data.startTime <= dateValue && dateValue <= data.endTime) checkedData = true;
                            })
                            return checkedData;
                        })
                        break;
                }
            }
            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static getColumnsPermission(columns: any[]) {
        var result = [];
        try {
            let accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
                const helper = new JwtHelperService();
                const decodedToken = helper.decodeToken(accessToken);
                var permissions = JSON.parse(decodedToken['permission']) ?? {};
                if (permissions && permissions[Object.keys(permissions)[0]]['view'].length > 0)
                    result = [...columns.filter(x =>
                        permissions[Object.keys(permissions)[0]]['view'].includes(x.permissionColumn) ||
                        !x.hasOwnProperty('permissionColumn'))];
            }
        } catch (ex) {

        }

        return result;
    }

    static checkPermission(permission: string) {
      try{
        let accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(accessToken);
          var permissions = JSON.parse(decodedToken['permission']) ?? {};
          let listPerm = [];
          if (permissions && permissions[Object.keys(permissions)[0]]['view'].length > 0) {
            Object.keys(permissions).forEach(role => {
              let rolePermissions = permissions[role]['view'];
              listPerm = [...listPerm, ...rolePermissions]
            })
          }
          return listPerm.includes(permission);
        }
      }
      catch (ex){}
    }

    static copyRemoveHeap(input: any) {
        return JSON.parse(JSON.stringify(input));
    }

    static formatDisplayColumn(columns: any[], displayColumns: DataField[] = [], propCompare: string = 'prop', propChange: string = 'name'): any[] {
        var result = columns;
        try {
            if (columns != null && columns.length > 0 && displayColumns.length > 0) {
                var sliceDisplayColumns = [...Helper.copyRemoveHeap(displayColumns)];
                sliceDisplayColumns.forEach(x => {
                    var arraySplit = x.dataFieldKey.split(".");
                    if (arraySplit != null && arraySplit.length > 0) {
                        x.dataFieldKey = arraySplit.slice(1, arraySplit.length).join(".");
                    }
                });

                var mapColumn = columns.filter(x => sliceDisplayColumns.map(y => y.dataFieldKey.toLocaleLowerCase())
                    .includes(x[propCompare].toLocaleLowerCase()));

                if (mapColumn != null && mapColumn.length > 0) {
                    mapColumn.forEach(x =>
                        x[propChange] = sliceDisplayColumns.find(y =>
                            y.dataFieldKey.toLocaleLowerCase() == x[propCompare].toLocaleLowerCase()
                        ).displayData ?? x[propChange])
                }

                result = [...columns];
            }
        }
        catch (ex) {
            console.log(ex);
        }

        return result;
    }

    static findItemByKey(array: KeyPairsValue[], key: string, propFind: string = 'key') {
        var result: string = null;
        try {
            if (array != null && array.length > 0)
                result = array.find(x => x[propFind].toLocaleLowerCase() == key.toLocaleLowerCase())?.value ?? "";
        } catch (ex) {
            console.log(ex);
        }
        return result;
    }
    static bindingImportWithImportProp(columnsImport: any[]) {
        columnsImport = JSON.parse(JSON.stringify(columnsImport));
        // 2022-09-07 tienlm add start
        columnsImport = columnsImport.map(item => {
            if (item?.importProp) {
                item.prop = item.importProp;
            }
            return item;
        });
        return columnsImport;
        // 2022-09-07 tienlm add end
    }

    static trimUserPhoneNumber(phone: string): string {
        // tslint:disable-next-line:prefer-const
        let newPhone = phone;
        newPhone = newPhone = newPhone.trim();
        newPhone = newPhone.replace(' ', '');
        newPhone = newPhone.replace('+', '');
        newPhone = newPhone.replace('-', '');
        newPhone = newPhone.replace('(', '');
        newPhone = newPhone.replace(')', '');
        newPhone = newPhone.replace('.', '');
        return newPhone;
    }
    static isDateString(s: string): boolean {
        if (!s) {
            return false;
        }
        if (isNaN(Date.parse(s))) {
            return false;
        }
        return true;
    }
    static userURL(data): string {
        if (data)
            return data.replace(/\\/g, '/');
        else null;
    }
    static checkHasEmail(entity: string, contact: Contact, contactLst: ProfileContact[] = [], buyerContacts: BuyerContacts[] = []) {
        if (contact) {
            return contact.contactEmail ? true : (contact.secondaryEmail ? true : false);
            return;
        }
        if (entity == 'profile') {
            if (contactLst.length > 0) {
                let alternativeEmail = contactLst[0];
                return alternativeEmail.contact?.contactEmail ? true : (alternativeEmail.contact?.secondaryEmail ? true : false);
            } else if (!contact && contactLst.length == 0) {
                return false;
            }
        } else if (entity == 'buyer') {
            if (buyerContacts.length > 0) {
                let alternativeEmail = buyerContacts[0];
                return alternativeEmail.contact?.contactEmail ? true : (alternativeEmail.contact?.secondaryEmail ? true : false);
            } else if (!contact && contactLst.length == 0) {
                return false;
            }
        }
    }
    static cancelNavigate(replaceUrl: string) {
        window.history.pushState({}, '', window.location.href);
        window.history.replaceState({}, '', `${replaceUrl}`);
    }
    static cancelNavigateNoTrackingHistory(replaceUrl: string) {
        window.history.replaceState({}, '', `${replaceUrl}`);
    }
    // Session URL will be: /configuration/profile ...
    static handleTabChangedUrl(tabIndex: number, sessionURL: string) {
        let patternRegex = /#([\d]+)/;
        let newReplaceUrl = sessionURL + window.location.href.split(sessionURL)[window.location.href.split(sessionURL).length - 1];
        if (patternRegex.test(newReplaceUrl)) {
            newReplaceUrl = newReplaceUrl.replace(patternRegex, `#${tabIndex}`);
        } else {
            newReplaceUrl = newReplaceUrl + `#${tabIndex}`;
        }
        Helper.cancelNavigateNoTrackingHistory(newReplaceUrl);
    }

    static removeStyleHtml(data?: string): string {
        var result = data;
        try {
            if (result) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = result;
                result = tmp.textContent || tmp.innerText || "";
            }
        } catch (ex) { }

        return result;
    }

    static checkTabMode = (): boolean => {
        var isTabMode = localStorage.getItem('tabMode');
        if (isTabMode && isTabMode === 'true')
            return true;
        return false;
    }

    static heightDialog(): string {
        if (Helper.checkTabMode())
            return 'calc(100vh - 100px)';
        return '100vh';
    }

    static findLastIndex(array, searchKey, searchValue) {
        var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
        var count = array.length - 1
        var finalIndex = index >= 0 ? count - index : index;
        return finalIndex;
    }
    // function to get query parameter Ex:  /configuration/profile?profileId=00cd6e9c-d2e9-4087-8a1c-94cd49c07c5d#2
    // will return ?profileId=00cd6e9c-d2e9-4087-8a1c-94cd49c07c5d#2
    static checkUrlHasQueryParamString(value: string): string {
        let regexParam = /(\?[\s\S]*)/;
        if (value && value.match(regexParam)) {
            return value.match(regexParam)[0];
        }
        return null;
    }
    static getQueryParamObject(value: string): ObjectValue[] {
        let paramLst = value.split('&');
        let returnObjects: ObjectValue[] = [];
        paramLst.forEach(item => {
            if (item && item.trim().length > 0 && item.trim().split('=').length > 0) {
                let splittedValue = item.split('=');
                returnObjects.push({
                    id: splittedValue[0],
                    value: splittedValue[1]
                });
            }
        });
        return returnObjects;
    }

    static removeOverlay() {
        try {
            var overlayBackdropDetails = window.document.querySelectorAll(".cdk-overlay-backdrop.overlay-backdrop-tab-mode.cdk-overlay-backdrop-showing");
            var overlayContentDetails = window.document.querySelectorAll(".cdk-overlay-pane.dialog-detail");
            if (overlayBackdropDetails.length > 0) {
                for (var i = overlayBackdropDetails.length - 1; i >= 0; i--) {
                    overlayBackdropDetails[i].remove();
                }
            }

            if (overlayContentDetails.length > 0) {
                for (var i = overlayContentDetails.length - 1; i >= 0; i--) {
                    overlayContentDetails[i].remove();
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    static recursiveFindItemArray(data: any[], prop: string, value: any) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][prop] === value) {
                return data[i];
            } else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
                let result = this.recursiveFindItemArray(data[i].children, prop, value);
                if (result) return result;
            }
        }
    }
    static verifyJson(text) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            var json = JSON.parse(text);
            return typeof json === "object";
        } catch (error) {
            return false;
        }
    }
    static checkPhoneNumberContact(contact: Contact): boolean {
        let result = false;
        if (contact.contactPhone) result = true;
        else if (contact.secondaryPhone) result = true;
        else if (contact.cellPhone) result = true;
        return result;
    }
    static getEmailFromContact(contact: Contact): string | null {
        let result = null;
        if (!contact) return result;
        if (!this.isNullOrEmpty(contact.contactEmail)) return contact.contactEmail;
        if (!this.isNullOrEmpty(contact.secondaryEmail)) return contact.secondaryEmail;
        return result;
    }
    static getPhoneFromContact(contact: Contact): string {
        let result = null;
        if (!contact) return result;
        if (!this.isNullOrEmpty(contact.contactPhone)) return contact.contactPhone;
        if (!this.isNullOrEmpty(contact.secondaryPhone)) return contact.secondaryPhone;
        if (!this.isNullOrEmpty(contact.cellPhone)) return contact.cellPhone;
    }

    static exportFrontEnd(dataExport: any[], fileName: string = 'InvalidCampaignContact_Export_') {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExport);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
        fileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    }

    static sortBy(key, order: SortOrderType = SortOrderType.ASC) {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === SortOrderType.DESC) ? (comparison * -1) : comparison
            );
        };
    }

    static sortClientSidePaging<T>(sorts: OrderMapping[], data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (sorts && sorts.length > 0)) {
                sorts.forEach((sort: OrderMapping) => {
                    data.sort(this.sortBy(sort.sort, sort.sortDir))
                });

                result = [...data.slice()];
            }
        }
        catch (ex) { }
        return result;
    }

    static formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    static filterAllClientSide<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            switch (filter.filterOperator) {
                case "Is Empty":
                    data = data.slice().filter(x => !x[filter.prop]);
                    break;
                case "Is Not Empty'":
                    data = data.slice().filter(x => x[filter.prop]);
                    break;
            }

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static getSessionPage() {
        var result = null;
        try {
            const isClose = window.sessionStorage.getItem("isClose");
            const pageSession = window.sessionStorage.getItem("page");

            if (isClose) window.sessionStorage.removeItem('isClose');
            if (pageSession) window.sessionStorage.removeItem("page");
            if (pageSession && isClose) result = JSON.parse(pageSession);
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static checkUrlDetailObj(obj: string): boolean {
        var result: boolean = null;
        try {
            const url = window.location.pathname;
            if (url.includes(`/configuration/${obj}/`)) {
                window.sessionStorage.setItem('isClose', 'true');
                result = true;
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static checkMoreTable(obj: string): boolean {
        var result: boolean = false;
        try {
            const isExisted = window.sessionStorage.getItem(obj);
            const isClose = window.sessionStorage.getItem("isClose");

            if (isExisted) window.sessionStorage.removeItem(obj);
            if (isExisted && isClose) result = true;
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static popBackURL(): string {
        var result: string;
        try {
            const isExistedUrl = window.sessionStorage.getItem("backURL");
            if (isExistedUrl) {
                var stackURL = [...JSON.parse(isExistedUrl)];
                var lastItem = stackURL?.pop();
                if (lastItem) result = lastItem;

                if (stackURL && stackURL.length > 0) {
                    window.sessionStorage.setItem("backURL", JSON.stringify(stackURL));
                    window.sessionStorage.setItem("cancelRemoveURL", 'true');
                } else {
                    window.sessionStorage.removeItem("backURL");
                    window.sessionStorage.removeItem("cancelRemoveURL");
                }
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static pushBackURL() {
        try {
            const checkStack = window.sessionStorage.getItem("backURL");
            var stackProfile = [];
            if (checkStack) stackProfile = [...JSON.parse(checkStack)] || [];
            stackProfile.push(window.location.pathname);
            window.sessionStorage.setItem("backURL", JSON.stringify(stackProfile));
            window.sessionStorage.setItem("cancelRemoveURL", 'true');
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    static circleDetail(id: string, obj: string, page: any, router: Router, tabMode: AdminTabModeService) {
        try {
            const tabData = tabMode.getCurrentActiveTab();
            window.sessionStorage.setItem("page", JSON.stringify(page));
            if (tabData) window.sessionStorage.setItem("tabIndexStorage", tabData.tabId?.toString());
            const urlDetail = `/configuration/${obj}/${id}`;
            router.navigate([urlDetail]);
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    static cleanCircleDetail() {
        try {
            const tabTagIndex = window.sessionStorage.getItem("tabTagIndex");
            window.sessionStorage.clear();
            if (tabTagIndex) window.sessionStorage.setItem("tabTagIndex", tabTagIndex);
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    static validateTabSession(tabModeService: AdminTabModeService) {
        try {
            const tabCurSession = window.sessionStorage.getItem("tabIndexStorage");
            if (tabCurSession && +tabCurSession) {
                const tabCur = tabModeService.getCurrentActiveTab();
                if (tabCur.tabId != +tabCurSession) Helper.cleanCircleDetail()
            }
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    static setItemSessionFilterTask(filterList: TaskFilterProp[]) {
        if (filterList && filterList.length > 0) {
            filterList.map(x => {
                switch (x.key) {
                    case 4: //search task
                        window.sessionStorage.setItem("searchTask", x.searchValue)
                        break;
                    case 2: //filter task type
                        window.sessionStorage.setItem("filterTaskType", JSON.stringify(x.propertyValue));
                        break;
                }
            });
        }
    }

    static validateRouteInApplication(route: Routes | Routes[]) {
        Helper._memoryPath = [];
        Helper.getPathFromRouting(route);
        return Helper._memoryPath;
    }

    static getPathFromRouting(entity: Routes | Routes[]) {
        entity.forEach(item => {
            if (!item.children) {
                // no children:
                Helper._memoryPath.push(item);
            } else {
                // has children:
                this.getPathFromRouting(item.children);
            }
        });
    }

    static getURLByRelationshipType(type: string) {
        var result: string = '';
        try {
            switch (type) {
                case "SALEPROFILE":
                    result = 'profile';
                    break;
                case "LEADS":
                    result = 'sale-lead';
                    break;
                case "SALEACCOUNT":
                    result = 'sale-account';
                    break;
                case "OPPORTUNITY":
                    result = 'opportunity';
                    break;
                case "TASK":
                    result = 'task';
                    break;
                default:
                    result = 'profile';
                    break;
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static timeZoneAddOffSet(date: Date) {
        var result = date ? new Date(date) : date;
        try {
            if (result) {
                const offsetHours = result.getTimezoneOffset() / 60;
                result = new Date(result.getTime() + (offsetHours * 60 * 60 * 1000));
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }
}

