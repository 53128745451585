import { CallEventModel } from './call-event-model';
import { PagedData } from './../../../shared/models/paging/paged-data';
import { Observable } from 'rxjs/Observable';
import { Page } from './../../../shared/models/paging/page';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { ActivityLog } from '../campaign-management/campaign-tracking.model';

@Injectable({
  providedIn: 'root'
})
export class CallEventService {

  baseUrl = environment.apiCallEvent;
  constructor(private http: HttpClient) { }
  getCallEventPaging(page: Page): Observable<ReturnResult<PagedData<CallEventModel>>> {
    return this.http.post<ReturnResult<PagedData<CallEventModel>>>(`${this.baseUrl}/GetCallEventPaging`, page)
  }
  deleteCallEvent(callEventId: number) {
    return this.http.delete<ReturnResult<Boolean>>(`${this.baseUrl}/DeleteCallEvent/${callEventId}`)
  }
  deleteCallEvents(callEventIds: number[]) {
    return this.http.post<ReturnResult<Boolean>>(`${this.baseUrl}/DeleteCallEvents`, callEventIds)
  }
  saveCallEvent(model: CallEventModel): Observable<ReturnResult<Boolean>> {
    return this.http.post<ReturnResult<Boolean>>(`${this.baseUrl}/SaveCallEvent`, model);
  }
  makeSimulateCalls(model: CallEventModel[]) {
    return this.http.post<ReturnResult<Boolean>>(`${this.baseUrl}/MakeSimulateCall`, model);
  }
  CreateSimulateActivityLogCall(phoneNumber: string): Observable<ReturnResult<ActivityLog>> {
    return this.http.post<ReturnResult<ActivityLog>>(`${this.baseUrl}/CreateSimulateActivityLogCall`, { PhoneNumber: phoneNumber });
  }
  checkStatePhoneNumber(): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/CheckStatePhoneCall`);
  }
  changeStatePhoneNumber(): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ChangeStatePhoneCall`, {});
  }

  enableFeatureChangeStatePhoneCall(): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/EnableFeatureChangeStatePhoneCall`);
  }

  countInboundCallEvent(page: Page){
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/CountInBound`,page);
  }
  countOutBoundCallEvent(page:Page){
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/CountOutbound`,page);
  }
  
}
