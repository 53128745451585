import { error } from 'console';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Contact, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { SaleAccountService } from '../../sale-account-management/sale-account.service';
import { SaleLeadService } from '../sale-lead.service';
import { ConvertSaleLeadInputModel, RecommentSaleLeadConvertInput } from './convert-sale-lead.model';
import { ConvertSaleLeadNotificationComponent } from './convert-sale-lead-notification/convert-sale-lead-notification.component';
import { RecommendSaleLeadConvert } from '../add-sale-leads.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-convert-sale-lead',
  templateUrl: './convert-sale-lead.component.html',
  styleUrls: ['./convert-sale-lead.component.scss']
})
export class ConvertSaleLeadComponent implements OnInit, AfterViewInit {
  @Input() saleLeadModel: ProfileDetailModel;

  @Output() refreshTable: EventEmitter<any> = new EventEmitter();
  environment = environment;
  convertSaleLeadInputForm: FormGroup;
  convertSaleLeadInputModel: ConvertSaleLeadInputModel = new ConvertSaleLeadInputModel();
  apiGetSearchRelationship: (data) => void = null;
  placeholderSearch: string = null;
  isLoading = false;
  dynamicRowHeight = '57px';
  recommendConvert: RecommendSaleLeadConvert = null;
  constructor(
    private frmBuilder: RxFormBuilder,
    private dialogRef: MatDialogRef<ConvertSaleLeadComponent>,
    private saleAccountService: SaleAccountService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SaleLeadService,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private saleLeadService: SaleLeadService,
    private cd: ChangeDetectorRef
  ) {
    this.saleLeadModel = data.model ?? this.saleLeadModel;
    this.refreshTable = data.refreshTable ?? this.refreshTable;
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    if (this.dialogRef) {
      this.dialogRef.updateSize('80vw');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResizeTextSwitch(event) { }

  ngOnInit() {
    this.convertSaleLeadInputForm = this.frmBuilder.formGroup(ConvertSaleLeadInputModel, this.convertSaleLeadInputModel);
    this.convertSaleLeadInputForm.controls.orgLeadId.setValue(this.saleLeadModel.profileId);
    this.convertSaleLeadInputForm.controls.newAccountName.setValue(this.saleLeadModel.company1);
    // config search sale account:
    this.apiGetSearchRelationship = (data) => this.saleAccountService.searchSaleAccount(data);
    this.placeholderSearch = `Search ${this.environment.titleAccount}`;

    this.recommendLookupSaleLead();
  }

  recommendLookupSaleLead() {
    // var keywordsLookup: string[] = [
    //   this.saleLeadModel.saleLeadsContact.contactName,
    //   this.saleLeadModel.saleLeadsContact.contactLastName,
    //   this.saleLeadModel.saleLeadsContact.contactEmail,
    //   this.saleLeadModel.saleLeadsContact.contactPhone,
    //   this.saleLeadModel.company1
    // ];
    var keywordsLookup : RecommentSaleLeadConvertInput = {
      email: this.saleLeadModel.saleLeadsContact.contactEmail,
      firstName: this.saleLeadModel.saleLeadsContact.contactName,
      lastName: this.saleLeadModel.saleLeadsContact.contactLastName,
      company: this.saleLeadModel.company1
    }

    this.saleLeadService.recommendSaleLeadConvert(keywordsLookup)
      .subscribe(
        resp => this.recommendConvert = resp.result,
        err => this.recommendConvert = Object.assign({
          recommendContacts: 0,
          recommendSaleAccounts: 0,
        } as RecommendSaleLeadConvert)
      );
  }

  onToggleCheckedChanged(event: MatCheckboxChange, controlName: string) {
    this.convertSaleLeadInputForm.controls[controlName].setValue(!event.checked);
  }

  getSaleAccountSelected(data: ProfileDetailModel) {
    if (this.convertSaleLeadInputForm.controls.isNewAccount.value)
      this.convertSaleLeadInputForm.controls['isNewAccount'].setValue(false);

    this.convertSaleLeadInputForm.controls.saleAccountProfileModel.setValue(data);
    this.convertSaleLeadInputForm.controls.existedSaleAccountId.setValue(data.profileId);
  }

  removeSelectedAccount() {
    this.convertSaleLeadInputForm.controls.saleAccountProfileModel.setValue(null);
    this.convertSaleLeadInputForm.controls.existedSaleAccountId.setValue(null);

  }

  getContactSelected(data: Contact) {
    if (this.convertSaleLeadInputForm.controls.isNewContact.value)
      this.convertSaleLeadInputForm.controls['isNewContact'].setValue(false);

    this.convertSaleLeadInputForm.controls.contact.setValue(data);
    this.convertSaleLeadInputForm.controls.existedContactId.setValue(data.contactId);
  }

  removeSelectedContact() {
    this.convertSaleLeadInputForm.controls.contact.setValue(null);
    this.convertSaleLeadInputForm.controls.existedContactId.setValue(null);

  }
  onSubmitClicked() {
    this.isLoading = true;
    let submitModel = this.convertSaleLeadInputForm.value;
    //this.toast.info('This function is under development. Will soon be available', 'Warning');
    // this.isLoading = false;
    // call api here:
    this.service.convertSaleLead(submitModel).subscribe({
      next: (data) => {
        if (data.result > 0) {
          this.toast.success('Convert Success', 'Success');
          let successDialog = this.dialog.open(ConvertSaleLeadNotificationComponent, {
            width: '70vw',
            disableClose: true,
            data: {
              convertHistoryId: data.result
            }
          });
          this.isLoading = false;
          this.dialogRef.close(true);
        }
      },
      error: (data) => {
        this.toast.danger('Convert Error', 'Error');
        this.isLoading = false;
      },
      complete: () => {
        // this.isLoading = false;
        // this.dialogRef.close(true);
      }
    });
  }
  onNumberFocus(controlName: string) {
    let value = this.convertSaleLeadInputForm.controls[controlName].value;
    if (!isNaN(value) && value == 0) {
      this.convertSaleLeadInputForm.controls[controlName].setValue(undefined);
      this.convertSaleLeadInputForm.controls[controlName].markAsTouched();
    }
  }
  onNumberUnFocus(controlName: string) {
    let value = this.convertSaleLeadInputForm.controls[controlName].value;
    if (!value || value == "") {
      this.convertSaleLeadInputForm.controls[controlName].setValue(0);
    }
  }
}
