import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { HotToastService } from '@ngneat/hot-toast';
import { UserService } from 'src/app/@core/mock/users.service';
import { DatastateManagementService } from 'src/app/modules/admin/datastate-management/datastate-management.service';
import { Contact } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { SaleLeadDetailsComponent } from 'src/app/modules/admin/sale-lead-management/sale-lead-details/sale-lead-details.component';
import { SaleLeadService } from 'src/app/modules/admin/sale-lead-management/sale-lead.service';
import { TaskBoardService } from 'src/app/modules/admin/task-management/task-board/task-board.service';
import { UserUploadManagementService } from 'src/app/modules/admin/user-upload-management/user-upload-management.service';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { CallService } from 'src/app/shared/services/call.service';
import { ContactService } from 'src/app/shared/services/contact.service';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { NoteManagementService } from '../../note-management/note-management.service';
import { AdminTabModeService } from '../../stand-alone-component/admin-tab-mode/admin-tab-mode.service';

@Component({
  selector: 'app-contact-review',
  templateUrl: './contact-review.component.html',
  styleUrls: ['./contact-review.component.scss']
})
export class ContactReviewComponent implements OnInit {

  contactId: number;
  contactData: Contact;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public dialModalRef: MatDialogRef<ContactReviewComponent>,
  public numberPipe: NumberFormatPipe,
  public automatService: DatastateManagementService,
  public noteService: NoteManagementService,
  private contactService: ContactService
  ) { 
    if(this.data && this.data.row) {
      this.data.importColumn = this.formatKeyPairValue(this.data.row);
      this.data.importColumn = this.data.importColumn.sort((a, b) => a.prop.localeCompare(b.prop))
      this.contactId = this.data.row.contactId
    }
    
  }

  ngOnInit(): void {
     this.dialModalRef.afterOpened().subscribe(() => {
      var overlayBackdrops = window.document
        .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
      for (var i = 0; i < overlayBackdrops.length; i++)
        overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
    })

    this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
    this.getContactById();

  }
  async getContactById() {
    const apiResult = await this.contactService.getContactById(this.contactId).toPromise();
    if(apiResult && apiResult.result) {
        this.contactData = apiResult.result;
    }
  }

  formatKeyPairValue(row) {
    let keyPairLst = []
    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key)) {
        if (key == 'profileId') continue;
        if (key == 'extendData') continue;
        if (key == 'contactContactTypes') continue;
        if (key == 'dateCreated') continue;
        if (key == 'dateModified') continue;
        if (key == 'dateDeleted') continue;
        if (key == 'deleted') continue;
        if (key.includes('Id')) continue;
        if(row[key] &&typeof(row[key]) == 'object' ) {
          keyPairLst.push(...this.formatKeyPairValue(row[key]));
        }
        else {
          var column = {
            name: Helper.displayNameProp(key),
            prop: key,
            value: row[key],
            sortable: true,
            filter: true,
          };
        }
       
        keyPairLst.push(column);
      }
    };

    return keyPairLst;

  }
}
