<mat-dialog-content style="padding-bottom: 10px;">
    <form [formGroup]="frmTitle">
        <mat-form-field appearance="standard">
            <mat-label>Subject</mat-label>
            <input matInput type="text" formControlName="title" autocomplete="off" placeholder="Subject email..."
                required trim="blur">
            <mat-error
                *ngIf="frmTitle.controls.title.errors && (frmTitle.controls.title.dirty || frmTitle.controls.title.touched)">
                {{frmTitle.controls.title['errorMessage']}}</mat-error>
        </mat-form-field>
    </form>
    <div *ngIf="isOpenEditor">
        <app-editor-email *ngIf="!isNormalEditor; else normalEditor" #editorEmail
            [emailDesign]="emailTemplate ?? obsEmailTemplate" [classicEditor]="isClassicEditor"
            [bodyEmbedded]="bodyEmbedded" [userModel]="userModel" [isEmbedded]="isEmbedded">
        </app-editor-email>
    </div>
</mat-dialog-content>

<ng-template #normalEditor>
    <app-email-quill-editor #quillEditor [emailDesign]="obsEmailTemplate" [bodyEmbedded]="bodyEmbedded"
        [userModel]="userModel">
    </app-email-quill-editor>
</ng-template>