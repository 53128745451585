import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ReturnResult } from '../../models/return-result';


@Component({
  selector: 'app-dynamic-content-form',
  templateUrl: './dynamic-content-form.component.html',
  styleUrls: ['./dynamic-content-form.component.scss']
})
export class DynamicContentFormComponent implements OnInit, OnChanges {
  @Input() label : string;
  @Input() control: FormControl;
  @Input() displayKey: string;

  @Input() searchArrayAPI: Observable<ReturnResult<any>>;

  filteredOptions: Observable<string[]>;
  @Input()
  options = [];
  loading = false;
  constructor() { }

  ngOnInit(): void {
   this.setupData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const optionChange = changes.options;
    if(optionChange && optionChange.currentValue && optionChange.currentValue.length) {
      this.pushDataToStream();
    }
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option[this.displayKey].toLowerCase().includes(filterValue));
  }
  async setupData() {
    try {
      if(this.searchArrayAPI) {
        this.loading = true;
        const data = await this.searchArrayAPI.toPromise();
        if(data.result) this.options = data.result;
        this.loading = false;
      }
      this.pushDataToStream();
    }
    catch(e) {
      console.log(e);
      this.loading = false;
    }
  }
  pushDataToStream() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value[this.displayKey];
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }
  displayFn(data) {
    if(data) {
      return data[this.displayKey] ? data[this.displayKey] : data;
    }
  }
}
