<div [ngSwitch]="operationMode">
  <mat-form-field *ngSwitchDefault>
    <mat-label>{{columnName}}</mat-label>
    <input matInput [matDatepicker]="picker.datePicker" [formControl]="datetime"
      (dateChange)="picker.dateChangeSubject.next($event)">
    <mat-datepicker-toggle matSuffix [for]="picker.datePicker"></mat-datepicker-toggle>
    <!-- <ngx-mat-datetime-picker #picker [showSeconds]="true">
      <ng-template>
        <span>OK</span>
      </ng-template>
    </ngx-mat-datetime-picker> -->
    <app-primas-custom-date-time-picker #picker="PrimasCustomDateTimePicker" (dateChange)="onClickSubmit($event)"
      [twelveHoursFormat]="false" [minuteStep]="1"
      (editManualSave)="onClickSubmit($event)"></app-primas-custom-date-time-picker>
  </mat-form-field>
  <mat-form-field *ngSwitchCase="'Between'">
    <mat-label>{{columnName}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" (dateChange)="refreshValue()">
      <input matEndDate formControlName="end" (dateChange)="onClickDateRangeSubmit()">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
      <mat-datepicker-actions>
        <button mat-stroked-button matDatepickerApply>OK</button>
      </mat-datepicker-actions>
    </mat-date-range-picker>
  </mat-form-field>
  <mat-form-field *ngSwitchCase="'Quarter'">
    <mat-label>{{columnName}}</mat-label>
    <mat-select [formControl]="datetime" multiple (openedChange)="onClickDropdown($event)">
      <mat-option *ngFor="let quarter of quarterList" [value]="quarter.value">{{quarter.text}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<!-- <span>
  {{columnName}}
  <button mat-icon-button [nbPopover]="templateRef" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
    (click)="toggleDatetimePop()">
    <mat-icon fontSet="material-icons-outlined">filter_alt</mat-icon>
  </button>
  <ng-template #templateRef>
    <nb-card class="mb-0" style="width: 217px;border: unset;">
      <nb-card-body>
        <div class="row">
          <mat-form-field>
            <input matInput [ngxMatDatetimePicker]="fromPicker" placeholder="From Date-time" [formControl]="from">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #fromPicker>
              <ng-template>
                <span>OK</span>
              </ng-template>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input matInput [ngxMatDatetimePicker]="toPicker" placeholder="To Date-time" [formControl]="to">
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #toPicker>
              <ng-template>
                <span>OK</span>
              </ng-template>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" mat-button (click)="toggleDatetimePop()">
            Cancel
          </button>
          <button mat-raised-button color="primary" (click)="onClickSubmit()">
            Ok
          </button>
        </div>

      </nb-card-body>

    </nb-card>
  </ng-template>
</span> -->