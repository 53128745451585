import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreeNode } from 'src/app/shared/components/tree-structure-filter/tree-structure-filter.component';
import { Contact as ContactDetail } from '../../profile-management/profile-detail.model';

@Component({
  selector: 'app-add-group-contact',
  templateUrl: './add-group-contact.component.html',
  styleUrls: ['./add-group-contact.component.scss']
})
export class AddGroupContactComponent implements OnInit {
  groupName = ''; //or groupId
  isError = false;
  errorMsg = 'This field is required';
  isAddGroupExisting: boolean = false;
  listGroups: TreeNode[] = [];
  constructor(
    public dialModalRef: MatDialogRef<AddGroupContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.isAddGroupExisting = data.isAddGroupExisting;
    if (this.isAddGroupExisting) {
      this.listGroups = data.listGroups;
    }
  }

  ngOnInit() {
  }
  onCancelClick() {
    this.dialModalRef.close({
      isConfirm: false,
    });
  }
  onOkClick() {
    debugger;
    if (this.groupName !== '') {
      this.dialModalRef.close({
        isConfirm: true,
        groupName: this.groupName // groupName or groupId,
      });
    } else {
      this.isError = true;
    }
  }

}
