import { RealtimeNotificationDTO } from './../../../shared/components/realtime-notification/responseRealtimeNotification.model';
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from "rxjs";
import { Priority } from "src/app/modules/admin/task-management/task-board/task-board-lane/task-status.model";
import { ReturnResult } from "src/app/shared/models/return-result";
import { environment } from "src/environments/environment";
import { TasksNotification } from "src/app/@theme/components/header/mockup-notifications";
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';


@Injectable({
  providedIn: 'root'
})
export class TasksNotificationService {


  baseUrl = environment.aipNotification;
  realtimeNotificationUrl = environment.realtimeNotification;
  _taskNotificationList = new Subject<TasksNotification[]>();
  constructor(private http: HttpClient) { }


  getTaskNotification() {
    return this.http.get<ReturnResult<TasksNotification[]>>(`${this.baseUrl}/getNotification`).subscribe(result => {
      if (result.result != null) {
        this._taskNotificationList.next(result.result);
      }
    });

  }

  getTasksNotificationList(): Observable<TasksNotification[]> {
    return this._taskNotificationList.asObservable();
  }
  notification() {
    return this.http.get<ReturnResult<TasksNotification[]>>(`${this.baseUrl}/getNotification`);
  }

  readNotification(id: number) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/readNotification/${id}`, {});
  }

  getRealtimeNotificationPaging(page: Page, OwnerId: string): Observable<ReturnResult<PagedData<RealtimeNotificationDTO>>> {
    return this.http.post<ReturnResult<PagedData<RealtimeNotificationDTO>>>(`${this.realtimeNotificationUrl}/GetListNotification/${OwnerId}`, page);
  }

  readRealtimeNotification(id: number) {
    console.log(`${this.realtimeNotificationUrl}/readRealtimeNotification/${id}`);
    return this.http.post<ReturnResult<boolean>>(`${this.realtimeNotificationUrl}/readRealtimeNotification/${id}`,{});
  }
}
