export enum TblActionType {
    Add,
    Edit,
    Delete,
    // 2022-01-05 gnguyen start add
    Profile,
    // 2022-01-05 gnguyen end add
    ReadOnly
}
export namespace TblActionType {

    export function values() {
        return Object.keys(TblActionType).filter(
            (type) => isNaN(type as any) && type !== 'values'
        );
    }
}
