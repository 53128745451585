import { prop, required } from "@rxweb/reactive-form-validators";
import { ColumnMapping } from "./column-mapping";
import { OrderMapping } from "./order-mapping";
import { FilterMapping } from "./paging/filter-mapping";

export class CustomView {
  @prop()
  viewId: number = 0;
  @prop()
  @required()
  viewIdentify: string;
  @prop()
  @required()
  viewName: string;
  @prop()
  dateCreated: Date;
  @prop()
  orderMapping: OrderMapping[];//array
  @prop()
  sharedWith: string;
  @prop()
  columnMapping: ColumnMapping[];
  //2022-01-25 gnguyen start add
  @prop()
  isPrivate: boolean = true;
  //2022-01-25 gnguyen end add
  @prop()
  userName: string | null;
  @prop()
  columns: string | null;
  @prop()
  sorts: string | null;
}
