import { Component, Input, OnInit } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { EmailTemplate } from 'src/app/modules/admin/setting-management/setting-campaign/email-template';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { BodyEmbedded } from 'src/app/shared/models/body-embedded.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { QuillConfiguration } from '../rich-inline-edit/rich-inline-edit.component';
@Component({
  selector: 'app-email-quill-editor',
  templateUrl: './email-quill-editor.component.html',
  styleUrls: ['./email-quill-editor.component.scss']
})
export class EmailQuillEditorComponent implements OnInit {

  @Input() emailDesign: EmailTemplate | Observable<ReturnResult<string>> = null;
  @Input() bodyEmbedded: BodyEmbedded = null;
  @Input() userModel: UserModel;

  constructor(
    private authService: NbAuthService,
    private userService: UserService,
    private toast: NbToastrService
  ) {
    if (this.userModel == null)
      this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
          if (token.isValid()) {
            const user = token.getPayload();
            this.userId = user['nameid'];
          };
        });
  }

  editorOptions = QuillConfiguration;
  emailTemplate: EmailTemplate;
  isLoadingComponent: boolean = true;
  userId: any;

  ngOnInit(): void {
    if (this.userId)
      this.userService.getUserById(this.userId).pipe(first()).subscribe(resp => {
        if (resp.result) {
          this.userModel = resp.result
          if (this.bodyEmbedded) {
            this.bodyEmbedded['username'] = this.userModel.userName;
            this.bodyEmbedded['useremail'] = this.userModel.email;
          } else this.toast.warning("User information isn't embedded for email!!", "Warning");
        };
      })
    else this.toast.danger("Error get current user!", "Error");

    this.emailTemplate = new EmailTemplate();
    this.emailTemplate.HtmlTemplate = `<html><body><div>Edit email</div></body></html>`;

    if (this.emailDesign) {
      if (this.emailDesign instanceof Observable) {
        this.emailDesign.subscribe(resp => {
          if (resp.result) {
            this.emailTemplate = JSON.parse(resp.result) as EmailTemplate;
          }
        })
      } else {
        this.emailTemplate = this.emailDesign;
      }
    }
  }

  getEmailHtmlQuill() {
    if (this.emailTemplate) {
      if (this.bodyEmbedded) {
        var bodyHtml = this.emailTemplate.HtmlTemplate;
        var currentTimestamp = Date.now();
        this.bodyEmbedded['timestamp'] = currentTimestamp.toString();

        const str = bodyHtml.substring(
          bodyHtml.indexOf(`<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;`),
          bodyHtml.indexOf(`;EMBEDDED_END</span>`) + 20
        ).slice();
        if (str.length > 0 && str.startsWith(`<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;`) && str.endsWith(`;EMBEDDED_END</span>`)) {
          bodyHtml = bodyHtml.replace(str, '');
        }

        bodyHtml += `<span style="opacity:0 !important;display:none !important; width:0px !important; height: 0px !important">EMBEDDED_START;${btoa(JSON.stringify(this.bodyEmbedded))};EMBEDDED_END</span>`;
        this.emailTemplate.HtmlTemplate = bodyHtml;
      }

      return { html: this.emailTemplate.HtmlTemplate, design: this.emailTemplate.DesignTemplate }
    }
  }

  createEditor() {
    this.isLoadingComponent = false;
  }
}
