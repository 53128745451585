import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { OpportunityManagementService } from 'src/app/modules/admin/opportunity-management/opportunity-management.service';
import { KeyPairsValueObjectAPI, KeyPairsValueSelectDropDown, KeyPairsValueTypeObjectAPI } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { SaleAccountService } from 'src/app/modules/admin/sale-account-management/sale-account.service';
import { SaleLeadService } from 'src/app/modules/admin/sale-lead-management/sale-lead.service';
import { SettingCompanyViewModel } from 'src/app/modules/admin/setting-management/setting-company/setting-company';
import { environment } from 'src/environments/environment';
import { ShadowProfileEnum } from '../../enums/shadow-profile.enum';
import { ReturnResult } from '../../models/return-result';
import { BuyerManagementService } from '../../services/buyer-management.service';

@Component({
  selector: 'app-search-type-objects',
  templateUrl: './search-type-objects.component.html',
  styleUrls: ['./search-type-objects.component.scss']
})
export class SearchTypeObjectsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() selectedForm: FormControl = new FormControl(ShadowProfileEnum[ShadowProfileEnum.SALEPROFILE]);
  @Input() typeObjects: KeyPairsValueTypeObjectAPI[] = [
    { key: ShadowProfileEnum[ShadowProfileEnum.SALEPROFILE], displayType: 'profile', iconType: 'account_circle', value: (data) => this.profileService.searchProfile(data), color: '#808080', propId: 'profileId', propName: 'displayName' } as KeyPairsValueTypeObjectAPI,
    { key: 'BUYER', displayType: 'buyer', iconType: 'local_mall', value: (data) => this.buyerService.searchBuyer(data), color: '#0078A8', propId: 'buyerId', propName: 'companyName' } as KeyPairsValueTypeObjectAPI,
    //{ key: ShadowProfileEnum[ShadowProfileEnum.LEADS], displayType: 'saleleads', iconType: 'account_circle', value: (data) => this.saleLeadService.searchSaleLead(data), color: '#5c5c8a', propId: 'profileId', propName: 'displayName' } as KeyPairsValueTypeObjectAPI,
    { key: ShadowProfileEnum[ShadowProfileEnum.SALEACCOUNT], displayType: environment.titleAccount, iconType: 'account_circle', value: (data) => this.saleAccountService.searchSaleAccount(data), color: '#808000', propId: 'profileId', propName: 'displayName' } as KeyPairsValueTypeObjectAPI,
    { key: ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY], displayType: environment.titleOpportunity, iconType: 'account_circle', value: (data) => this.opportunityService.searchOpportunity(data), color: '#004d4d', propId: 'profileId', propName: 'displayName' } as KeyPairsValueTypeObjectAPI,
  ];

  @Input() apiGetSelectedObjects: Observable<ReturnResult<KeyPairsValueObjectAPI[]>> = null;
  @Input() selectedObjects: KeyPairsValueObjectAPI[] = [];
  @Input() customClassIndex: string;
  @Input() isShowMatChip: boolean = true;

  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedObjectsValue: EventEmitter<KeyPairsValueObjectAPI[]> = new EventEmitter<KeyPairsValueObjectAPI[]>();
  @ViewChild('inputSearch') inputSearch: ElementRef;

  isLoading: boolean = false;
  isLoadingSearch: boolean = false;
  isEdit: boolean = false;

  selected: KeyPairsValueTypeObjectAPI = this.typeObjects ? this.typeObjects.find((x, index) => index == 0) : null;
  searchForm: FormControl = new FormControl('');
  objects: any[] = [];


  defaultAvatar = SettingCompanyViewModel.golbalDefaultAvatar;
  protected _onDestroy = new Subject<void>();
  constructor(
    private saleLeadService: SaleLeadService,
    private saleAccountService: SaleAccountService,
    private opportunityService: OpportunityManagementService,
    private profileService: ProfileService,
    private buyerService: BuyerManagementService,
    public element: ElementRef
  ) { }

  ngOnInit(): void {
    if (this.apiGetSelectedObjects) {
      this.isLoading = true;
      this.apiGetSelectedObjects.pipe(take(1))
        .subscribe({
          next: resp => {
            if (resp.result) {
              this.selectedObjects = resp.result;
              this.configInputSelectedObjects();
            }
          },
          complete: () => this.isLoading = false
        });
    }
  }

  ngAfterViewInit() {
    fromEvent(this.inputSearch.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      filter(res => res.length > 2 || res.length === 0),
      debounceTime(1000),
    ).subscribe((keyword: string) => {
      this.searchObjects(keyword);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeSelectedForm = changes.selectedForm;
    const changeSelectedObjects = changes.selectedObjects;

    if (changeSelectedForm && JSON.stringify(changeSelectedForm.currentValue) != JSON.stringify(changeSelectedForm.previousValue))
      if (this.typeObjects && this.typeObjects.length > 0)
        this.selected = this.typeObjects.find(x => x.key == changeSelectedForm.currentValue.value);

    if (changeSelectedObjects && JSON.stringify(changeSelectedObjects.currentValue) != JSON.stringify(changeSelectedObjects.previousValue))
      this.configInputSelectedObjects();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  configInputSelectedObjects() {
    if (this.typeObjects && this.typeObjects.length > 0)
      this.selectedObjects = this.selectedObjects.map(x => {
        var item = this.typeObjects.find(y => y.key == x.key);

        if (!item && x.key == ShadowProfileEnum[ShadowProfileEnum.LEADS]) {
          item = {
            key: ShadowProfileEnum[ShadowProfileEnum.LEADS],
            displayType: environment.titleLead,
            color: '#5c5c8a',
            propId: 'profileId',
            propName: 'displayName',
          } as KeyPairsValueTypeObjectAPI;
        }

        return Object.assign(x, {
          color: item?.color ?? '',
          displayType: item?.displayType ?? '',
        });
      });
  }

  searchObjects(data: string) {
    this.objects = [];
    if (data === '') {
      return;
    }

    this.isLoadingSearch = true;
    if (this.selected && this.selected.value) {
      this.selected.value(data).pipe(takeUntil(this._onDestroy))
        .subscribe({
          next: resp => {
            if (resp.result != null && resp.result.length !== 0)
              this.objects = resp.result.map(x => Object.assign(x, {
                disabled: this.selectedObjects.find(y => y.id == x[this.selected.propId]) ? true : false,
              }));
          },
          complete: () => this.isLoadingSearch = false,
        });
      return;
    }
  }

  changeSelected() {
    if (this.selectedForm) {
      if (this.customClassIndex) {
        var overlayTypeObject = window.document.querySelector<any>(`.${this.customClassIndex}`);
        if (overlayTypeObject) {
          overlayTypeObject.closest('.cdk-overlay-connected-position-bounding-box').style.zIndex = 1005;
          var backdropMatSelect = window.document.querySelector<any>(`.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing`);
          if (backdropMatSelect) backdropMatSelect.style.zIndex = 1005;
        }
      }
      this.selected = this.typeObjects.find(x => x.key == this.selectedForm.value);
      this.cleanSearchObject();
    }
  }

  selectedObject(data: any) {
    if (data) {
      if (this.selected && this.selectedObjects) {
        var newObject: KeyPairsValueObjectAPI = Object.assign({}, this.selected, {
          id: data[this.selected.propId],
          value: data[this.selected.propName],
          isRemove: true,
        });

        this.selectedObjects.unshift(newObject);
        this.selectedValue.emit(newObject);
        this.selectedObjectsValue.emit(this.selectedObjects);
        this.cleanSearchObject();
        this.isEdit = !this.isEdit;
      }
    }
  }

  cleanSearchObject() {
    if (this.inputSearch) {
      this.objects = [];
      this.inputSearch.nativeElement.value = '';
    }
  }

  removeSelectedObj(data: any) {
    if (data) {
      this.selectedObjects = this.selectedObjects.filter(x => x.id != data.id);
      this.selectedObjectsValue.emit(this.selectedObjects);
    }
  }

  clickEditor() {
    this.isEdit = !this.isEdit;
    setTimeout(() => this.inputSearch.nativeElement.focus(), 100);
  }

  customIndexAutocomplete() {
    if (this.customClassIndex) {
      var overlayTypeObject = window.document.querySelector<any>(`.${this.customClassIndex}`);
      if (overlayTypeObject) overlayTypeObject.closest('.cdk-overlay-connected-position-bounding-box').style.zIndex = 1005;
    }
  }
}
