<app-primas-table #profileHistoryFileTable (onRefresh)="refreshData($event)" [resource]="resource.table"
  [columnsTable]="columns" [tableAction]="false" [allowSelectRow]="false" [optionHeight]="'45vh'">
</app-primas-table>

<ng-template #fileNameCol let-row="row" let-rowIndex="rowIndex">
  <span *ngIf="!downloading || (downloading && selectedRowIndex !== rowIndex)"
    (click)="!downloading? downLoadFile(row,rowIndex): null" [ngClass]="'hoverable'">
    <b>{{displayFormatFileName(row.userUpload?.fileName)}} </b>
  </span>
  <ng-container *ngIf="selectedRowIndex == rowIndex && downloading">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <nb-progress-bar [value]="downloadPercent" [status]="status" fxFlex="90" size="large" [displayValue]="true">
      </nb-progress-bar>
      <mat-spinner [diameter]="15" fxFlex></mat-spinner>
    </div>
  </ng-container>
</ng-template>

<div *ngIf="showBottomAction" mat-dialog-actions class="float-right">
  <button mat-button color="primary" (click)="closeDialog()">
    Close
  </button>
</div>