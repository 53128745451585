import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingService } from './setting.service';

@Injectable({
  providedIn: 'root'
})
export class BrowserTabHandlerService {
  private appStartTime: number = 0;
  // number of minute to refresh app:
  public limitRefreshApp: number = 0;
  constructor(private http: HttpClient, private settingService: SettingService) { }
  trackStartApp() {
    if (!this.appStartTime) this.appStartTime = (new Date()).getTime();
  }
  checkTabGreaterThanReset(): boolean {
    if (this.limitRefreshApp <= 0) return false;
    let result = false;
    let currentTime = (new Date()).getTime();
    let tabLifetime = currentTime - this.appStartTime;
    let tabLifetimeSeconds = tabLifetime / 1000;
    // console.log('second tab lifetime: ' + tabLifetimeSeconds);
    if (tabLifetimeSeconds > this.limitRefreshApp) {
      // console.log('Satisfy: > ' + this.limitRefreshApp);
      result = true;
    } else {
      // console.log('Under Satisfy: > ' + this.limitRefreshApp);
    }
    return result;
  }
  // GET API:
  setLimitRefresh(limit: number) {
    this.limitRefreshApp = limit;
  }
}
