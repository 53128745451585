import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vital-helper',
  templateUrl: './vital-helper.component.html',
  styleUrls: ['./vital-helper.component.scss']
})
export class VitalHelperComponent implements OnInit {
  constructor( 
    public dialModalRef: MatDialogRef<VitalHelperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

    }

  ngOnInit(): void {
    this.dialModalRef.updateSize('50%');  
  }

}
