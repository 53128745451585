import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserToken } from 'src/app/modules/admin/user-token-management/user-token.model';
import { environment } from 'src/environments/environment';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ReturnResult } from '../models/return-result';

@Injectable({
  providedIn: 'root'
})
export class UserTokenManagementService {
  baseUrl = environment.apiUserToken;

  constructor(private http: HttpClient) { }

  removeToken(): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>> (`${this.baseUrl}/remove`);
  }

  getPaging(page: Page): Observable<ReturnResult<PagedData<UserToken>>> {
    return this.http.post<ReturnResult<PagedData<UserToken>>>(`${this.baseUrl}/get`, page);
  }

  deleteToken(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>> (`${this.baseUrl}/delete/${id}`);
  }

  deleteMultipleTokens(idList: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>> (`${this.baseUrl}/deleteTokens`, idList);
  }

  refreshMemoryList(): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>> (`${this.baseUrl}/refresh`);
  }
}
