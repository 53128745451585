import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { AltusLocation } from './location';
import { Location } from 'src/app/modules/admin/profile-management/profile-detail.model';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.locationManagement;
  getLocationById(profileId: string): Observable<ReturnResult<AltusLocation[]>> {
    return this.http.get<ReturnResult<AltusLocation[]>>(`${this.baseUrl}/GetLocationByProfileId/${profileId}`);
  }
  saveLocation(
    locationLst: AltusLocation[],
    profileId: string,
    producerId: string,
    buyerId: string,
    isUpdate: boolean = false): Observable<ReturnResult<AltusLocation[]>> {
    return this.http.post<ReturnResult<AltusLocation[]>>(`${this.baseUrl}/SaveLocationList`, {
      locations: locationLst,
      profileId, isUpdate,
      producerId, buyerId
    });
  }
  getLocationByLocationId(locationId: string): Observable<ReturnResult<AltusLocation>> {
    return this.http.get<ReturnResult<AltusLocation>>(`${this.baseUrl}/${locationId}`);
  }
  unLinkLocation(location: Location, table: string, id: string) {
    return this.http.post(`${this.baseUrl}/UnlinkLocation`, {
      location, id, table
    });
  }
}
