import { date, digit, maxLength, minNumber, numeric, NumericValueType, prop, required } from "@rxweb/reactive-form-validators";
import { ProfileDetailModel } from "../profile-management/profile-detail.model";

export class SaleOpportunity {
  constructor() {
    this.saleOpportunityId = 0;
    this.probability = 0;
    this.amount = 0;
  }
  @prop()
  saleOpportunityId: number;
  @prop()
  @required()
  @maxLength({ value: 255 })
  opportunityName: string;
  @prop()
  @maxLength({ value: 255 })
  stageId: number;
  @prop()
  @maxLength({ value: 255 })
  accountName: string;
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numberic is allowed' })
  probability: number | 0;
  @prop()
  @maxLength({ value: 255 })
  type: string;
  @prop()
  @prop()
  @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numberic is allowed' })
  amount: number | 0;
  @prop()
  @maxLength({ value: 255 })
  leadSource: string;
  @prop()
  @maxLength({ value: 36 })
  referenceId: string;
  @prop()
  @maxLength({ value: 255 })
  referenceType: string;
  @prop()
  @date({ message: 'Please enter valid format: MM/DD/YYYY.Ex: 20/11/2022' })
  closeDate: Date | string | null;
  @prop()
  @maxLength({ value: 255 })
  company: string;
  dateCreated: Date | string | null;
  dateModified: Date | string | null;
  opportunityProfile: ProfileDetailModel | null;
  // fetching data:
  lostReason: string | null;
}
export class SaleOpportunityViewModel extends SaleOpportunity {
  @prop()
  saleAccountId: string;
}
export class SaleOpportunityProfile extends ProfileDetailModel {
  saleOpportunityId: number;
  saleOpportunity: SaleOpportunityViewModel;
}