import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataField } from 'src/app/modules/admin/data-field-management/data-field-model';
import { environment } from 'src/environments/environment';
import { ReturnResult } from '../models/return-result';

@Injectable({
  providedIn: 'root'
})
export class DataFieldsManagementService {

  baseUrl = environment.apiDataFields;
  constructor(
    private http: HttpClient
  ) { }

  getDisplayDataByScreen(screen: string): Observable<ReturnResult<DataField[]>> {
    return this.http.get<ReturnResult<DataField[]>>(`${this.baseUrl}/GetDisplayDataByScreen?screenName=${screen}`);
  }
}
