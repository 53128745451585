import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { Helper } from '../utility/Helper';

@Pipe({
  name: 'contactInfo'
})
export class ContactInfoPipe implements PipeTransform {

  transform(contact: Contact, type: string): string {
    let result = `No ${type} to display`;
    if (contact) {
      switch (type) {
        case 'phone':
          let phone = Helper.getPhoneFromContact(contact);
          if (phone) result = phone;

          break;
        case 'email':
          let email = Helper.getEmailFromContact(contact);
          if (email) result = email;
          break;
      }
    }
    return result;
  }

}
