import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DynamicContentModel } from '../models/dynamic-content.model';
import { ReturnResult } from '../models/return-result';

@Injectable({
  providedIn: 'root'
})
export class DynamicContentService {
  baseUrl = environment.apiDynamicContent;
  constructor(private http: HttpClient) { }
  getDynamicContentByType(type: string): Observable<ReturnResult<DynamicContentModel[]>> {
    return this.http.get<ReturnResult<DynamicContentModel[]>>(`${this.baseUrl}/GetDynamicContentByType?type=${type}`);
  }
}
