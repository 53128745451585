<h2 mat-dialog-title>    What's New?</h2>
<!-- <mat-divider></mat-divider> -->
<mat-dialog-content class="mat-typography">
  <div *ngIf="changelogs.length == 0">
    <div *ngIf="!loading;else skeleton">
      <app-blank-page [message]="'No changelog available'"></app-blank-page>
    </div>
  </div>
  <nb-list *ngIf="!loading">
    <nb-list-item *ngFor="let changelog of changelogs" fxLayout="column" fxLayoutAlign="center stretch">
            <h3 class="custom-h3"  fxLayout="row" fxLayoutAlign="space-between center">
              <span>{{changelog.title}}</span>
              <span>{{changelog.datecreated | date: 'short'}}</span>
            </h3>
            <p>
              <span *ngIf="changelog.changes; else noChanges " [innerHTML]="changelog.changes | safeHtml">
              </span>
              <span *ngIf="changelog.referenceLink">
                <a href="{{changelog?.referenceLink}}" target="_blank"> Learn more</a>
              </span>
            </p>
    </nb-list-item>
  </nb-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="isShowChangeLogs==false" mat-button color="primary" (click)="readOnChangeLogs()">See more</button>
  <button mat-button color="warn" [disabled]="loading" [mat-dialog-close]="false">Read later</button>
  <button mat-button color="primary" [disabled]="loading" [mat-dialog-close]="true" cdkFocusInitial>I understood</button>
</mat-dialog-actions>

<ng-template #skeleton>
  <div>
    <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #noChanges>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <img src="assets/images/not-found.gif" alt="nochanges" style="width:20vw">
  <div style="font-weight: 900;">
    This changelog has no content...
  </div>
  </div>
</ng-template>