import { prop, required } from "@rxweb/reactive-form-validators";

export class EmailCampaignTemplate {
    data: SingleMailBodyTemplate[];
    total: number;
    aggregateResults: string;
    errors: string;
}
export class SingleMailBodyTemplate {
    id: number;
    category: string;
    title: string;
    body: string;
    bodyJson: string;
    note: string;
    deleted: boolean;
    createdDate: string;
    updatedDate: string;
    createdUser: string;
    updatedUser: string;
    emailTemplate: string;
    name: string;

    createNewEmail() {
        return Object.assign({}, {
            id: 0,
            deleted: false
        } as SingleMailBodyTemplate)
    }
}

export class SingleMailBodyTemplateModel {
    @required()
    id: number;
    @prop()
    @required()
    title: string;
    @prop()
    @required()
    name: string;
    @prop()
    @required()
    category: string;
    @prop()
    note: string;
    @prop()
    deleted: boolean;
    @prop()
    createdDate: string;
    @prop()
    updatedDate: string;
    @prop()
    createdUser: string;
    @prop()
    updatedUser: string;
    @prop()
    emailTemplate: string;
}

export class SingleMailBodyResponse {
    success: boolean = false;
    message: string;
}