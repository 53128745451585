<div *ngIf="isDialog==true" class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;">
  <!-- <button mat-button color="primary" (click)="saveData()" [disabled]="!frmUser.valid">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
  </button> -->
  <!-- <button mat-button color="primary" (click)="closeDialog()()">
        <mat-icon>clear</mat-icon>Cancel
    </button> -->
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content style="height: 85%;">
  <div class="col-lg-12">
    <div class="alert alert-warning" role="alert" *ngIf="readonlyContact">
      This contact was created by the another user. You can read-only.
    </div>
    <div class="alert alert-info" role="alert" *ngIf="bypassPermissions">
      This contact was created by the another user. But you can still edit it.
    </div>
    <!-- <div name="saveError" class="alert alert-danger d-flex align-items-center" role="alert"
      *ngIf="saveErrorNotification">
      <div>{{saveErrorMessage}}</div>
      <div class="float-right">
        <button mat-icon-button aria-label="Close the dialog" (click)="closeSaveErrorMessage()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div> -->

  </div>
  <div class="col-lg-12">
    <!-- <mat-card-title style="padding-left: 8px;">
      Contact
    </mat-card-title> -->
    <div *ngFor="let formInstance of formContactLst;  index as i; trackBy: trackByFn">
      <form class="column " [formGroup]="formInstance">
        <mat-card key="i" style="margin-top: 2px;">
          <div class="row  d-flex-row justify-content-between p-2">
            <div class="h6">
              <!-- <span *ngIf="formInstance.get('primaryContact').value == true">
                  <mat-icon style="color: rgb(250, 250, 15);transform: rotate(90deg)"
                    matTooltip="This is a primary contact">
                    vpn_key
                  </mat-icon>
                </span> -->
              <span *ngIf="!isUpdateAction;else contactTitle">
                Contact {{i+1}}
              </span>
              <ng-template #contactTitle>
                <span>
                  {{contactIndex || ""}}
                </span>
              </ng-template>
              <button *ngIf="(isSetPrimaryable && (
                  formInstance.get('primaryContact').value == false || formInstance.get('primaryContact').value == null
                ) && isUpdateAction)" mat-button color="primary" (click)="setPrimaryContact(i); isChange = true"
                [disabled]="loading"
                matTooltip="When this button clicked, the contact will be set as a primary contact">
                Set Primary
              </button>
              <!-- <button *ngIf="formInstance.get('primaryContact').value == true;" mat-button color="warn"
                  [attr.disabled]='loading' (click)="removePrimaryContact(i)"
                  matTooltip="By pressing this button, the contact will be removed the primary">Remove
                  Primary</button> -->
              <mat-chip style="background-color: #007100;" *ngIf="formInstance.get('primaryContact').value == true;"
                [attr.disabled]='loading' matTooltip="This is primary contact of this profile" selected
                [removable]="false" (removed)="removePrimaryContact(i); isChange = true">Primary
                <!-- <mat-icon matChipRemove *ngIf="true">cancel</mat-icon> -->
              </mat-chip>

            </div>
            <div class="row" style="padding-right: 30px;">
              <button *ngIf="isAddMoreBtn && isDupplicatable" mat-raised-button color="primary"
                [attr.disabled]='loading' (click)="duplicate(formInstance); isChange = true">
                <mat-icon>content_copy</mat-icon>Duplicate
              </button>
              <div *ngIf='contactLst.length>1 && i!== 0' style="margin-left: 20px;"></div>
              <button *ngIf='contactLst.length>1 && i!== 0' mat-raised-button color="warn" [attr.disabled]='loading'
                (click)="onDeleteContactBtn(i); isChange = true">
                <mat-icon>remove</mat-icon>Remove
              </button>
            </div>
          </div>
          <div class="row col-lg-12">

            <!-- First Name -->
            <div class="col-lg-4 col-md-6">
              <div fxFlex="30" style="padding-right: 10px">
                <!-- Saluation -->
                <mat-form-field appearance="standard">
                  <mat-label>Saluation</mat-label>
                  <mat-select formControlName="saluation" [disabled]="readonlyContact"
                    (selectionChange)="isChange = true">
                    <mat-option *ngFor="let sal of saluationList" [value]="sal">
                      {{sal}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="70">
                <mat-form-field appearance="standard">
                  <mat-label>First Name</mat-label>
                  <input matInput autocomplete="off" formControlName="contactName" trim="blur"
                    [readonly]="readonlyContact" (change)="refreshTask.emit()">
                </mat-form-field>
              </div>
            </div>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Last Name</mat-label>
              <input matInput autocomplete="off" formControlName="contactLastName" trim="blur"
                [readonly]="readonlyContact" (change)="refreshTask.emit()">
              <!-- <mat-error
                *ngIf="formInstance.controls.contactLastName.errors && (formInstance.controls.contactLastName.dirty || formInstance.controls.contactLastName.touched)">
                {{formInstance.controls.contactLastName['errorMessage']}}</mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Contact Company</mat-label>
              <input matInput formControlName="relationship" (keyup)="onChangesCompany($event)"
                [matAutocomplete]="autoCompany" [readonly]="readonlyContact">
              <mat-autocomplete #autoCompany="matAutocomplete">
                <mat-option *ngFor="let dynamic of filterArray" [value]="dynamic?.content">
                  {{dynamic?.content}}
                </mat-option>
              </mat-autocomplete>
              <!-- <mat-error
                *ngIf="formInstance.controls.relationship.errors && (formInstance.controls.relationship.dirty || formInstance.controls.relationship.touched)">
                {{formInstance.controls.relationship['errorMessage']}}</mat-error> -->
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Phone</mat-label>
              <input matInput autocomplete="off" formControlName="contactPhone" trim="blur"
                [readonly]="readonlyContact">
              <!-- <mat-error
                *ngIf="formInstance.controls.contactPhone.errors && (formInstance.controls.contactPhone.dirty || formInstance.controls.contactPhone.touched)">
                {{formInstance.controls.contactPhone['errorMessage']}}</mat-error> -->
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Email</mat-label>
              <input matInput autocomplete="off" formControlName="contactEmail" [readonly]="readonlyContact">
              <mat-error
                *ngIf="formInstance.controls.contactEmail.errors && (formInstance.controls.contactEmail.dirty || formInstance.controls.contactEmail.touched)">
                {{formInstance.controls.contactEmail['errorMessage']}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" class="col-lg-4 col-md-6" trim="blur">
              <mat-label>Job Title</mat-label>
              <input matInput autocomplete="off" formControlName="jobTitle" [readonly]="readonlyContact">
            </mat-form-field>
            <!-- 2022-09-12 tienlm add start -->
            <!-- Account name -->
            <!-- <mat-form-field appearance="standard" class="col-lg-4 col-md-6" trim="blur">
              <mat-label>Account Name</mat-label>
              <input matInput autocomplete="off" formControlName="accountName" [readonly]="readonlyContact">
            </mat-form-field> -->
            <!-- Departement -->
            <mat-form-field appearance="standard" class="col-lg-4 col-md-4" trim="blur">
              <mat-label>Department</mat-label>
              <input #departmentInput matInput [matAutocomplete]="departmentTypeInput.autocomplete"
                formControlName="department" [readonly]="readonlyContact">

              <mat-spinner *ngIf="typeLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px">
              </mat-spinner>

              <app-primas-auto-complete #departmentTypeInput="primasAutoComplete" [searchAPI]="typeDynamicAPI"
                [input]="departmentInput" [searchProperty]="'content'" [keyProperty]="'content'"
                [valueProperty]="'content'" (toggleLoading)="getLoadingValueType($event)">
              </app-primas-auto-complete>
            </mat-form-field>
            <!-- Birth date -->
            <mat-form-field appearance="standard" class="col-lg-4 col-md-4" trim="blur">
              <mat-label>Birthday</mat-label>
              <input matInput [matDatepicker]="birhdatePicker" (dateChange)="isChange = true"
                formControlName="birthDate" [readonly]="readonlyContact">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="birhdatePicker">
                <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #birhdatePicker [disabled]="readonlyContact">
              </mat-datepicker>
            </mat-form-field>
            <!-- 2022-09-12 tienlm add start -->
            <!-- Source -->
            <mat-form-field appearance="standard" class="col-lg-4 col-md-4" trim="blur">
              <mat-label>Source</mat-label>
              <input #sourceInput matInput [matAutocomplete]="sourceTypeInput.autocomplete" formControlName="source"
                [readonly]="readonlyContact">

              <mat-spinner *ngIf="sourceLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px">
              </mat-spinner>

              <app-primas-auto-complete #sourceTypeInput="primasAutoComplete" [searchAPI]="sourceDynamicAPI"
                [input]="sourceInput" [searchProperty]="'content'" [keyProperty]="'content'" [valueProperty]="'content'"
                (toggleLoading)="getLoadingValueSource($event)">
              </app-primas-auto-complete>
            </mat-form-field>

            <app-contact-tag [contactTag]="formInstance.get('tags').value"
              (contactTagSelected)="selectTagContact($event, formInstance); isChange = true" class="col-lg-12">
            </app-contact-tag>

            <mat-form-field appearance="standard" class="col-lg-12">
              <mat-label>Contact Type</mat-label>
              <mat-select formControlName="listContactTypeId" required [disabled]="readonlyContact" multiple
                (selectionChange)="isChange = true">
                <mat-option *ngFor="let item of arrayContactType" [value]="item.contactTypeId">
                  {{item.contactTypeName}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="formInstance.controls.listContactTypeId.errors && (formInstance.controls.listContactTypeId.dirty || formInstance.controls.listContactTypeId.touched)">
                {{formInstance.controls.listContactTypeId['errorMessage']}}</mat-error>
            </mat-form-field>

            <!--Relationship -->
            <app-search-type-objects #relationshipToObj class="col-lg-12"
              [apiGetSelectedObjects]="apiGetSelectedObjects" (selectedObjectsValue)="isChange = true">
            </app-search-type-objects>

            <div class="col-lg-12" style="margin-bottom: 4.34375em;">
              <mat-label class="custom-label">Description</mat-label>
              <quill-editor class="content-editor" [modules]="editorOptions" placeholder="Click to add a description..."
                formControlName="description" style="min-height: 200px;">
              </quill-editor>
            </div>
            <!-- location block -->
            <div class="row  d-flex-row justify-content-between p-2">
              <div class="h6">
                Location
              </div>
            </div>
            <div class="row col-lg-12">
              <mat-form-field appearance="standard" class="col-lg-12 col-md-6">
                <mat-label>Address 1</mat-label>
                <input matInput autocomplete="off" formControlName="address" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-lg-12 col-md-6">
                <mat-label>Address 2</mat-label>
                <input matInput autocomplete="off" formControlName="address2" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                <mat-label>City</mat-label>
                <input matInput autocomplete="off" formControlName="city" trim="blur" [readonly]="readonlyContact">
                <!-- <mat-error
                  *ngIf="formInstance.controls.city.errors && (formInstance.controls.city.dirty || formInstance.controls.city.touched)">
                  {{formInstance.controls.city['errorMessage']}}</mat-error> -->
              </mat-form-field>
              <app-dynamic-content-form class="col-lg-4 col-md-6" style="width: 100%" label="State/Province"
                [options]="stateOptions" [displayKey]="'content'" [control]="formInstance.controls.state">
              </app-dynamic-content-form>
              <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                <mat-label>Country</mat-label>
                <input type="text" placeholder="Select your Country" matInput [matAutocomplete]="auto"
                  formControlName="country" [readonly]="readonlyContact">
                <mat-error
                  *ngIf="formInstance.controls.country.errors && (formInstance.controls.country.dirty || formInstance.controls.country.touched)">
                  {{formInstance.controls.country['errorMessage']}}</mat-error>
                <mat-autocomplete #auto="matAutocomplete" (closed)="checkCountryMatchOnBlur(formInstance)"
                  (optionSelected)="isChange = true">
                  <ng-container *ngIf="countries != null; else loadingDataCountries">
                    <mat-option *ngFor="let country of contactLst[i].countryObservable | async" [value]="country.value">
                      {{country.value}}
                    </mat-option>
                  </ng-container>
                  <ng-template #loadingDataCountries>
                    <mat-option [nbSpinner]="true"></mat-option>
                  </ng-template>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                <mat-label>Postal/Zip code</mat-label>
                <input matInput autocomplete="off" formControlName="zipcode" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                <mat-label>Region</mat-label>
                <input matInput autocomplete="off" formControlName="region" [readonly]="readonlyContact">
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                <mat-label>Nearest major city</mat-label>
                <input matInput autocomplete="off" formControlName="nearestBigCity" [readonly]="readonlyContact">
              </mat-form-field>
            </div>
            <!-- end of location block -->
            <!-- optional -->
            <mat-expansion-panel class="col-lg-12">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Optional
                </mat-panel-title>
                <mat-panel-description>
                  Include your address and social accounts
                </mat-panel-description>
              </mat-expansion-panel-header>
              <!-- content -->
              <div class="row ">
                <mat-form-field appearance="standard" class="col-lg-5">
                  <mat-label>Skype</mat-label>
                  <input matInput autocomplete="off" formControlName="skype" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-lg-5">
                  <mat-label>WhatsApp</mat-label>
                  <input matInput autocomplete="off" formControlName="whatsApp" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-lg-5 ">
                  <mat-label>Facebook</mat-label>
                  <input matInput autocomplete="off" formControlName="facebook" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-lg-5 ">
                  <mat-label>Tiktok</mat-label>
                  <input matInput autocomplete="off" formControlName="tiktok" [readonly]="readonlyContact">
                </mat-form-field>
                <!-- <mat-form-field appearance="standard" class="col-lg-5 ">
                    <mat-label>Title</mat-label>
                    <input matInput autocomplete="off" formControlName="title">
                  </mat-form-field> -->
                <mat-form-field appearance="standard" class="col-lg-5">
                  <mat-label>Secondary telephone</mat-label>
                  <input matInput autocomplete="off" formControlName="secondaryPhone" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-lg-5">
                  <mat-label>Mobile/Cell Phone</mat-label>
                  <input matInput autocomplete="off" formControlName="cellPhone" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-lg-5">
                  <mat-label>Secondary Email</mat-label>
                  <input matInput autocomplete="off" formControlName="secondaryEmail" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-lg-5">
                  <mat-label>Website</mat-label>
                  <input matInput autocomplete="off" formControlName="website" [readonly]="readonlyContact">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-lg-5" *nbIsGranted="['view', 'linked-in-contact']">
                  <mat-label>LinkedIn</mat-label>
                  <input matInput autocomplete="off" formControlName="linkedIn" [readonly]="readonlyContact">
                </mat-form-field>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </form>
      <br>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" style="background-color: white;">
    <div class="row-lg-8">
      <!-- <button *ngIf="contactLst.length>1" mat-raised-button color="warn" class="float-right"
          (click)="onDeleteContactBtn()">
          <mat-icon>remove</mat-icon>Delete
        </button> -->
      <button *ngIf="isAddMoreBtn" mat-raised-button color="primary" class="float-right"
        (click)="onAddContactBtn(); isChange = true" [attr.disabled]='loading'
        matTooltip="By pressing this button, new contact will be showed">
        <mat-icon>add</mat-icon>More
      </button>
    </div>
    <div class="col-lg-4">
      <button mat-raised-button color="primary" class="float-right" (click)="onSave()" [nbSpinner]="loading"
        [disabled]="readonlyContact || loading" nbSpinnerStatus="primary">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
      </button>
    </div>
  </mat-toolbar>
</div>