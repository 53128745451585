<mat-form-field color="primary" [appearance]="appearance">
  <mat-select [formControl]="bankMultiCtrl" placeholder="{{columnName}}" [multiple]="multiple" #multiSelect
    (openedChange)="changeValue($event)">
    <mat-option>
      <ngx-mat-select-search noEntriesFoundLabel="No matching item found" placeholderLabel=""
        [showToggleAllCheckbox]="checkAll" [toggleAllCheckboxIndeterminate]="isIndeterminate"
        [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleSelectAll($event)"
        [formControl]="bankMultiFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    <ngx-skeleton-loader *ngIf="!filterOption" count="1" appearance="line"
      [theme]="{ height: '40px', 'margin-bottom': '0px' }" fxFlexFill>
    </ngx-skeleton-loader>
    <mat-option *ngFor="let item of filteredBanksMulti | async" [value]="item" style="margin-top: 8px">
      {{item[filter.displayText]}}
    </mat-option>
  </mat-select>
</mat-form-field>