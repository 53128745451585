import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { EmailTemplate } from 'src/app/modules/admin/setting-management/setting-campaign/email-template';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { EditorEmailComponent } from 'src/app/shared/components/stand-alone-component/editor-email/editor-email.component';
import { EmailQuillEditorComponent } from 'src/app/shared/components/stand-alone-component/email-quill-editor/email-quill-editor.component';
import { TableEntity } from 'src/app/shared/enums/table-entities.enum';
import { BodyEmbedded } from 'src/app/shared/models/body-embedded.model';
import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { SettingService } from 'src/app/shared/services/setting.service';
import { ProfileDetailModel } from '../../../profile-detail.model';
import { SingleMailBodyTemplate } from '../campaign-linkscope-email.model';
import { CampaignLinkscopeService } from '../campaign-linkscope.service';

@Component({
  selector: 'app-add-new-email',
  templateUrl: './add-new-email.component.html',
  styleUrls: ['./add-new-email.component.scss']
})
export class AddNewEmailComponent implements OnInit, OnDestroy {

  editorEmail: EditorEmailComponent;
  @ViewChild('editorEmail', { static: false }) set setEditorEmail(item: EditorEmailComponent) { if (item) this.editorEmail = item; };

  editorQuill: EmailQuillEditorComponent
  @ViewChild('quillEditor', { static: false }) set setEditorQuill(item: EmailQuillEditorComponent) { if (item) this.editorQuill = item };

  @Input() profileModel: ProfileDetailModel;
  @Input() emailSubject: string = '';
  @Input() isNormalEditor: boolean = false;
  // 2022-05-11 tienlm add start
  @Input() buyerModel: BuyerModel;
  @Input() entity = 'profile';
  @Input() obsEmailTemplate: Observable<ReturnResult<string>>;
  @Input() bodyEmbedded: BodyEmbedded = null;
  @Input() userModel: UserModel;
  @Input() isOpenEditor: boolean = true;
  // 2022-06-15 tienlm add start
  @Input() targetEmail: string;
  @Input() isClassicEditor: boolean = true;
  @Input() isEmbedded: boolean = true;
  @Input() emailTemplate: EmailTemplate;
  // 2022-06-15 tienlm add end
  @Output() closeDialog = new EventEmitter<any>();

  // 2022-05-11 tienlm add end
  frmTitle: FormGroup = this.frmBuilder.group({
    title: ['', RxwebValidators.required()]
  })


  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private frmBuilder: RxFormBuilder,
    private campaignService: CampaignLinkscopeService,
    private toast: NbToastrService,
    private settingService: SettingService
  ) { }

  ngOnInit(): void {
    this.frmTitle.patchValue({ title: this.emailSubject });
    // default obsEmailTemplate null: then set to default email template Altus:
    if (!this.obsEmailTemplate) {
      this.obsEmailTemplate = this.settingService.getSettingEmailTemplateCampaign();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //Note: Can't add destroy to this function
  sendEmail() {
    if (this.frmTitle.valid) {
      if (!this.isNormalEditor) this.saveEmailWithUnLayer();
      else this.saveEmailWithQuill();
    }
  }

  saveEmailWithUnLayer() {
    this.editorEmail.exportHtml();
    this.editorEmail.getEmailHtml().subscribe(data => {
      this.sendEmailWithEditor(data);
    });
  }

  saveEmailWithQuill() {
    var data = this.editorQuill.getEmailHtmlQuill();
    this.sendEmailWithEditor(data);
  }

  sendEmailWithEditor(data: any) {
    if (data) {
      var bodyEmail = data.html;
      var titleEmail = this.frmTitle.controls['title'].value;
      if (this.entity.toLowerCase() == TableEntity.Profile.toLowerCase()) {
        this.campaignService.sendWithEditorEmail(this.profileModel, titleEmail, bodyEmail).subscribe(resp => {
          if (resp.result) {
            this.toast.success(`Send Email for ${this.profileModel.displayName} successfully`, 'Success');
          } else {
            this.toast.danger(`Send Email for ${this.profileModel.displayName} fail`, 'Error');
          }
        });
      } else if (this.entity.toLowerCase() == TableEntity.Buyer.toLowerCase()) {
        this.campaignService.sendWithEditorEmailByBuyerId(this.buyerModel, titleEmail, bodyEmail).subscribe(resp => {
          if (resp.result) {
            this.toast.success(`Send Email for ${this.buyerModel.companyName || 'Buyer'} successfully`, 'Success');
          } else {
            this.toast.danger(`Send Email for ${this.buyerModel.companyName || 'Buyer'} fail`, 'Error');
          }
        });
      }
      // send specific email
      else if (this.entity.toLowerCase() == TableEntity.NoEntity.toLowerCase()) {
        this.campaignService.sendEmailIndividual(titleEmail, bodyEmail, this.targetEmail).subscribe(res => {
          if (res.result) {
            this.toast.success(`Send Email to ${this.targetEmail} successfully`, 'Success');
          }
        });
      }
    } else {
      this.toast.danger(`Can't get body email from library!`, 'Error');
    }
  }
  // 2022-05-16 tienlm add start
  async sendSupportTicket(userModel: UserModel) {
    if (this.frmTitle.valid) {
      if (!userModel) {
        this.toast.warning('Cannot get current user, please try to refresh the page', 'Warning');
        return false;
      }
      this.editorEmail.exportHtml();
      this.editorEmail.getEmailHtml().subscribe(data => {
        if (data) {
          //let removedTrackString = data.html.substring(0, data.html.lastIndexOf("</html>") + 7);
          var bodyEmail = data.html;
          var titleEmail = this.frmTitle.controls['title'].value;
          this.campaignService.sendSupportTicketEmail(null, userModel, titleEmail, bodyEmail).pipe(shareReplay(1)).subscribe(resp => {
            if (resp.result) {
              this.toast.success(`Send support email successfully`, 'Success');
              this.closeDialog.emit();
              return true;
            } else {
              this.toast.danger(`Send support email fail`, 'Error');
              return false;
            }
          });
        }
        return false;
      });
    }
  }

  setTitleFrm(data) {
    if (data) this.frmTitle.controls['title'].setValue(data ?? '');
  }
}
