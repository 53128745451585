import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { KeyPairsValueSelectDropDown } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-multi-select-drop-down',
  templateUrl: './multi-select-drop-down.component.html',
  styleUrls: ['./multi-select-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectDropDownComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() inputValues: KeyPairsValueSelectDropDown[] | Observable<KeyPairsValueSelectDropDown[]> = [];
  @Input() readonly: boolean = false;
  @Input() id: string;
  @Input() selectedValues: any[] = [];
  @Input() isMatChip: boolean = false;
  @Input() placeholder: string = "Please select values..."
  @Input() maxHeight: string = '';
  @Input() isMultiple: boolean = true;
  @Input() isTooltip: boolean = false;
  @Input() hiddenUnderLine : boolean = true;
  @Input() disabledFirstObject : boolean = false;
  @Input() alwayChoose: string = null;
  @Output() selected: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedObj: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  constructor() { }

  isLoading: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  values: KeyPairsValueSelectDropDown[] = [];
  filteredBanksCache: KeyPairsValueSelectDropDown[] = [];
  isChecked: boolean = false;
  isIndeterminate: boolean = false;
  public bankMultiFilterCtrl: FormControl = new FormControl();
  public filteredBanksMulti: ReplaySubject<KeyPairsValueSelectDropDown[]> = new ReplaySubject<KeyPairsValueSelectDropDown[]>(1);
  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.isLoading = true;
    if (this.inputValues) {
      if (this.inputValues instanceof Observable) {
        this.inputValues.subscribe(resp => {
          if (resp && resp.length > 0) this.values = resp;
          this.updateDataFilter()
          this.isLoading = false;
        })
      } else {
        this.values = this.inputValues;
        this.isLoading = false;
        this.updateDataFilter()
      }

    }
  }

  ngAfterViewInit(): void {
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
        this.setToggleAllCheckboxState();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputChanges = changes.selectedValues;
    if (inputChanges && !inputChanges.firstChange && JSON.stringify(inputChanges.previousValue) !== JSON.stringify(inputChanges.currentValue)) {
      this.selectedValues = inputChanges.currentValue;
    }
  }

  protected filterBanksMulti() {
    if (!this.values) {
      return;
    }
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.updateDataFilter();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksCache = this.values.filter(bank => bank.value.toLowerCase().indexOf(search) > -1);
    this.filteredBanksMulti.next(this.filteredBanksCache);
  }

  updateDataFilter() {
    this.filteredBanksCache = this.values.slice();
    this.filteredBanksMulti.next(this.filteredBanksCache);
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredBanksMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) this.selectedValues = val.map(x => x.key).slice();
        else this.selectedValues = this.alwayChoose ? [this.alwayChoose] : [];
        this.selectedValues = [...this.selectedValues];
      });
  }

  protected setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.selectedValues && this.selectedValues.length >= 0) {
      this.filteredBanksCache.forEach(el => {
        if (this.selectedValues.indexOf(el.key) > -1) {
          filteredLength++;
        }
      });

      this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredBanksCache.length;
      this.isChecked = filteredLength > 0 && filteredLength === this.filteredBanksCache.length;
    }
  }

  getListSelectMatChip(arr: any[]) {
    var listValue = [] as KeyPairsValueSelectDropDown[];
    if (arr && arr.length > 0) {
      listValue = this.values.filter(x => arr.includes(x.key)).slice();
    }
    return listValue
  }

  removeChip(event: KeyPairsValueSelectDropDown) {
    if (event && this.selectedValues.length > 0) {
      this.selectedValues = this.selectedValues.filter(x => event.key != x);
      this.selected.emit(this.selectedValues);
    }
  }

  onBlurSelect() {
    this.selected.emit(this.selectedValues);
    if(this.selectedObj) {
      this.selectedObj.emit(this.getListSelectMatChip([this.selectedValues]));
    }
  }
}
