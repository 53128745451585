import { PagingSearchLocation } from 'src/app/modules/admin/profile-management/grid-nearby-profile/paging-search-location';
import { Page } from './page';

/**
 * An array of data with an associated page object used for paging
 */
export class PagedData<T> {
    data = new Array<T>();
    page = new Page();
}

export class PagedDataNearbyProfile<T> {
    data = new Array<T>();
    page = new PagingSearchLocation();
}